import React from 'react'
import './BenefitsHealthStyle.css'
import whitetick from '../assets/check-bg-white.svg'
import healthbenefits from '../assets/health-benefits.webp'

const BenefitsHealth = () => {
    const benefitsPoints = [
        {
            icon: whitetick,
            points: 'রোগ প্রতিরোধ ক্ষমতা বাড়ান'
        },
        {
            icon: whitetick,
            points: 'পাচনতন্ত্র উন্নত করুন'
        },
        {
            icon: whitetick,
            points: 'আপনার মন এবং শরীর শিথিল করুন'
        },
        {
            icon: whitetick,
            points: 'চাপ এবং উদ্বেগ কমান'
        },
        {
            icon: whitetick,
            points: 'কোনো ওষুধ ছাড়াই মাথাব্যথা থেকে মুক্তি পান'
        },
        {
            icon: whitetick,
            points: 'ঘুমের মান উন্নত করুন'
        },
        {
            icon: whitetick,
            points: 'ক্ষতিকারক নেশা গুলি ত্যাগ করুন'
        },
        {
            icon: whitetick,
            points: 'ভালো অভ্যাস গড়ে তুলবেন'
        },
    ]



    return (
        <>
            <section className='benefitsHealthSection ptop pbtm silvaDiffSection'>
                <div className='container'>
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-5">
                            <h2 class="h2 fw600 poppins primaryWhite mb-3 mb-lg-4">
                                এই মাস্টারক্লাসে যোগদানের সুবিধা
                            </h2>
                            <ul className='resetUl'>
                                {
                                    benefitsPoints?.map((items, idx) =>
                                        <li class="d-flex align-items-start text-start gap-2 mb-2">
                                            <img src={items.icon} alt="#" class="circleTick" />
                                            <p class="primaryWhite roboto fw300 mb-0">{items.points}</p>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                        <div class="col-lg-6 col-xl-7">
                            <div class="videoArea ms-auto">
                                <img src={healthbenefits} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BenefitsHealth