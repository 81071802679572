// import useRazorpay from "react-razorpay";
import { handleLeadFormSubmit } from "./PaymentHelper";
import toast from "react-hot-toast";
import Swal from "sweetalert2";




export const handleRazorPayPayment = (formData, orderId, setFormData, thankYouPageUrl, Razorpay) => {
    var options = {
        "key": process.env.REACT_APP_RAZORPAY_KEY,
        // "key": "rzp_live_vzB1GK1MVQTtsU",
        name: "Payment",
        description: "Payment Method",
        image: "https://silvamethod.com/img/logo/title-logo-removebg-preview.png",
        order_id: orderId,
        handler: async function (response) {
            if (response?.razorpay_payment_id) {
                const capturePayment = await
                    handleLeadFormSubmit("orders/capture-order", "POST", {
                        "paymentId": response?.razorpay_payment_id,
                    }, {});

                // fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                //     method: "POST",
                //     body: JSON.stringify({
                //         "paymentId": response?.razorpay_payment_id,
                //     }),
                //     headers: {
                //         "Content-Type": "application/json",
                //     }
                // });
                // console.log(capturePayment,'captureRes')

                // const captureRes = await capturePayment?.json();
                console.log(capturePayment, 'captureRes')

                if (capturePayment?.success) {
                    // toast.success("Payment Successful",{
                    //   position:"bottom-center"
                    // });
                    console.log("pay success")
                    setFormData({
                        fname: '',
                        mobile: '',
                        email: '',
                        country: ''

                    });
                    window.location.href = `${thankYouPageUrl}`;
                }
            }
        },
        "modal": {
            "ondismiss": async function () {
                // console.log(res,"failed response");
                const captRes = await
                    handleLeadFormSubmit("orders/capture-failed-payment", "POST", {
                        "order_id": orderId,
                    }, {});

                // fetch('https://silvamethod.in:5000/api/v1/orders/capture-failed-payment', {
                //     method: "POST",
                //     body: JSON.stringify({
                //         "order_id": order?.order_id,
                //     }),
                //     headers: {
                //         "Content-Type": "application/json",
                //     }
                // });
                // const captRes = await capt.json();
                console.log(captRes, 'failed res')

                if (captRes?.success) {
                    // toast.error("Payment Failed", {
                    //     position: "bottom-center"
                    // })
                    // console.log("payment failed")
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Payment Failed",
                        text: "Your last payment failed. Try again later",
                    });
                }
            }
        },
        prefill: {
            name: formData.name,
            email: formData.email,
            contact: formData.whatsapp_number,
            country: formData.country || "India",
            timeslot: formData.timeslot || "NA",
        },
        notes: {
            address: "The Silva Method India",
        },
        theme: {
            color: "#A500FF",
        },
    }


    const rzp1 = new Razorpay(options);


    rzp1.on("payment.failed", async function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);

        if (response?.error?.reason === "payment_failed") {
            const body = JSON.stringify({
                "paymentId": response?.error?.metadata?.payment_id,
            })
            const capturePayment = await handleLeadFormSubmit("orders/capture-order", "POST", body, {})
            // fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
            //     method: "POST",
            //     body: JSON.stringify({
            //         "paymentId": response?.error?.metadata?.payment_id,
            //     }),
            //     headers: {
            //         "Content-Type": "application/json",
            //     }
            // });
            if (capturePayment?.success) {
                const modalElement = document.querySelector('.razorpay-container');
                if (modalElement) {
                    modalElement.style.display = 'none';
                }

            }
            window.location.href = '/payment-failure';
        }
    });

    Swal.fire({
        position: "center",
        icon: "warning",
        title: "Caution",
        text: "After successful payment please wait until you go to the payment successful page. DO NOT close or refresh the page before that.",
        timer: 6000,
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading();
        },
    });

    setTimeout(() => {
        rzp1.open();
    }, 6000)
}