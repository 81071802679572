import React from 'react'
import './CourseForWhoseStyle.css'
const CourseForWhose = ({ bgColor, startText, spanText, endText, imgPos, img, pointsArr, ptop, sectionBtn }) => {
    return (
        <>
            <section className={`courseForWhose ${ptop ? "ptop" : ""} pbtm ${bgColor}`}>
                <div className="container">
                    <div className={`row ${imgPos === "left" ? "flex-row-reverse" : ""} align-items-center row-gap-5`}>
                        <div className="col-lg-6">
                            <div className="leftArea">
                                <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-4">
                                    {startText}
                                    <span class="gradientText">{spanText}</span>
                                    {endText}
                                </h2>
                                <ul className='resetUl'>
                                    {
                                        pointsArr?.map((item, idx) =>
                                            <li class={`d-flex align-items-start gap-2 ${item.hidemargin ? "" : "mb-3"}`}>
                                                {
                                                    item.checkicon &&
                                                    <img src={item.checkicon} alt="#" loading="lazy" class="circleTick" />
                                                }
                                                <p class="textDark roboto fw500 mb-0">{item.points}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                                {
                                    sectionBtn || null
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className={`rightArea ${imgPos === "left" ? "ms-0" : ""}`}>
                                <img src={img} alt="#" loading='lazy' className='w100 h100' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CourseForWhose