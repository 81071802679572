import React, { useEffect, useState } from 'react';
import "./TypeSettingStyle.css";
import LeafImage from "../assets/leaf.webp"
import RazorpayIcon from "../assets/RAZORPAY-ICON.webp"
import { Button, Modal } from 'react-bootstrap';
import useRazorpay from 'react-razorpay';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { validatePhoneNumber } from '../utils/Helper';



const TypeSetting = () => {

  const cardData = [
    {
      id: 1,
      price: '1000',
      time: '1 Hour Counselling Session',
      productId: 'CONSULT_1000'
    },
    {
      id: 2,
      price: '1200',
      time: '1.5 Hour Counselling Session',
      productId: 'CONSULT_1200'
    },
    {
      id: 3,
      price: '1800',
      time: '2 Hour Counselling Session',
      productId: 'CONSULT_1800'
    },
    {
      id: 4,
      price: '0',
      time: '15 Minutes Free Counselling Session',
      productId: 'CONSULT_FREE'
    },
  ]



  const [show, setShow] = useState({ status: false, id: "" });
  const [modalData, setModalData] = useState(null)



  const [formData, setFormData] = useState({
    fname: '',
    phn: '',
    email: '',
    query: ''
  });

  const [error, setError] = useState('');
  // console.log('formdata', formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const [isChecked, setIsChecked] = useState(true);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };



  const handleClose = () => setShow({ status: false, id: "" });
  const handleShow = (id) => setShow({ status: true, id });


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!formData.fname || !formData.phn || !formData.email || !formData.query) {
      setError('All fields are required');
      return;
    }
    const isValid = validatePhoneNumber(formData.phn)
    // console.log(isValid, "sukanta");
    if (!isValid) {
      return toast.error("Please enter a valid Phone Number")
    }
    try {
      console.log('i am inside try');
      const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-consultancy-lead', {
        method: "POST",
        body: JSON.stringify({
          "name": formData.fname,
          "email": formData.email,
          "whatsapp_number": formData.phn,
          "problem": formData.query,
          "product_id": modalData?.productId
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });

      const resData = await response.json();
      console.log('Response:', resData);
      // Reset form fields after successful submission
      //   launchRazorpayPayment11(formData);
      if (resData?.success) {
        if (Number(modalData?.price > 0)) {
          await handlePayment(formData);

        } else {
          // handleClose();
          console.log("ami achi")
          toast.success(resData?.message || 'Form has been submitted successfully....')
        }
        setFormData({
          fname: '',
          phn: '',
          email: '',
          query: ''
        });
        setError('');
        handleClose();
      }

    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };




  // ------------code for payment--------------------------------
  const [Razorpay] = useRazorpay();
  const handlePayment = async (formData) => {
    try {
      const createOrder = await fetch('https://silvamethod.in:5000/api/v1/orders/create-order', {
        method: "POST",
        body: JSON.stringify({
          product_id: `${modalData?.productId}`,
          // product_id: "test01",
          user_id: `${formData.fname}-${formData.email}-${formData.phn}`,
          purchase_type: "course"
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });
      const order = await createOrder.json();
      // console.log(order,"order created") 
      if (order?.order_id) {
        var options = {
          "key": process.env.REACT_APP_RAZORPAY_KEY,
          name: "Payment",
          description: "Payment Method",
          image: "https://silvamethod.com/img/logo/title-logo-removebg-preview.png",
          order_id: order?.order_id,
          handler: async function (response) {
            if (response?.razorpay_payment_id) {
              const capturePayment = await
                //   Apirequests("orders/capture-order","POST",{
                //   "paymentId": response?.razorpay_payment_id,
                //  },{"Content-Type": "application/json"});
                fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                  method: "POST",
                  body: JSON.stringify({
                    "paymentId": response?.razorpay_payment_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              // console.log(capturePayment,'captureRes')
              const captureRes = await capturePayment?.json();
              console.log(captureRes, 'captureRes')
              console.log(response, 'Response')
              if (captureRes?.success) {
                // toast.success("Payment Successful",{
                //   position:"bottom-center"
                // });
                setFormData({
                  fname: '',
                  mobile: '',
                  email: '',
                  query: ''
                });
                console.log("pay success")
                window.location.href = '/payment-successful';
              }
            }
          },
          "modal": {
            "ondismiss": async function () {
              // console.log(res,"failed response");
              const capt = await
                // Apirequests("orders/capture-failed-payment","POST",{
                //   "order_id": order?.order_id,
                // },{ "Content-Type": "application/json"})
                fetch('https://silvamethod.in:5000/api/v1/orders/capture-failed-payment', {
                  method: "POST",
                  body: JSON.stringify({
                    "order_id": order?.order_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              const captRes = await capt.json();
              console.log(captRes, 'failed res')
              if (capt?.success) {
                // toast.error("Payment Failed",{
                //   position:"bottom-center"
                // })
                console.log("payment failed")
              }
            }
          },
          prefill: {
            name: formData.fname,
            email: formData.email,
            contact: formData.phn,
          },
          notes: {
            address: "The Silva Method India",
          },
          theme: {
            color: "#A500FF",
          },
        }


        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", async function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);
          if (response?.error?.reason === "payment_failed") {
            const capturePayment = await
              // Apirequests("orders/capture-order","POST",{
              //   "paymentId": response?.error?.metadata?.payment_id,
              //  },{"Content-Type": "application/json"});
              fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                method: "POST",
                body: JSON.stringify({
                  "paymentId": response?.error?.metadata?.payment_id,
                }),
                headers: {
                  "Content-Type": "application/json",
                }
              });
            if (capturePayment?.success) {
              const modalElement = document.querySelector('.razorpay-container');
              if (modalElement) {
                modalElement.style.display = 'none';
              }
            }
            window.location.href = '/payment-failure';
          }
        });
        // rzp1.open();
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Caution",
          text: "After successful payment please wait until you go to the payment successful page. DO NOT close or refresh the page before that.",
          timer: 6000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        setTimeout(() => {
          rzp1.open();
        }, 6000)
      }
      // setApiOrder(order); 
    } catch (error) {
      console.log("error:", error);
    }

    // console.log(process.env.REACT_APP_RAZORPAY_KEY,"payment key")
  };
  // ----------code for payment ends------------------------------

  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.querySelector('.btnSubmit');
    if (btn) {
      if (!isFormFilled) {
        btn.style.opacity = 0.4
      } else {
        btn.style.opacity = 1
      }
    }
    // console.log(isFormFilled, 'heloooooooooooooo')
  }, [isFormFilled, show.status])


  useEffect(() => {
    const sObj = cardData?.filter((data, i) => data.id === show.id);
    if (sObj?.length) {
      setModalData(sObj[0])
    }
  }, [show.id])






  return (
    <div>
      <section className="industrySection ptop pbtm" id='industrySection'>
        <div className="rightObj d-none d-lg-block">
          <img src={LeafImage} alt="#" loading="lazy" className="w100 h100" />
        </div>
        <div className="container">
          <div className="industryCont text-center">
            <p className="title fw600 poppins textDark mb-2">
              1 to 1 Counselling
            </p>
            <h2 className="h2 fw700 poppins textDark text-capitalize mb-4">
              Expert <span className="gradientText">Consultancy</span>
            </h2>
            <div className="d-flex align-items-start justify-content-center gap-3">
              <span className="spanNum">1</span>
              <p className="mb-0 p1 poppins">
                Receive a FREE 15-minute counselling session with one of our certified
                instructors via Zoom to help address any challenges you're facing.
              </p>
            </div>
            <button className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape my-4 border-0 shadowLight" onClick={() => handleShow(4)}>
              Get Free Counselling
            </button>
            <div className="d-flex align-items-start justify-content-center gap-3 mb-0">
              <span className="spanNum">2</span>
              <p className="mb-0 p1 poppins">
                If you find the free 15-minute consultation beneficial and desire more time for
                personalized support, you can schedule hourly counselling sessions.
              </p>
            </div>
            <div className="bookCard">
              <div className="outerBorder">
                <div className="cardItem">
                  <h3 className="h2 fw600 primaryWhite roboto mb-1">1 Hour</h3>
                  <p className="fw500 roboto primaryWhite">
                    Counselling Session
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">1000</span>
                    </p>
                    <p className="title oldPrice primaryWhite mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">2000</span>
                    </p>
                  </div>
                  <button className="cardBtn" onClick={() => handleShow(1)} >
                    Book Now
                  </button>
                </div>
              </div>
              <div className="outerBorder">
                <div className="cardItem">
                  <h3 className="h2 fw600 primaryWhite roboto mb-1">1.5 Hour</h3>
                  <p className="fw500 roboto primaryWhite">
                    Counselling Session
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">1200</span>
                    </p>
                    <p className="title oldPrice primaryWhite mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">2400</span>
                    </p>
                  </div>
                  <button className="cardBtn" onClick={() => handleShow(2)}>
                    Book Now
                  </button>
                </div>
              </div>
              <div className="outerBorder">
                <div className="cardItem">
                  <h3 className="h2 fw600 primaryWhite roboto mb-1">2 Hour</h3>
                  <p className="fw500 roboto primaryWhite">
                    Counselling Session
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">1800</span>
                    </p>
                    <p className="title oldPrice primaryWhite mb-0">
                      <span className="me-1">₹</span>
                      <span className="fw600 roboto">3600</span>
                    </p>
                  </div>
                  <button className="cardBtn" onClick={() => handleShow(3)} >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
            <p className="mb-5 poppins">
              (You will get - Expert consultation + Free Guided Meditation Session + Practice Module)
            </p>
            <div className="d-flex align-items-start justify-content-center gap-3 mb-4">
              <span className="spanNum">3</span>
              <p className="mb-0 p1 poppins">
                As a bonus, after completing one month of your paid counselling sessions, you'll
                have the opportunity to schedule a review call.
              </p>
            </div>
          </div>
        </div>



        <Modal show={show.status} onHide={handleClose}
          centered
          className='consultancyModal'
        >
          <Modal.Body>
            <span className='modalClsBtn' onClick={handleClose}><i className="fa-solid fa-xmark"></i></span>
            <h3 className="mdFont fw700 poppins textDark mb-4">
              {modalData?.time}
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <input type="text" className="form-control" name="fname" placeholder="Full Name" value={formData.fname} onChange={handleChange} />
                </div>
                <div className="col-md-6">
                  <input type="email" className="form-control" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <input type="text" className="form-control" name="phn" placeholder="WhatsApp Number" value={formData.phn} onChange={handleChange} />
                </div>
                <div className="col-md-12">
                  <textarea className="form-control" name="query" id="inputMsg" placeholder="Summary of your problems" value={formData.query} onChange={handleChange} style={{ height: "120px" }} ></textarea>
                </div>
              </div>
              {
                Number(modalData?.price) > 0
                  ?
                  <div>
                    <div className="d-flex align-items-center column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                      <p className="priceNum fw800 poppins textDark mb-0">
                        <span className="me-1">₹</span><span className="poppins">{modalData?.price}/-</span>
                      </p>
                    </div>
                    <div className="d-flex align-items-center flex-wrap column-gap-3 mb-4 paymentWidget" role="button">
                      <span className="fw600 textDark">Pay Via</span><img src={RazorpayIcon} alt="#" loading="lazy" />
                    </div>
                    <ul className="resetUl">
                      <li className="d-flex align-items-start gap-2 mb-2">
                        <div className="form-check">
                          <label className="form-check-label" for="flexCheckChecked">
                            <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked={isChecked} onChange={handleCheckboxChange} />
                            <p className="textDark roboto mb-0 smFont">I accept your <a className="fw700 textDark" href="/terms-&amp;-conditions">Terms &amp; Conditions</a> and willingly agree to receive Marketing emails.</p>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  :
                  null
              }
              <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 btnSubmit">
                submit
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </section>



    </div>
  )
}

export default TypeSetting