import React from 'react'
import './MasteryHero99Style.css'


const MasteryHero99 = ({ heroSection, mobBanner, tabBanner, webBanner, heroTextCont, heroVidCont, heroForm }) => {
    return (
        <>
            <section className={heroSection}>
                <img src={mobBanner} className="d-md-none d-block w-100 mobBanner" alt="#" loading="lazy" />
                <img src={tabBanner} className="d-none d-md-block d-lg-none w-100 tabBanner" alt="#" loading="lazy" />
                <img src={webBanner} className="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" />
                <div className="carousel-caption">
                    <div className="container">
                        <div className="heroCont">
                            {heroTextCont}
                            {heroVidCont}
                            {heroForm}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MasteryHero99