import React from 'react'
import "./AllAboutStyle.css";
import JoseImg from "../assets/jose-silva.webp"



const AllAbout = () => {
  return (
    <div>
         <section className="allAboutSection pbtm">
      <div className="container">
        <div className="allAboutCont">
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="aboutPoints">
                <h2 className="h2 fw700 poppins textDark mb-4">
                  What you’ll get?
                </h2>
                <ul className="resetUl">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <span className="pointBg fw500">1</span>
                    <p className="textDark roboto mb-0 fw500">
                      Insight into seven core ingredients of a successful life.
                    </p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <span className="pointBg fw500">2</span>
                    <p className="textDark roboto mb-0 fw500">
                      Concept clarity on every ingredient.
                    </p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <span className="pointBg fw500">3</span>
                    <p className="textDark roboto mb-0 fw500">
                      A roadmap to live a purposeful life.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="aboutDesc">
                <h3 className="h2 fw700 poppins textDark mb-4">
                  What is it all about?
                </h3>
                <p className="textDark roboto mb-3 mb-lg-5">
                  This workbook is designed with the core learnings of the Silva Mind Empowerment
                  Techniques. Its purpose is simple: to provide you with practical tools to tackle the
                  everyday hurdles that life throws your way.
                </p>
                <p className="textDark roboto mb-3 mb-lg-5">
                Whether you're seeking to regain control over your emotions, improve your relationships, or boost your productivity and financial well-being, this workbook is here to guide you every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AllAbout