import React from 'react'
import "../components/FAQStyle.css";
import './SlsFaqsStyle.css'


const SlsFaqs = ({ faqsData }) => {
  return (
    <div>
      <section className="faqSection lightBg slsFaqsPadding">
        <div className="faqCont">
          <div className="headingArea text-center mb-4">
            <h2 className="h2 fw700 poppins textDark">
              জিজ্ঞাসিত কিছু প্রশ্ন।
            </h2>
          </div>
          <div className="accordion" id="accordionExample">
            {/* {
                faqsData?.map((faq,i) =>
                    <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {faq?.header}
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                   {faq?.text}
                  </div>
                </div>
              </div> 
                )
            } */}
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Silva Life System কি?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  সিলভা লাইফ সিস্টেম সম্পূর্ণ প্রোগ্রামের সূচনা বিন্দু। এটি আপনাকে, মেডিটেশন, ভিজ্যুয়ালাইজেশন, আপনার ইচ্ছাগুলিকে সত্য করে তোলা, রোগ নিরাময় এবং আরও অনেক কিছুর প্রাথমিক দক্ষতা শেখায়। এগুলি হল সহজ এবং ব্যবহারিক কৌশল যা আপনি এখনই আপনার দৈনন্দিন জীবনে ব্যবহার করতে পারেন।
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Silva Method কি সত্যিই কাজ করে?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  সিলভা পদ্ধতি মানসিক চাপ কমাতে, ফোকাস উন্নত করতে এবং আত্মবিশ্বাস বাড়ানোর জন্য একটি সহায়ক হাতিয়ার হতে পারে। এই পদ্ধতির কার্যকারিতা ব্যক্তিভেদে পরিবর্তিত হয়। 120টি দেশে 12 মিলিয়নেরও বেশি মানুষ সিলভা মেথড মেডিটেশনের শক্তি অনুভব করেছে।
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Silva Method মেডিটেশনের উপকারিতা কি?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  এই পদ্ধতি অনুশীলনের সুবিধাগুলি আপনার কল্পনার মতো অসংখ্য। আপনার অবচেতন মনকে জাগরিত করার শিল্প আয়ত্ত করার মাধ্যমে, আপনি কার্যকরভাবে স্ট্রেস এবং উদ্বেগ দূর করতে পারেন, শান্তিপূর্ণ নিদ্রা অর্জন করতে পারেন, স্ব-নিরাময় এবং অন্যদের রোগ নিরাময়ে সহায়তা করতে পারেন, বিষণ্নতা এবং মাথাব্যথার অনুভূতি দূর করতে পারেন এবং শেষ পর্যন্ত সমস্ত দিকগুলিতে আরও বেশি সাফল্য অর্জন করতে পারেন। আপনার জীবন।
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  The Silva Life System course কোর্স কি নিরাময় শেখায়?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  সিলভা লাইফ সিস্টেম আপনার শারীরিক এবং মানসিক সমস্যাগুলি সমাধান করার ক্ষমতা বাড়াতে, মাথাব্যথা উপশম করতে, স্মৃতিশক্তি বাড়াতে এবং ঘুমের গুণমান উন্নত করতে প্রয়োজনীয় কৌশলগুলি অফার করে। এটিতে সহজ ধাপে ধাপে মেডিটেশন অন্তর্ভুক্ত রয়েছে যা আপনাকে অবিলম্বে আপনার শিক্ষাকে আপনার জীবনে প্রয়োগ করতে সহায়তা করে।
                </div>
              </div>
            </div>
            {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Is meditation really that important?
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes. Meditation is crucial for health and well-being, countering stress, the main cause
                of illness. Whether you call it relaxation, centering, or prayer with intent, daily
                practice strengthens the connection between body, mind, spirit, and emotions.
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default SlsFaqs