import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import './TestimonialBengaliStyle.css'
import TestimonialBengali from "./TestimonialBengali";
import { Appcontext } from "../context/appcontext";


function Reviews({ extraClass, sectionHead, reviewCont }) {
    // const [reviews, setReviews] = useState([]);
    


    
    const [loading, setLoading] = useState(false);
    const { isMobile, isTablet, isBigScreen } = useContext(Appcontext);
    const settings = {
        className: "center",
        infinite: true,
        cssEase: "linear",
        centerMode: true,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: true,
        centerPadding: (isMobile || isTablet) ? "10px" : isBigScreen ? "400px" : "100px",
        autoplay: true
    };
    return (
        <div className={`padding-bottom-80 ${extraClass}`} style={{ overflowX: 'hidden', }}>
            {/* <Heading
                title={"Say"}
                colored={"About Us"}
                subtitle={"See what others have to"}
            /> */}
            {sectionHead}
            {loading ? (
                <div className="d-flex flex-column w-100 align-items-center justify-content-center p-5">
                    <p>loading...</p>
                </div>
            ) : (
                <div className="reviews-carousel mt-0 mb-5">
                    <Slider {...settings}>
                        {reviewCont?.map((review, i) => (
                            <div key={i}>
                                <TestimonialBengali data={review} />
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </div>
    );
}
export default Reviews;