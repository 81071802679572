import React from 'react'
import './VideoBtmBonusStyle.css'
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'
const VideoBtmBonus = () => {
    const bonusPoints = [
        {
            points: 'ইন্সট্রাক্টর দের সাথে  ৭ দিন ২ বেলা মোট ১৪ টি ফ্রি গাইডেড মেডিটেশন অনুশীলন করুন',
            fontBold: true
        },
        {
            points: 'আমাদের অফিসিয়াল হোয়াটস্যাপ গ্রুপের সদস্য হন',
        },
        {
            points: 'সার্টিফায়েড সিলভা মেথড ইন্সট্রাক্টর দের সাথে যোগাযোগ করার সুযোগ পান।',
        },
    ]


    return (
        <>
            <section className="videoBtmBonus">
                <div className="container">
                    <div className="bonusCont" style={{ backgroundImage: "url('/assets/images/rahul/video-btmcont-bg.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        <h2 className="mdFont fw700 poppins textDark mb-4 mb-lg-5 text-center">
                            কিছু এক্সট্রা <span className="gradientText me-2">বোনাস</span>যেগুলি আপনি পাবেন
                        </h2>
                        <ul className='resetUl bounusList'>
                            {
                                bonusPoints?.map((items, idx) =>
                                    <li className="d-flex align-items-start gap-2 mb-2">
                                        <span><i className="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i></span>
                                        <p className={`textDark roboto mb-0 pt-1 ${items.fontBold === true ? "fw700" : "fw500"}`}>
                                            {items.points}
                                        </p>
                                    </li>
                                )
                            }
                        </ul>
                        <div className="text-center">
                        <Link to="masterClassForm_health" smooth={true} duration={500}>
                            <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 ms-0 border-0 shadowLight" onClick={() => handleScroll()}>
                                বোনাসগুলি আনলক করুন
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VideoBtmBonus