import React from 'react'
import './WhatLearnStyle.css'
import checkIcon from "../assets/check-bg.svg"

const WhatLearn = () => {
    const liCardCont = [
        {
            iconCont: checkIcon,
            textCont: 'What is Silva method'
        },
        {
            iconCont: checkIcon,
            textCont: 'Brain and Mind Connection'
        },
        {
            iconCont: checkIcon,
            textCont: 'The Altered State of Mind'
        },
        {
            iconCont: checkIcon,
            textCont: 'Brainwave Frequencies'
        },
        {
            iconCont: checkIcon,
            textCont: 'How to reduce stress'
        },
        {
            iconCont: checkIcon,
            textCont: 'Improve Physical Wellness'
        },
        {
            iconCont: checkIcon,
            textCont: 'Excelling Mental Health'
        },
        {
            iconCont: checkIcon,
            textCont: 'Building Fulfilling Connections'
        },
    ]



    return (
        <>
            <section className="whatLearnSection ptop pbtm">
                <div className="container">
                    <h2 className="h2 fw700 poppins textDark text-center mb-4">
                        What will you <span className="gradientText">Learn?</span>
                    </h2>
                    <ul className='resetUl d-md-flex flex-wrap justify-content-center column-gap-3'>
                        {
                            liCardCont?.map((items, idx) =>
                                <li className='d-flex gap-3 liCard mb-3'>
                                    <img src={items.iconCont} alt="#" loading='lazy' className='checkIcon' />
                                    <p className='title fw600 poppins mb-0'>{items.textCont}</p>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </section>
        </>
    )
}

export default WhatLearn