import React, { useContext, useState } from 'react'
import Navbar2 from '../components/Navbar2'
import MasteryHero99 from '../components/MasteryHero99'
import ReactPlayer from 'react-player'
import { Appcontext } from '../context/appcontext'
import VideoBtmSection from '../components/VideoBtmSection'
import PracticeMeditation14 from '../components/PracticeMeditation14'
import HappyLifeSection from '../components/HappyLifeSection'
import MoneyMagnetForm from '../components/MoneyMagnetForm'
import AboutInstructor from '../components/AboutInstructor'
import Reviews from '../components/Reviews'
import Footer2 from '../components/Footer2'
import instructor2 from '../assets/dr-kalam.webp'
import { Link } from 'react-scroll'
import { handleScroll } from '../utils/animPulse'
const InstructorVideo = "https://silva-method-official.b-cdn.net/silva-india-parenting-workshop/landscape.mp4"



const ParentingWorkshop = () => {
    const [playing, setPlaying] = useState(false)
    const { isMobile } = useContext(Appcontext)


    const smartPoints1 = [
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `আপনার সন্তানের সাথে ফিজিক্যাল, মেন্টাল, ইমোশনাল ও স্পিরিচুয়াল - এই  চারটি স্তরের কানেকশন গড়ে তুলুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানকে শারীরিক, মানসিক ও আবেগিকভাবে সামর্থ্য  করে তুলুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানের ভাবনা, বিশ্বাস ও ধারণা গঠনের প্রক্রিয়া শিখুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানের মস্তিষ্ক থেকে নেতিবাচক চিন্তা অপসারণের কৌশল জানুন।`
        },
    ]

    const smartPoints2 = [
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানের মধ্যে শিক্ষা গ্রহণের আগ্রহ তৈরি করার পদ্ধতি শিখুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানের অন্তর্গত ক্ষমতা ও গুণাবলি ডেভেলপ করার কৌশল জানুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `তাদের স্বাধীন চিন্তাধারা গড়ে তোলার পদ্ধতি শেখান।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানকে আত্মবিশ্বাসী ও উদ্যমী করার কৌশল।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সামাজিক সুসম্পর্ক গড়ে তোলা ও রক্ষণাবেক্ষণ করা শেখান।`
        },
    ]

    const smartPoints3 = [
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সন্তানকে পড়াশোনার গুরুত্ব বোঝান এবং এর ফলাফল সম্পর্কে অবগত করুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `সমস্যা সমাধানের দক্ষতা শেখান।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `জীবনযাত্রার ভারসাম্য বজায় রাখতে শেখান।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `আপনার সন্তানের বন্ধু হয়ে উঠুন, তার অনুভূতি ও আবেগ গুলি বুঝুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `ব্রেইন ও মাইন্ডের সঠিক ব্যবহারের কৌশল শেখান।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `নিজের কাজ নিজে করার প্রশিক্ষণ এবং ফিডব্যাক গ্রহনে সক্ষম করে তুলুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            desc: `জীবনযাত্রার ছোট ছোট সিদ্ধান্ত নেয়ার প্রাকটিক্যাল প্রোগ্রামিং শেখান।`
        },
    ]

    const instructorCardData = [
        {
            id: 1,
            instructorimg: instructor2,
            instructortitle: 'ডা. কালাম ফারুক',
            instructordesc: `ডাঃ আবুল কালাম ১৯৬৭ সালে বাংলাদেশে জন্মগ্রহণ করেন। তিনি তার পড়াশোনা শেষ করেন 
            এবং ১৯৯৮ সালে ICAM থেকে মেডিকেল সায়েন্সে স্নাতক হন। ডাঃ আবুল কালাম একজন জেনারেল ফিজিশিয়ান হিসেবে 
            তার কর্মজীবন শুরু করেন। ২০০১ সালে, তিনি বাংলাদেশের সিলভা কিংবদন্তি জনাব মাহি কাজীর কাছ থেকে জোসে 
            সিলভার সিলভা পদ্ধতি সম্পর্কে জানতে পারেন। ২০০১ থেকে ২০০৫ সাল পর্যন্ত, ডাঃ কালাম ৪০ টিরও বেশি সেমিনারে 
            অংশ নিয়ে জনাব কাজীর কাছ থেকে সিলভা কোর্সের পুরো পরিসর নিয়েছিলেন। ২০০৬ থেকে তিনি ব্যক্তিগতভাবে জনাব 
            কাজীর অধীনে প্রশিক্ষণ নেন, সংগঠক, ব্যবস্থাপক এবং ইন্সট্রাক্ট্ররের দায়িত্ব পালন করেন। শুধু তাই নয় তিনি ISOMETRIC LTD-এর 
            একটি গুরুত্বপূর্ণ অংশ ছিলেন। তিনি সিলভা পদ্ধতি এবং জনাব কাজীর কাছ থেকে অনেক কিছু শিখেছেন, বিশেষ করে কীভাবে 
            মানুষকে ভালোবাসতে হয় এবং সাহায্য করতে হয়।`
        },
    ]



    return (
        <div style={{ backgroundImage: "url('/assets/images/rahul/line-bg.jpg')", backgroundSize: "contain", backgroundPosition: "center" }}>
            <Navbar2 />
            <MasteryHero99
                playing={playing}
                setPlaying={setPlaying}
                heroSection="parentingWorkshop"
                mobBanner="/assets/images/rahul/parenting-workshop-banner-mob.webp"
                tabBanner="/assets/images/rahul/parenting-workshop-banner-web.webp"
                webBanner="/assets/images/rahul/parenting-workshop-banner-web.webp"
                heroTextCont={
                    <>
                        <h1 className='h1 fw700 NotoSansBengali primaryWhite mb-3 mb-lg-4 smHead'>
                            আর্ট অফ <span className='colorYellow'>প্যারেন্টিং ওয়ার্কশপ</span>
                        </h1>
                        <h3 className='mdFont fw600 primaryWhite NotoSansBengali mb-0 mb-lg-3'>
                            সন্তানকে কি শেখাবেন আর কি নয়, তা বুঝে উঠতে পারছেন না?
                            একটি শিশু বড়ো হয়ে কেমন আচরণ করবে, সেটি নির্ভর করছে তাকে ছোটো থেকে কীভাবে লালন পালন করা হচ্ছে।
                            এই ওয়ার্কশপটিতে অংশগ্রহণ করে আপনি শিখবেন কিছু স্মার্ট প্যারেন্টিং টিপস,  যা আপনার শিশুর সামগ্রিক উন্নয়নকে নিশ্চিত করবে।
                        </h3>
                    </>
                }
                heroVidCont={
                    <div className="masteryHeroVideo" style={{ maxHeight: playing && !isMobile ? '450px' : isMobile ? playing ? '192px' : '450px' : '' }}>
                        <ReactPlayer
                            url={InstructorVideo}
                            light="assets/images/rahul/parenting-workshop-vid-banner.webp"
                            width="100%"
                            height="100%"
                            playing
                            muted
                            loop
                            controls={true}
                            onPlay={() =>
                                setPlaying(true)
                            }
                        />
                    </div>
                }
            />
            <VideoBtmSection
                playing={playing}
                bgImg="/assets/images/rahul/money-management-bg.webp"
                textCont={
                    <>
                        <div className='d-flex justify-content-around flex-column row-gap-4 flex-lg-row NotoSansBengali'>
                            <div className='dateTime fw600 poppins primaryWhite text-center flex-column'>
                                <p className='mdFont fw900 colorYellow mb-2'>
                                    <span className='me-2'><i class="fa-solid fa-clock"></i></span>
                                    ৫ই অক্টোবর
                                </p>
                                <p className='mb-3'>৭:৩০ P.M. - ৯:৩০ P.M. (ভারতীয় সময়)</p>
                                <p className='mb-0'>৮ P.M. - ১০ P.M. (বাংলাদেশী সময়)</p>
                                {/* <p className='mb-0'>শীঘ্রই আসছে</p> */}
                            </div>
                            <div className='text-center text-lg-start'>
                                <p className='primaryWhite fw800 NotoSansBengali mb-4 dateTimeTitle ps-lg-5'>
                                    আর্ট অফ প্যারেন্টিং ওয়ার্কশপ
                                </p>
                                <p className='primaryWhite NotoSansBengali mb-0 ps-lg-5'>
                                    <p className='mb-0 fw500 title colorYellow'>রেজিস্ট্রেশন ফি:</p>
                                    <p className='dateTimeTitle fw800'>₹ ৯৯/- (INR) / ৳ ১৪০/- (BDT)</p>
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="masterClassForm" smooth={true} duration={500}>
                                <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 mt-lg-5 border-0" onClick={() => handleScroll()}>
                                    রেজিস্টার করুন
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <PracticeMeditation14
                lightBg="lightBg"
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4 reverseCol">
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 NotoSansBengali textDark mb-4 text-center text-lg-start">
                                <span className="gradientText ms-2">আর্ট অফ প্যারেন্টিং ওয়ার্কশপটি</span> কেন করবেন?
                            </h2>
                            <p className="textDark NotoSansBengali mb-3 text-center text-lg-start">
                                আপনি কি চান আপনার সন্তান জীবনে সঠিক পথে চলুক এবং আত্মবিশ্বাসী হয়ে উঠুক? তাহলে এখন
                                থেকেই কিছু গুরুত্বপূর্ণ পদক্ষেপ গ্রহন করুন। অনেক বাবা মা, মূলত যাঁরা প্রথমবার মা বাবা হয়েছেন
                                তাঁরা বুঝতে পারেন না যে সন্তানকে কী শেখাবেন, কখন শেখাবেন, কীভাবে মানুষ করবেন, ইত্যাদি।
                                অনেক খুঁটিনাটি বিষয়ই তাঁদের অজানা থাকে। ফলে নানান সমস্যা তৈরি হয়, এবং তারা অল্পেই চিন্তিত
                                হয়ে পরেন। আপনার শিশুর সঠিক বিকাশ এবং সামগ্রিক উন্নয়ন নিশ্চিত করার জন্য প্রয়োজন সঠিক
                                দিক-নির্দেশনা ও কৌশল। আমাদের মাস্টারক্লাসে অংশগ্রহণ করে আপনি শিখবেন কিভাবে আপনার
                                সন্তানের বিভিন্ন বয়সে তাদের সঠিক বিকাশ ও উন্নয়ন নিশ্চিত করবেন।
                            </p>
                            <div className="text-center text-lg-start">
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={() => handleScroll()}>
                                        এখনি জয়েন করুন
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="videoArea ps-lg-5">
                                <img src="/assets/images/rahul/parenting-workshop-on-zoom.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                            </div>
                        </div>
                    </div>
                }
            />
            <HappyLifeSection
                contentSection={
                    <>
                        <h2 class="h2 fw700 NotoSansBengali textDark mb-4 text-center">
                            শিশুদের বিভিন্ন বয়সের জন্য <span class="gradientText">স্মার্ট প্যারেন্টিং কেন গুরুত্বপূর্ণ</span>
                        </h2>
                        <p class="p1 textDark NotoSansBengali mb-4 text-center m-auto">
                            সন্তানকে কিছু শেখানোর ক্ষেত্রে মনে রাখবেন শিশুরা ছোট বয়স থেকেই তাদের পারিপার্শ্বিক পরিবেশ
                            থেকেই শিক্ষা গ্রহণ করে। তারা যা দেখে তাই শেখে। তাই তাকে কিছু শেখানোর হলে সুস্থ পরিবেশ দিন
                            সবার আগে, এছাড়া নিজেরা এমন কাজ করুন যা দেখে সে অনুপ্রাণিত হতে পারে।
                        </p>
                        <div class="videoArea m-auto mb-4">
                            <img src="/assets/images/rahul/smart-parenting.webp" alt="#" loading="lazy" class="w100 h100 agendaImg" />
                        </div>
                        <p class="p1 textDark NotoSansBengali mb-0 text-center m-auto">
                            শিশুদের বয়সভেদে বিভিন্ন মানসিক, শারীরিক, এবং সামাজিক চাহিদা থাকে। প্রাথমিক বয়সে, সঠিক
                            প্যারেন্টিং শিশুর বুনিয়াদি দক্ষতা ও আত্মবিশ্বাস গড়তে সাহায্য করে। স্কুল বয়সী শিশুরা যখন নতুন দক্ষতা
                            অর্জন করে এবং সৃজনশীল চিন্তাভাবনায় জড়িত থাকে, স্মার্ট প্যারেন্টিং তাদের শিক্ষাগত ও সামাজিক দক্ষতা
                            উন্নত করতে সহায়তা করে। কিশোর বয়সে, সঠিক নির্দেশনা ও সমর্থন তাদের আত্মবিশ্বাস ও সিদ্ধান্ত গ্রহণের
                            ক্ষমতা বৃদ্ধিতে গুরুত্বপূর্ণ ভূমিকা পালন করে। এই ওয়ার্কশপটি থেকে আপনি শিখবেন আপনার কোন বয়সের
                            শিশুকে কীভাবে আর কি শিক্ষা দেবেন।
                        </p>
                        <div className='text-center mt-4'>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 border-0 shadowLight" onClick={() => handleScroll()}>
                                    রেজিস্টার করুন
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <PracticeMeditation14
                lightBg="lightBg"
                contentArea={
                    <>
                        <h2 className="h2 fw700 NotoSansBengali textDark mb-4 mb-lg-5 text-center">
                            শিশুদের বিভিন্ন বয়সের জন্য <span className="gradientText">স্মার্ট প্যারেন্টিং কেন গুরুত্বপূর্ণ</span>
                        </h2>
                        <div className="row align-items-center position-relative row-gap-4 mb-4 mb-lg-5">
                            <div className="col-lg-6">
                                <div className="videoArea pe-lg-5">
                                    <img src="/assets/images/rahul/0-3-years-child.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="mdFont fw700 NotoSansBengali textDark mb-4">
                                    <span className="gradientText">শূন্য থেকে তিন</span> বছরের সন্তানদের জন্য:
                                </h3>
                                <ul className='resetUl'>
                                    {
                                        smartPoints1?.map((items, idx) =>
                                            <li className={`d-flex align-items-start text-start gap-2 ${items.hideMargin === true ? "" : "mb-3"}`}>
                                                <img src={items.imgIcon} alt="#" className="circleTick" />
                                                <p className="textDark NotoSansBengali fw500 mb-0">{items.desc}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="row align-items-center position-relative row-gap-4 mb-4 mb-lg-5 reverseCol">
                            <div className="col-lg-6">
                                <h3 className="mdFont fw700 NotoSansBengali textDark mb-4">
                                    <span className="gradientText">তিন থেকে সাত</span> বছরের সন্তানদের জন্য:
                                </h3>
                                <ul className='resetUl'>
                                    {
                                        smartPoints2?.map((items, idx) =>
                                            <li className={`d-flex align-items-start text-start gap-2 ${items.hideMargin === true ? "" : "mb-3"}`}>
                                                <img src={items.imgIcon} alt="#" className="circleTick" />
                                                <p className="textDark NotoSansBengali fw500 mb-0">{items.desc}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <div className="videoArea pe-lg-5">
                                    <img src="/assets/images/rahul/3-7-years-child.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center position-relative row-gap-4">
                            <div className="col-lg-6">
                                <div className="videoArea pe-lg-5">
                                    <img src="/assets/images/rahul/7-14-years-child.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="mdFont fw700 NotoSansBengali textDark mb-4">
                                    <span className="gradientText">সাত থেকে চোদ্দ</span> বছরের সন্তানদের জন্য:
                                </h3>
                                <ul className='resetUl'>
                                    {
                                        smartPoints3?.map((items, idx) =>
                                            <li className={`d-flex align-items-start text-start gap-2 ${items.hideMargin === true ? "" : "mb-3"}`}>
                                                <img src={items.imgIcon} alt="#" className="circleTick" />
                                                <p className="textDark NotoSansBengali fw500 mb-0">{items.desc}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </>
                }
            />
            <MoneyMagnetForm
                formStyle="moneyMagnetForm"
                formHead={
                    <div className="div1">
                        <p className="title fw700 NotoSansBengali primaryWhite mb-1 p1">আর্ট অফ প্যারেন্টিং ওয়ার্কশপ</p>
                        <div className='d-flex flex-wrap row-gap-2'>
                            {/* <span className="title primaryWhite fw600 NotoSansBengali">
                                Coming Soon
                            </span> */}
                            <span className="primaryWhite fw500 NotoSansBengali date">
                                Date: <span className="fw600 shapeRect primaryYellow primaryBlack">৫ই অক্টোবর</span>
                            </span>
                            <span className="primaryWhite fw500 NotoSansBengali ms-1 ms-lg-4">
                                Time: <span className="fw600 shapeRect primaryYellow primaryBlack">৭:৩০ PM(IST)</span>
                            </span>
                        </div>
                    </div>
                }
                priceSection={
                    <div className="d-flex align-items-center column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">₹</span>
                            <span className="NotoSansBengali">৯৯/-<span className='title fw600'>(INR)</span></span>
                        </p>
                        <span>|</span>
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">৳</span>
                            <span className="NotoSansBengali">১৪০/-<span className='title fw600'>(BDT)</span></span>
                        </p>
                    </div>
                }
                formImg="assets/images/rahul/parenting-workshop-form.webp"
                productId="ART-PARENTING-99"
                leadAPI="https://silvamethod.in:5000/api/v1/leads/submit-parenting-lead"
                thankYouPageUrl="/payment-successful"
                langCount="false"
            />
            <AboutInstructor
                lightBg="lightBg"
                contentArea={
                    <>
                        <h2 class="h2 fw700 NotoSansBengali textDark mb-4 mb-lg-5 text-center">
                            আপনার <span class="gradientText">ইন্সট্রাক্টরের সম্পর্কে</span> কিছু কথা
                        </h2>
                        <div className="healthInstructorCard">
                            {
                                instructorCardData?.map((item, idx) =>
                                    <div className="cardItem">
                                        <div class="leftSide">
                                            <img src={item.instructorimg} alt="#" loading="lazy" class="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 class="mdFont textDark fw700 NotoSansBengali mb-3">{item.instructortitle}</h3>
                                            <p class="desc textDark fw400 NotoSansBengali mb-0">{item.instructordesc}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                }
            />
            <Reviews
                sectionHead={
                    <h2 class="h2 fw700 NotoSansBengali textDark mb-4 mt-4 text-center">সেলিব্রিটি জগতে <span class="gradientText">মেডিটেশন</span></h2>
                }
            />
            <Footer2 />
        </div>
    )
}

export default ParentingWorkshop