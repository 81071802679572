import React from 'react'
import { Link } from 'react-router-dom'


const CoursePreviewCard = ({ data }) => {
    const { pageLink, cardBgImg, cardThumbImg, name, newPrice, oldPrice, free } = data

    return (
        <>
            <Link to={pageLink}>
                {/* <div className="coursePreviewCard bgImgProp" style={{ backgroundImage: "url('/assets/images/rahul/page-bls.webp')" }}> */}
                <div className="coursePreviewCard bgImgProp" style={{ backgroundImage: `url('${cardBgImg}')` }}>
                    <div className='position-relative z-1'>
                        <div className="cardThumb">
                            <img src={cardThumbImg} alt="#" loading='lazy' className='w100 h100' />
                        </div>
                        <h3 class="title fw600 poppins primaryWhite mt-3 mb-0">{name}</h3>
                        <div class="d-flex align-items-center mt-3 column-gap-3">
                            {
                                newPrice &&
                                <p class="title fw600 roboto colorYellow d-flex align-items-center mb-0">
                                    <span class="me-1">₹</span><span class="roboto">{newPrice}/-</span>
                                </p>
                            }
                            {
                                oldPrice &&
                                <p class="title fw600 oldPrice textLight mb-0">
                                    <span class="me-1">₹</span><span class="roboto">{oldPrice}/-</span>
                                </p>
                            }
                            {
                                free &&
                                <p class="fw600 roboto d-flex align-items-center mb-0 shapeRect primaryYellow primaryBlack">{free}</p>
                            }
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default CoursePreviewCard