import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import "./NavbarStyle.css";
import LogoImg from "../assets/silva-india-logo-white.webp";
import { Dropdown, Modal, Nav, Offcanvas, Row, Col, Tab, TabContent } from 'react-bootstrap';
import { Appcontext } from '../context/appcontext';




const Newnavbar = () => {
  const location = useLocation();
  const { isDesktop } = useContext(Appcontext)



  const navigate = useNavigate()
  const [showModal, setShowModal] = useState({ status: false, id: null });

  const handleShowModal = (id) => setShowModal({ status: true, id });
  const handleCloseModal = () => setShowModal({ status: false, id: null });


  // Define an array of objects for your navbar links
  // const navLinks = [
  //   { to: '/', text: 'Home' },
  //   { to: '/about', text: 'About', sublinks: [
  //     { to: '/instructor', text: 'Instructors' },
  //     { to: '/solution', text: 'Solutions We Provide' },
  //     Add other sublinks under About
  //   ]},
  //   { to: '/courses', text: 'Courses', sublinks: [
  //     { to: '/beginners-online-masterclass-bengali', text: 'BLS (Intro) Bengali' },
  //     { to: '/silva-life-system', text: 'Silva Life System' },
  //     { to: '/courses', text: 'BLS English' },
  //     Add other sublinks under About
  //   ] },
  //   { to: '/contact', text: 'Contact' },
  //   Add other links
  // ];

  const navLinks = [
    { to: '/', text: 'Home' },
    {
      to: '/about', text: 'About', sublinks: [
        { to: '/instructor', text: 'Instructors' },
        { to: '/solution', text: 'Solutions We Provide' },
        // Add other sublinks under About
      ]
    },
    {
      to: '/courses', text: 'Courses', sublinks: [
        { to: '/silva-method-india-all-courses', text: 'All Courses', modal: false },
        { text: 'Live Class', modal: true, modalid: 1 },
        { text: 'Digital Courses', modal: true, modalid: 2 },
        { text: 'Mini workshop', modal: true, modalid: 3 },

        // Add other sublinks under About
      ]
    },
    { to: '/contact', text: 'Contact' },
    // Add other links
  ];



  const [showoffcanvas, setshowoffcanvas] = useState(false);

  const handleCloseCanvas = () => setshowoffcanvas(false);
  const handleShowCanvas = () => setshowoffcanvas(true);
  useEffect(() => {
    if (isDesktop) {
      handleCloseCanvas()
    }
  }, [isDesktop])
  return (



    <>
      <section className="navSection">
        <nav className="navbar navbar-expand-lg">
          <div className="container">

            <Link to="/" className="navbar-brand">
              <img src={LogoImg} alt="Logo" loading="lazy" />
            </Link>

            <button className="navbar-toggler mobilehide" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" aria-label="Toggle navigation" onClick={() => {
                // alert("hello world")
                handleShowCanvas()
              }}>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop"
              aria-labelledby="staticBackdropLabel">
              <div className="offcanvas-header">
                <Link to="/" className="navbar-brand">
                  <img src={LogoImg} alt="Logo" loading="lazy" />
                </Link>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  {navLinks.map((link) => (
                    <li key={link.to} className="nav-item">
                      {link.sublinks ? (
                        <div className="nav-item dropdown">
                          <NavLink to={link.to} className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {link.text}
                          </NavLink>
                          <ul className="dropdown-menu p-0" aria-labelledby="navbarDropdown">



                            {link.sublinks.map((sublink, i) => (
                              <li key={sublink.to}>
                                {
                                  sublink.modal ? (
                                    <button to={sublink.to} className="dropdown-item modalMenu" onClick={() => {
                                      // alert(`button clicked ${i+1}`)
                                      handleShowModal(sublink.modalid)
                                    }}>{sublink.text}</button>
                                  ) : (
                                    <NavLink to={sublink.to} className={`dropdown-item ${sublink.text === 'All Courses' ? 'normalMenu' : ''}`}>{sublink.text}</NavLink>
                                  )
                                }
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <NavLink exact to={link.to} className="nav-link">{link.text}</NavLink>
                      )}
                    </li>
                  ))}
                  <li className="nav-item">
                    <a className="nav-link text-light" href="https://silvamethod.com" target="_blank" rel="noopener noreferrer">Silva International</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>




        <Modal
          onHide={handleCloseModal}
          show={showModal.status && showModal.id == 1}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop
          backdropClassName='modalBackdrop'
          className='menuModal'
        >
          <Modal.Body>
            <span className='modalMenuCls' onClick={handleCloseModal}><i className="fa-solid fa-xmark"></i></span>
            <h2 className="h2 fw700 poppins textDark mb-3">
              Be a <span className="gradientText">Silva Graduate</span>
            </h2>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first" className='modalTabBtn mb-3'>English</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className='modalTabBtn mb-3'>Bengali</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row className='row-gap-3'>
                    <Col xs={6} lg={4}>
                      <Link to={"/basic-lecture-series"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-bls.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Basic Lecture Series (BLS)</p>
                        </div>
                      </Link>
                    </Col>
                    <Col xs={6} lg={4}>
                      <Link to={"/silva-life-system"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-sls.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Silva Life System(SLS)</p>
                        </div>
                      </Link>
                    </Col>
                    <Col xs={6} lg={4}>
                      {/* <Link to={"/silva-life-system"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/resource-img-20.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Silva Life System(SLS)</p>
                        </div>
                      </Link> */}
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  {/* <p className='title fw600 mb-0'>Coming Soon</p> */}
                  <Row className='row-gap-3'>

                    <Col xs={6} lg={4}>
                      <Link to={"/silva-life-system-bengali"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-sls.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">সিলভা লাইফ সিস্টেম (SLS)</p>
                        </div>
                      </Link>
                    </Col>

                    <Col xs={6} lg={4}>
                      <Link to={"/silva-basic-lecture-series-bengali"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-bls.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">সিলভা মেথড বেসিক লেকচার সিরিজ (BLS)</p>
                        </div>
                      </Link>
                    </Col>
                    
                    <Col xs={6} lg={4}>
                      {/* <Link to={"/silva-life-system"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/resource-img-20.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Silva Life System(SLS)</p>
                        </div>
                      </Link> */}
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
        </Modal>

        <Modal
          onHide={handleCloseModal}
          show={showModal.status && showModal.id == 2}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop
          backdropClassName='modalBackdrop'
          className='menuModal'
        >
          <Modal.Body>
            <span className='modalMenuCls' onClick={handleCloseModal}><i className="fa-solid fa-xmark"></i></span>
            <h2 className="h2 fw700 poppins textDark mb-3">
              <span className="gradientText">Digital</span> Courses
            </h2>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first" className='modalTabBtn mb-3'>English</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className='modalTabBtn mb-3'>Bengali</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <p className='title fw600 mb-0'>Coming Soon</p>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <p className='title fw600 mb-0'>Coming Soon</p>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
        </Modal>


        <Modal
          onHide={handleCloseModal}
          show={showModal.status && showModal.id == 3}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop
          backdropClassName='modalBackdrop'
          className='menuModal'
        >
          <Modal.Body>
            <span className='modalMenuCls' onClick={handleCloseModal}><i className="fa-solid fa-xmark"></i></span>
            <h2 className="h2 fw700 poppins textDark mb-3">
              Mini <span className="gradientText">Workshop</span>
            </h2>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="first" className='modalTabBtn mb-3'>English</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className='modalTabBtn mb-3'>Bengali</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row className='row-gap-3'>
                    {/* <Col xs={6} lg={4}>
                      <Link to={"/emotion_mastery_99_workshop"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-emotion-99.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Emotion Mastery Workshop</p>
                        </div>
                      </Link>
                    </Col> */}
                    <Col xs={6} lg={4}>
                      <Link to={"/BLS-english-free-intro"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-BLS-free-eng-intro.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">BLS Free Intro English Masterclass</p>
                        </div>
                      </Link>
                    </Col>
                    <Col xs={6} lg={4}>
                      <Link to={"/english-money-magnet-masterclass"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-eng-money-magnet-2.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Money Magnet Masterclass in English</p>
                        </div>
                      </Link>
                    </Col>
                    {/* <Col xs={6} lg={4}>
                      <Link to={"/lions-gate-portal-day"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-lions-gate.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">Lion's Gate Portal Day</p>
                        </div>
                      </Link>
                    </Col> */}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row className='row-gap-3'>
                    <Col xs={6} lg={4}>
                      <Link to={"/beginners-online-masterclass-bengali"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-ben-bls-intro.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">বাংলা ফ্রি ইন্ট্রো মাস্টারক্লাস (BLS)</p>
                        </div>
                      </Link>
                    </Col>
                    {/* <Col xs={6} lg={4}>
                      <Link to={"/masterclass-on-health"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/page-health-management.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">হেলথ ম্যানেজমেন্ট মাস্টারক্লাস ₹৯৯/-</p>
                        </div>
                      </Link>
                    </Col> */}
                    {/* <Col xs={6} lg={4}>
                      <Link to={"/bengali-money-magnet-masterclass"}>
                        <div className="pageCard">
                          <div className='pageThumb'>
                            <img src="/assets/images/rahul/money-challenge.webp" alt="#" loading='lazy' className='w100 h100' />
                          </div>
                          <p class="fw600 poppins textDark mt-3 mb-0">মানি ম্যাগনেট মাস্টারক্লাস</p>
                        </div>
                      </Link>
                    </Col> */}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );



}

export default Newnavbar;