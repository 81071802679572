import React from 'react'
import './MasterclassInstructor.css'

const MasterclassInstructor = () => {
    const instructorCont = [
        {
            instructorimg: '/assets/images/rahul/instructors-1.webp',
            instructorname: 'দেবলীনা চ্যাটার্জ্জী',
            instructordesc: `দেবলীনা একজন সার্টিফায়েড সিলভা মেথড প্রশিক্ষক। সফ্টওয়্যার ইঞ্জিনিয়ারিং এবং সিলভা
            পদ্ধতি নীতিতে দক্ষতার একটি অনন্য মিশ্রণের সাথে, তিনি আপনাকে স্ব-আবিষ্কার এবং
            ব্যক্তিগত বৃদ্ধির জন্য একটি রূপান্তরমূলক যাত্রায় গাইড করার লক্ষ্য রাখেন। বর্তমানে তিনি
            মানুষের সমস্যাগুলির সমাধান খুঁজে বের করার একজন বিশেষজ্ঞ। তিনি সমস্যা সমাধান, মানসিকতা,
            আত্মবিশ্বাস, উৎপাদনশীলতা, লক্ষ্য নির্ধারণ, স্ট্রেস ম্যানেজমেন্ট ইত্যাদির মতো বিভিন্ন বিষয়ে মানুষকে
            প্রশিক্ষণ দিচ্ছেন। তাঁর একটি বন্ধুত্বপূর্ণ, হাসিখুশি এবং যত্নশীল প্রকৃতি রয়েছে এবং তিনি মানুষকে তাঁর
            সর্বোত্তম ক্ষমতায় সাহায্য করতে ভালবাসেন।আমরা সকলেই আমাদের মধ্যে অবিশ্বাস্য শক্তির অধিকারী,
            কাজে লাগানোর অপেক্ষায়, এবং দেবলীনা দৃঢ়ভাবে বিশ্বাস করে যে প্রত্যেকেই এই সম্ভাবনাকে অ্যাক্সেস
            করতে পারে। সর্বোপরি, তিনি মানুষকে তাদের জীবনে চূড়ান্ত সমৃদ্ধি অর্জনের জন্য প্রচুর স্বাস্থ্য, সম্পদ,
            সম্পর্ক, মানসিক শান্তি, সুখ এবং আধ্যাত্মিকতা অর্জনে সহায়তা করছেন।`
        },
        {
            instructorimg: '/assets/images/rahul/instructors-2.webp',
            instructorname: 'দেবাশীষ চ্যাটার্জ্জী',
            instructordesc: `একজন সার্টিফায়েড সিলভা মেথড প্রশিক্ষকের ভূমিকায়, তাঁর senior accountant 
            হিসাবে প্রায় 40 বছরের দক্ষতা এবং আধ্যাত্মিক অন্তর্দৃষ্টি মসৃণভাবে একত্রিত হয়। পাঁচ দশকেরও বেশি সময় 
            ধরে নিবেদিত আধ্যাত্মিক অনুশীলন তাঁর গভীর যাত্রাকে সংজ্ঞায়িত করে।তাঁর লক্ষ্য হল ছাত্রদের ক্ষমতায়ন করা, 
            আর্থিক দক্ষতা এবং আধ্যাত্মিকতা উভয় ক্ষেত্রেই প্রসিদ্ধ করা। আপনার অভ্যন্তরীণ উজ্জ্বলতাকে খুঁজে বের করতে 
            এবং আপনার লক্ষ্যগুলি অর্জন করতে তিনি আপনাকে সাহায্য করবেন।একজন লাইফ ট্রান্সফরমেশন কোচ হিসাবে 
            তিনি মানুষকে তাদের জীবনে ইতিবাচক শক্তি খুঁজে পেতে সাহায্য করেন। সিলভা পদ্ধতির মাধ্যমে, তিনি আপনার 
            সত্যিকারের বাস্তবতাকে রূপ দেওয়ার চেষ্টা করেন।তিনি ব্যক্তিদের তাদের ভাগ্য নিয়ন্ত্রণ করতে এবং তাদের অভ্যন্তরীণ 
            সম্ভাবনা আবিষ্কার করতে সহায়তা করার জন্য প্রতিশ্রুতিবদ্ধ।`
        },
        {
            instructorimg: '/assets/images/rahul/instructors-3.webp',
            instructorname: 'দেবায়ন সেন',
            instructordesc: `একটি সমৃদ্ধ কর্পোরেট ব্যাকগ্রাউন্ড সহ একজন সার্টিফায়েড সিলভা মেথড প্রশিক্ষক হিসেবে 
            দেবায়ন ব্যক্তিদের ক্ষমতায়নের দিকে গাইড করেন। কর্পোরেট পথে হেঁটে দেবায়ন আধুনিক পেশাদার জগতের চাহিদা 
            ও চাপ বুঝতে পারেন।তাই সিলভা মেথড মেডিটেশন পদ্ধতির মাধ্যমে আপনার মনের সুপ্ত সম্ভাবনাকে তিনি জাগরিত 
            করতে সক্ষম। মর্যাদাপূর্ণ MNC-তে তাঁর অভিজ্ঞতা, ব্যবসা এবং ব্যক্তিগত বৃদ্ধির বিষয়ে তাঁর ব্যবহারিক অন্তর্দৃষ্টির, 
            আপনার স্বপ্নের জীবনকে প্রকাশ করার জন্য আপনার যাত্রায় দেবায়ন একজন বিশ্বস্ত পরামর্শদাতা। মানুষের নেতিবাচক 
            মানসিকতাকে তিনি তাঁর দক্ষতার সাথে ইতিবাচক মানসিকতাই পরিবর্তন করতে পারেন। আপনার ব্যর্থতা, দুঃখ, চাপ, 
            উদ্বেগ, আতঙ্ক, রাগ, অস্থিরতা, বিষণ্নতা, এবং আর্থিক কষ্ট ইত্যাদি সমস্যার সমাধান তাঁর কাছে আছে। তিনি আপনার 
            মধ্যে লুকিয়ে থাকা সুযোগগুলি খুঁজে পেতে সাহায্য করবেন যাতে আপনি আপনার জীবন এবং পরিবেশে পরিবর্তন আনতে 
            পারেন।`
        },
        {
            instructorimg: '/assets/images/rahul/instructors-4.webp',
            instructorname: 'সৌম্যজিৎ বন্দ্যোপাধ্যায়',
            instructordesc: `একজন সার্টিফায়েড সিলভা মেথড প্রশিক্ষক হিসেবে, সৌম্যজিৎ তাঁর প্রশিক্ষণ সেশনের মাধ্যমে 
            ব্যক্তিগত ক্ষমতায়নের জন্য একটি গতিশীল এবং আবেগপূর্ণ পদ্ধতি নিয়ে আসে। সফ্টওয়্যার ইঞ্জিনিয়ারিং, ব্র্যান্ডিং এবং 
            বিজ্ঞাপনের পটভূমি সহ, তিনি প্রচুর প্রযুক্তিগত দক্ষতার সাথে সজ্জিত।সৌম্যজিৎ ব্যক্তিদের তাদের ভাগ্যের নিয়ন্ত্রণ নিতে 
            এবং তাদের সম্পূর্ণ সম্ভাবনা জাগরিত করতে সাহায্য করেন। তিনি অতি সহজেই সিলভা পদ্ধতির শক্তিশালী কৌশলগুলি 
            আয়ত্ত করতে আপনাকে সেখাবেন, যার মাধ্যমে আপনাকে সত্যিকারের বাস্তবতাকে রূপ দিতে সক্ষম করবে।তাঁর লক্ষ্য 
            আপনার স্বপ্নের জীবনকে প্রকাশ করার জন্য সমর্থন ও ক্ষমতায়ন করা, স্ব-আবিষ্কার এবং ব্যক্তিগত বৃদ্ধির জন্য একটি 
            রূপান্তরমূলক যাত্রায় আপনাকে গাইড করা।তিনি একজন ব্যক্তির সামগ্রিক বিকাশে বিশ্বাস করেন এবং তিনি মানুষকে 
            নিজের সেরা সংস্করণ হতে সাহায্য করেন।`,
            hidemargin: true
        },
    ]


    return (
        <>
            <section className="masterclassInstructor lightBg ptop pbtm">
                <div className="container">
                    <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        আপনার <span class="gradientText">প্রশিক্ষকদের</span> সম্পর্কে কিছু কথা
                    </h2>
                    {
                        instructorCont?.map((item, idx) =>
                            <div className={`masterclassInstructorCard ${item.hidemargin ? "" : "mb-4"}`}>
                                <div className="row row-gap-4">
                                    <div className="col-lg-5">
                                        <div className="imgArea">
                                            <img src={item.instructorimg} alt="#" loading='lazy' className='w100 h100' />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="textArea">
                                            <h3 class="mdFont fw600 textDark mb-4">{item.instructorname}</h3>
                                            <p class="textDark roboto fw300 mb-0">{item.instructordesc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
        </>
    )
}

export default MasterclassInstructor