import { createContext } from "react";
import { useMediaQuery } from "react-responsive";

export const Appcontext = createContext();
export const AppcontextProvider = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isBigScreen = useMediaQuery({ minWidth: 1824 });

    const values = {
        // userData,
        // setUserData,
        isDesktop,
        isTablet,
        isMobile,
        isBigScreen,
        // updateUser,
        // handleLogout
    };

    return <Appcontext.Provider value={values}>{children}</Appcontext.Provider>;
}