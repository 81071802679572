import React, { useContext } from 'react'
import './CourseListCardStyle.css'
import CoursePreviewCard from './CoursePreviewCard'
import { Appcontext } from '../context/appcontext'

const CourseListCard = () => {
    const { isMobile } = useContext(Appcontext)


    const cardCont = [
        {
            sectionHeadText: 'Be a',
            sectionSpanText: 'Silva Graduate',
            smallDesc: '8 Days 4 Hours live on Zoom',
            classType: [
                {
                    classHeadText: 'English',
                    classSpanText: 'Live Class',
                    cardData: [
                        {
                            pageLink: '/basic-lecture-series',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-bls.webp',
                            name: 'Basic Lecture Series (BLS)',
                            newPrice: '9,999',
                            oldPrice: '19,999',
                            free: ''
                        },
                        {
                            pageLink: '/silva-life-system',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-sls.webp',
                            name: 'Silva Life System (SLS)',
                            newPrice: '6,000',
                            oldPrice: '12,000',
                            free: ''
                        },
                    ]
                },

                {
                    classHeadText: 'Bengali',
                    classSpanText: 'Live Class',
                    cardData: [
                        {
                            pageLink: '/silva-life-system-bengali',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-sls.webp',
                            name: 'সিলভা লাইফ সিস্টেম (SLS)',
                            newPrice: '৫,৫০০',
                            oldPrice: '১১,000',
                            free: ''
                        },
                        
                        {
                            pageLink: '/silva-basic-lecture-series-bengali',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-bls.webp',
                            name: 'সিলভা মেথড বেসিক লেকচার সিরিজ (BLS)',
                            newPrice: '৯৯৯৯',
                            oldPrice: '১৯৯৯৯',
                            free: ''
                        },
                    ]
                },
            ]
        },

        {
            sectionHeadText: 'Mini',
            sectionSpanText: 'Workshop',
            smallDesc: '2-3 Hours live on Zoom',
            classType: [
                {
                    classHeadText: 'English',
                    classSpanText: 'One Day Workshop',
                    cardData: [
                        // {
                        //     pageLink: '/emotion_mastery_99_workshop',
                        //     cardBgImg: '/assets/images/rahul/texture-bg.webp',
                        //     cardThumbImg: '/assets/images/rahul/page-emotion-99.webp',
                        //     name: 'Emotion Mastery Workshop',
                        //     newPrice: '99',
                        //     oldPrice: '999',
                        //     free: ''
                        // },

                        {
                            pageLink: '/BLS-english-free-intro',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-BLS-free-eng-intro.webp',
                            name: 'BLS Free Intro English Masterclass',
                            newPrice: '',
                            oldPrice: '',
                            free: 'Free'
                        },

                        {
                            pageLink: '/english-money-magnet-masterclass',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-eng-money-magnet-2.webp',
                            name: 'Money Magnet Masterclass in English',
                            newPrice: '249',
                            oldPrice: '',
                            free: ''
                        },

                        // {
                        //     pageLink: '/lions-gate-portal-day',
                        //     cardBgImg: '/assets/images/rahul/texture-bg.webp',
                        //     cardThumbImg: '/assets/images/rahul/page-lions-gate.webp',
                        //     name: "Lion's Gate Portal Day",
                        //     newPrice: '99',
                        //     oldPrice: '',
                        //     free: ''
                        // },
                        
                    ]
                },

                {
                    classHeadText: 'Bengali',
                    classSpanText: 'One Day Workshop',
                    cardData: [
                        {
                            pageLink: '/beginners-online-masterclass-bengali',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-ben-bls-intro.webp',
                            name: 'বাংলা ফ্রি ইন্ট্রো মাস্টারক্লাস (BLS)',
                            newPrice: '',
                            oldPrice: '',
                            free: 'Free'
                            // free: 'Coming Soon'
                        },

                        {
                            pageLink: '/masterclass-on-health',
                            cardBgImg: '/assets/images/rahul/texture-bg.webp',
                            cardThumbImg: '/assets/images/rahul/page-health-management.webp',
                            name: 'হেলথ ম্যানেজমেন্ট মাস্টারক্লাস ₹৯৯/-',
                            // newPrice: '৯৯',
                            // oldPrice: '৯৯৯',
                            free: 'শীঘ্রই আসছে'
                        },
                        
                        // {
                        //     pageLink: '/bengali-money-magnet-masterclass',
                        //     cardBgImg: '/assets/images/rahul/texture-bg.webp',
                        //     cardThumbImg: '/assets/images/rahul/money-challenge.webp',
                        //     name: 'মানি ম্যাগনেট মাস্টারক্লাস',
                        //     newPrice: '৩৪৯',
                        //     oldPrice: '',
                        //     free: ''
                        // },
                    ]
                }
            ]
        }
    ]


    return (
        <section className="courseListCard ptop">
            {
                cardCont?.map((sectionItems, id) =>
                    <div className={`courseClasses ${id % 2 == 0 ? '' : 'pt-5 mt-5'} ${id % 2 == 0 ? '' : 'pb-5'} ${(id === cardCont.length - 1) && id % 2 === 1 ? '' : 'mb-5'}`}>
                        <div className="container">
                            <h2 class="h2 fw700 poppins textDark mb-3 text-center">
                                {sectionItems.sectionHeadText} <span class="gradientText">{sectionItems.sectionSpanText}</span>
                            </h2>
                            <p class="fw600 poppins textDark text-center mb-5">{sectionItems.smallDesc}</p>
                            <div className="row gy-4">
                                {
                                    sectionItems?.classType?.map((classTypeItems, i) =>
                                        <>
                                            <div className="col-lg-5">
                                                <h3 class="mdFont fw700 poppins textDark mb-4">
                                                    {classTypeItems.classHeadText} <span class="gradientText">{classTypeItems.classSpanText}</span>
                                                </h3>
                                                {
                                                    classTypeItems?.cardData?.length ?
                                                        <div className="row gy-4">
                                                            {
                                                                classTypeItems?.cardData?.map((cardDataItems, idx) =>
                                                                    <div className="col-md-6 col-lg-6">
                                                                        <CoursePreviewCard
                                                                            data={cardDataItems}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </div> :
                                                        <h3 className='h2 textDark fw700 poppins mb-0'>
                                                            Coming Soon
                                                        </h3>
                                                }
                                            </div>
                                            {
                                                !isMobile &&
                                                <>

                                                    {
                                                        (i % 2 === 0) && <div className="col-lg-2">
                                                            <div className="previewCardBorder"></div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {isMobile &&
                                                <>
                                                    <div className="col-lg-2">
                                                        <div className="previewCardBorder"></div>
                                                    </div>

                                                </>
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </section>
    )
}

export default CourseListCard