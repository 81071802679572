import React from 'react'
import './WhatSilvaStyle.css'
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'
const WhatSilva = ({ sectionContent,sectionHeading, sectionText, buttonText }) => {
    return (
        <>
            <section className="whatSilvaSection pbtm">
                <div className="container">
                    {sectionContent}
                </div>
            </section>
        </>
    )
}

export default WhatSilva