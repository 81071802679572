import React from 'react'
import './SilvaProgramDetailsStyle.css'


const SilvaProgramDetails = ({ sectionTitle, sectionCont }) => {
    return (
        <>
            <section className="silvaProgramDetails ptop pbtm">
                <div className="container">
                    {sectionTitle}
                    {sectionCont}
                </div>
            </section>
        </>
    )
}

export default SilvaProgramDetails