import React from 'react'
import "./WhyChooseStyle.css";
import Icon13 from "../assets/choose-us-icon-13.svg"
import Icon14 from "../assets/choose-us-icon-14.svg"
import Icon15 from "../assets/choose-us-icon-15.svg"
import Icon16 from "../assets/choose-us-icon-16.svg"
import Icon17 from "../assets/choose-us-icon-17.svg"
import Icon18 from "../assets/choose-us-icon-18.svg"
import ReactPlayer from 'react-player'
const masterclassVideo = "https://www.youtube.com/watch?v=vR60nWTV0zo"


const WhyChoose = () => {
  return (
    <div>
      <section className="chooseUsSection text-center ptop pbtm lightBg">
        <div className="container">
          <p className="title fw600 poppins textDark text-capitalize">
            our specialties
          </p>
          <h2 className="h2 fw700 poppins textDark text-capitalize mb-5">
            why choose <span className="gradientText">silva method</span>
          </h2>
          <div className="row align-items-center gy-4 colRev">
            <div className="col-lg-6 col-xl-5">
              <div className="row gy-4">
                <div className="col-6">
                  <div className="widgetItems widgetItems1">
                    <div className="itemsCont">
                      <div className="cardFront">
                        <div className="icon">
                          <img src={Icon13} alt="#" loading="lazy" />
                        </div>
                        <div className="textCont text-start">
                          <h3 className="mdFont fw700 poppins textDark m-0">500+</h3>
                          <p className="fw600 poppins textDark text-capitalize m-0">instructors</p>
                        </div>
                      </div>
                      <div className="cardBack"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="widgetItems widgetItems2">
                    <div className="itemsCont">
                      <div className="cardFront">
                        <div className="icon">
                          <img src={Icon14} alt="#" loading="lazy" />
                        </div>
                        <div className="textCont text-start">
                          <h3 className="mdFont fw700 poppins textDark m-0">12M+</h3>
                          <p className="fw600 poppins textDark text-capitalize m-0">graduates</p>
                        </div>
                      </div>
                      <div className="cardBack"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="widgetItems widgetItems3">
                    <div className="itemsCont">
                      <div className="cardFront">
                        <div className="icon">
                          <img src={Icon15} alt="#" loading="lazy" />
                        </div>
                        <div className="textCont text-start">
                          <h3 className="mdFont fw700 poppins textDark m-0">120+</h3>
                          <p className="fw600 poppins textDark text-capitalize m-0">countries</p>
                        </div>
                      </div>
                      <div className="cardBack"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="widgetItems widgetItems4">
                    <div className="itemsCont">
                      <div className="cardFront">
                        <div className="icon">
                          <img src={Icon16} alt="#" loading="lazy" />
                        </div>
                        <div className="textCont text-start">
                          <h3 className="mdFont fw700 poppins textDark m-0">4.6/<sub>5</sub></h3>
                          <p className="fw600 poppins textDark text-capitalize m-0">amazon</p>
                        </div>
                      </div>
                      <div className="cardBack"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="widgetItems widgetItems5">
                    <div className="itemsCont">
                      <div className="cardFront">
                        <div className="icon">
                          <img src={Icon17} alt="#" loading="lazy" />
                        </div>
                        <div className="textCont text-start">
                          <h3 className="mdFont fw700 poppins textDark m-0">4.7/<sub>5</sub></h3>
                          <p className="fw600 poppins textDark text-capitalize m-0">facebook</p>
                        </div>
                      </div>
                      <div className="cardBack"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="widgetItems widgetItems6">
                    <div className="itemsCont">
                      <div className="cardFront">
                        <div className="icon">
                          <img src={Icon18} alt="#" loading="lazy" />
                        </div>
                        <div className="textCont text-start">
                          <h3 className="mdFont fw700 poppins textDark m-0">1M+</h3>
                          <p className="fw600 poppins textDark text-capitalize m-0">testimonials</p>
                        </div>
                      </div>
                      <div className="cardBack"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-7">
              <div className="rightCont">
                <ReactPlayer
                  url={masterclassVideo}
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WhyChoose