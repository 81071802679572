export const handleLeadFormSubmit = async (path, method = "POST", formData, headers) => {
    let requestOptions = {
        method: method,
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            ...headers
        }
    };

    // Only include body if method is not GET
    if (method !== "GET") {
        requestOptions.body = JSON.stringify(formData);
    }

    // console.log(`${process.env.REACT_APP_SERVER_URI}${path}`,'url')
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, requestOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
        return error
    }
}



export const handlePaymentNew = async (courseId, formData, purchaseType, headers, coupon, timeSlot) => {
    const reqBody = {
        product_id: courseId,
        user_id: `${formData.name}-${formData.email}-${formData.whatsapp_number}-${formData.country || null}-${formData.timeslot || null}`,
        purchase_type: purchaseType,
        coupon,
        notes: { timeSlot, coupon }
    }


    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}orders/create-order`,
            {
                method: "POST",
                body: JSON.stringify(reqBody),
                headers: {
                    "Content-Type": "application/json",
                    ...headers
                }
            }
        )
        const data = await res.json()
        // console.log(data);
        return data

    } catch (error) {
        console.log(error);
        return error
    }
}
