import React, { useContext, useState } from 'react'
import './JoinMasteryClassStyle.css'
import { Appcontext } from '../context/appcontext'
import { Link } from 'react-scroll';

const JoinMasteryClass = () => {
    const { isMobile } = useContext(Appcontext)
    const [showitem, setShowitem] = useState(4)

    const cardCont = [
        {
            desc: <p className='fw400 primaryWhite mb-0'>
                Overcome debts and financial Stress
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Get a positive mind to manage your finances
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Learn to handle overwhelming situations
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Release your negative thought patterns
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Break the chain of limiting beliefs
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Deeply relax your mind & body
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Reprogram the subconscious mind
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Manage relationship Issues
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Get rid of stress, anxiety & depression
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Overcome fear and past traumas
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                Eliminate self-victimization
            </p>
        },
        {
            desc: <p className='fw500 primaryWhite mb-0'>
                <span className='fw600'>As a bonus</span>, get three free guided meditation
            </p>
        },
    ]

    const copycardCont = [...cardCont];

    const handleShowMore = () => {
        if (showitem !== cardCont.length) {
            setShowitem((previousItem) =>
                previousItem + 4
            )
        }
    }

    const handlleShowLess = () => {
        setShowitem(4)
    }


    return (
        <>
            <section className="joinMasteryClass lightBg ptop pbtm" style={{ backgroundImage: "url('assets/images/rahul/masterclass-join.png')" }}>
                <div className="container">
                    <h2 class="h2 fw700 poppins primaryWhite mb-4 mb-lg-5 text-center">
                        Why Should Join This Masterclass?
                    </h2>
                    <div className="row">
                        {
                            isMobile && copycardCont?.splice(0, showitem).map((items, idx) =>
                                <div className="col-lg-4 col-md-6 p-2">
                                    <div className="classJoinCard d-flex align-items-center gap-2">
                                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ color: "white", fontSize: "20px" }}><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                        {items.desc}
                                    </div>
                                </div>
                            )
                        }

                        {
                            !isMobile && copycardCont?.map((items, idx) =>
                                <div className="col-lg-4 col-md-6 p-2">
                                    <div className="classJoinCard d-flex align-items-center gap-2">
                                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" color="white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ color: "white", fontSize: "20px" }}><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                        {items.desc}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        isMobile &&
                        <>
                            <div className='text-center'>
                                {

                                    showitem >= cardCont?.length ?
                                        <button onClick={handlleShowLess} className="btnMain fw700 primaryBlack text-capitalize mt-3 mt-lg-5 border-0 btnShow">
                                            Show Less
                                        </button> :
                                        <button onClick={handleShowMore} className="btnMain fw700 primaryBlack text-capitalize mt-3 mt-lg-5 border-0 btnShow">
                                            Show More
                                        </button>
                                }
                            </div>
                        </>
                    }
                    {/* <div className="text-center">
                    <Link to="masterClassForm" smooth={true} duration={500}>
                        <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 mt-lg-5 border-0">
                            Reserve Your Seat For Just Rs. 99
                        </button>
                        </Link>
                    </div> */}
                </div>
            </section >
        </>
    )
}

export default JoinMasteryClass