import React from 'react'
import './LearnMasterClassStyle.css'
import KalamImg from "../assets/KalamImg.webp"
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'

import classAgenda from '../assets/class-agenda.jpg'
import checkIcon from '../assets/check-bg-white.svg'

const LearnMasterClass = () => {
    const masterclassPoints = [
        {
            icon: checkIcon,
            points: 'মন, শরীর, ও প্রকৃতির মধ্যে সমন্বয় সাধন করতে শিখুন'
        },
        {
            icon: checkIcon,
            points: 'নিজের মনকে নিয়ন্ত্রনে রাখার উপায়'
        },
        {
            icon: checkIcon,
            points: 'নিয়মিত মেডিটেশন কেন করবেন'
        },
        {
            icon: checkIcon,
            points: 'ওষুধের সাহায্য ছাড়ায় মানসিক এবং শারীরিক সুস্থতা অর্জন'
        },
        {
            icon: checkIcon,
            points: 'মনকে ব্যবহার করে দেহকে সুস্থ  রাখার উপায়'
        },
        {
            icon: checkIcon,
            points: 'স্ট্রেস, রাগ, দুঃখ ইত্যাদি অনুভূতি গুলিকে নিয়ন্ত্রন করে সুখী থাকতে শিখুন'
        },
        {
            icon: checkIcon,
            points: 'সুস্বাস্থ্য বজায় রাখার উপায়'
        },
        {
            icon: checkIcon,
            points: 'রোগাক্রান্ত হয়ে পড়লে মনকে ব্যবহার করে রোগমুক্ত হন'
        },
        {
            icon: checkIcon,
            points: 'স্বাস্থ্যকর জীবনযাপনের অভ্যাস কীভাবে গড়বেন'
        },
        {
            icon: checkIcon,
            points: 'কিভাবে খারাপ অভ্যাস ত্যাগ করবেন'
        },
    ]


    return (
        <>
            <section className='LearnMasterClass silvaDiffSection ptop pbtm'>
                <div className='container'>
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-7">
                            <div class="videoArea"> 
                                <img src={KalamImg} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-5">
                            <h2 class="h2 fw600 poppins primaryWhite mb-3 mb-lg-4">
                                এই মাস্টারক্লাস থেকে আপনি কি কি শিখবেন
                            </h2>
                            <ul className='resetUl'>
                                {
                                    masterclassPoints?.map((items, idx) =>
                                        <li class="d-flex align-items-start text-start gap-2 mb-2">
                                            <img src={items.icon} alt="#" class="circleTick" />
                                            <p class="primaryWhite roboto fw300 mb-0">{items.points}</p>
                                        </li>
                                    )
                                }
                            </ul>
                            <div className="text-center text-lg-start">
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                            <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0" onClick={() => handleScroll()}>
                                রেজিস্টার করুন
                            </button>
                            </Link>
                            </div>
                            {/* <div className="text-center text-lg-start">
                                <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0">
                                    রেজিস্টার করুন
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LearnMasterClass