import React from 'react'
import Navbar2 from '../components/Navbar2'
import NewHeroSection from '../components/NewHeroSection'
import Quote from '../components/Quote'
import WhatSilva from '../components/WhatSilva'
import MasterclassForm from '../components/MasterclassForm'
import Highlights from '../components/Highlights'
import CourseForWhose from '../components/CourseForWhose'
import MasterclassSession from '../components/MasterclassSession'
import SilvaProgramDetails from '../components/SilvaProgramDetails'
import MasterclassBgImg from '../components/MasterclassBgImg'
import MasterclassInstructor from '../components/MasterclassInstructor'
import ReactPlayer from 'react-player'
import SlsFaqs from '../components/SlsFaqs'
import VideoTestimonial from '../components/VideoTestimonial'
import Reviews from '../components/Reviews'
import checkicon from '../assets/check-bg.svg'
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'
import { Helmet } from 'react-helmet';
const masterclassVideo = "https://www.youtube.com/watch?v=vt8E9JK0ujo"


const BengaliBLSIntro = () => {
    const beginnerCoursePoints = [
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যিনি সিলভা পদ্ধতির কথা শুনেছেন এবং এই পদ্ধতি সম্পর্কে জানতে আরও আগ্রহী।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যিনি জীবনে ব্যর্থতা, দঃখু , নেতিবাচকতা, ক্ষতি, ভয়, চাপ, উদ্বেগ, আতঙ্ক, রাগ, অস্থিরতা, বিষণ্নতা এবং উত্তেজনা ইত্যাদি অনভবু করছেন।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যদি শারীরিক বা মানসিক অসুস্থতায় ভুগছেন এবং শারীরিক ও মানসিকভাবে সুস্থ থাকতে চান।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যেকোনো ব্যক্তিগত এবং পেশাগত সম্পর্কের সমস্যা সমাধান করুন।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যিনি বেকারত্ব বা আর্থিক সমস্যার সমাধান চাইছেন।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'ক্লান্তি, অতিরিক্ত কাজের চাপ, অত্যধিক ব্যস্ততা এবং অস্থিরতা অনভবু করছেন।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যে কোনো মানষু যিনি, জীবনে আধ্যাত্মিক জাগরণ পেতে চায়।'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যিনি জীবনের চূড়ান্ত সাফল্য অর্জন করতে চায়।',
            hidemargin: true
        },
    ]

    const masterclassPoints = [
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Silva Method কি?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'কে আবিষ্কার করেছে?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'মস্তিষ্ক এবং মনের সংযোগ স্থাপনের উপায় কি?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'আত্মবিশ্বাসকে চূড়ান্ত পর্যায়ে উন্নীত করা যায় কীভাবে?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'ব্যর্থতা, দুঃখ , নেতিবাচকতা, ভয়, মানসিক চাপ, উদ্বেগ, রাগ, বিষণ্ণতা এবং টেনশনের স্থায়ী সমাধান কি?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'চিন্তাভাবনা এবং কল্পনাকে পুনর্নির্মাণ করা যায় কীভাবে?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'দ্রুত লক্ষ্য অর্জন কীভাবে সম্ভব?'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'বিশ্বের সব সফল ব্যক্তিদের অকথিত গোপন রহস্য কি?',
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'মানসিক ও শারীরিক সুস্থতা উন্নয়নের পদ্ধতি কি?',
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'যেকোনো পরিস্থিতিতে সুখী এবং শান্তিপূর্ণ হওয়া যায় কীভাবে?',
            hidemargin: true
        },
    ]

    const aboutJoseSilva = [
        {
            points: `José Silva হলেন Silva Method- এর প্রতিষ্ঠাতা, এবং mind empowerment 
            গবেষণার পথিকৃৎ। সিলভা মানুষের মনের লুকানো সম্ভাবনা জাগ্রত করার জন্য তাঁর জীবন উৎসর্গ 
            করেছিলেন। 22 বছরের গবেষণার পর, তিনি 1966 সালে তাঁর ফলাফল প্রকাশ করেছেন।তাঁর 
            আবিষ্কার কে নিখুঁত এবং মানুশকে শিক্ষাদানের জন্য বাকি জীবন অতিবাহিত করেন।সিলভার উত্তরাধিকার 
            আজ Silva International- এর মাধ্যমে পরিচালিত হয়, একটি সংস্থা যা তাঁর মিশন চালিয়ে যাওয়ার জন্য 
            প্রতিশ্রুতিবদ্ধ – যা মননশীল অনুশীলনের মাধ্যমে জীবনকে উন্নত করার লক্ষ্যে নিবেদিত।`
        }
    ]

    const celebCont = [
        {
            points: `সেলিব্রিটিদের সাফল্যের একটি গোপন অস্ত্র হল – মে ডি টে শন।মনোযোগ, 
            সৃজনশীলতা এবং সামগ্রিক সুস্থতা বাড়াতে তারা নিয়মিত মে ডি টে শন করেন।তারকারা 
            বলেছেন যে মে ডি টে শন করার জন্য প্রতিদিন একটুসময় নেওয়া তাদের মনোযোগ নিবদ্ধ 
            রাখতে এবং সমস্ত মনোযোগ পরিচালনা করতে সহায়তা করে। এটা শুধুশান্ত বোধ সম্পর্কে নয়, 
            মে ডি টে শন তাদের আরও সৃজনশীল হতে এবং তাদের কাজে আরও ভাল করতে সহায়তা করে।
            মননশীলতা কৌশল এবং মে ডি টে শন মানসিক চাপ কমাতে, রাগ ও বিভিন্ন সমস্যাগুলি নিয়ন্ত্রণ 
            করতে এবং একটি শান্ত জীবনযাপনের সর্বোত্তম উপায় হিসাবে আবির্ভূত হয়েছে।সে লি ব্রি টিদে র 
            মধ্যে মে ডি টে শন এখন খুবই জনপ্রি য় একটি চর্চা।`
        }
    ]

    const benefitsPoints = [
        {
            points: 'বৈচিত্র্যময় ব্যক্তিদের সাথে একটি global community - তে সংযোগ স্থাপন করুন৷'
        },
        {
            points: 'আপনার প্রশ্নের সমাধান করুন।'
        },
        {
            points: 'সিলভা গ্র্যাজুয়েটদের সাথে সাপ্তাহিক গ্র্যাজুয়েট মিটিংয়ে অংশগ্রহণ করুন।'
        },
        {
            points: 'সার্টিফায়েড সিলভা পদ্ধতি প্রশিক্ষকদের সাথে সংযোগ করুন।'
        },
        {
            points: 'সফলতার প্রকৃত সংজ্ঞা জানুন।',
            hidemargin: true
        },
    ]

    const reviews = [
        {
            image: "/assets/images/rahul/hugh-jackman.webp",
            name: "হিউ জ্যাকম্যান",
            comment: `'এক্স ম্যান উলভারাইন' খ্যাত অভিনেতা হিউ জ্যাকম্যান। তার ক্যারিয়ারের সবচেয়ে 
            আলোচিত এই ফিল্মসিরিজে অভিনয় করার সময় যে কঠোর অনুশীলন এবং ডায়েটিং করতে হয়েছে 
            তাকে, তখন মেডিটেশনই তাকে যুগিয়েছে ধৈর্য, সাহায্য করেছে সবকিছু ভুলে শুধু তার চরিত্রের প্রতি 
            মন দিতে। অপরাহ উইনফ্রে-র সাথে এক সাক্ষাৎকারে জ্যাকম্যান বলছিলেন,মেডিটেশন করতে করতে 
            আমি ভুলে যেতাম—আমি একজন বাবা, আমি একজন স্বামী। আমি হিউ জ্যাকম্যান। শুধু মনে পড়ত যে, 
            আমি ডুব দিচ্ছি এমন এক শক্তিভাণ্ডারে, যা সবকিছুকে সৃষ্টি করেছে। মেডিটেশন করে জ্যাকম্যান তার 
            ওসিডি থেকে মুক্ত হয়েছিলেন।`
        },
        {
            image: "/assets/images/rahul/Steve_Jobs.webp",
            name: "স্টিভ জবস",
            comment: `স্টিভ জবস মেডিটেশন করতেন অ্যাপল কোম্পানির সিইও প্রয়াত স্টিভ জবসও। আধ্যাত্মিকতার সন্ধানে বন্ধু ড্যানিয়েলকে 
            নিয়ে তিনি ভারত যেমন ভ্রমণ করেছেন, তেমনি নিজ দেশ আমেরিকার এক জেন সন্ন্যাসীর কাছেও নিয়েছেন 
            জেন মেডিটেশনের পাঠ।`
        },
        {
            image: "/assets/images/rahul/Oprah-Winfrey.webp",
            name: "অপরাহ গেইল উইনফ্রে",
            comment: `‘অপরাহ উইনফ্রে শো’ খ্যাত মিডিয়াব্যক্তিত্ব অপরাহ গেইল উইনফ্রে। গ্রামের এক দরিদ্র কিশোরীর গর্ভে পিতৃপরিচয়হীন 
            অবস্থায় যার জন্ম, তিনিই আজ বিংশ শতাব্দীর সবচেয়ে ধনী আফ্রিকান আমেরিকান, উত্তর আমেরিকার একমাত্র কালো বিলিয়নার, 
            পৃথিবীর সবচেয়ে প্রভাবশালী নারী। সেই অপরাহ এখন মেডিটেশন করছেন। ২০১১ সালে হার্পো প্রডাকশন্সের স্টুডিওতে মেডিটেশন 
            ট্রেইনারদের ডেকেছিলেন তার সব সহকর্মীদের মেডিটেশন শেখাবার জন্যে। তখন থেকেই কাজের শুরু এবং শেষে মেডিটেশন করছেন 
            তার কর্মীরা। ফলাফল চমৎকার। তাদের মাইগ্রেন ভালো হয়ে গেছে। ঘুম ভালো হচ্ছে, সহকর্মী এবং বন্ধুদের সাথে তারা এখন আগের 
            চেয়ে অনেক সৌহার্দ্যপূর্ণ। অপরাহ বলেন, মেডিটেশন তাকে স্ট্রেসমুক্ত হতে শিখিয়েছে, জীবন থেকে ইতিবাচকতাকে খুঁজে নিতে শিখিয়েছে।`
        },
    ]


    return (
        <>
            <Helmet>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              fbq('track', 'CompleteRegistration');
            `,
                    }}
                />
            </Helmet>
            <Navbar2 />
            <NewHeroSection
                customClassName="bengaliMasterclassPage"
                bannerImg="/assets/images/rahul/masterclass-bengali-header.webp"
                bannerTabImg="/assets/images/rahul/masterclass-bengali-header-tab.webp"
                bannerMobileImg="/assets/images/rahul/masterclass-bengali-header-mob.webp"
                title="The Silva Method"
                nextLine="মন, দেহ, ও আত্মার সমন্বয় সাধন"
                subtitle="যেমন জীবনের কল্পনা আপনি করেছিলেন, সেই জীবন কি আপনি ব্যাতিত করছেন? না, 
                তাই তো? আপনি হয়তো বলবেন, মন তো অনেক কিছুই কল্পনা করে কিন্তু সব ইচ্ছা পূর্ণতা পায় না।কিন্তু 
                আমি যদি বলি Silva Method আপনাকে আপনার সব ইচ্ছা পূরণের রাস্তা দেখাতে পারে। অন্য কেউ বা অন্য 
                কিছু না, আপনি নিজেই আপনার নিজের কাঙ্ক্ষিত জীবনের স্থপতি হতে পারেন।কিন্তু কিভাবে? নিজের মন কে 
                নিয়ন্ত্রন করতে শিখুন। আপনার মন-ই আপনার সকল সাফল্যের চাবিকাঠি।"
                dateTime={
                    <p class="p2 fw600 poppins primaryWhite">
                        Date : 21st September<br />
                        Time : 08:30 PM (IST)
                    </p>
                }
                description=""
                webBannerImg="webBannerImg"
                bannerBtn={
                    <Link to="masterClassForm_bls" smooth={true} duration={500}>
                        <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape" onClick={() => handleScroll("#masterClassForm_bls")}>
                            এখনই যোগদান করুন
                        </a>
                    </Link>
                }
            />
            <Quote
                quote="অসাধারণ হওয়ার চাবিকাঠি হল, কোন নিয়মগুলি অনসরণ করতে হবে এবং কোন নিয়ম ভঙ্গ করতে হবে তা জানা।"
                quoteStyle="quoteStyle"
            />
            <WhatSilva
                sectionContent={
                    <>
                        <h2 class="h2 fw700 poppins textDark text-capitalize text-center mb-4">
                            <span class="gradientText">Silva Method</span> কি?
                        </h2>
                        <p class="fw500 textDark poppins mb-4 mb-lg-5 text-center p1">
                            The Silva Method – বৈজ্ঞানিকভাবে প্রমাণিত মন নিয়ন্ত্রণ কৌশল।1960 এর দশকে José Silva
                            দ্বারা তৈরি একটি Self-Help, মেডিটেশন পদ্ধতি। এটি একটি personal development পদ্ধতি যা
                            মানসিক ক্ষমতা বাড়াতে এবং ব্যক্তিগত লক্ষ্য অর্জনের জন্য meditation, visualization, এবং
                            positive thinking – কে একত্রিত করে। অংশগ্রহণকারীদের একটি স্বস্তিদায়ক মেডিটেটিভ লেভেলে
                            পৌঁছানোর জন্য অনুশীলনের মাধ্যমে নির্দেশিত করা হয়, যা আলফা স্তর নামে পরিচিত, যেখানে পৌঁছে
                            তারা আত্মনিমগ্ন হয়ে যায়।গভীর আত্মনিমগ্নতা আত্মশক্তির জাগরণ ঘটায় ভেতর থেকে। আর অন্তরের এই
                            জাগরণই বদলে দেয় জীবনের বাকি সবকিছু।ক্রমাগত তারা সমস্যা সমাধান এবং স্ব-উন্নতির জন্য তাদের
                            অবচেতন মনকে নিয়ন্ত্রন করতে শেখে।পদ্ধতিটির লক্ষ্য একটি ইতিবাচক মানসিকতা গড়ে তোলা, ফোকাস
                            উন্নত করা এবং ব্যক্তিদের তাদের জীবনের বিভিন্ন দিকগুলিতে কাঙ্ক্ষিত ফলাফল প্রকাশের জন্য ক্ষমতায়ন করা।
                        </p>
                        <div className="text-center">
                            <Link to="masterClassForm_bls" smooth={true} duration={500}>
                                <button type="button" class="btnMain btnSec fw700 gradientBg primaryWhite text-capitalize border-0" onClick={() => handleScroll("#masterClassForm_bls")}>
                                    শেখার অভিজ্ঞতা শুরু করুন
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <MasterclassForm
                heading="বিগিনারদের জন্য The Silva Method"
                spanText="Online Masterclass"
                descText="Positive Thinking দ্বারা মনের স্বেচ্ছানিয়ন্ত্রণ করতে শিখুন এবং আপনার ইচ্ছাকৃত ভবিষ্যত ডিজাইন করুন!"
                dateBox={
                    <p className="p2 fw600 poppins textDark text-center">
                        Date : 21st September, 2024
                        <br />
                        Time : 08:30 PM (IST)
                    </p>
                }
                formHeading="এখনই আপনার স্পট বুক করুন!"
                placeName="নাম"
                placeNo="হোয়াটসঅ্যাপ নম্বর"
                placeMail="ইমেইল"
                btnText="আমাদের হোয়াটসঅ্যাপ গ্রুপে যোগ দিন"
                btnBtmText="* সীমিত আসন উপলব্ধ"
                video={
                    <ReactPlayer
                        url={masterclassVideo}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                }
            />

            <Highlights />
            <CourseForWhose
                ptop="ptop"
                startText="এই"
                spanText=" Beginner's Course"
                endText=" টি কাদের জন্য?"
                pointsArr={beginnerCoursePoints}
                img="/assets/images/rahul/course-img.webp"
                imgPos="right"
            />
            <MasterclassSession
                bgImg="assets/images/rahul/session-img.webp"
                headingText=""
                descText=""
                cardTitle1="আমাদের প্রাক-রেকর্ড করা Beginner's মাস্টার ক্লাস সেশন দেখুন"
                cardTitle2="এখনই আমাদের Beginner's মাস্টার ক্লাসে যোগ দিন এবং নতুন দিগন্ত অন্বেষণ করুন"
                cardBtn1="এখন দেখো"
                cardBtn2={
                    <div className="text-center">
                        <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" onClick={() => handleScroll("#masterClassForm_bls")}>
                            এখনি যোগ দিন
                        </button>
                    </div>
                }
                outerBtn=""
            />
            <CourseForWhose
                ptop="ptop"
                startText="এই"
                spanText=" মাস্টারক্লাস"
                endText=" থেকে আপনি কি কি শিখবেন?"
                pointsArr={masterclassPoints}
                img="/assets/images/rahul/masterclass-img.webp"
                imgPos="left"
            />
            <MasterclassSession
                bgImg="assets/images/rahul/offer-img.webp"
                headingText="বিশেষ Offer"
                descText="বিনামূল্যে দুটি Meditation Exercise- এর উপকারিতা পেয়ে যান"
                cardTitle1="মন,শরীর, ও আত্মার রিলাক্সেশন মেডিটেশন"
                cardTitle2="স্ট্রেস উপশমকারী মেডিটেশন"
                cardBtn1=""
                cardBtn2=""
                outerBtn={
                    <div className="text-center">
                        <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" onClick={() => handleScroll("#masterClassForm_bls")}>
                            Offer টি পেতে এখনই Click করুন
                        </button>
                    </div>
                }
            />
            <SilvaProgramDetails
                sectionTitle={
                    <h2 className="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        সিলভা মেথড <span className="gradientText">বিগিনার প্রোগ্রামের</span> বিশদ বিবরণ
                    </h2>
                }
                sectionCont={
                    <div className="row align-items-center row-gap-5">
                        <div className="col-lg-6">
                            <div className="leftCard">
                                <h3 className='mdFont fw600 textDark mb-4'>
                                    আপনি কি প্রস্তুত?
                                </h3>
                                <p class="title fw500 textDark roboto mb-4">
                                    1Million – এরও বেশি মানুষের সাথে যোগ দিন যারা একটি রূপান্তরমূলক যাত্রা
                                    গ্রহণ করেছে, তাদের ব্যক্তিগত এবং পেশাদার পথগুলিকে নতুন করে সাজিয়েছে৷
                                    ভয়কে জয় করুন,নতুন করে নিজেকে চিনুন এবং Silva Method – এর মাধ্যমে
                                    শারীরিক সুস্থতা এবং মানসিক স্বাস্থ্য উভয় ক্ষেত্রেই একটি বিপ্লব অনুভব করুন।
                                    José Silva বিশ্বাস করতেন যে আমরাই পারি আমাদের নিজেদের জীবনের স্থপতি হতে।
                                    তাই আপনিও বলুন, “আমি যা চাই তা-ই পাবো। আমি পারি আমি করব।”
                                    আর দ্বিধা করবেন না – আশ্চর্যজনক নতুন আপনাকে উন্মোচন করতে পদক্ষেপ গ্রহন করুন।
                                </p>
                                <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0" onClick={() => handleScroll("#masterClassForm_bls")}>
                                    বিনামূল্যে যোগদান করুন
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rightCont">
                                <h3 className='mdFont fw600 textDark mb-4'>
                                    অংশগ্রহণ করে কি কি সুবিধা পাবেন?
                                </h3>
                                <ul className='resetUl'>
                                    {
                                        benefitsPoints?.map((item, idx) =>
                                            <li class={`d-flex align-items-start gap-2 ${item.hidemargin ? "" : "mb-3"}`}>
                                                <img src={checkicon} alt="#" loading="lazy" class="circleTick" />
                                                <p class="title textDark roboto fw300 mb-0">{item.points}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            />
            <MasterclassBgImg
                fullWidthImg={
                    <img src="/assets/images/rahul/large-img.webp" alt="#" loading='lazy' className='w100 h100' />
                }
            />
            <CourseForWhose
                ptop="ptop"
                bgColor="lightBg"
                startText="Silva Method- এর"
                spanText=" অগ্রগামী এবং প্রতিষ্ঠাতা"
                endText=""
                pointsArr={aboutJoseSilva}
                img="/assets/images/rahul/masterclass-jose-silva.webp"
                imgPos="right"
            />
            <CourseForWhose
                ptop="ptop"
                startText="সেলিব্রিটি জগতে"
                spanText=" মেডিটেশন"
                endText=""
                pointsArr={celebCont}
                img="/assets/images/rahul/celeb-img.webp"
                imgPos="left"
            />
            <Reviews
                reviewCont={reviews}
            />
            <MasterclassInstructor />
            <VideoTestimonial
                heading="Silva Method -এ অংশগ্রহণকারীরা কি বলছে জানুন"
            />
            <MasterclassForm
                heading=""
                spanText=""
                descText=""
                dateBox=""
                rightText={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 ms-0">
                            মনকে নিয়ন্ত্রন করুন এবং নিজের বাস্তবতা তৈরি করুন
                        </h2>
                        <p class="title fw500 textDark roboto mb-4">
                            পরিবর্তিত মনের অবস্থা অ্যাক্সেস করতে শিখুন এবং নিজের পছন্দ মত ভবিষ্যত ডিজাইন করুন
                        </p>
                    </>
                }
                formHeading="এখনই আপনার স্পট বুক করুন!"
                placeName="নাম"
                placeNo="হোয়াটসঅ্যাপ নম্বর"
                placeMail="ইমেইল"
                btnText="এখন বুক করুন"
                btnBtmText="* সীমিত আসন উপলব্ধ"
                formPosition="left"
                mobFormPos="bottom"
            />
            <SlsFaqs />
        </>
    )
}

export default BengaliBLSIntro