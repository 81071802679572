import React from 'react'
import "./FAQ2Style.css";


const FAQ2 = () => {
  return (
    <div>
      <section className="faqSection lightBg faqSection2">
        <div className="faqCont">
          <div className="headingArea text-center mb-4">
            <p className="title fw600 poppins textDark mb-2">
              Frequently asked questions
            </p>
            <h2 className="h2 fw700 poppins textDark text-capitalize">
              about <span className="gradientText">silva method</span>
            </h2>
          </div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  1.Are these live sessions or recorded videos?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  All sessions are conducted live on zoom where the instructor guides and interacts with the participants. However, if anyone missed the live class, recorded videos are also provided through mail on the next day after live class.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  2. In which language is the course taught?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The course is taught in English language to allow the course to reach a broader audience and facilitate better understanding among participants from different countries.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  3. What if I miss a session due to urgent work?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  No worries, if you miss a session! You can catch up with session by watching the recorded video provided through mail.
                  Recordings are accessible for 48 hours, so try not to miss the practice.

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  4. Can I attend both Morning and Evening Sessions?
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  You can choose one session between the two time slots either in the morning from 10 Am to 1 PM/ 6:30 PM TO 9:30 PM. But, you can change the time slot according to availability by raising a request on WhatsApp No. 6290945413

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  5. Will it really have a positive impact on life?
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The change depends on your dedication and regular practice as guided during the sessions.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  6. What’s the fee for this program?
                </button>
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The fee for this program is Rs.99 only.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  7. What is the duration of the session?
                </button>
              </h2>
              <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  The duration of the session lasts for approximately 3 hours followed by complete guidance and resolving participant’s doubts.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  8. What if I don’t get payment receipt immediately?
                </button>
              </h2>
              <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  If you are having problems getting payment receipts, you are requested to wait for a while, and our team will send the receipt to your registered email id shortly.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FAQ2