import React from "react";
import "../../components/PersonalGrowthStyle.css";

const SlsPersonalGrowth = () => {
  return (
    <div>
      <section className="personalGrowthSection pbtm">
        <div className="container">
          <div className="contArea text-center">
            <p className="title fw600 poppins textDark">
            Silva Life System
            </p>
            <h2 className="h2 fw700 poppins textDark text-center mb-5">
              
              <span className="gradientText">
                A Dynamic & Active Meditation Course
              </span>
            </h2>
            <p className="desc fw400 textDark poppins text-start">
              Have you ever wondered why some of these seemingly “fortunate”
              individuals experience profound, transformative breakthroughs
              while you might still be struggling with financial difficulties,
              health issues, unfulfilled potential, and erratic ups and downs in
              your professional and personal life? Research in the field of mind
              science has indicated that achieving our desires, be it abundance,
              career achievements, well-being, happiness, or spiritual
              enlightenment, hinges on a specific state of mind, the Alpha &
              Theta level of mind. And you can attain these state of your mind
              using Silva Life System.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SlsPersonalGrowth;
