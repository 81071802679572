import React, { useContext, useState } from 'react'
import Navbar2 from '../components/Navbar2'
import MasteryHero99 from '../components/MasteryHero99'
import VideoBtmSection from '../components/VideoBtmSection'
import ClassAgenda from '../components/ClassAgenda'
import HappyLifeSection from '../components/HappyLifeSection'
import SilvaFounder from '../components/SilvaFounder'
import SickSection from '../components/SickSection'
import PracticeMeditation14 from '../components/PracticeMeditation14'
import MoneyMagnetForm from '../components/MoneyMagnetForm'
import AboutInstructor from '../components/AboutInstructor'
import Footer2 from '../components/Footer2'
import { handleScroll } from '../utils/animPulse'
import ReactPlayer from 'react-player'
import { Appcontext } from '../context/appcontext'
import { Link } from 'react-scroll'
import CheckImg from "../assets/check-bg.svg"
import facebook from "../assets/facebook-official.svg"
import youtube from "../assets/youtube-official.svg"
import instagram from "../assets/instagram-official.svg"
import instructor1 from '../assets/Debalina-Chatterjee.webp'
const InstructorVideo = "https://silva-method-official.b-cdn.net/lions-gate-silva-india/Lions%20Gate%20Portal%20Day-1920x1080.mp4"



const LionGate = () => {
    const [playing, setPlaying] = useState(false)
    const { isMobile } = useContext(Appcontext)


    const portalDayPoints = [
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Enhance Manifestation:</strong> The amplified energy creates a fertile ground for
                planting your seeds of intention and watching them grow rapidly.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Amplified Energies:</strong> This day is known for its heightened vibrational energies,
                making it an optimal time to set intentions and attract positive changes into your life.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Intuitive Insight:</strong> The portal enhances your intuitive abilities, providing clarity
                and a deeper understanding of your life’s purpose and direction.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Transformation:</strong> Embrace significant personal and spiritual growth as the energies of
                the portal support profound changes and new beginnings.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Universal Support:</strong> Receive cosmic support for your aspirations as the universe aligns
                to help you manifest your dreams and goals.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Empowerment:</strong> Experience a boost in confidence and personal power, enabling you to take
                decisive actions towards achieving your desires.</p>,
            hidemargin: true
        },
    ]

    const workshopPoints = [
        {
            icon: CheckImg,
            desc: `Learn Effective Manifestation Technique`,
        },
        {
            icon: CheckImg,
            desc: `Understand the significance of the Lion's Gate Portal.`,
        },
        {
            icon: CheckImg,
            desc: `Master powerful manifestation techniques.`,
        },
        {
            icon: CheckImg,
            desc: `Learn how to set clear, actionable goals.`,
        },
        {
            icon: CheckImg,
            desc: `Harness the energy of the universe to manifest your desires.`,
        },
        {
            icon: CheckImg,
            desc: `Use visualization techniques to attract abundance.`,
        },
        {
            icon: CheckImg,
            desc: `Overcome limiting beliefs that hold you back.`,
        },
        {
            icon: CheckImg,
            desc: `Experience an expert-guided meditation for effective manifestation.`,
        },
        {
            icon: CheckImg,
            desc: `Learn practical exercises to enhance your intuitive power.`,
        },
    ]

    const cardData = [
        {
            id: 1,
            instructorimg: instructor1,
            instructortitle: 'Debalina Chatterjee',
            instructordesc1: `As a Certified Silva Method Instructor, Debalina brings a 
            dynamic and passionate approach to personal empowerment through her training sessions. 
            With a background in esteemed multinational corporations such as Accenture and Tata 
            Consultancy Services, she is equipped with problem-solving skills and a deep understanding 
            of how the mind works, which she constantly integrates into her teachings. Debalina is dedicated 
            to helping individuals take control of their destinies and unlock their full potential. Through 
            the Silva Method, she provides you with a toolkit of powerful techniques that will enable you to 
            shape the reality you truly desire.`,
            instructordesc2: `You can find Debalina in The Podcast series "The Silva Talk Show" hosted 
            on the Silva Method Official YouTube channel, where she discusses amazing topics with other 
            instructors from all over the world.`
        }
    ]



    return (
        <div style={{ backgroundImage: "url('/assets/images/rahul/line-bg.jpg')", backgroundSize: "contain", backgroundPosition: "center" }}>
            <Navbar2 />
            <MasteryHero99
                playing={playing}
                setPlaying={setPlaying}
                heroSection="lionsGatePortal"
                mobBanner="/assets/images/rahul/lions-gate-mob-banner.webp"
                tabBanner="/assets/images/rahul/lions-gate-web-banner.webp"
                webBanner="/assets/images/rahul/lions-gate-web-banner.webp"
                heroTextCont={
                    <>
                        <h1 className='h1 fw700 poppins primaryWhite mb-3 mb-lg-4 smHead'>
                            Get ready to Tap Into <span className='colorYellow'>The Universe’s Most Powerful Energies</span>
                        </h1>
                        <h2 class="h2 fw700 NotoSansBengali primaryWhite mb-lg-4">
                            This August 8th, join us for an extraordinary manifestation workshop on the Lion's Gate Portal day.
                            Manifest Your deepest desires and experience the ultimate synergy of celestial power to transform them into reality.
                        </h2>
                    </>
                }
                heroVidCont={
                    <div className="masteryHeroVideo" style={{ maxHeight: playing && !isMobile ? '450px' : isMobile ? playing ? '192px' : '450px' : '' }}>
                        {/* <img src="assets/images/rahul/lions-gate-thumb.webp" alt="#" className='w100 h100' /> */}
                        <ReactPlayer
                            url={InstructorVideo}
                            light="assets/images/rahul/lions-gate-thumb.webp"
                            width="100%"
                            height="100%"
                            playing
                            muted
                            loop
                            controls={true}
                            onPlay={() =>
                                setPlaying(true)
                            }
                        />

                    </div>
                }
            />
            <VideoBtmSection
                playing={playing}
                bgImg="/assets/images/rahul/money-management-bg.webp"
                textCont={
                    <>
                        <div className='d-flex justify-content-around flex-column row-gap-4 flex-lg-row poppins'>
                            <div className='dateTime fw600 poppins primaryWhite text-center flex-column'>
                                {/* <p className='mdFont fw900 colorYellow mb-2'>
                                    <span className='me-2'><i class="fa-solid fa-clock"></i></span>
                                    সময়
                                </p> */}
                                {/* <p className='mb-3'>সন্ধ্যে ৭ টা থেকে রাত ৯ টা (ভারতীয় সময়)</p> */}

                                {/* <p className='mb-0'>8th August, 8 P.M.(IST)</p>
                                <p className='mb-0'>Language: English</p> */}
                                
                                <p className='mb-0'>Coming Soon</p>
                            </div>
                            <div className='text-center text-lg-start'>
                                <p className='primaryWhite fw800 poppins mb-4 dateTimeTitle ps-lg-5'>
                                    Special Manifestation Workshop on 8th August
                                </p>
                                <p className='primaryWhite poppins mb-0 ps-lg-5'>
                                    <p className='mb-0 fw500 title colorYellow'>Registration Fee:</p>
                                    <p className='dateTimeTitle fw800'>₹ 99/- (INR)</p>
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="masterClassForm" smooth={true} duration={500}>
                                <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 mt-lg-5 border-0" onClick={() => handleScroll()}>
                                    Register Now
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <ClassAgenda
                lightBg="lightBg"
                masterClassCont={
                    <div className="masterClassCont">
                        <div className="row align-items-center row-gap-4">
                            <div className="col-lg-6">
                                <div className="videoArea pe-lg-5">
                                    <img src="/assets/images/rahul/Lions-Gate-Portal-img.webp" alt="#" loading="lazy" className="w100 h100 agendaImg border-0" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="rightCont">
                                    <h2 className="h2 fw700 poppins textDark mb-3 text-center text-lg-start">
                                        Specialties of <span className="gradientText">The Lion’s Gate Portal Day</span>
                                    </h2>
                                    <p class="mb-5 fw500 poppins text-center text-lg-start">
                                        This potent event generates cosmic energy that amplifies intentions and manifestation power.
                                    </p>
                                    <ul className="resetUl">
                                        {
                                            portalDayPoints.map((items, idx) =>
                                                <li className={`d-flex align-items-start gap-2 ${items.hidemargin == true ? "" : "mb-3"}`}>
                                                    <img src={items.icon} alt="#" loading="lazy" className="circleTick" />
                                                    <p className="textDark roboto fw500 mb-0">{items.desc}</p>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className='text-center text-lg-start mt-lg-4'>
                                        <Link to="masterClassForm_health" smooth={true} duration={500}>
                                            <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-3 border-0 shadowLight" onClick={() => handleScroll()}>
                                                Enroll Now
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            <HappyLifeSection
                contentSection={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 text-center">
                            <span class="gradientText ms-2">Significance of Manifestation</span> on This Day
                        </h2>
                        <p class="p1 textDark roboto mb-4 text-center m-auto">
                            The Lion's Gate Portal magnifies the power of manifestation, providing a rare opportunity
                            to align your goals with the cosmos. The portal's energies offer a clear channel to the universe,
                            amplifying your thoughts, intentions, and desires. This alignment creates a fertile ground for manifesting
                            dreams, making your intentions more likely to materialize.
                        </p>
                        <div class="videoArea m-auto mb-4">
                            <img src="/assets/images/rahul/Lions-Gate-Portal-img-1.webp" alt="#" loading="lazy" class="w100 h100 agendaImg" />
                        </div>
                        <p class="p1 textDark roboto mb-0 text-center m-auto">
                            On this day, the alignment between the Sun and Sirius opens a gateway to cosmic wisdom and clarity,
                            allowing you to refine and focus your goals with precision. The heightened vibrational energies make
                            it easier to overcome obstacles and attract the resources and opportunities needed to achieve your aspirations.
                            By harnessing these powerful energies, you can transform your vision into reality with greater ease and effectiveness.
                        </p>
                        <div className='text-center mt-4'>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 border-0 shadowLight" onClick={() => handleScroll()}>
                                    Register Now
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <SickSection
                extraSecName="attendWorkshop workshopExtraStyle"
                lightBg=""
                sectionCont={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h2 className="h2 fw700 poppins textDark mb-3 mb-lg-4">Why Should Attend This
                                <span className="gradientText"> Workshop</span>
                            </h2>
                            <ul className='resetUl'>
                                {
                                    workshopPoints?.map((item, idx) =>
                                        <li className="d-flex align-items-center gap-2 mb-2">
                                            <img src={item.icon} alt="#" loading="lazy" className="circleTick" />
                                            <p className="textDark fw500 roboto mb-0 pt-1 text-start">{item.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                            <div className='mt-lg-4'>
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-3 border-0 shadowLight" onClick={() => handleScroll()}>
                                        Enroll Now
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="instructorVideo ms-auto">
                                <img src="/assets/images/rahul/Attend-This-Workshop.webp" alt="#" loading="lazy" className="w100 h100" />
                            </div>
                        </div>
                    </div>
                }
            />
            <SilvaFounder
                sectionBg="/assets/images/rahul/texture-bg.webp"
                heading={
                    <h2 class="h2 fw700 poppins primaryWhite mb-4 mb-lg-5 text-center">
                        Why This Day Holds <span className='colorYellow'>Special Spiritual Meaning</span>
                    </h2>
                }
                textCont={
                    <>
                        <p className="p1 fw300 primaryWhite poppins mb-0 m-auto text-center">
                            The Lion's Gate Portal, an astrological and spiritual event, occurs annually between
                            July 28 and August 12, peaking on August 8  or 8/8. During this time, the star Sirius
                            aligns with the Sun, creating a powerful opportunity for people to strengthen their
                            dreams, hopes, and intentions. It is a multi-dimensional astrological alignment that
                            connects Earth with the initiating energies of Sirius. This event is known for strength
                            and determination and powerful alignment of celestial energies. In the New Age, this
                            alignment is known as the 'Lion's Gate,' while in Christianity, it is recognized as the
                            time of Christ's Transfiguration.
                        </p>
                        <div className='text-center mt-4'>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 border-0" onClick={() => handleScroll()}>
                                    Book Now for ₹99/-
                                </button>
                            </Link>
                        </div>
                    </>
                }
                imgCont={
                    <div class="videoArea m-auto mt-5">
                        <img src="/assets/images/rahul/star-lion-copy.webp" alt="#" loading="lazy" class="w100 h100 challengeImg" />
                    </div>
                }
            />
            <PracticeMeditation14
                customStyle="moneyAttraction"
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4 reverseCol">
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-4 text-center text-lg-start">
                                <span className="gradientText ms-2">The Numerological Importance</span> of this Day
                            </h2>
                            <p className="textDark poppins mb-3 text-center text-lg-start">
                                The significance of August 8 (8/8) in numerology shouldn't be overlooked. The number eight
                                is associated with wealth, luck, and positivity. When turned sideways, it becomes the symbol
                                for <strong>infinity</strong>, representing abundance and endless possibilities—key elements
                                in manifestation.
                            </p>
                            <p className="textDark poppins mb-3 text-center text-lg-start">
                                The number 8 also symbolizes confidence, passion, power, resilience, karmic cycles, and infinite
                                transformation. When the Lion's Gate Portal opens on the new moon on 8/8, it presents a golden
                                opportunity to harness the best energies for your desires and goals.
                            </p>
                            <div className="text-center text-lg-start">
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={() => handleScroll()}>
                                        Book Your Spot
                                    </button>
                                </Link>
                            </div>
                            {/* <div className="grpBox">
                                <p className='primaryWhite NotoSansBengali mb-3'>
                                    আমাদের হোয়াটস্যাপ গ্রুপ জয়েন করার জন্য নীচে দেওয়া লিংকে ক্লিক করুন:
                                </p>
                                <a href="https://chat.whatsapp.com/KXmoMfCjkEq8LwqJBcbudj"
                                    className='btnMain fw700 primaryBlack shapeTrape border-0'>
                                    <span className='me-2 d-inline-block iconWhatsapp'>
                                        <img src="/assets/images/rahul/whatsapp-svg-icon.svg" alt="#" className='w100 h100' />
                                    </span>
                                    হোয়াটস্যাপে যুক্ত হন
                                </a>
                            </div> */}
                        </div>
                        <div className="col-lg-6">
                            <div className="videoArea ps-lg-5">
                                <img src="/assets/images/rahul/Numerological-Importance.webp" alt="#" loading="lazy" className="w100 h100 agendaImg border-0" />
                            </div>
                        </div>
                    </div>
                }
            />
            <PracticeMeditation14
                customStyle="benefitsMoneyMasterclss"
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6">
                            <div className="videoArea pe-lg-5">
                                <img src="/assets/images/rahul/lions-portal-history.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-4 text-center text-lg-start">
                                What <span className="gradientText ms-2">History says</span>
                            </h2>
                            <p className="textDark poppins mb-3 text-center text-lg-start">
                                Ancient civilizations like the Egyptians and Mayans revered the Lion’s Gate Portal,
                                a powerful cosmic alignment when the Sun in Leo, Earth, and Sirius align with the
                                pyramids of Giza. For the Egyptians, this marked the annual flooding of the Nile and
                                the start of a new year, symbolizing renewal and divine wisdom. They believed Sirius
                                was a gateway to heaven. Similarly, the Mayans held this alignment in high esteem,
                                meticulously aligning their festivals and rituals with the stars, viewing the Lion’s
                                Gate as a period of profound spiritual significance and powerful energy for setting
                                new resolutions and embracing the future.
                            </p>
                            <div className="text-center text-lg-start">
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={() => handleScroll()}>
                                        Enroll Now
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
            />
            <MoneyMagnetForm
                lightBg={'lightBg'}
                formStyle="moneyMagnetForm"
                formHead={
                    <div className="div1">
                        <p className="title fw700 NotoSansBengali primaryWhite mb-1 p1">Special Manifestation Workshop</p>
                        <div className='d-flex flex-wrap row-gap-2'>
                            <span className="title primaryWhite fw600 NotoSansBengali">
                                Coming Soon
                            </span>
                            {/* <span className="primaryWhite fw500 NotoSansBengali date">
                                Date: <span className="fw600 shapeRect primaryYellow primaryBlack">8th Aug</span>
                            </span>
                            <span className="primaryWhite fw500 NotoSansBengali ms-1 ms-lg-4">
                                Time: <span className="fw600 shapeRect primaryYellow primaryBlack">08:00 PM(IST)</span>
                            </span> */}
                        </div>
                    </div>
                }
                priceSection={
                    <div className="d-flex align-items-center column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">₹</span>
                            <span className="NotoSansBengali">99/-</span>
                        </p>
                        {/* <span>|</span>
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">৳</span>
                            <span className="NotoSansBengali">499/-<span className='title fw600'>(BDT)</span></span>
                        </p> */}
                    </div>
                }
                formImg="assets/images/rahul/lions-gate-form-img.webp"
                productId="LIONS-99"
                leadAPI="https://silvamethod.in:5000/api/v1/leads/submit-lions-gate-portal-day-lead"
                thankYouPageUrl="/payment-successful"
                langCount="false"
            />
            <AboutInstructor
                customStyle={'portalDayPage'}
                contentArea={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                            About Your <span class="gradientText">Instructor</span>
                        </h2>
                        <div className="healthInstructorCard">
                            {
                                cardData.map((item, idx) =>
                                    <div className="cardItem">
                                        <div class="leftSide">
                                            <img src={item.instructorimg} alt="#" loading="lazy" class="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 class="mdFont textDark fw700 poppins mb-2 text-center text-lg-start">{item.instructortitle}</h3>
                                            <p class="fw600 poppins textDark text-capitalize mb-2 text-center text-lg-start">Certified Silva Method Instructor</p>
                                            <div class="socialIcons d-flex gap-1 mb-4 justify-content-center justify-content-lg-start">
                                                <a href="https://www.facebook.com/debalina.silvamethod.instructor">
                                                    <img src={facebook} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                                <a href="https://www.youtube.com/@SilvaMethodIndiaOfficial">
                                                    <img src={youtube} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                                <a href="https://www.instagram.com/silva.instructor.debalina/">
                                                    <img src={instagram} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                            </div>
                                            <p class="desc textDark fw400 poppins text-center text-lg-start">{item.instructordesc1}</p>
                                            <p class="desc textDark fw400 poppins mb-0 text-center text-lg-start">{item.instructordesc2}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                }
            />
            <Footer2 />
        </div>
    )
}

export default LionGate