import React from 'react'
import "./SliderBandStyle.css";

import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick'
import BrandLogoImage1 from "../assets/brand-logo-1.webp"
import BrandLogoImage2 from "../assets/brand-logo-2.webp"
import BrandLogoImage3 from "../assets/brand-logo-3.webp"
import BrandLogoImage4 from "../assets/brand-logo-4.webp"




const SliderBand = () => {

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1279 })

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isDesktopOrLaptop ? 5 : isTablet ? 3 : 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  return (
    //   <div>

    //     const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
    // const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1279 })

    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   slidesToShow: isDesktopOrLaptop?5:isTablet?3:2,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   speed: 2000,
    //   autoplaySpeed: 2000,
    //   cssEase: "linear"
    // };

    //       {/* <section className="sliderSection">
    //     <div className="headingArea d-flex align-items-center justify-content-center text-center gap-3">
    //       <div className="starIcon">
    //         <span className="material-symbols-outlined primaryWhite star star1">
    //           star
    //         </span>
    //         <span className="material-symbols-outlined primaryWhite star star2">
    //           star
    //         </span>
    //         <span className="material-symbols-outlined primaryWhite star star3">
    //           star
    //         </span>
    //       </div>
    //       <p className="title fw600 primaryWhite poppins borderRect mb-0">
    //         Silva Method got featured on
    //       </p>
    //       <div className="starIcon">
    //         <span className="material-symbols-outlined primaryWhite star star3">
    //           star
    //         </span>
    //         <span className="material-symbols-outlined primaryWhite star star2">
    //           star
    //         </span>
    //         <span className="material-symbols-outlined primaryWhite star star1">
    //           star
    //         </span>
    //       </div>
    //     </div>
    //     <div className="owl-carousel owl-theme brandSlider">
    //       <div className="item">
    //         <img src={BrandLogoImage1} alt="#" loading="lazy" />
    //       </div>
    //       <div className="item">
    //         <img src={BrandLogoImage2} alt="#" loading="lazy" />
    //       </div>
    //       <div className="item">
    //         <img src={BrandLogoImage3} alt="#" loading="lazy" />
    //       </div>
    //       <div className="item">
    //         <img src={BrandLogoImage4} alt="#" loading="lazy" />
    //       </div>
    //       <div className="item">
    //         <img src={BrandLogoImage1} alt="#" loading="lazy" />
    //       </div>
    //       <div className="item">
    //         <img src={BrandLogoImage2} alt="#" loading="lazy" />
    //       </div>
    //       <div className="item">
    //         <img src={BrandLogoImage3} alt="#" loading="lazy" />
    //       </div>
    //     </div>
    //   </section> */}
    //   </div>
    <div className='sliderSection'>
      <div class="headingArea d-flex align-items-center justify-content-center text-center gap-3">
        <div class="starIcon d-none d-md-flex">
          <span class="material-symbols-outlined primaryWhite star star1">
            star
          </span>
          <span class="material-symbols-outlined primaryWhite star star2">
            star
          </span>
          <span class="material-symbols-outlined primaryWhite star star3">
            star
          </span>
        </div>
        <p class="title fw600 primaryWhite poppins borderRect mb-0">
          Silva Method got featured on
        </p>
        <div class="starIcon d-none d-md-flex">
          <span class="material-symbols-outlined primaryWhite star star3">
            star
          </span>
          <span class="material-symbols-outlined primaryWhite star star2">
            star
          </span>
          <span class="material-symbols-outlined primaryWhite star star1">
            star
          </span>
        </div>
      </div>
      <div className="not-slide-icon my-4">
        <Slider {...settings}>
          <div>
            <img src={BrandLogoImage1} style={{ objectFit: "contain", width: "100%", height: "100%", maxWidth: "250px" }} alt={BrandLogoImage1} />
          </div>
          <div>
            {/* <img src={BrandLogoImage2} width={150} height={70} style={{objectFit:"contain"}} alt={BrandLogoImage2}/> */}
            <img src={BrandLogoImage2} style={{ objectFit: "contain", width: "100%", height: "100%", maxWidth: "250px" }} alt={BrandLogoImage2} />
          </div>
          <div>
            <img src={BrandLogoImage3} style={{ objectFit: "contain", width: "100%", height: "100%", maxWidth: "250px" }} alt={BrandLogoImage3} />
          </div>
          <div>
            <img src={BrandLogoImage4} style={{ objectFit: "contain", width: "100%", height: "100%", maxWidth: "250px" }} alt={BrandLogoImage4} />
          </div>
          <div>
            <img src={BrandLogoImage1} style={{ objectFit: "contain", width: "100%", height: "100%", maxWidth: "250px" }} alt={BrandLogoImage1} />
          </div>
          <div>
            <img src={BrandLogoImage2} style={{ objectFit: "contain", width: "100%", height: "100%", maxWidth: "250px" }} alt={BrandLogoImage2} />
          </div>

        </Slider>
      </div>
    </div>

  )
}

export default SliderBand