import React, { useEffect, useState } from "react";
import Newnavbar from "../../components/Newnavbar";
import BengaliBlsHero from "./BengaliBls_Hero";
import AboutBLS from "../../components/AboutBLS";
import BengaliBlsKeyPoints from "./BengaliBlsKeypoints";
import DebasishImg from "../../assets/Debasish-Chatterjee.webp";
import DebayanImg from "../../assets/Debayan-Sen.webp"
import BengaliBlsCouseSchedule from "./BengaliBlsCourseSchedule";
import SlsWebinarWorks from "../Sls/SlsWebinarWorks";
import WebinarCard_1_Img from "../../assets/webinar-card-1.webp";
import WebinarCard_2_Img from "../../assets/webinar-card-2.webp";
import WebinarCard_3_Img from "../../assets/webinar-card-3.webp";
import CourseForWhose from "../../components/CourseForWhose";
import Reviews from "../../components/Reviews";
import MasterclassInstructor from "../../components/MasterclassInstructor";
import VideoTestimonial from "../../components/VideoTestimonial";
import DynamicFaqs from "../../components/DynamicFaqs";
import Footer2 from "../../components/Footer2";
import { useLocation } from "react-router-dom";
import { handleLeadFormSubmit } from "../../utils/PaymentHelper";

function BenagaliBls() {

  const [courseData, setCourseData] = useState(null);


  const [courseId, setCourseId] = useState(null)
  const location = useLocation()


  const [finalPrice, setFinalPrice] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);


  const fetchCourse = async (course_id) => {
    console.log(course_id, "world")
    try {
      const res = await handleLeadFormSubmit(`courses/get-course/${course_id}`, 'GET', {}, {});
      console.log(res, 'course res')
      if (res.success) {
        console.log(res?.data,'data')
        setCourseData(res.data[0]);
        const price = Number(res?.data[0]?.course_price) - (Number(res?.data[0]?.course_price) * (Number(res?.data[0]?.course_discount_percentage) / 100));
        setFinalPrice(Math.ceil(price))
        setDiscountPercentage(res?.data[0]?.course_discount_percentage)
      } else {
        console.log(res?.message, 'fetch went wrong')
      }
    } catch (error) {
      console.log(error, 'course fetch error')
    }
  }









  const [eventData, setEventData] = useState(null)
  const getEventData = async () => {
    try {
      const res = await fetch('https://silvamethod.in:5000/api/v1/events/get-events')
      const data = await res.json()
      if (data?.success) {
        setEventData(data?.data?.find((event, idx) =>
          event?.title === 'সিলভা লাইফ এন্ড ইনটিউশন সিস্টেম'
        ))
      } else {
        console.log(data)
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getEventData()
  }, [])





  const perksData = [
    {
      id: 1,
      text: "লাইফ চেঞ্জিং ট্রান্সফরমেশন কোর্স",
    },
    {
      id: 2,
      text: "শক্তিশালী সিলভা মেথড মেডিটেশন টেকনিকস",
    },
    {
      id: 3,
      text: "নিজের মনকে  নিয়ন্ত্রণ করার প্রশিক্ষণ",
    },
    {
      id: 4,
      text: "একটি গ্লোবাল কমিউনিটির সদস্য হন",
    },
    {
      id: 5,
      text: "ডাউট ক্লিয়ারিং এন্ড হিলিং সেশন",
    },
    {
      id: 6,
      text: "সিলভা গ্র্যাজুয়েটদের সাথে সাপ্তাহিক মিটআপ",
    },
    {
      id: 7,
      text: "উপকৃত না হলে অর্থ ফেরত গ্যারান্টি",
    },
  ];

  const keyData = [
    {
      id: 1,
      text: "আলফা ব্রেন ওয়েভ লেভেলে গিয়ে,নিজের বর্ধিত চেতনার সাহায্যে ব্রেইনের উভয় গোলার্ধকে সঠিক ভাবে ব্যবহার করতে শিখুন।"
    },
    {
      id: 2,
      text: "নিজের ইতিবাচক চিন্তাশক্তিকে ব্যবহার করে সাক্সেস অর্জন করুন।"
    },
    {
      id: 3,
      text: "স্ট্রেস দূর করুন, আপনার ইমিউন সিস্টেমকে শক্তিশালী করুন এবং সামগ্রিক স্বাস্থ্য ও সুস্থতা অর্জন করুন।"
    },
    {
      id: 4,
      text: "ডিপ রিলাক্সেশন টেকনিক গুলি আয়ত্ত করে, স্ট্রেস এবং টেনশন কে দূর করুন, বেটার প্রোডাক্টিভিটি পান।"
    },
    {
      id: 5,
      text: "আপনার মানসিক ল্যান্ডস্কেপকে শুদ্ধ করুন, সীমিত চিন্তা, বিশ্বাস এবং নেগেটিভ সেলফ টক থেকে মুক্ত হন।"
    },
    {
      id: 6,
      text: "আপনার উদ্দেশ্য এবং লক্ষ্যগুলির সাথে অসঙ্গতিপূর্ণ চিন্তাভাবনা, বিশ্বাস এবং আচরণ নির্মূল করুন।"
    },
    {
      id: 7,
      text: "প্রাকৃতিক ঘুমোতে শিখুন, ওষুধ বা অ্যালার্ম ছাড়াই সতেজ হয়ে জেগে উঠুন।"
    },
    {
      id: 8,
      text: "স্থিতিশীলতা, সতর্কতা এবং উত্পাদনশীলতা বাড়ান, ক্লান্তি মোকাবেলা করুন এবং সামগ্রিক উন্নতি অর্জন করুন।"
    }
  ];

  const courseScheduleData = [
    {
      id: 'pills-one-tab',
      ariaControl: 'pills-one',
      title1: "১ম ",
      title2: "সপ্তাহ",
      dayText: "২ দিন",
      data: {
        cardTitle: "১ম সপ্তাহের বিষয়বস্তু",
        instructor: "ইন্সট্রাক্টর-দেবাশীষ চ্যাটার্জ্জী",
        instructorImage: DebasishImg,
        points: [
          {
            id: 1,
            points: "ইন্ট্রো এবং শর্ট রিলাক্সেশন",
            pointNumber: "১",
          },
          {
            id: 2,
            points: "লং রিলাক্সেশন (এক্সপ্লেইনেশন এবং এক্সারসাইজ)",
            pointNumber: "২",
          },
          {
            id: 3,
            points: "স্লীপ কন্ট্রোল",
            pointNumber: "৩",
          },
          {
            id: 4,
            points: "অ্যাওয়েক কন্ট্রোল",
            pointNumber: "৪",
          },
          {
            id: 5,
            points: "অ্যালার্ম ক্লক",
            pointNumber: "৫",
          },
          {
            id: 6,
            points: "ড্রিম কন্ট্রোল",
            pointNumber: "৬",
          },
          {
            id: 7,
            points: "হেডএক কন্ট্রোল",
            pointNumber: "৭",
          },
          {
            id: 8,
            points: "চেঞ্জিং লিমিটিং বিলিফ এক্সারসাইজ",
            pointNumber: "৮",
          },
          {
            id: 9,
            points: "প্রশ্ন - উত্তর সেশন",
            pointNumber: "৯",
          },
        ]
      }
    },
    {
      id: 'pills-two-tab',
      ariaControl: 'pills-two',
      title1: "২য় ",
      title2: "সপ্তাহ",
      dayText: "২ দিন",
      data: {
        cardTitle: "২য় সপ্তাহের বিষয়বস্তু",
        instructor: "ইন্সট্রাক্টর- দেবায়ন সেন",
        instructorImage: DebayanImg,
        points: [
          {
            id: 1,
            points: "১ম সপ্তাহের সেশনের পর্যালোচনা",
            pointNumber: "১",
          },
          {
            id: 2,
            points: "মেন্টাল স্ক্রিন এবং মেমরি পেগস  (এক্সপ্লেইনেশন এবং এক্সারসাইজ)",
            pointNumber: "২",
          },
          {
            id: 3,
            points: "থ্রী ফিঙ্গারস টেকনিক   (এক্সপ্লেইনেশন এবং এক্সারসাইজ)",
            pointNumber: "৩",
          },
          {
            id: 4,
            points: "মিরর অফ দ্য মাইন্ড   (এক্সপ্লেইনেশন এবং এক্সারসাইজ)",
            pointNumber: "৪",
          },
          {
            id: 5,
            points: "হ্যান্ড লেভিটেশন এবং গ্লোভ এনেস্থেসিয়া",
            pointNumber: "৫",
          },
          {
            id: 6,
            points: "গ্লাস অফ ওয়াটার",
            pointNumber: "৬",
          },
          {
            id: 7,
            points: "হ্যাবিট কন্ট্রোল",
            pointNumber: "৭",
          },
          {
            id: 8,
            points: "প্রশ্ন - উত্তর সেশন",
            pointNumber: "৮",
          },
        ]
      }
    },
    {
      id: 'pills-three-tab',
      ariaControl: 'pills-three',
      title1: "৩য় ",
      title2: "সপ্তাহ",
      dayText: "২ দিন",
      data: {
        cardTitle: "৩য় সপ্তাহের বিষয়বস্তু",
        instructor: "ইন্সট্রাক্টর-দেবাশীষ চ্যাটার্জ্জী",
        instructorImage: DebasishImg,
        points: [
          {
            id: 1,
            points: "মাইন্ড, ইএসপি,ইনটিউশন এক্সপ্লেইনেশন",
            pointNumber: "১",
          },
          {
            id: 2,
            points: "হোম প্রজেকশন",
            pointNumber: "২",
          },
          {
            id: 3,
            points: "মেটাল প্রজেকশন",
            pointNumber: "৩",
          },
          {
            id: 4,
            points: "প্ল্যান্ট প্রজেকশন (এক্সপ্লেইনেশন এবং এক্সারসাইজ)",
            pointNumber: "৪",
          },
          {
            id: 5,
            points: "প্রজেকশন অন পেটস",
            pointNumber: "৫",
          },
          {
            id: 6,
            points: "প্রশ্ন - উত্তর সেশন",
            pointNumber: "৬",
          },
        ]
      }
    },
    {
      id: 'pills-four-tab',
      ariaControl: 'pills-four',
      title1: "৪র্থ ",
      title2: "সপ্তাহ",
      dayText: "২ দিন",
      data: {
        cardTitle: "৪র্থ সপ্তাহের বিষয়বস্তু",
        instructor: "ইন্সট্রাক্টর- দেবায়ন সেন",
        instructorImage: DebayanImg,
        points: [
          {
            id: 1,
            points: "এস্টাব্লিশ কানেকশন উইথ পারপাস",
            pointNumber: "১",
          },
          {
            id: 2,
            points: "ল্যাবরেটরি মেকিং",
            pointNumber: "২",
          },
          {
            id: 3,
            points: "ক্রিয়েশন অফ কাউন্সেলরস",
            pointNumber: "৩",
          },
          {
            id: 4,
            points: "হিউম্যান অ্যানাটমি টোটাল এক্সপ্লেইনশন",
            pointNumber: "৪",
          },
          {
            id: 5,
            points: "অ্যানাটমি এক্সপ্লেইনশন কন্টিনিউ",
            pointNumber: "৫",
          },
          {
            id: 6,
            points: "হিউম্যান প্রজেকশন এক্সারসাইজ",
            pointNumber: "৬",
          },
          {
            id: 7,
            points: "কেস ওয়ার্ক(ডেমোন্সট্রেশন অফ ওরিয়েন্টোলজিস্ট)",
            pointNumber: "৭",
          },
          {
            id: 8,
            points: "হ্যাবিট কন্ট্রোল",
            pointNumber: "৮",
          },
          {
            id: 9,
            points: "প্রশ্ন - উত্তর সেশন এবং অ্যাওয়ার্ড সেরিমনি",
            pointNumber: "৯",
          },
        ]
      }
    },
  ];

  const bengaliWebinarWorksData = [
    {
      id: 1,
      img: WebinarCard_1_Img,
      text: "সিলভা মেথড ফ্রি ওয়েবিনার বা ওয়ার্কশপগুলির উদ্দেশ্য বুঝুন।",
      headingText: "১ম স্টেপ"
    },
    {
      id: 2,
      img: WebinarCard_2_Img,
      text: "কোনো প্রশ্ন থাকলে আমাদের অফিসিয়াল টিমের সাথে যোগাযোগ করুন।",
      headingText: "২য় স্টেপ"
    },
    {
      id: 3,
      img: WebinarCard_3_Img,
      text: "আপনার সমস্ত ডিটেলস সহ ফর্মটি  ফিলআপ করুন এবং জীবন রূপান্তরকারী যাত্রা শুরু করুন।",
      headingText: "৩য় স্টেপ"
    },
  ]

  const aboutJoseSilva = [
    {
      points: `José Silva হলেন Silva Method- এর প্রতিষ্ঠাতা, এবং mind empowerment 
        গবেষণার পথিকৃৎ। সিলভা মানুষের মনের লুকানো সম্ভাবনা জাগ্রত করার জন্য তাঁর জীবন উৎসর্গ 
        করেছিলেন। 22 বছরের গবেষণার পর, তিনি 1966 সালে তাঁর ফলাফল প্রকাশ করেছেন।তাঁর 
        আবিষ্কার কে নিখুঁত এবং মানুশকে শিক্ষাদানের জন্য বাকি জীবন অতিবাহিত করেন।সিলভার উত্তরাধিকার 
        আজ Silva International- এর মাধ্যমে পরিচালিত হয়, একটি সংস্থা যা তাঁর মিশন চালিয়ে যাওয়ার জন্য 
        প্রতিশ্রুতিবদ্ধ – যা মননশীল অনুশীলনের মাধ্যমে জীবনকে উন্নত করার লক্ষ্যে নিবেদিত।`
    }
  ]

  const celebCont = [
    {
      points: `সেলিব্রিটিদের সাফল্যের একটি গোপন অস্ত্র হল – মে ডি টে শন।মনোযোগ, 
        সৃজনশীলতা এবং সামগ্রিক সুস্থতা বাড়াতে তারা নিয়মিত মে ডি টে শন করেন।তারকারা 
        বলেছেন যে মে ডি টে শন করার জন্য প্রতিদিন একটুসময় নেওয়া তাদের মনোযোগ নিবদ্ধ 
        রাখতে এবং সমস্ত মনোযোগ পরিচালনা করতে সহায়তা করে। এটা শুধুশান্ত বোধ সম্পর্কে নয়, 
        মে ডি টে শন তাদের আরও সৃজনশীল হতে এবং তাদের কাজে আরও ভাল করতে সহায়তা করে।
        মননশীলতা কৌশল এবং মে ডি টে শন মানসিক চাপ কমাতে, রাগ ও বিভিন্ন সমস্যাগুলি নিয়ন্ত্রণ 
        করতে এবং একটি শান্ত জীবনযাপনের সর্বোত্তম উপায় হিসাবে আবির্ভূত হয়েছে।সে লি ব্রি টিদে র 
        মধ্যে মে ডি টে শন এখন খুবই জনপ্রি য় একটি চর্চা।`
    }
  ];

  const bengaliblsFaqs = [
    {
      id: 1,
      title: "সিলভা মেথড ইন্ডিয়ার সদর দপ্তর কোথায় অবস্থিত?",
      text: "সিলভা মেথড ইন্ডিয়ার সদর দপ্তর অবস্থিত - 1st FLOOR, UCP 10, AMBUJA, PO, City Center, Durgapur, West Bengal 713216",
    },
    {
      id: 2,
      title: "আমি কিভাবে সিলভা মেথড ইন্ডিয়ার সাথে যোগাযোগ করব?",
      text: <div>
        <ul className="list-unstyled">
          <li>সিলভা মেথড ইন্ডিয়ার  কনট্যাক্ট  ডিটেলস</li>
        </ul>
        <ul>
          <li>ফোন নম্বর: +91 6290 945 413,</li>
          <li> ইমেল আইডি: silvaindiaofficial@gmail.com,</li>
          <li>অফিসিয়াল ওয়েবসাইট: www.silvamethod.in</li>
        </ul>
      </div>,
    },
    {
      id: 3,
      title: "সিলভা মেথড কি?",
      text: "সিলভা  মেথড  হল একটি স্ব-সহায়ক, ব্যক্তিগত বিকাশের পদ্ধতি যা মানসিক ক্ষমতা বাড়াতে এবং ব্যক্তিগত লক্ষ্য অর্জনের জন্য ধ্যান, ভিজ্যুয়ালাইজেশন এবং ইতিবাচক চিন্তাভাবনাকে একত্রিত করে। আলফা মানসিক অবস্থায় প্রবেশ করে ব্যক্তিরা অবচেতন মনকে নিয়ন্ত্রণ করতে পারে লক্ষ্য অর্জন করতে এবং জীবনের বিভিন্ন দিক উন্নত করতে।",
    },
    {
      id: 4,
      title: "কীভাবে বিনামূল্যে সিলভা মেথড শিখবেন?",
      text: <div>
        <ul className="list-unstyled">
          <li>সিলভা  মেথডের মূল বিষয়গুলি বিনামূল্যে অন্বেষণ করতে:</li>
        </ul>
        <ul>
          <li>অনলাইন আর্টিকলস এবং ব্লগ পড়ুন।</li>
          <li> ইউটিউব ভিডিও দেখুন।</li>
          <li>সিলভা মেথড ইন্ডিয়ার অফিসিয়াল ওয়েবসাইট: www.silvamethod.in-এ বিনামূল্যে সেমিনারে যোগ দিন বা BLS কোর্সে নথিভুক্ত করুন।</li>
          <li>যাইহোক, আরও ভালভাবে বোঝার জন্য, পেইড কোর্স বা মাস্টারক্লাসগুলিতে বিনিয়োগ করার কথা বিবেচনা করুন, যা ব্যক্তিগতকৃত নির্দেশিকা এবং ব্যাপক প্রশিক্ষণ প্রদান করে।</li>
        </ul>
      </div>,
    },
    {
      id: 5,
      title: "মেডিটেশন কি সত্যিই গুরুত্বপূর্ণ?",
      text: "হ্যাঁ. স্বাস্থ্য এবং সুস্থতার জন্য মেডিটেশন অত্যন্ত গুরুত্বপূর্ণ, মানসিক চাপের বিরুদ্ধে লড়াই করা, অসুস্থতার প্রধান কারণ। আপনি এটিকে শিথিলকরণ, কেন্দ্রীভূতকরণ বা উদ্দেশ্য সহ প্রার্থনা বলুন না কেন, প্রতিদিনের অনুশীলন শরীর, মন, আত্মা এবং আবেগের মধ্যে সংযোগকে শক্তিশালী করে।",
    },
  ];

  useEffect(() => {
    const docs = document.querySelector(".navSection");
    if (docs) {
      docs.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [])


  useEffect(() => {
    if (courseId) {
      fetchCourse(courseId)
    }
  }, [courseId])


  useEffect(() => {
    if (location?.pathname?.includes("silva-besic-lecture-series-bengali")) {
      setCourseId("BLS-BENG-9999")
    };
  }, [location])


  useEffect(() => {
    if (courseData) {
      setDiscountPercentage(courseData?.course_discount_percentage)
    }
  }, [courseData])



  return (
    <div>
      <Newnavbar />
      <BengaliBlsHero
        perksData={perksData}
        eventDate={eventData?.date}
        eventTime={eventData?.time}
        title={"সিলভা মেথড বেসিক লেকচার সিরিজ (BLS)"}
        subTitle={
          "এই কোর্সটি সিলভা লাইফ সিস্টেম এবং সিলভা ইনটিউশন সিস্টেমের সমন্বয়ে গঠিত, যা স্বয়ং হোসে সিলভা কর্তৃক ডিজাইন করা। আত্ম-সচেতনতা, মানসিক শক্তি বৃদ্ধি এবং জীবনের সকল ক্ষেত্রে উন্নতির জন্য একটি এক শক্তিশালী মাধ্যম।"
        }
        btnText={"শীঘ্রই আসছে"}
        coursePriceBtnText={"কোর্সের মূল্য"}
        originalPrice={"১৯৯৯৯"}
        discountedPrice={"৯৯৯৯"}
        discountText={`আপনি ${Math.ceil(discountPercentage)}% ছাড় পাবেন`}
        rating={"৪.৬"}
        ratingCount={"১০৮,২৮৩ রেটিং"}
        perksTitle={"সিলভা  বেসিক লেকচার সিরিজের কিছু আনলিমিটেড সুবিধা"}
        eventTitle={"ইভেন্টের"}
        eventColoredTitle={"সময়"}
        payBtnText={"রেজিস্টার করুন"}
        heroExtraClass="bengaliBlsHero"
        courseData={courseData}
        finalPrice={finalPrice}
        setFinalPrice={setFinalPrice}
        discountPercentage={discountPercentage}
        setDiscountPercentage={setDiscountPercentage}
      />
      <AboutBLS
        title={"সিলভা  বেসিক লেকচার সিরিজের "}
        coloredTitle={"উপকারিতা"}
        description={`সিলভা বেসিক লেকচার সিরিজ সেমিনার হল আপনার ব্যক্তিগত বৃদ্ধি এবং জ্ঞানার্জনের একটি রূপান্তরমূলক যাত্রার প্রবেশদ্বার। এই সেমিনারটি হল আপনার সিলভা পদ্ধতির যাত্রার সূচনা বিন্দু, এবং এটি গ্র্যাজুয়েট লেকচার সিরিজ সেমিনার এবং আল্ট্রা ডাইনামিক এনার্জি হিলিং সেমিনার সহ অন্যান্য সমস্ত উন্নত সেমিনারগুলির মূল এবং ভিত্তি হিসাবে কাজ করে। এটি সেই মুহূর্ত যখন আপনি আপনার জীবনের একটি নতুন অধ্যায়ের দিকে প্রথম পদক্ষেপ নেন, সীমাহীন সুযোগ এবং গভীর রূপান্তরে ভরা। সিলভা বিএলএস সেমিনারের মাধ্যমে অসীম সম্ভাবনার জগতে পা বাড়ান, যেখানে আপনার মনের শক্তির কোন সীমা নেই।`}
        headingClass={"slsbengaliAboutHeading"}
      />
      <BengaliBlsKeyPoints
        title={"এই কোর্সের মুখ্য "}
        coloredTitle={"বিষয়বস্তু"}
        btnText={"এখনই রেজিস্টার করুন "}
        keyData={keyData}
      />
      <BengaliBlsCouseSchedule courseScheduleData={courseScheduleData} />
      <SlsWebinarWorks
        data={bengaliWebinarWorksData}
        title={"সিলভা মেথডের"}
        subtitle={"ফ্রি ওয়ার্কশপগুলি কেমন ভাবে কাজ করে"}
        description={
          "আপনি যদি সিলভা মেথড সম্পর্কে আগ্রহী এবং ভাবছেন কিভাবে সিলভা পদ্ধতিটি বিনামূল্যে শিখবেন, তাহলে নীচে দেওয়া স্টেপ গুলি অনুসরন করুন"
        }
      />
      <CourseForWhose
        bgColor=""
        startText="Silva Method- এর"
        spanText=" অগ্রগামী এবং প্রতিষ্ঠাতা"
        endText=""
        pointsArr={aboutJoseSilva}
        img="/assets/images/rahul/masterclass-jose-silva.webp"
        imgPos="right"
        ptop={false}
      />
      <CourseForWhose
        startText="সেলিব্রিটি জগতে"
        spanText=" মেডিটেশন"
        endText=""
        pointsArr={celebCont}
        img="/assets/images/rahul/celeb-img.webp"
        imgPos="left"
        bgColor="lightBg"
        ptop={true}
      />
      <Reviews extraClass={"ptop"} />
      <MasterclassInstructor />
      <VideoTestimonial heading="Silva Method -এ অংশগ্রহণকারীরা কি বলছে জানুন" />
      <DynamicFaqs data={bengaliblsFaqs} defaultactive={0} title='জিজ্ঞাসিত কিছু প্রশ্ন।' />
      <Footer2 />
    </div>
  );
}

export default BenagaliBls;
