import React from 'react'
import '../components/CertificateStyle.css'
import { useLocation, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// import HeadingNew from '../../AboutUs/HeadingNew';

import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

const ModalComponent = ({ isOpen, onRequestClose, fbLink }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Review Modal"
            style={customStyles}
        >


            <h2>Please leave a review</h2>
            <p>
                We appreciate your feedback. Please leave a review on The Silva Method India's Facebook page.
            </p>
            <a className='btnMain fw600 gradientBg primaryWhite text-capitalize' href={fbLink} target="_blank" rel="noopener noreferrer">
                Go to Facebook Page
            </a>
            <FaTimes onClick={onRequestClose} color='#ccc' style={{
                position: "absolute",
                top: "10px",
                right: "10px"
            }} />
        </Modal>
    );
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        padding: "50px",
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};




function Certificate() {
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const [loading, setLoading] = useState(false);

    const location = useLocation()

    const generatePdf = async () => {
        // const element = html;
        const element = document.createElement('div');
        element.innerHTML = html; // Set the HTML content

        // Hide the element
        element.style.position = 'absolute';
        element.style.left = '-9999px';
        
        document.body.appendChild(element); // Append the element to the body
        const canvas = await html2canvas(element,{
            scale:0.5
        });
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('download.pdf');
    };

    const [html, setHtml] = useState("")

    const params = location.search.substring(1).split("&");
    let name, courseid;

    for (const param of params) {
        const [key, value] = param.split("=");
        if (key === "name") {
            name = value;
        } else if (key === "courseid") {
            courseid = value;
        }
    }




    const handleSubmit = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            setLoading(true)
            // const res = await axios.post("http://localhost:5000/api/v1/user/auth/allfreecourses"
            const res = await axios.post("https://silvacasesonline.com:5000/api/v1/user/auth/getPdfFile"

                , {
                    name,
                    courseid
                }, {
                headers
            })
            setLoading(false)




            if (res && res.data.success) {
                toast.success("Certificate fetched successfully")
                setHtml(res.data.html)

            }

        } catch (err) {
            setLoading(false)
            const er = err.response.data.success
            if (!er) {
                toast.error("Something went wrong")
                return

            }
        }
    }

    useEffect(() => {
        handleSubmit()
    }, [])


    if (loading) {
        return (
            <
                div
                class
                =
                "spinner-border"
                role
                =
                "status"
            >

                <
                    span
                    class
                    =
                    "visually-hidden"
                >Loading...
                </
                span
                >
            </
            div
            >
        )
    }
    return (
        <>
            <div className='d-flex justify-content-center align-items-center flex-column gap-4' style={{
                height: "100vh",
                width: "100%"
            }}>

                {/* <HeadingNew
                    title={"Your certificate is ready"}

                /> */}
                <h1>your certificate is ready</h1>

                {
                    html && (
                        <button className='btn btn-warning' onClick={generatePdf}>Download pdf</button>

                    )
                }


            </div>
            <ModalComponent
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                fbLink="https://www.facebook.com/silvamethodindia/reviews"
            />
        </>
    )
}

export default Certificate