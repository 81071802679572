import React from 'react'
import "./ClassAgendaStyle.css";


const ClassAgenda = ({ lightBg, masterClassCont }) => {
  return (
    <div>
      <section className={`masterClass ptop pbtm ${lightBg}`}>
        <div className="container">
          {masterClassCont}
        </div>
      </section>
    </div>
  )
}

export default ClassAgenda