import React, { useEffect } from 'react'
import Newnavbar from '../components/Newnavbar'
import NewHeroSection from '../components/NewHeroSection'
import CourseListCard from '../components/CourseListCard'
import Footer2 from '../components/Footer2'


const CourseList = () => {

    useEffect(() => {
        const docs = document.querySelector(".navSection");
        if (docs) {
            docs.scrollIntoView({
                behavior: "smooth"
            })
        }
    }, [])


    return (
        <>
            <Newnavbar />
            <NewHeroSection
                customClassName="allCoursePage"
                bannerMobileImg="/assets/images/rahul/courses-header-for-mobile.webp"
                bannerTabImg="/assets/images/rahul/courses-header-for-tab.webp"
                bannerImg="/assets/images/rahul/courses-header-for-web.webp"
                title="Explore Original Silva Mind Control Courses"
                subtitle="Dynamic meditation and mind empowerment techniques for Betterment in every aspect of life!"
                description=""
            />
            <CourseListCard />
            <Footer2
                footer2Margin=''
            />
        </>
    )
}

export default CourseList