import React from 'react'
import Navbar2 from '../components/Navbar2'
import RecordClassCont from '../components/RecordClassCont'
const recVid = "https://youtu.be/5zzkwqPdOzc"

const RecordedBeginnerMasterclass = () => {
    return (
        <div className='silvaDiffSection pbtm vh-100'>
            <Navbar2 />
            <RecordClassCont
                maintitle="প্রাক-রেকর্ড করা Beginner's মাস্টার ক্লাস সেশন"
                vidUrl={recVid}
            />
        </div>
    )
}

export default RecordedBeginnerMasterclass