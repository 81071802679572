import React, { Component } from "react";
import Slider from "react-slick";
import './LiveClassandWorkshopStyle.css'
import ReactPlayer from 'react-player'

const LiveClassandWorkshop = ({ sectionHead, videoSection, videoThumb, videoUrl, classImgs, extraClass, sectionName }) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
    }

    return (
        <>
            <section className={`liveClassWorkshop ptop pbtm ${sectionName}`}>
                <div className="container">
                    {sectionHead}
                    <div className="row align-items-center row-gap-4">
                        <div className="col-lg-7">
                            <div className={`liveClassVideo ${extraClass}`}>
                                {videoSection}
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="slider-container liveClassImg ps-lg-5">
                                <Slider {...settings}>
                                    {
                                        classImgs?.map((item, idx) =>
                                            <div className="liveClassImgs ">
                                                <img src={item.imgUrl} alt="#" loading='lazy' className='w100 h100' />
                                            </div>
                                        )
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default LiveClassandWorkshop