import React, { useContext } from "react";
// import { Card, Col, Row } from "react-bootstrap";
// import { AllContext } from "../context/AllContext";
// import { ImQuotesRight } from "react-icons/im";
import './TestimonialBengaliStyle.css'
import { useMediaQuery } from "react-responsive";
import { Appcontext } from "../context/appcontext";



const TestimonialBengali = ({ data }) => {
    const { isMobile, isDesktop } = useContext(Appcontext)
    return (
        <div
            className="p-5 card"
            style={{
                width: isDesktop ? "95%" : "98%",
                height: isMobile ? "400px " : "300px",
                overflowY: isMobile ? "scroll" : "",
                borderRadius: "15px",
            }}
        >
            <div className="row">
                <div className="col-md-2 col-xl-1">
                    <div className="image-circle">
                        <img
                            src={data?.image || "/assets/img/no_image.jpg"}
                            alt=""
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "100px",
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-10 col-xl-11">
                    <div>
                        <h5 className="mb-3 fw700">{data?.name}</h5>
                        {/* <p
                            style={{
                                padding: " 0px 10px",
                                backgroundColor: "#f5f6fa",
                                fontWeight: "500",
                                borderRadius: "5px",
                                display: "inline-block",
                            }}
                            className="my-2 mb-3"
                        >
                            {data?.designation}
                        </p> */}
                    </div>
                    <div>
                        <p>{data?.comment}</p>
                    </div>
                    {/* <div className="d-flex justify-content-end">
                        <ImQuotesRight color="#faecff" size={50} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TestimonialBengali