import React from "react";
import "../../components/KeyPointsStyle.css";
import CheckBGImg from "../../assets/check-bg.svg";
import SkyBGImg from "../../assets/sky.webp";
const SlsKeypoints = ({ data,btnText,btnOnclick,title,span,extraClass }) => {
  return (
    <div>
      <section className={`keyPointsSection ptop lightBg`}>
        <div id="clouds">
          <div className="cloud x1"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
        </div>
        <div className="container">
          <div className={`pointsCont position-relative pbtm ${extraClass}`}>
            <h2 className="h2 fw700 poppins textDark text-center mb-4">
              {title || "Key Points of the"} <span className="gradientText">{span || "Course"}</span>
            </h2>
            <ul className="resetUl">
              {data?.map((item, i) => (
                <li className="d-flex align-items-start gap-2 mb-3" key={i}>
                  <img src={CheckBGImg} alt="#" loading="lazy" />
                  <p className="textDark poppins mb-0">{item.heading && <span className="fw600">{item.heading}</span>}{item.text}</p>
                </li>
              ))}
            </ul>
            <div className="text-center ptop">
              <button
                className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape"
                fdprocessedid="zzienr"
                onClick={btnOnclick}
              >
                {btnText || "enroll now"}
              </button>
            </div>
          </div>
        </div>
        <img src={SkyBGImg} alt="#" loading="lazy" className="w100 h100" />
      </section>
    </div>
  );
};
export default SlsKeypoints;