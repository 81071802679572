import React from 'react'
import Slider from "react-slick";
import './InternationalMeditationStyle.css'

const InternationalMeditation = ({ ptop, pbtm, lightBg, sectionTitle, sectionHeading, sectionDesc, sliderImgs }) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    return (
        <>
            <section className={`internationalMeditation ${lightBg} ${ptop} ${pbtm}`}>
                <div className="container">
                    {
                        sectionTitle && sectionTitle
                    }
                    {
                        sectionHeading && sectionHeading
                    }
                    {
                        sectionDesc && sectionDesc
                    }
                    <div className="slider-container">
                        <Slider {...settings}>
                            {
                                sliderImgs?.map((items, idx) =>
                                    <div className="sliderImg">
                                        <img src={items.imgUrl} alt="#" loading='lazy' className='w100 h100' />
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InternationalMeditation