import React from 'react'
import "./Navbar2Style.css";
import LogoImg from "../assets/silva-india-logo-white.webp"
import { Link } from 'react-router-dom';


const Navbar2 = () => {
  return (
    <div>
         <header>
      <section className="navSection">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link to={"/"} className="navbar-brand">
              <img src={LogoImg} alt="#" loading="lazy" />
            </Link>
          </div>
        </nav>
      </section>
    </header>
    </div>
  )
}

export default Navbar2