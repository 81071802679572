import React from 'react'
import './CourseCurriculumStyle.css'

const CourseCurriculum = () => {
    const chapterCardCont = [
        {
            heading: "Chapter 1",
            title: "Introduction:",
            desc: "Brief idea on the Silva Method"
        },
        {
            heading: "Chapter 2",
            title: "",
            desc: "What you will learn in this course (mention course name)"
        },
        {
            heading: "Chapter 3",
            title: "",
            desc: "Different brainwave levels and benefits of staying at Alpha"
        },
        {
            heading: "Chapter 4",
            title: "",
            desc: "Different ways to enter at Alpha Level and countdown deepening"
        },
        {
            heading: "Chapter 5",
            title: "Silva Short Relaxation Exercise",
            desc: "Enter the alpha level by fully relaxing your mind and body"
        },
        {
            heading: "Chapter 6",
            title: "Silva Long Relaxation Exercise",
            desc: "Stay at the Alpha Level for the longest time, without drifting into sleep"
        },
        {
            heading: "Chapter 7",
            title: "Silva Mental Screen and Visualisation",
            desc: "Visualize your desires in a subjective dimension before they materialize in objective dimension"
        },
        {
            heading: "Chapter 8",
            title: "Silva Mirror of the Mind Technique",
            desc: "Reverse a problematic situation by creating anything you desire to have, do, or be"
        },
        {
            heading: "Chapter 9",
            title: "Silva 3 Finger Technique",
            desc: "Solve your daily small problems quickly by reaching the alpha level"
        },
    ]

    return (
        <>
            <section className='CourseCurriculum lightBg ptop pbtm'>
                <div className='container'>
                    <h2 className="h2 fw700 poppins textDark text-center mb-4 mb-lg-5">
                        About The <span className="gradientText">Course Curriculum
                        </span>
                    </h2>
                    <div className='curriculumTabs'>
                        <ul class="nav nav-pills justify-content-lg-between" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-one-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one"
                                    aria-selected="true">
                                    Chapters
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-two-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two"
                                    aria-selected="false">
                                    Resources
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                    aria-selected="false">
                                    Daily life Applications
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                    aria-selected="false">
                                    Benefits
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-one" role="tabpanel"
                                aria-labelledby="pills-one-tab" tabindex="0">
                                <div className="chaptersCont">
                                    {
                                        chapterCardCont.map((item, idx) =>
                                            <div className="chapterCard">
                                                <div class="d-flex align-items-center">
                                                    <p class="fw400 primaryWhite poppins mb-0 p1">{item.heading}</p>
                                                    <p class="textDark roboto m-0 p2">
                                                        {
                                                            item.title &&
                                                            <span className='fw700 textDark'>{item.title} </span>
                                                        }
                                                        {item.desc}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="text-center mt-4 mt-lg-5">
                                        <button type="button" class="btnMain btnSec fw700 gradientBg primaryWhite text-capitalize border-0">
                                            enroll now
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab"
                                tabindex="0">
                                <div className="resourceCards">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <div className="cardItem">
                                                <img src="" alt="#"  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab"
                                tabindex="0">
                                <div class="instructorCard">
                                    3
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab"
                                tabindex="0">
                                <div class="instructorCard">
                                    4
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CourseCurriculum