import React from 'react'
import './RegisterFormStyle.css'


const RegisterForm = ({ lightBg, contentArea }) => {
    return (
        <section className={`registerFormSection ptop pbtm ${lightBg}`}>
            <div className="container">
                {contentArea}
                {/* <div className="row row-gap-4">
                    <div className="col-lg-5">
                        {formChild || null}
                    </div>
                    <div className="col-lg-7 position-relative">
                        <div className="formAdjacentArea">
                            <div className="contentArea">
                                <img src="/assets/images/rahul/masterclass-bengali-header.webp" alt="#" className='w100 h100' style={{ objectFit: "cover" }} />
                                <ReactPlayer
                                    url={introVideo}
                                    light="assets/images/rahul/eng-money-magnet-vid-banner.webp"
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default RegisterForm