import React from 'react'
import "./CourseScheduleStyle.css";
import DebayanImg from "../assets/Debayan-Sen.webp"
import ZoomImg from "../assets/zoom.webp"
import SoumyajeetImg from "../assets/Soumyajeet-Bandyopadhay.webp"
import DebalinaImg from "../assets/Debalina-Chatterjee.webp"
import DebasishImg from "../assets/Debasish-Chatterjee.webp"
import 'bootstrap/dist/css/bootstrap.min.css';


const cardPoints = [
    {
        pointsNum: '1',
        points: 'Intro & Short Relaxation'
    },
    {
        pointsNum: '2',
        points: 'Long Relaxation(explainantion & exercise)'
    },
    {
        pointsNum: '3',
        points: 'Sleep Control'
    },
    {
        pointsNum: '4',
        points: 'Awake Control'
    },
    {
        pointsNum: '5',
        points: 'Alarm Clock Technique'
    },
    {
        pointsNum: '6',
        points: 'Dream Control'
    },
    {
        pointsNum: '7',
        points: 'Headache Control'
    },
    {
        pointsNum: '8',
        points: 'Changing Limiting Belief Exercise'
    },
    {
        pointsNum: '9',
        points: 'Question & Answer Session'
    },

    // {
    //     pointsNum: '10',
    //     points: 'Review Week One'
    // },
    {
        pointsNum: '10',
        points: 'Mental Screen and Memory Pegs Explain and Exercise'
    },
    {
        pointsNum: '11',
        points: 'Three Fingers Technique (explain and exercise)'
    },
    {
        pointsNum: '12',
        points: 'Mirror of the Mind (explain and exercise)'
    },
    {
        pointsNum: '13',
        points: 'Hand Levitation'
    },
    {
        pointsNum: '14',
        points: 'Glove Anaesthesia'
    },
    {
        pointsNum: '15',
        points: 'Glass of Water'
    },
    {
        pointsNum: '16',
        points: 'Habit Control'
    },
    {
        pointsNum: '17',
        points: 'Question and Answer'
    },

    {
        pointsNum: '18',
        points: 'Mind, ESP, Intuition Explaination'
    },
    {
        pointsNum: '19',
        points: 'Home Projection'
    },
    {
        pointsNum: '20',
        points: 'Metal Projection'
    },
    {
        pointsNum: '21',
        points: 'Plant Projection (explaination and exercise)'
    },
    {
        pointsNum: '22',
        points: 'Projection on Pet'
    },
    {
        pointsNum: '23',
        points: 'Question, Answer and Conclusion'
    },

    {
        pointsNum: '24',
        points: 'Establish Connection with Purpose'
    },
    {
        pointsNum: '25',
        points: 'Laboratory Making'
    },
    {
        pointsNum: '26',
        points: 'Creation Of Councelors'
    },
    {
        pointsNum: '27',
        points: 'Human Anatomy Total Explaination'
    },
    {
        pointsNum: '28',
        points: 'Anatomy Explaination Continue'
    },
    {
        pointsNum: '29',
        points: 'Human Projection Exercise'
    },
    {
        pointsNum: '30',
        points: 'Case work(demonstration of orientologist)'
    },
    {
        pointsNum: '31',
        points: 'Habit Control'
    },
    {
        pointsNum: '32',
        points: 'Question and Answer and Award Ceremony'
    },
]

const EightDaySchedule = ({ customClass }) => {
    return (
        <div className={`instructorCard ${customClass}`}>
            <div className='container'>
            <div className="cardItem text-start justify-content-around align-items-center">
                <div className="leftSide">
                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                </div>
                <div className="rightSide">
                    <h3 className="fw700 h3 poppins textDark mb-3">
                        Topics for Eight Days Course
                    </h3>
                    <p className="roboto textDark shapeRect lightTrape mb-4">
                        <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                    </p>
                    <ul className='resetUl'>
                        {
                            cardPoints?.map((items, idx) =>
                                <li className='d-flex align-items-center gap-2 mb-2'>
                                    <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                    <p className='textDark mb-0'>{items.points}</p>
                                </li>
                            )
                        }
                    </ul>
                    <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">
                        <p className="desc textDark roboto mb-0">
                            Online class via
                        </p>
                        <img src={ZoomImg} alt="#" loading="lazy" />
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default EightDaySchedule