import React from 'react'
import "./PracticeStyle.css";
import CardImage28 from "../assets/card-img-28.webp"
import CardImage29 from "../assets/card-img-29.webp"
import CardImage30 from "../assets/card-img-30.webp"

const Practice = () => {
  return (
    <div>
        <section className="practiceSection ptop pbtm">
      <div className="container">
        <div className="practiceCont text-center">
          <p className="fw700 poppins textDark shapeTrape2">
            What Makes Us Unique
          </p>
          <h2 className="h2 fw700 poppins textDark text-capitalize">
            practice wherever <span className="gradientText">you want</span>
          </h2>
          <p className="title fw600 poppins textDark">
            Whenever You Need
          </p>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="trapeCardItem">
                <div className="cardItem">
                  <div className="imgCont">
                    <img src={CardImage28} alt="#" loading="lazy" className="w100 h100" />
                  </div>
                  <h3 className="title fw700 poppins textDark mt-4 mb-3">
                    Restfullness
                  </h3>
                  <p className="desc fw300 textDark roboto mb-4">
                    The amazing feeling of freedom from stress or tension. How would life change if you could savor this at any time, anywhere?
                  </p>
                  <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                    know more
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="trapeCardItem">
                <div className="cardItem">
                  <div className="imgCont">
                    <img src={CardImage29} alt="#" loading="lazy" className="w100 h100" />
                  </div>
                  <h3 className="title fw700 poppins textDark mt-4 mb-3">
                    Healing
                  </h3>
                  <p className="desc fw300 textDark roboto mb-4">
                    Have you ever envisioned a life of well-being, where you possess the ability to heal naturally from pain and suffering?
                  </p>
                  <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                    know more
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="trapeCardItem">
                <div className="cardItem">
                  <div className="imgCont">
                    <img src={CardImage30} alt="#" loading="lazy" className="w100 h100" />
                  </div>
                  <h3 className="title fw700 poppins textDark mt-4 mb-3">
                    Intuition (ESP)
                  </h3>
                  <p className="desc fw300 textDark roboto mb-4">
                    Do you have the feeling that you can sense anything without using the five senses of sight, smell, taste touch or hearing?
                  </p>
                  <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                    know more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default Practice