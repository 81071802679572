import React, { useEffect } from 'react'
import Newnavbar from '../../../components/Newnavbar'
import SlsHeroSection from '../../Sls/SlsHeroSection'
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";
import AboutSls from '../../Sls/AboutSls';
import SlsKeypoints from '../../Sls/SlsKeypoints';
import BengaliSlsCourseSchedule from './BengaliSlsCourseSchedule';
// import SlsCourseSchedule from '../Sls/SlsCourseSchedule';
import SlsWebinarWorks from '../../Sls/SlsWebinarWorks';
import WebinarCard_1_Img from "../../../assets/webinar-card-1.webp";
import WebinarCard_2_Img from "../../../assets/webinar-card-2.webp";
import WebinarCard_3_Img from "../../../assets/webinar-card-3.webp";
import SlsPersonalGrowth from '../../Sls/SlsPersonalGrowth';
import SilvaFounder from '../../../components/SilvaFounder';
import CourseForWhose from '../../../components/CourseForWhose';
import Reviews from '../../../components/Reviews';
import MasterclassInstructor from '../../../components/MasterclassInstructor';
import VideoTestimonial from '../../../components/VideoTestimonial';
import SlsFaqs from '../../../components/SlsFaqs';
import DynamicFaqs from '../../../components/DynamicFaqs';
import Footer2 from '../../../components/Footer2';

function BengaliSls() {

  const perksData = [
    "লাইফ চেঞ্জিং ট্রান্সফরমেশন কোর্স।",
    "শক্তিশালী সিলভা মেথড মেডিটেশন টেকনিকস।",
    "নিজের মনকে  নিয়ন্ত্রণ করার প্রশিক্ষণ।",
    "একটি গ্লোবাল কমিউনিটির সদস্য হন।",
    "ডাউট ক্লিয়ারিং এন্ড হিলিং সেশন।",
    "সিলভা গ্র্যাজুয়েটদের সাথে সাপ্তাহিক মিটআপ ।",
    "উপকৃত না হলে অর্থ ফেরত গ্যারান্টি।"
  ];

  const slsBengKeydata = [
    {
      id: 1,
      heading: "শর্ট রিলাক্সেশন: ",
      text: "নিজের শরীর ও মনকে সম্পূর্ণ ভাবে রিলাক্স করে, আলফা স্তরে প্রবেশ করতে শিখুন।",
    },
    {
      id: 2,
      heading: "লং রিলাক্সেশন: ",
      text: "দীর্ঘ সময়ের জন্য কিভাবে আলফা স্তরে মেডিটেশন করতে শিখুন।",
    },
    {
      id: 3,
      heading: "স্লীপ কন্ট্রোল: ",
      text: "প্রাকৃতিকভাবে গভীর, স্বাস্থ্যকর, নিরবচ্ছিন্ন এবং বিশ্রামের ঘুম উপভোগ করুন।",
    },
    {
      id: 4,
      heading: "অ্যাওয়েক কন্ট্রোল: ",
      text: "প্রয়োজনে বেশিক্ষণ জেগে থাকতে শিখুন। আপনার এনার্জি লেভেল বাড়ান। ",
    },
    {
      id: 5,
      heading: "অ্যালার্ম ক্লক: ",
      text: "অ্যালার্ম ছাড়াই পছন্দসহ সময়ে জেগে উঠুন এবং আপনার স্বাভাবিক মানসিক নিয়ন্ত্রণকে শক্তিশালী করুন। ",
    },
    {
      id: 6,
      heading: "ড্রিম কন্ট্রোল: ",
      text: "আপনার স্বপ্নগুলি ভালভাবে মনে রাখতে শিখুন, এবং সমস্যাগুলি সমাধান করতে এবং নিজের সম্পর্কে অন্তর্দৃষ্টি পেতে এগুলি ব্যবহার করুন।",
    },
    {
      id: 7,
      heading: "হেডএক কন্ট্রোল: ",
      text: "ওষুধ ব্যবহার না করে মাথাব্যথা এবং মাইগ্রেনকে উপশম করুন।",
    },
    {
      id: 8,
      heading: "মেন্টাল স্ক্রিন / মেমরি পেগস: ",
      text: "আপনার কল্পনা শক্তিগুলিকে প্রজেক্ট করতে এবং বিকাশ করতে শিখুন। আপনার স্মৃতি শক্তি উন্নত করুন।",
    },
    {
      id: 9,
      heading: "থ্রী ফিঙ্গারস টেকনিক : ",
      text: "দ্রুত আলফাতে যান আপনার শরীর, মন, আত্মা, আবেগকে একত্রিত করুন।",
    },
    {
      id: 10,
      heading: "মিরর অফ দ্য মাইন্ড: ",
      text: "নিজের মাইন্ডকে ব্যবহার করে যেকোনো সমস্যার সমাধান করতে শিখুন।",
    },
    {
      id: 10,
      heading: "হ্যান্ড লেভিটেশন: ",
      text: "নিজেই থিটা লেভেলে প্রবেশ করতে শিখুন।",
    },
    {
      id: 11,
      heading: "গ্লোভ এনেস্থেসিয়া: ",
      text: "শারীরের ব্যথা নিয়ন্ত্রণ করতে শিখুন এমনকি রক্তপাত এবং রক্তক্ষরণ নিয়ন্ত্রণ করুন।",
    },
    {
      id: 12,
      heading: "গ্লাস অফ ওয়াটার টেকনিক: ",
      text: "যেকোনো সমস্যার সমাধানের জন্য বা হারিয়ে যাওয়া বস্তু খোঁজার জন্য এই কৌশলটি ব্যবহার করুন। ",
    },
    {
      id: 13,
      heading: "হ্যাবিট কন্ট্রোল: ",
      text: "দীর্ঘদিনের ক্ষতিকারক অভ্যাসকে ত্যাগ করতে এই পদ্ধতিটি ব্যবহার করুন।",
    },
    {
      id: 14,
      heading: "থ্রী সিন টেকনিক: ",
      text: "যেকোনো জায়গায় এবং যেকোনো সময় এই পদ্ধতির মাধ্যমে দ্রুত আলফা স্তরে পৌঁছে আপনার প্রতিদিনকার ছোট ছোট সমস্যার সমাধান করুন।",
    },
    {
      id: 15,
      heading: "মেন্টাল ভিডিও: ",
      text: "আপনার মস্তিষ্কের ডেল্টা স্তরে পৌঁছে আপনার বড়ো কোন সমস্যার সহজ উপায় খুঁজে বের করুন।",
    },
  ];


  const bengaliWebinarWorksData = [
    {
      id: 1,
      img: WebinarCard_1_Img,
      text: "সিলভা মেথড ফ্রি ওয়েবিনার বা ওয়ার্কশপগুলির উদ্দেশ্য বুঝুন।",
      headingText: "১ম স্টেপ"
    },
    {
      id: 2,
      img: WebinarCard_2_Img,
      text: "কোনো প্রশ্ন থাকলে আমাদের অফিসিয়াল টিমের সাথে যোগাযোগ করুন।",
      headingText: "২য় স্টেপ"
    },
    {
      id: 3,
      img: WebinarCard_3_Img,
      text: "আপনার সমস্ত ডিটেলস সহ ফর্মটি  ফিলআপ করুন এবং জীবন রূপান্তরকারী যাত্রা শুরু করুন।",
      headingText: "৩য় স্টেপ"
    },
  ]


  const aboutJoseSilva = [
    {
      points: `José Silva হলেন Silva Method- এর প্রতিষ্ঠাতা, এবং mind empowerment 
            গবেষণার পথিকৃৎ। সিলভা মানুষের মনের লুকানো সম্ভাবনা জাগ্রত করার জন্য তাঁর জীবন উৎসর্গ 
            করেছিলেন। 22 বছরের গবেষণার পর, তিনি 1966 সালে তাঁর ফলাফল প্রকাশ করেছেন।তাঁর 
            আবিষ্কার কে নিখুঁত এবং মানুশকে শিক্ষাদানের জন্য বাকি জীবন অতিবাহিত করেন।সিলভার উত্তরাধিকার 
            আজ Silva International- এর মাধ্যমে পরিচালিত হয়, একটি সংস্থা যা তাঁর মিশন চালিয়ে যাওয়ার জন্য 
            প্রতিশ্রুতিবদ্ধ – যা মননশীল অনুশীলনের মাধ্যমে জীবনকে উন্নত করার লক্ষ্যে নিবেদিত।`
    }
  ]

  const celebCont = [
    {
      points: `সেলিব্রিটিদের সাফল্যের একটি গোপন অস্ত্র হল – মে ডি টে শন।মনোযোগ, 
            সৃজনশীলতা এবং সামগ্রিক সুস্থতা বাড়াতে তারা নিয়মিত মে ডি টে শন করেন।তারকারা 
            বলেছেন যে মে ডি টে শন করার জন্য প্রতিদিন একটুসময় নেওয়া তাদের মনোযোগ নিবদ্ধ 
            রাখতে এবং সমস্ত মনোযোগ পরিচালনা করতে সহায়তা করে। এটা শুধুশান্ত বোধ সম্পর্কে নয়, 
            মে ডি টে শন তাদের আরও সৃজনশীল হতে এবং তাদের কাজে আরও ভাল করতে সহায়তা করে।
            মননশীলতা কৌশল এবং মে ডি টে শন মানসিক চাপ কমাতে, রাগ ও বিভিন্ন সমস্যাগুলি নিয়ন্ত্রণ 
            করতে এবং একটি শান্ত জীবনযাপনের সর্বোত্তম উপায় হিসাবে আবির্ভূত হয়েছে।সে লি ব্রি টিদে র 
            মধ্যে মে ডি টে শন এখন খুবই জনপ্রি য় একটি চর্চা।`
    }
  ];


  const bengaliSlsFaqs = [
    {
      id: 1,
      title: "Silva Life System কি?",
      text: "সিলভা লাইফ সিস্টেম সম্পূর্ণ প্রোগ্রামের সূচনা বিন্দু। এটি আপনাকে, মেডিটেশন, ভিজ্যুয়ালাইজেশন, আপনার ইচ্ছাগুলিকে সত্য করে তোলা, রোগ নিরাময় এবং আরও অনেক কিছুর প্রাথমিক দক্ষতা শেখায়। এগুলি হল সহজ এবং ব্যবহারিক কৌশল যা আপনি এখনই আপনার দৈনন্দিন জীবনে ব্যবহার করতে পারেন।",
    },
    {
      id: 2,
      title: "Silva Method কি সত্যিই কাজ করে?",
      text: "সিলভা পদ্ধতি মানসিক চাপ কমাতে, ফোকাস উন্নত করতে এবং আত্মবিশ্বাস বাড়ানোর জন্য একটি সহায়ক হাতিয়ার হতে পারে। এই পদ্ধতির কার্যকারিতা ব্যক্তিভেদে পরিবর্তিত হয়। 120টি দেশে 12 মিলিয়নেরও বেশি মানুষ সিলভা মেথড মেডিটেশনের শক্তি অনুভব করেছে।",
    },
    {
      id: 3,
      title: "Silva Method মেডিটেশনের উপকারিতা কি?",
      text: "এই পদ্ধতি অনুশীলনের সুবিধাগুলি আপনার কল্পনার মতো অসংখ্য। আপনার অবচেতন মনকে জাগরিত করার শিল্প আয়ত্ত করার মাধ্যমে, আপনি কার্যকরভাবে স্ট্রেস এবং উদ্বেগ দূর করতে পারেন, শান্তিপূর্ণ নিদ্রা অর্জন করতে পারেন, স্ব-নিরাময় এবং অন্যদের রোগ নিরাময়ে সহায়তা করতে পারেন, বিষণ্নতা এবং মাথাব্যথার অনুভূতি দূর করতে পারেন এবং শেষ পর্যন্ত সমস্ত দিকগুলিতে আরও বেশি সাফল্য অর্জন করতে পারেন। আপনার জীবন।",
    },
    {
      id: 4,
      title: "The Silva Life System course কোর্স কি নিরাময় শেখায়?",
      text: "সিলভা লাইফ সিস্টেম আপনার শারীরিক এবং মানসিক সমস্যাগুলি সমাধান করার ক্ষমতা বাড়াতে, মাথাব্যথা উপশম করতে, স্মৃতিশক্তি বাড়াতে এবং ঘুমের গুণমান উন্নত করতে প্রয়োজনীয় কৌশলগুলি অফার করে। এটিতে সহজ ধাপে ধাপে মেডিটেশন অন্তর্ভুক্ত রয়েছে যা আপনাকে অবিলম্বে আপনার শিক্ষাকে আপনার জীবনে প্রয়োগ করতে সহায়তা করে।",
    }
  ];

  const handleBtnClick = (className) => {
    console.log(`.${className}`)
    const keyPointsSection = document.querySelector(`.${className}`);
    if (keyPointsSection) {
      keyPointsSection.scrollIntoView({
        behavior: "smooth",
      });
    }
  };


  useEffect(() => {
    const docs = document.querySelector(".navSection");
    if (docs) {
      docs.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [])

  return (
    <>
      <Newnavbar />
      <SlsHeroSection
        course_title={"সিলভা লাইফ সিস্টেম (SLS)"}
        course_description={
          "স্ট্রেস, চিন্তা, ভয়, এবং নেতিবাচক আবেগ যা আপনার অগ্রগতিতে বাধা দেয় এগুলি থেকে মুক্ত হয়ে, আপনার জীবন রূপান্তরের যাত্রা শুরু করুন।"
        }
        btnCta={"আরও জানুন"}
        CardThumbImg={"/assets/assets/img/sls/SLS img-16.webp"}
        discountPrice={"৫,৫০০"}
        // originalPrice={"১২,০০০"}
        // discount_text={"(৫০ শতাংশ ছাড় )"}
        star={"৪.৫"}
        ratingText={"৭০০০+ রেটিং"}
        perksData={perksData}
        perksTitle={"সিলভা লাইফ সিস্টেমের কিছু আনলিমিটেড সুবিধা"}
        card={
          <div className="doubtsArea d-block d-lg-block">
            {/* <div className="d-flex gap-3">
              <FaCalendarAlt />
              <p class="primaryWhite roboto">৩১শে মে এবং ১ম,২য়,৩য় জুন</p>
            </div>
            <div className="d-flex gap-3">
              <FaClock />
              <p class="primaryWhite roboto">রাত্রি ৮ ঘটিকা - রাত্রি ১১ ঘটিকা(বাংলাদেশ সময়)</p>
            </div>
            <div className="d-flex gap-3">
              <FaClock />
              <p class="primaryWhite roboto m-0">৭ঘ: ৩০ মিনিট - ১০ঘ: ৩০ মিনিট (ভারতীয় সময়)</p>
            </div>
            <div className="d-flex gap-3 mt-3">
              <IoMdPricetags />
              <p class="primaryWhite roboto m-0">৭৫০০(বাংলাদেশি মুদ্রা) / ৫,৫০০(ভারতীয় মুদ্রা)</p>
            </div> */}
            <p class="primaryWhite roboto my-3 title fw400">সিলভা লাইফ সিস্টেমের আগামী মাস্টারক্লাস</p>
            <p class="primaryWhite roboto m-0">শীঘ্রই আসছে</p>
          </div>
        }
        buyBtnCTA={"শীঘ্রই আসছে "}
        coursePriceBtnText={"কোর্সের মূল্য"}
        btnShow={false}
        extraClass={"h2"}
        BtnClick={() => handleBtnClick("keyPointsSection")}
      />
      <AboutSls
        title={"সিলভা লাইফ সিস্টেমের "}
        colored={"উপকারিতা"}
        subheading={"জীবনের জটিলতা গুলিকে কিছুতেই কাটিয়ে উঠতে পারছেন না?"}
        description={`সিলভা লাইফ সিস্টেমের সাহায্যে আপনার জীবনকে সহজ করুন। নেতিবাচক চিন্তাধারাকে ইতিবাচক ও সচেতন চিন্তাধারার মাধ্যমে দূরীভূত করুন। মাথাব্যথা, মাইগ্রেন এবং দীর্ঘদিনের খারাপ অভ্যাস থেকে নিজেকে মুক্ত করুন। জটিলতাকে সহজেই জয় করুন এবং আপনার মনের অজানা শক্তিগুলিকে অনুসন্ধান করুন। সিলভা লাইফ সিস্টেমের রূপান্তরকামী শক্তি অনুভব করুন এবং নতুন আপনাকে আবিষ্কার করুন!`}
        headingStyle={"blsAboutHeading"}
      />
      <SlsKeypoints
        data={slsBengKeydata}
        title={"কোর্সের মুখ্য "}
        span={"বিষয়বস্তু"}
        btnText={"এখনি রেজিস্টার করুন"}
        extraClass={"mt-0"}
        btnOnclick={() => handleBtnClick("rightCard")}
      />
      <BengaliSlsCourseSchedule />
      <SlsWebinarWorks
        data={bengaliWebinarWorksData}
        title={"সিলভা মেথডের"}
        subtitle={"ফ্রি ওয়ার্কশপগুলি কেমন ভাবে কাজ করে"}
        description={
          "আপনি যদি সিলভা মেথড সম্পর্কে আগ্রহী এবং ভাবছেন কিভাবে সিলভা পদ্ধতিটি বিনামূল্যে শিখবেন, তাহলে নীচে দেওয়া স্টেপ গুলি অনুসরন করুন"
        }
      />
      <CourseForWhose
        bgColor=""
        startText="Silva Method- এর"
        spanText=" অগ্রগামী এবং প্রতিষ্ঠাতা"
        endText=""
        pointsArr={aboutJoseSilva}
        img="/assets/images/rahul/masterclass-jose-silva.webp"
        imgPos="right"
        ptop={false}
      />
      <CourseForWhose
        startText="সেলিব্রিটি জগতে"
        spanText=" মেডিটেশন"
        endText=""
        pointsArr={celebCont}
        img="/assets/images/rahul/celeb-img.webp"
        imgPos="left"
        bgColor="lightBg"
        ptop={true}
      />
      <Reviews extraClass={"ptop"} />
      <MasterclassInstructor />
      <VideoTestimonial heading="Silva Method -এ অংশগ্রহণকারীরা কি বলছে জানুন" />
      <DynamicFaqs data={bengaliSlsFaqs} defaultactive={0} title='জিজ্ঞাসিত কিছু প্রশ্ন।' />
      <Footer2 />
    </>
  );
}

export default BengaliSls