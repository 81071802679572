import React from 'react'
import './AboutInstructorStyle.css'


const AboutInstructor = ({ lightBg, customStyle, contentArea }) => {
    return (
        <>
            <section className={`aboutInstructorSection ptop pbtm ${lightBg} ${customStyle}`}>
                <div className="container">
                    {contentArea}
                </div>
            </section>
        </>
    )
}

export default AboutInstructor