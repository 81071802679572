import React from 'react'
import "./AboutUsStyle.css";
import AboutUsBGImage from "../assets/about-us-banner.webp"

const AboutUs = () => {
  return (
    <div>
        <section className="aboutUsSection bgImgProp">
      <div className="container">
        <div className="aboutCont">
          <p className="title primaryWhite poppins text-capitalize borderRect">
            about us
          </p>
          <h2 className="h2 fw700 poppins primaryWhite text-capitalize">
            jose silva
          </h2>
          <p className="title primaryWhite poppins">
            The Pioneer and the Founder of the Silva Method
          </p>
          <p className="desc fw300 primaryWhite roboto">
            With a tough early life, the weight of responsibility in his teenage years, and an ever-curious
            youth - how did these experiences cul in crafting a transformative meditation program that
            strengthens the mind and bridges its connection to the body?
          </p>
          <a href="#" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape">
            know more
          </a>
        </div>
      </div>
    </section>
    </div>
  )
}

export default AboutUs