import React, { useState } from 'react';
import './MasterclassSessionStyle.css'
import { Link } from 'react-scroll';
import { Link as UrlLink } from 'react-router-dom';
import { handleScroll } from '../utils/animPulse'




const MasterclassSession = ({ bgImg, headingText, descText, cardTitle1, cardTitle2, cardBtn1, cardBtn2, outerBtn }) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };


    return (
        <>
            <section className="masterClassSession ptop pbtm bgImgProp" style={{ backgroundImage: `url(${bgImg})` }}>
                <div className="container">
                    {
                        headingText &&
                        <h2 className="h2 fw700 poppins primaryWhite text-center mb-4">{headingText}</h2>
                    }
                    {
                        descText &&
                        <p className="fw500 poppins primaryWhite text-center mb-4 mb-lg-5">{descText}</p>
                    }
                    <div className="row row-gap-5">
                        <div className="col-lg-6">
                            <div className="cardItem">
                                <h3 className='mdFont fw600 primaryWhite mb-0'>{cardTitle1}</h3>
                                <div className="text-center">
                                    {
                                        <>
                                            {
                                                cardBtn1 &&
                                                <UrlLink to="/pre-record-masterclass" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0">
                                                    {cardBtn1}
                                                </UrlLink>
                                            }


                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cardItem">
                                <h3 className='mdFont fw600 primaryWhite mb-0'>{cardTitle2}</h3>
                                {
                                    cardBtn2 || null
                                }
                            </div>
                        </div>
                    </div>
                    {
                        outerBtn || null
                    }
                    {/* <div className="text-center">
                        {
                            outerBtn &&
                            <Link to="masterClassForm_bls" smooth={true} duration={500}>
                                <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" onClick={() => handleScroll("#masterClassForm_bls")}>
                                    {outerBtn}
                                </button>
                            </Link>
                        }
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default MasterclassSession