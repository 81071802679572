import React from 'react'
import './CurriculumAccordionStyle.css'



const CurriculumAccordion = ({ lightBg, ptop, pbtm, customClass, accordionCont }) => {

    return (
        <section className={`curriculumAccordionSection ${lightBg} ${ptop} ${pbtm} ${customClass}`}>
            <div className='container'>
                {accordionCont}
            </div>
        </section>
    )
}

export default CurriculumAccordion