import React from 'react';
import "./HeroSectionStyle.css";
import MobileBannerImg1 from "../assets/homepage-banner-for-mobile-01.webp"
import MobileBannerImg2 from "../assets/homepage-banner-for-mobile-02.webp"
import MobileBannerImg3 from "../assets/homepage-banner-for-mobile-03.webp"
import TabBannerImg1 from "../assets/homepage-banner-for-tab-01.webp"
import TabBannerImg2 from "../assets/homepage-banner-for-tab-02.webp"
import TabBannerImg3 from "../assets/homepage-banner-for-tab-03.webp"
import BannerImg1 from "../assets/slider-bg-1.webp"
import BannerImg2 from "../assets/slider-bg-2.webp"
import BannerImg3 from "../assets/slider-bg-3.webp"
import { Link } from 'react-router-dom';

const HeroSection = () => {
  const scrollToSec = () => {
    const elem = document.getElementById("industrySection")
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

  

  return (
    <div>
      <section className="heroSection lightBg">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2500">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
              className="indicatorsBtn active" aria-current="true" aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
              className="indicatorsBtn" aria-label="Slide 2">
            </button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
              className="indicatorsBtn" aria-label="Slide 3">
            </button>
          </div>

          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={MobileBannerImg2} className="d-md-none d-block w-100 indexHeroMob"
                alt="Slide 1" loading="lazy" />
              <img src={TabBannerImg2}
                className="d-none d-md-block d-lg-none w-100" alt="Slide 1" loading="lazy" />
              <img src={BannerImg2} className="d-none d-lg-block w-100" alt="Slide 1" loading="lazy" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="heroCont">
                    <h1 className="title primaryWhite poppins p1 borderRect fw600">
                      The Silva Method India
                    </h1>
                    <h2 className="h1 fw700 poppins">
                      Healthy Mind, Wealthy Life!
                    </h2>
                    <h3 className="desc fw600 roboto mb-4 heroH3Title">
                      Dynamic meditation and mind empowerment techniques for
                      Betterment in every aspect of life!
                    </h3>
                    <h4 className="desc roboto heroH4Title">
                      Register for the Silva Method BLS course today by clicking the button below.
                    </h4>
                    <Link to="/basic-lecture-series" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={MobileBannerImg1} className="d-md-none d-block w-100 indexHeroMob"
                alt="Slide 1" loading="lazy" />
              <img src={TabBannerImg1}
                className="d-none d-md-block d-lg-none w-100" alt="Slide 1" loading="lazy" />
              <img src={BannerImg1} className="d-none d-lg-block w-100" alt="Slide 1" loading="lazy" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="heroCont">
                    <h1 className="title primaryWhite poppins p1 borderRect fw600">
                      The Silva Method India
                    </h1>
                    <h2 className="h1 fw700 poppins">
                      Better Version, Each Day
                    </h2>
                    <h3 className="desc fw600 roboto mb-4 heroH3Title">
                      Embrace the power of the Alpha State and
                      flourish in every endeavor of life!
                    </h3>
                    <h4 className="desc roboto heroH4Title">
                      Need personalized guidance from a certified Silva Method instructor? Book a 1-on-1 counseling session now by clicking the button below.
                    </h4>
                    <button className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape border-0" onClick={() => scrollToSec()}>
                      Book an Appointment
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={MobileBannerImg3} className="d-md-none d-block w-100 indexHeroMob"
                alt="Slide 1" loading="lazy" />
              <img src={TabBannerImg3}
                className="d-none d-md-block d-lg-none w-100" alt="Slide 1" loading="lazy" />
              <img src={BannerImg3} className="d-none d-lg-block w-100" alt="Slide 1" loading="lazy" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="heroCont">
                    <h1 className="title primaryWhite poppins p1 borderRect fw600">
                      The Silva Method India
                    </h1>
                    <h2 className="h1 fw700 poppins">
                      Empower Your Mind
                      The Greatest Weapon of Life!
                    </h2>
                    <h3 className="desc fw600 roboto mb-4 heroH3Title">
                      Dynamic meditation and mind empowerment techniques for everyday life!
                    </h3>
                    <h4 className="desc roboto heroH4Title">
                      Take a step towards personal transformation with Silva Method courses. Click the button below to view all our courses.
                    </h4>
                    <Link to="/silva-method-india-all-courses" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                      Explore All Courses
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HeroSection