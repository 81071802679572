import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Link as SliderLink } from 'react-scroll'
import Navbar2 from '../components/Navbar2'
import MasteryHero99 from '../components/MasteryHero99'
import MasterclassBgImg from '../components/MasterclassBgImg'
import SliderCardTestimonial from '../components/SliderCardTestimonial'
import SliderBand from '../components/SliderBand'
import WhoProgram from '../components/WhoProgram'
import WhatLearn from '../components/WhatLearn'
import MasterclassSession from '../components/MasterclassSession'
import WhyMasterClass from '../components/WhyMasterClass'
import SilvaProgramDetails from '../components/SilvaProgramDetails'
import checkicon from '../assets/check-bg.svg'
import Footer2 from '../components/Footer2'
import Sticky from '../components/Sticky'
import MasterclassForm from '../components/MasterclassForm'
import InternationalMeditation from '../components/InternationalMeditation'
import toast from 'react-hot-toast';
import { validatePhoneNumber } from '../utils/Helper';
import { handleScroll } from '../utils/animPulse'

const EngFreeIntroBLS = () => {

    //---------------code for form data submit----------------

    const [formData, setFormData] = useState({
        fname: '',
        mobile: '',
        email: '',


    });

    const [error, setError] = useState('');
    console.log('formdata', formData)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.fname || !formData.mobile || !formData.email) {
            setError('All fields are required');
            return;
        }

        const isValid = validatePhoneNumber(formData.mobile)
        // console.log(isValid,"hello");
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }

        try {
            console.log('i am inside try');
            console.log('formdata', formData)
            const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-blsengfree-lead', {
                method: "POST",
                body: JSON.stringify({
                    "name": formData.fname,
                    "email": formData.email,
                    "whatsapp_number": formData.mobile

                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });



            console.log('Response:', response);
            // Reset form fields after successful submission
            //   launchRazorpayPayment11(formData);

            setFormData({
                fname: '',
                mobile: '',
                email: '',

            });

            setError('');
            window.location.href = '/successfully-registered';
            //   await handlePayment();
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred, please try again later.');
        }
    };


    const detailsCont = [
        {
            image: 'assets/images/rahul/certified-instructor-1.webp',
            name: 'Debalina Chatterjee',
            designation: '',
            text: 'As a Certified Silva Method Instructor, Debalina brings a dynamic and passionate approach to personal empowerment through her training sessions.',
        },
        {
            image: 'assets/images/rahul/certified-instructor-2.webp',
            name: 'Soumyajeet Bandyopadhay',
            designation: '',
            text: 'As a Certified Silva Method Instructor with a background in Software Engineering, Branding, and advertising, I blend technical expertise into my training sessions.',
        },
    ]


    const testimonialCont = [
        {
            image: 'assets/images/rahul/testimonial-1.webp',
            name: 'Sanjay Dutt',
            designation: 'Actor',
            text: 'I learned the art of Silva meditation and it helps in positive thinking',
        },
        {
            image: 'assets/images/rahul/testimonial-2.webp',
            name: 'Amrish Puri',
            designation: 'Actor',
            text: 'I learned a lot about meditation in Silva Classes',
        },
        {
            image: 'assets/images/rahul/testimonial-3.webp',
            name: 'Kishore Namit Kapoor',
            designation: 'Acting Teacher, Actor and Author',
            text: 'Silva can help bring direction and purpose to your life',
        },
        {
            image: 'assets/images/rahul/testimonial-4.webp',
            name: 'Naveen Jindal',
            designation: 'Chairman Jindal Steel, Politician and MP',
            text: 'Silva can help in every area of life',
        },
        {
            image: 'assets/images/rahul/testimonial-5.webp',
            name: 'Chitra Singh',
            designation: 'Ghazal Singer',
            text: 'Silva is a great program',
        },
        {
            image: 'assets/images/rahul/testimonial-6.webp',
            name: 'Yashovardhan Birla',
            designation: 'Director, Yash Birla Group',
            text: 'With Silva you can enhance and maintain your health and fitness',
        },
        {
            image: 'assets/images/rahul/testimonial-7.webp',
            name: 'Tanuja Mukherjee Samarth',
            designation: 'Actress',
            text: 'Silva is very helpful for attaining mental relaxation',
        },
        {
            image: 'assets/images/rahul/testimonial-8.webp',
            name: 'Debraj Chatterjee',
            designation: 'Working Professional',
            text: 'Silva Techniques are amazing for stress relief and managing negative thoughts',
        },
        {
            image: 'assets/images/rahul/testimonial-9.webp',
            name: 'Sneha Panda',
            designation: 'IT Professional',
            text: 'I have been practicing for a year. It really helped in my work by enhancing creativity',
        },
        {
            image: 'assets/images/rahul/testimonial-10.webp',
            name: 'Monideepa Das',
            designation: 'Graphics Designer',
            text: 'Silva has been transformative in helping me stay focused and productive at work.',
        },
        {
            image: 'assets/images/rahul/testimonial-11.webp',
            name: 'Prerna Roy',
            designation: 'Budding Singer',
            text: 'As a budding singer, Silva has been instrumental in boosting my confidence and performance.',
        },
    ]


    const benefitsPoints = [
        {
            points: 'Gain entry to a global community of diverse individuals.'
        },
        {
            points: 'Have your questions addressed and attend healing sessions.'
        },
        {
            points: 'Participate in weekly graduate meetings with Silva graduates.'
        },
        {
            points: 'Connect with certified Silva Method Instructors.',
            hidemargin: true
        },
    ]


    const sliderImgs = [
        {
            id: 1,
            imgUrl: "/assets/images/rahul/Artboard 1.webp"
        },
        {
            id: 2,
            imgUrl: "/assets/images/rahul/Artboard 2.webp"
        },
        {
            id: 3,
            imgUrl: "/assets/images/rahul/Artboard 3.webp"
        },
        {
            id: 4,
            imgUrl: "/assets/images/rahul/Artboard 4.webp"
        },
        {
            id: 5,
            imgUrl: "/assets/images/rahul/Artboard 5.webp"
        },
        {
            id: 6,
            imgUrl: "/assets/images/rahul/Artboard 6.webp"
        },
    ]


    return (
        <>
            <Navbar2 />
            <MasteryHero99
                heroSection="engFreeIntroBLS"
                mobBanner="/assets/images/rahul/eng-bls-free-intro-banner.webp"
                tabBanner="/assets/images/rahul/eng-bls-free-intro-banner.webp"
                webBanner="/assets/images/rahul/eng-bls-free-intro-banner.webp"
                heroTextCont={
                    <>
                        <h1 className="h1 fw400 anton primaryWhite mb-4">
                            Step into Stress-Free Living in <span className='colorYellow'>2024</span>
                            <span className='d-block colorYellow mt-2'>SILVA METHOD</span>
                            <span className='d-block mt-2 poppins fw600'>INTRODUCTORY</span>
                        </h1>
                        <h2 className='h2 fw700 poppins shapeRect primaryYellow primaryBlack'>
                            FREE MASTERCLASS
                        </h2>
                    </>
                }
                heroForm={
                    <div className="rightForm" id="masterClassForm">
                        <div className="div1 d-flex flex-column align-items-start row-gap-2 flex-lg-row justify-content-lg-center">
                            <span className="primaryBlack fw500 date">
                                Date <span className="fw700 shapeRect gradientBg primaryWhite">18th September</span>
                            </span>
                            <span className="primaryBlack fw500 ms-lg-4">
                                Time <span className="fw700 shapeRect gradientBg primaryWhite">08:30 PM (IST)</span>
                            </span>
                        </div>
                        <form onSubmit={handleSubmit}>
                            {error && <div className="error">{error}</div>}
                            <div className="row gy-4">
                                <div className="col-12">
                                    <input type="text" className="form-control" id="userName" name="fname" placeholder="Full Name" value={formData.fname} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" id="userPhn" name="mobile" placeholder="Whatsapp Number" value={formData.mobile} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <input type="email" className="form-control" id="userEmail" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                            <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 ms-0 border-0">Enroll Now</button>
                        </form>
                    </div>
                }
            />
            <MasterclassBgImg
                fullWidthImg={
                    <img src="/assets/images/rahul/book-img-copy.webp" alt="#" loading='lazy' className='w100 h100' />
                }
            />
            <SliderCardTestimonial
                heading={
                    <h2 className="h2 fw700 poppins textDark text-center mb-4">
                        Meet Our <span className="gradientText">Silva Certified Instructors</span>
                    </h2>
                }
                data={detailsCont}
                rating={false}
            />
            <SliderBand />
            <WhoProgram />
            <MasterclassBgImg
                fullWidthImg={
                    <img src="/assets/images/rahul/benefit-points-bg.webp" alt="#" loading='lazy' className='w100 h100' />
                }
                mobImg={
                    <img src="/assets/images/rahul/benefit-points-bg-mob.webp" alt="#" loading='lazy' className='w100 h100' />
                }
            />
            <WhatLearn />
            <MasterclassSession
                bgImg="assets/images/rahul/session-img.webp"
                cardTitle1="Watch Our Pre-recorded Introductory Master Class Session"
                cardTitle2="Join Our Introductory Master Class Now and Explore New Horizons"
                cardBtn1="Watch Now"
                cardBtn2={
                    <div className="text-center">
                        <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" onClick={() => handleScroll("#masterClassForm")}>
                            Join Now
                        </button>
                    </div>
                }
            />
            <WhyMasterClass
                classParent="whyMasterClassSection"
                bgWebImg="/assets/images/rahul/why-masterclass-web-bg.webp"
                bgMobImg="/assets/images/rahul/why-masterclass-mob-bg.webp"
                mobImgHeight="840px"
                contentArea={
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-3">
                                So, Why this Masterclass
                            </h2>
                            <h3 className="title fw600 poppins textDark mb-4">
                                Did you know?
                            </h3>
                            <p className="fw400 poppins textDark mb-0 masterClassDesc">
                                Albert Einstein once claimed that he tapped into just 10% of his mind,
                                while most of us typically use a mere 3-4%. That’s exactly why this
                                Silva Method Masterclass exists – to guide you in unlocking more of your
                                genius mind!
                            </p>
                            <SliderLink to="rightForm" smooth={true} duration={500}>
                                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" onClick={() => handleScroll(".rightForm")}>
                                    Book your spot
                                </button>
                            </SliderLink>
                        </div>
                        <div className="col-lg-6"></div>
                    </div>
                }
            />
            <SilvaProgramDetails
                sectionTitle={
                    <h2 className="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        The Silva Method <span className="gradientText">Beginner's Program Details</span>
                    </h2>
                }
                sectionCont={
                    <div className="row align-items-center row-gap-5">
                        <div className="col-lg-6">
                            <div className="leftCard">
                                <h3 className='mdFont fw700 poppins textDark mb-4'>
                                    Ready to Dive In?
                                </h3>
                                <p class="title fw500 textDark roboto mb-4">
                                    Join over a million individuals who’ve embraced a transformative journey,
                                    reshaping their personal and professional paths. Conquer fears, unlock your
                                    improved self, and experience a revolution in both physical well-being and
                                    mental health with the Silva Method. Don’t hesitate – embark on this exciting
                                    adventure to unveil the amazing new you!
                                </p>
                                <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0" onClick={() => handleScroll("#masterClassForm")}>
                                    Join for free
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rightCont">
                                <h3 className='mdFont fw600 poppins textDark mb-4'>
                                    What you get ?
                                </h3>
                                <ul className='resetUl'>
                                    {
                                        benefitsPoints?.map((item, idx) =>
                                            <li class={`d-flex align-items-start gap-2 ${item.hidemargin ? "" : "mb-3"}`}>
                                                <img src={checkicon} alt="#" loading="lazy" class="circleTick" />
                                                <p class="title textDark roboto fw300 mb-0">{item.points}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            />
            <MasterclassSession
                bgImg="assets/images/rahul/offer-img.webp"
                headingText="Exciting Bonus"
                descText="Unlock a complimentary bonus and delve into free meditation exercises"
                cardTitle1="Mind-Body Soul Relaxation Meditation"
                cardTitle2="Stress Reduction Meditation"
                outerBtn={
                    <div className="text-center">
                        <button type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" onClick={() => handleScroll("#masterClassForm")}>
                            Claim your free bonus now
                        </button>
                    </div>
                }
            />
            <InternationalMeditation
                lightBg=""
                ptop="ptop"
                pbtm="pbtm"
                sectionHeading={
                    <h2 class="h2 fw700 poppins textDark text-center mb-4">
                        Highlights from our <span class="gradientText">live classes</span>
                    </h2>
                }
                sliderImgs={sliderImgs}
            />
            <SliderCardTestimonial
                heading={
                    <h2 class="h2 fw700 poppins textDark text-center mb-4">
                        Hear From Our <span class="gradientText">Silva Enthusiasts</span>
                    </h2>
                }
                data={testimonialCont}
            />
            <Footer2
                footer2Margin='mb-60'
            />
            <Sticky
                date="Date: 18th September (Wednesday)"
                btnText="Enroll for free"
            />
        </>
    )
}

export default EngFreeIntroBLS