import React from 'react'
import "./InstructorsStyle.css";
import InstructorBGImage from "../assets/instructor-bg.webp"
import Instructor1Image from "../assets/instructor-1.webp"
import Instructor2Image from "../assets/instructor-2.webp"
import Instructor3Image from "../assets/instructor-3.webp"
import Instructor4Image from "../assets/instructor-4.webp"
import { Link } from 'react-router-dom';


const Instructors = () => {
  return (
    <div>
      <section className="instructorSection" >
        <div className="container">
          <div className="contArea pbtm text-center">
            <div className="blankHeight"></div>
            <p className="title primaryWhite poppins text-capitalize borderRect">
              our best certified
            </p>
            <h2 className="h2 fw700 poppins primaryWhite text-capitalize">
              silva method instructors
            </h2>
            <div className="row cardRowGap">
              <div className="col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="leftCont">
                      <div className="imgCont">
                        <img src={Instructor1Image} alt="#" loading="lazy" className="w100 h100" />
                      </div>
                    </div>
                    <div className="rightCont text-start">
                      <h3 className="title fw700 poppins primaryWhite mb-2">
                        Debasish Chatterjee
                      </h3>
                      <p className="fw600 poppins textDark shapeRect lightTrape">
                        Instructor
                      </p>
                      <p className="desc fw300 primaryWhite roboto mb-4">
                        With an illustrious career spanning nearly 40 years as a senior accountant,
                        offers a remarkable fusion of financial expertise and spiritual...
                      </p>
                      <Link to="/instructor"
                        className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape">
                        know more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Repeat this structure for other instructors */}
              {/* Instructor 2 */}
              <div className="col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="leftCont">
                      <div className="imgCont">
                        <img src={Instructor2Image} alt="#" loading="lazy" className="w100 h100" />
                      </div>
                    </div>
                    <div className="rightCont text-start">
                      <h3 className="title fw700 poppins primaryWhite mb-2">
                        Soumyajeet Bandyopadhay
                      </h3>
                      <p className="fw600 poppins textDark shapeRect lightTrape">
                        Instructor
                      </p>
                      <p className="desc fw300 primaryWhite roboto mb-4">
                      As a Certified Silva Method Instructor, Soumyajeet brings a dynamic and passionate approach to personal ...
                      </p>
                      <Link to="/instructor"
                        className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape">
                        know more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="leftCont">
                      <div className="imgCont">
                        <img src={Instructor3Image} alt="#" loading="lazy" className="w100 h100" />
                      </div>
                    </div>
                    <div className="rightCont text-start">
                      <h3 className="title fw700 poppins primaryWhite mb-2">
                        Debayan Sen
                      </h3>
                      <p className="fw600 poppins textDark shapeRect lightTrape">
                        Instructor
                      </p>
                      <p className="desc fw300 primaryWhite roboto mb-4">
                      Debayan is an extraordinary Certified Silva Method Instructor who brings a wealth of experience and a multifaceted background...
                      </p>
                      <Link to="/instructor"
                        className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape">
                        know more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="leftCont">
                      <div className="imgCont">
                        <img src={Instructor4Image} alt="#" loading="lazy" className="w100 h100" />
                      </div>
                    </div>
                    <div className="rightCont text-start">
                      <h3 className="title fw700 poppins primaryWhite mb-2">
                        Debalina Chatterjee
                      </h3>
                      <p className="fw600 poppins textDark shapeRect lightTrape">
                        Instructor
                      </p>
                      <p className="desc fw300 primaryWhite roboto mb-4">
                      As a Certified Silva Method Instructor, Debalina brings a dynamic and passionate approach to personal empowerment through her training...
                      </p>
                      <Link to="/instructor"
                        className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape">
                        know more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Instructors