import React from "react";
import './App.css';
// import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Courses from "./routes/Courses";
import Contact from "./routes/Contact";
import { Route, Routes, useLocation } from "react-router-dom"
import Instructor from "./routes/Instructor";
import Solution from "./routes/Solution";
import CourseLanding from "./routes/CourseLanding";
import ThankYou from "./routes/ThankYou";
import ThankYouPage from "./routes/ThankYouPage";
import LeadPage from "./routes/LeadPage";
import LeadPageOne from "./routes/LeadPageOne";
import SLSproblemFixer from "./routes/digital-course/SLSproblemFixer";
import HealthLanding from "./routes/HealthLanding";
import BengaliBLSIntro from "./routes/BengaliBLSIntro";
import CourseSLS from "./routes/Sls/CourseSLS";
import PaymentSuccess from "./routes/PaymentSuccess";
import PaymentFailure from "./routes/PaymentFailure";
import Certificate from "./routes/Certificate";
import RegisteredSuccess from "./routes/RegisteredSuccess";
import CourseList from "./routes/CourseList";
import EngFreeIntroBLS from "./routes/EngFreeIntroBLS";
import FinancesLead from "./routes/FinancesLead";
import { Toaster } from "react-hot-toast";
import MoneyMagnet from "./routes/MoneyMagnet.js";
import BengaliMoneyManagement from "./routes/BengaliMoneyManagement.js";
import Terms from "./routes/Terms.js";
import BengaliSls from "./routes/live-courses/BengaliSls.jsx/BengaliSls.jsx";
import NotFound from "./routes/NotFound/NotFound.js";
import BenagaliBls from "./routes/BengaliBls/BenagaliBls.jsx";
import ThankYouPageEng from "./routes/ThankYouPageEng.js";
import LionGate from "./routes/LionGate.js";
import ParentingWorkshop from "./routes/ParentingWorkshop.js";
import StressManagement from "./routes/StressManagement.js";
import RecordedMoneyMagnetMasterclass from "./routes/RecordedMoneyMagnetMasterclass.js";
import ManifestationMasterclass from "./routes/ManifestationMasterclass.js";
import RecordedBeginnerMasterclass from "./routes/RecordedBeginnerMasterclass.js";
import RecordedHealthMasterclass from "./routes/RecordedHealthMasterclass.js";
import RecordedEmotionMasterclassMorning from "./routes/RecordedEmotionMasterclassMorning.js";
import RecordedEmotionMasterclassEvening from "./routes/RecordedEmotionMasterclassEvening.js";
import { Helmet } from 'react-helmet';


function App() {

  const location = useLocation();
  console.log(location.pathname)





  return (
    <>
      <div>
        {
          location.pathname === "/" ? (
            <Helmet>
              <title> Silva Method India: Learn Original Silva Mind Control Meditation Techniques </title>

              <meta name="keywords" content=" Silva Method India, Silva Mind Control Meditation, Mind Control Techniques, Silva Method Courses, The Silva Mind control Method, Silva Method Meditation, Silva Method online course, Silva Mind Control Training, Jose Silva Method " />
              <meta name="description" content=" Silva Method India offers original Silva Mind Control Meditation and advanced mind control techniques. Enhance your expertise with our professional training programs. " />
              <meta property="og:title" content=" Silva Method India: Learn Original Silva Mind Control Meditation Techniques  " />
              <meta property="og:description" content=" Silva Method India offers original Silva Mind Control Meditation and advanced mind control techniques. Enhance your expertise with our professional training programs. " />
              <meta property="og:url" content="https://www.silvamethod.in/" />
              <meta property="og:type" content="website" />
              <meta property="og:image" content=" https://www.silvamethod.in/static/media/slider-bg-1.c3a7386d3cfff309cc6a.webp " />
              <meta property="og:site_name" content="Silva Method India" />

              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content=" Silva Method India: Learn Original Silva Mind Control Meditation Techniques  " />
              <meta name="twitter:site" content="@https://www.silvamethod.in/" />
              <meta name="twitter:description" content=" Silva Method India offers original Silva Mind Control Meditation and advanced mind control techniques. Enhance your expertise with our professional training programs." />
              <meta name="twitter:image" content="https://www.silvamethod.in/static/media/slider-bg-1.c3a7386d3cfff309cc6a.webp" />
              <meta name="twitter:image:alt" content="Silva Method India" />

              <link rel="canonical" href=" https://www.silvamethod.in/" />



              <script type="application/ld+json">
                {
                  `
                  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Where is the headquarter of the Silva Method India Situated?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Silva Method India’s headquarter is located at - 1st FLOOR, UCP 10, AMBUJA, PO, City Center, Durgapur, West Bengal 713216"
    }
  },{
    "@type": "Question",
    "name": "How do I Contact Silva Method India?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Silva Method contact info:
Phone Numbers: +91 6290 945 413
Email ID: better@silvamethod.in
Official Website:www.silvamethod.in
Official WhatsApp Group Joining Link:chat.whatsapp.com"
    }
  },{
    "@type": "Question",
    "name": "What is the Silva Method?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Silva Method is a self-help, personal development method that combines meditation, visualization, and positive thinking to enhance mental abilities and achieve personal goals. By entering the Alpha state of mind individuals can control the subconscious mind to achieve goals and improve the various aspects of life."
    }
  },{
    "@type": "Question",
    "name": "How to learn the Silva Method for free?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "To explore the basics of The Silva Method for free:
Read online articles and blogs.
Watch YouTube videos.
Attend free seminars or enroll in BLS courses on the Silva Method India's official website:www.silvamethod.in
However, for better understanding, consider investing in paid courses or masterclasses, which offer personalized guidance and comprehensive training."
    }
  },{
    "@type": "Question",
    "name": "Is Meditation really that important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes. Meditation is crucial for health and well-being, countering stress, the main cause of illness. Whether you call it relaxation, centering, or prayer with intent, daily practice strengthens the connection between body, mind, spirit, and emotions."
    }
  }]
}
</script>

                  `
                }
              </script>

            </Helmet>
          ) : location.pathname === "/silva-method-india-all-courses" ? (
            <Helmet>
              <title> Original Silva Mind Control: Live Classes & Courses Near You </title>

              <meta name="keywords" content="Original Silva Mind Control Courses, Silva Method live classes, Silva Mind Control Course Near Me, Silva Mind Control Course Online, Silva Method Online Course, Silva Method Courses India, Best Silva Mind Control Course in India, Silva Method India Course List, Mind Control Training " />
              <meta name="description" content=" Join original Silva Mind Control courses and live classes near you. Learn proven Silva Method techniques for mind control, meditation, and personal growth. " />
              <link rel="canonical" href=" https://www.silvamethod.in/silva-method-india-all-courses" />

              <meta property="og:type" content="website" />
              <meta property="og:title" content=" Original Silva Mind Control: Live Classes & Courses Near You " />
              <meta property="og:url" content="https://www.silvamethod.in/silva-method-india-all-courses" />
              <meta property="og:image" content="https://www.silvamethod.in/assets/images/rahul/courses-header-for-web.webp" />
              <meta property="og:description" content="Join original Silva Mind Control courses and live classes near you. Learn proven Silva Method techniques for mind control, meditation, and personal growth." />

              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content=" Original Silva Mind Control: Live Classes & Courses Near You " />
              <meta name="twitter:site" content="@Silva Method India" />
              <meta name="twitter:description" content="Join original Silva Mind Control courses and live classes near you. Learn proven Silva Method techniques for mind control, meditation, and personal growth." />
              <meta name="twitter:image" content="https://www.silvamethod.in/assets/images/rahul/courses-header-for-web.webp" />
              <meta name="twitter:image:alt" content="Original Silva Mind Control Courses" />

            </Helmet>
          ) : location.pathname === "/instructor" ? (
            <Helmet>
              <title> Best Certified Silva Method Instructors - Silva Method India </title>

              <meta name="description" content="Find India’s leading certified Silva Method instructors. Receive exceptional training for your growth. Contact us now to get started with our expert trainers!" />

              <meta name="keywords" content=" Best Instructors Silva Method India, Certified Silva Method Instructor, Best Silva Method Certified Instructors, Silva Certified Instructors, Best Silva Method Certified Instructors Near me, certified Silva Method instructors in India, Silva Method Instructor, Silva Method Instructor Training, Silva Mind control techniques, Professional Silva Method Instructor, Silva Method Trainer, " />

              <link rel="canonical" href=" https://www.silvamethod.in/instructor/" />

              <meta property="og:type" content="website" />
              <meta property="og:title" content="Best Certified Silva Method Instructors - Silva Method India " />
              <meta property="og:url" content="https://www.silvamethod.in/instructor" />
              <meta property="og:image" content="https://www.silvamethod.in/static/media/instructor-header.a86e2d5b690764235a56.webp" />
              <meta property="og:image:alt" content=" Best Instructors Silva Method India" />

              <meta property="og:description" content="Find India’s leading certified Silva Method instructors. Receive exceptional training for your growth. Contact us now to get started with our expert trainers!" />
              <meta property="og:keyword" content=" Best Instructors Silva Method India, Certified Silva Method Instructor, Best Silva Method Certified Instructors, Silva Certified Instructors, Best Silva Method Certified Instructors Near me, Certified Silva Method Instructors in India, Silva Method Instructor, Silva Method Instructor Training, Silva Mind control techniques, Professional Silva Method Instructor, Silva Method Trainer " />

              <meta name="twitter:card" content="summary" />
              <meta name="twitter:title" content="Best Certified Silva Method Instructors - Silva Method India " />
              <meta name="twitter:site" content="@https://www.silvamethod.in/instructor" />
              <meta name="twitter:description" content="Find India’s leading certified Silva Method instructors. Receive exceptional training for your growth. Contact us now to get started with our expert trainers!" />
              <meta name="twitter:image" content="https://www.silvamethod.in/static/media/instructor-header.a86e2d5b690764235a56.webp" />
              <meta name="twitter:image:alt" content="Best Instructors Silva Method India" />

            </Helmet>
          ) : (
            <Helmet>
              <title>The Silva Method India</title>
            </Helmet>
          )

        }
      </div>



      <Routes>

        {/* Main Page */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Instructor />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/silva-method-india-all-courses" element={<CourseList />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/thank-you-page" element={<ThankYouPage />} />
        <Route path="/thank-you-page-eng" element={<ThankYouPageEng />} />
        <Route path="/lead-page" element={<LeadPage />} />
        <Route path="/lead-page1" element={<LeadPageOne />} />
        <Route path="/certificate" element={<Certificate />} />
        <Route path="/payment-successful" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/successfully-registered" element={<RegisteredSuccess />} />
        <Route path="/terms-&-conditions" element={<Terms />} />
        <Route path="/*" element={<NotFound />} />
        {/* Main Page */}


        {/* Live Class */}
        <Route path="/basic-lecture-series" element={<Courses />} />
        <Route path="/silva-basic-lecture-series-bengali" element={<BenagaliBls />} />
        <Route path="/silva-life-system" element={<CourseSLS />} />
        <Route path="/silva-life-system-bengali" element={<BengaliSls />} />
        {/* Live Class */}


        {/* Recorded Class */}
        <Route path="/pre-record-masterclass" element={<RecordedBeginnerMasterclass />} />
        <Route path="/pre-record-health-masterclass" element={<RecordedHealthMasterclass />} />
        <Route path="/pre-record-emotion-masterclass-morning" element={<RecordedEmotionMasterclassMorning />} />
        <Route path="/pre-record-emotion-masterclass-evening" element={<RecordedEmotionMasterclassEvening />} />
        <Route path="/pre-record-money-magnet-masterclass" element={<RecordedMoneyMagnetMasterclass />} />
        {/* Recorded Class */}


        {/* Landing Page */}
        <Route path="/emotion_mastery_99_workshop" element={<CourseLanding />} />
        <Route path="/beginners-online-masterclass-bengali" element={<BengaliBLSIntro />} />
        <Route path="/BLS-english-free-intro" element={<EngFreeIntroBLS />} />
        <Route path="/masterclass-on-health" element={<HealthLanding />} />
        <Route path="/money-affirmation" element={<FinancesLead />} />
        <Route path="/english-money-magnet-masterclass" element={<MoneyMagnet />} />
        <Route path="/bengali-money-magnet-masterclass" element={<BengaliMoneyManagement />} />
        <Route path="/lions-gate-portal-day" element={<LionGate />} />
        <Route path="/art-of-parenting-workshop" element={<ParentingWorkshop />} />
        <Route path="/stress-management-program" element={<StressManagement />} />
        <Route path="/manifestation-masterclass" element={<ManifestationMasterclass />} />
        {/* Landing Page */}


        {/* Others */}
        <Route path="/SLS-problem-fixer" element={<SLSproblemFixer />} />
        {/* Others */}

      </Routes>
      <Toaster
        position="top-center"
      />
    </>
  );
}

export default App;
