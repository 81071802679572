import React from 'react'
import './SliderCardTestimonialStyle.css'
import Slider from "react-slick";
import TestimonialCard from './TestimonialCard';



const SliderCardTestimonial = ({ heading, data, rating = true }) => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    return (
        <>
            <section className="sliderCardTestimonial lightBg ptop">
                <div className="container">
                    {heading}
                    <div className="slider-container">
                        <Slider {...settings}>
                            {
                                data?.map((items, idx) =>
                                    <TestimonialCard
                                        rating={rating}
                                        data={items}
                                    />
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SliderCardTestimonial