import React, { useContext } from 'react'
import { Appcontext } from '../context/appcontext'

const MasterclassBgImg = ({ fullWidthImg, mobImg }) => {
    const { isMobile } = useContext(Appcontext)


    return (
        <>
            <section className="masterClassBgImg bgImgProp">
                {
                    !isMobile && fullWidthImg
                }
                {
                    isMobile && (mobImg || fullWidthImg)
                }
            </section>
        </>
    )
}

export default MasterclassBgImg