import React, { useContext } from 'react'
import './WhyMasterClassStyle.css'
import { Appcontext } from '../context/appcontext'

const WhyMasterClass = ({ classParent, bgWebImg, bgMobImg, mobImgHeight, contentArea }) => {
    const { isMobile } = useContext(Appcontext)


    return (
        <>
            <section className={`${classParent} bgImgProp`} style={{ backgroundImage: `url(${isMobile ? bgMobImg : bgWebImg})`, height: isMobile ? mobImgHeight : "" }}>
                <div className="container">
                    {contentArea}
                </div>
            </section>
        </>
    )
}

export default WhyMasterClass