import React from 'react'
import Navbar2 from '../components/Navbar2'
import RecordClassCont from '../components/RecordClassCont'
const recVid = "https://www.youtube.com/watch?v=xnZVYP9_-6c"

const RecordedHealthMasterclass = () => {
    return (
        <div className='silvaDiffSection pbtm vh-100'>
            <Navbar2 />
            <RecordClassCont
                maintitle="হেলথ ম্যানেজমেন্ট মাস্টারক্লাস” - এর রেকর্ডেড ভিডিও"
                vidUrl={recVid}
                recordVidCont={
                    <>
                        <p className='title fw600 poppins primaryWhite'>
                            এই মাস্টারক্লাস টি 22শে জুন, 2024-এ লাইভ ছিল
                        </p>
                    </>
                }
            />
        </div>
    )
}

export default RecordedHealthMasterclass