import React from 'react'
import "./FAQStyle.css";


const FAQ = () => {
  return (
    <div>
        <section className="faqSection lightBg">
      <div className="faqCont">
        <div className="headingArea text-center mb-4">
          <p className="title fw600 poppins textDark mb-2">
            Frequently asked questions
          </p>
          <h2 className="h2 fw700 poppins textDark text-capitalize">
            about <span className="gradientText">silva method</span>
          </h2>
        </div>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Where is the headquarter of the Silva Method India situated?
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample">
              <div className="accordion-body">
                The Silva Method India’s headquarter is located at - 1st FLOOR, UCP 10,
                AMBUJA, PO, City Center, Durgapur, West Bengal 713216
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                How do I contact The Silva Method India?
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                The Silva Method contact info:<br />
                Phone Numbers: +91 6290 945 413<br />
                Email ID: better@silvamethod.in<br />
                Official Website:
                <a href="#" target="_blank" className="primaryWhite fw600">www.silvamethod.in</a><br />
                Official WhatsApp Group Joining Link:
                <a href="https://chat.whatsapp.com/HBMmPq0qdER1gk4wYeZM0V" target="_blank" className="primaryWhite fw600">
                  chat.whatsapp.com
                </a>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                What is the Silva Method?
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                The Silva Method is a self-help, personal development method that combines
                meditation, visualization, and positive thinking to enhance mental abilities and achieve
                personal goals. By entering the Alpha state of mind individuals can control the
                subconscious mind to achieve goals and improve the various aspects of life.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                How to learn the Silva Method for free?
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                To explore the basics of The Silva Method for free:
                <ul>
                  <li>Read online articles and blogs.</li>
                  <li>Watch YouTube videos.</li>
                  <li>Attend free seminars or enroll in BLS courses on the Silva Method India's
                    official website:
                    <a href="#" target="_blank" className="primaryWhite fw600">
                      www.silvamethod.in
                    </a>
                  </li>
                </ul>
                However, for better understanding, consider investing in paid courses or masterclasses,
                which offer personalized guidance and comprehensive training.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Is meditation really that important?
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes. Meditation is crucial for health and well-being, countering stress, the main cause
                of illness. Whether you call it relaxation, centering, or prayer with intent, daily
                practice strengthens the connection between body, mind, spirit, and emotions.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default FAQ