import React from 'react'
import "./FooterStyle.css";
import FooterBGImg from "../assets/footer-bg.webp"
import SubscribeBGImg from "../assets/subscribe-bg.webp"
import LogoImg from "../assets/silva-india-logo-white.webp"
import IconLocationImg from "../assets/icon-location.webp"
import IconPhoneImg from "../assets/icon-phn.webp"
import IconMailImg from "../assets/icon-mail.webp"
import IconFacebookImg from "../assets/icon-facebook.webp"
import IconTwitterImg from "../assets/icon-twitter.webp"
import IconLinkedInImg from "../assets/icon-instagram.webp"
import IconInstagramImg from "../assets/icon-linkedin.webp"
import IconYoutubeImg from "../assets/icon-youtube.webp"
import IconFooterImg from "../assets/footer-icon-img.webp"
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div>
      <section className="footerSection bgImgProp" style={{ backgroundImage: `url(${FooterBGImg})` }}>
        <div className="container">
          <div className="subscribeBg">
            <div className="innerCont">
              <img src={SubscribeBGImg} alt="#" loading="lazy" className="w100" />
              <div className="subscribeCont">
                <div className="leftCont">
                  <h3 className="title fw900 textDark roboto">
                    Get Wishdom Articles Delivered to your <span className="gradientText">Mailbox</span>
                  </h3>
                </div>
                <div className="rightCont">
                  <form className="subscribeForm">
                    <input type="email" className="form-control" name="email" placeholder="Enter Your Email" required />
                    <button type="submit" className="btnMain fw700 gradientBg primaryWhite text-capitalize shapeTrape">
                      subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-sm-12">
              <div className="footerLeft d-flex gap-4 py-4">
                <div>
                  <Link to={"/"} className="footerLogo d-inline-block">
                    <img src={LogoImg} alt="#" loading="lazy" />
                  </Link>
                  {/* <div className="footerLogo mt-4">
                    <img src="/assets/images/rahul/certified-badge.webp" alt="#" loading='lazy' className='w100 h100' />
                  </div> */}
                </div>
                <div className="footerDesc">
                  <h2 className="title fw600 primaryWhite poppins pt-1 mb-3">
                    About The Silva Method India
                  </h2>
                  <p className="fw300 primaryWhite roboto mb-0">
                    Jose Silva revolutionized meditation with the Silva Method after decades of research and hands-on experimentation. The Silva Method harnesses meditation and relaxation to rewire our minds, bringing about significant personal growth. With regular practice of Silva Mind control techniques, it can transform our outlook on life. Also, it incorporates advanced meditation and mind control techniques for holistic development.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12">
              <div className="middleBorder"></div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="footerRight py-3">
                <div className="row rowGap">
                  <div className="col-md-6">
                    <div className="links mb-4">
                      <h3 className="poppins primaryWhite">
                        Quick Links
                      </h3>
                      <ul className="resetUl">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/instructor">About Us</Link></li>
                        <li><Link to="/silva-method-india-all-courses">Courses</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                        <li><Link to="/terms-&-conditions">Terms & Conditions</Link></li>
                        <li><a href="https://silvamethod.com/" target='_blank'>Silva International</a></li>
                      </ul>
                    </div>
                    <div className="links">
                      <h3 className="poppins primaryWhite">
                        Contact Info
                      </h3>
                      <ul className="resetUl">
                        <li className="d-flex align-items-baseline gap-2">
                          <div className="infoIcon">
                            <img src={IconLocationImg} alt="#" loading="lazy" />
                          </div>
                          <a href="#">West Bengal, India</a>
                        </li>
                        <li className="d-flex align-items-baseline gap-2">
                          <div className="infoIcon">
                            <img src={IconPhoneImg} alt="#" loading="lazy" />
                          </div>
                          <a href="#">+91 6290 945 413</a>
                        </li>
                        <li className="d-flex align-items-baseline gap-2">
                          <div className="infoIcon">
                            <img src={IconMailImg} alt="#" loading="lazy" />
                          </div>
                          <a href="#">better@silvamethod.in</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="links mb-4">
                      <h3 className="poppins primaryWhite">
                        Silva Method Courses
                      </h3>
                      <ul className="resetUl">
                        <li><Link to="/basic-lecture-series">Silva Basic Lecture Series (ENG)</Link></li>
                        <li><Link to="/silva-basic-lecture-series-bengali">Silva Basic Lecture Series (BEN)</Link></li>
                        <li><Link to="/silva-life-system">Silva Life System (ENG)</Link></li>
                        <li><Link to="/silva-life-system-bengali">Silva Life System (BEN)</Link></li>
                      </ul>
                    </div>
                    <div className="links">
                      <h3 className="poppins primaryWhite">
                        Stay Connected
                      </h3>
                      <div className="socialIcons">
                        <a href="https://www.facebook.com/silvamethodindia" target='_blank'><img src={IconFacebookImg} alt="#" loading="lazy" className="w100 h100" /></a>

                        <a href="https://x.com/silvaindia_" target='_blank'><img src={IconTwitterImg} alt="#" loading="lazy" className="w100 h100" /></a>
                        {/* <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FSilvaIndia_"><img src={IconTwitterImg} alt="#" loading="lazy" className="w100 h100" /></a> */}
                        <a href="https://www.instagram.com/silvamethodindia/" target='_blank'><img src={IconLinkedInImg} alt="#" loading="lazy" className="w100 h100" /></a>
                        <a href="https://www.linkedin.com/company/silvamethodindia/" target='_blank'><img src={IconInstagramImg} alt="#" loading="lazy" className="w100 h100" /></a>
                        <a href="https://www.youtube.com/@SilvaMethodIndiaOfficial" target='_blank'><img src={IconYoutubeImg} alt="#" loading="lazy" className="w100 h100" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="imgBar ptop">
          <img src={IconFooterImg} alt="#" className="w100 h100" />
        </div>
        <div className="copyRightArea">
          <p className="primaryWhite text-center mb-0 p-4">
            The Silva Method India. Copyright 2023, All rights reserved
          </p>
        </div>
      </section>
    </div>
  )
}

export default Footer