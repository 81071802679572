import React from 'react'
import './UpcomingMasterClassStyle.css'
import { Link } from 'react-router-dom'


const UpcomingMasterClass = () => {
    const masterClassCardCont = [
        // {
        //     cardBg: '/assets/images/rahul/texture-bg.webp',
        //     cardTitle: 'Lions Gate Portal Day Masterclass',
        //     dateHead: 'Date',
        //     cardDate: '8 August',
        //     timeHead: 'Time',
        //     cardTime: '8 PM (IST)',
        //     priceHead: 'Price',
        //     price: '₹ 99/- (INR)',
        //     pageLink: '/lions-gate-portal-day',
        //     cardImg: '/assets/images/rahul/page-lions-gate-portal.webp',
        //     btnText: 'Enroll Now',
        //     joinBtn: true
        // },
        // {
        //     cardBg: '/assets/images/rahul/texture-bg.webp',
        //     cardTitle: 'সিলভা মেথডের মানি ম্যাগনেট মাস্টারক্লাস',
        //     dateHead: 'তারিখ',
        //     cardDate: '৪ঠা আগস্ট',
        //     timeHead: 'সময়',
        //     cardTime: '৭ PM -  ৯ PM (IST)',
        //     priceHead: 'কোর্স ফি',
        //     price: '₹ ৩৪৯/- (INR)',
        //     pageLink: '/bengali-money-magnet-masterclass',
        //     cardImg: '/assets/images/rahul/page-ben-money-magnet.webp',
        //     btnText: 'এখন বুক করুন',
        //     joinBtn: true
        // },
        {
            cardBg: '/assets/images/rahul/texture-bg.webp',
            cardTitle: 'Silva Method Money Magnet Masterclass in English',
            dateHead: 'Date',
            cardDate: '19th October',
            timeHead: 'Time',
            cardTime: '7:30 PM (IST)',
            priceHead: 'Price',
            price: '₹ 249/- (INR)',
            pageLink: '/english-money-magnet-masterclass',
            cardImg: '/assets/images/rahul/page-eng-money-magnet.webp',
            btnText: 'Enroll Now',
            joinBtn: true
        },
       
    ]





    return (
        <>
            <section className="upcomingMasterClass lightBg ptop pbtm">
                <div className="container">
                    <h2 className="h2 fw700 poppins textDark text-capitalize text-center">
                        Short <span className="gradientText">Courses</span>
                    </h2>
                    <div className="row gy-4 justify-content-center">
                        {
                            masterClassCardCont?.map((items, idx) =>
                                <div className="col-md-6">
                                    <div className="masterClassCard d-flex justify-content-between align-items-xxl-center flex-column-reverse flex-xxl-row bgImgProp"
                                        style={{ backgroundImage: `url('${items.cardBg}')` }}>
                                        <div className="leftArea">
                                            <h3 className='mdFont primaryWhite fw700 poppins mb-4'>
                                                {items.cardTitle}
                                            </h3>
                                            {
                                                items.dateHead && items.timeHead &&
                                                <p className='poppins mb-2'>
                                                    <span class="primaryWhite fw400">
                                                        {items.dateHead}: <span class="fw600 primaryWhite">{items.cardDate}</span>
                                                    </span>
                                                    <span className='mx-2 primaryWhite'>|</span>
                                                    <span class="primaryWhite fw400">
                                                        {items.timeHead}: <span class="fw600 primaryWhite">{items.cardTime}</span>
                                                    </span>
                                                </p>
                                            }
                                            {/* <p className='poppins mb-4'>
                                                <span class="primaryWhite fw400">
                                                    {items.priceHead}: <span class="fw600 colorYellow ms-2">{items.price}</span>
                                                </span>
                                            </p> */}
                                            {
                                                items.joinBtn ?
                                                    <Link to={`${items.pageLink}`} className='btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape'>
                                                        {items.btnText}
                                                    </Link> :
                                                    <p className='title fw600 poppins colorYellow'>Coming Soon</p>
                                            }
                                        </div>
                                        <div className="rightArea">
                                            <img src={`${items.cardImg}`} alt="#" loading='lazy' className='w100 h100 object-fit-cover obj-pos-top' />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default UpcomingMasterClass