// Testimonial.js
import React, { useEffect, useState } from 'react';
import './SliderCardTestimonialStyle.css'

const TestimonialCard = ({ data, rating = true }) => {


    const { text, image, name, designation } = data;


    return (
        <div className={`eachdiv`}>
            <div className="userdetails">

                <div className="imgbox" style={{ flex: "none" }}>
                    <img src={image} alt={name} style={{ width: "100%", height: "100%", }} />
                </div>


                <div className="detbox">
                    <p className="name poppins" style={{
                        padding: 0,
                        margin: 0
                    }}>{name}</p>
                    <p className="designation" style={{
                        padding: 0,
                        margin: 0
                    }}>{designation}</p>
                    {
                        rating &&
                        <p>
                            <span><i class="fa-solid fa-star colorYellow"></i></span>
                            <span><i class="fa-solid fa-star colorYellow"></i></span>
                            <span><i class="fa-solid fa-star colorYellow"></i></span>
                            <span><i class="fa-solid fa-star colorYellow"></i></span>
                            <span><i class="fa-solid fa-star colorYellow"></i></span>
                        </p>
                    }
                </div>
            </div>
            <div className="review ctlel">
                <p className='mb-0'>{text}</p>
            </div>
        </div>
    );
};
export default TestimonialCard;