import React, { useState } from 'react'
import "./FormStyle.css";
import FormBGImage from "../assets/form-bg.webp"
import axios from 'axios';
import { validatePhoneNumber } from '../utils/Helper';
import toast from 'react-hot-toast';

const Form = () => {

  // const [formData, setFormData] = useState({
  //   fname: '',

  //   mobile: '',
  //   email: ''




  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const isValid = validatePhoneNumber(formData.mobile)
  //   // console.log(isValid,"hello");
  //   if (!isValid) {
  //     return toast.error("Please enter a valid Phone Number")
  //   }
  //   try {
  //     const response = await axios.post('https://dedicated-nurture-a182b31812.strapiapp.com/api/mediation-guide-forms', {
  //       data: {
  //         Name: formData.fname,
  //         Mobile: formData.mobile,
  //         Email: formData.email,
  //         Query: formData.msg
  //       }
  //     },
  //       {
  //         headers: {
  //           Authorization: 'Bearer 2497f22482c180fcae6f5fd6d00fda9251ceee94c45b1ad0f724e65515dbf6506b429203b2088c55753565f605d7e5ab04fb875d20e430bdba414d755110182a9e9c5fc0aaefeb0b5d3a7d6c566f34759430cfa082da1a5541ce45cc1d2a0d3262587390f2f68fd020e92e4951b13d97cc1c4a1dec4b0a54833320e957fac410'
  //           // Replace 'YOUR_API_TOKEN' with your actual API token
  //         }
  //       }
  //     );
  //     console.log('Response:', response.data);
  //     // Reset form fields after successful submission
  //     setFormData({
  //       fname: '',
  //       mobile: '',
  //       email: ''



  //     });
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };


  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: '',
    

  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // code for form data store------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email) {
      setError('All fields are required');
      return;
    }
    const isValid = validatePhoneNumber(formData.mobile)
    if (!isValid) {
      return toast.error("Please enter a valid Phone Number")
    }
    try {

      const response = await fetch('https://silvamethod.in:5000/api/v1/home/submit-free-video-meditation-lead', {
        method: "POST",
        body: JSON.stringify({
          "name": formData.fname,
          "email": formData.email,
          "whatsapp_number": formData.mobile
          

        }),
        headers: {
          "Content-Type": "application/json",
        }
      });


      setFormData({
        fname: '',
        mobile: '',
        email: ''
        

      });
      setError('');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };

  return (
    <div>
      <section className="formSection bgImgProp" style={{ backgroundImage: `url(${FormBGImage})` }}>
        <div className="container">
          <div className="formArea">
            <p className="title fw500 poppins primaryWhite mb-4">
              Submit your details to receive a free meditation guide by José Silva.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <input type="text" className="form-control" name="fname" value={formData.fname} onChange={handleChange} placeholder="Name" required />
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Whatsapp Number" required />
                </div>
                <div className="col-md-12">
                  <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                </div>
              </div>
              <p className="p1 fw600 primaryWhite">
                Join the Quest of Epic Transformation
              </p>
              <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape">
                submit
              </button>
            </form>
          </div>
          {/* <div className="vidBtn">
            <i className="fa-solid fa-play btnPlay"></i>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default Form