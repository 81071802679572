import React from 'react'
import './SilvaFounderStyle.css'


const SilvaFounder = ({ lightBg, sectionBg, heading, textCont, textPos = "up", imgCont, imgBtmText }) => {
    return (
        <>
            <section className={`silvaFounderSection bgImgProp ptop pbtm ${lightBg}`} style={{ backgroundImage: `url(${sectionBg})` }}>
                <div className='container'>
                    {heading}
                    {textPos === "up" ? textCont : null}
                    {imgCont}
                    {textPos === "down" ? textCont : null}
                    {imgBtmText}
                </div>
            </section>
        </>
    )
}

export default SilvaFounder