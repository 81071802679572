import React, { useEffect, useRef, useState } from "react";
import "../../components/HeroSection_CoursesStyle.css";
import HeaderBGImg from "../../assets/course-header.webp";
import CardThumbImg from "../../assets/card-thumb-img.webp";
import CheckDarkImg from "../../assets/check-dark.svg";
import MultiDateSelector from "../../components/MultiDateSelector"
import HeaderMobileBGImg from "../../assets/courses-header-for-mobile-tab.webp";
import HeaderTabBGImg from "../../assets/courses-header-for-mobile-tab.webp";
import useRazorpay from "react-razorpay";
import { Link } from "react-scroll";
// import { handleScroll } from "../../../utils/animPulse";
import { validatePhoneNumber } from "../../utils/Helper";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { handleLeadFormSubmit, handlePaymentNew } from "../../utils/PaymentHelper";
import { handleRazorPayPayment } from "../../utils/RazorPayHelper";
import { Button, Form, InputGroup } from "react-bootstrap";

const BengaliBlsHero = ({
  title,
  subTitle,
  btnText,
  payCardImg,
  bannerBgImgs,
  coursePriceBtnText,
  originalPrice,
  discountedPrice,
  discountText,
  rating,
  ratingCount,
  perksTitle,
  perksData,
  eventTitle,
  eventColoredTitle,
  eventDate,
  eventTime,
  form,
  payBtnText,
  heroExtraClass,
  courseData,
  finalPrice,
  setFinalPrice,
  discountPercentage,
  setDiscountPercentage
}) => {
  console.log(courseData, 'propsdata')
  const [selectedIndex, setSelectedIndex] = useState(null);

  const dates = [
    { date: "27th Sep - 4th Oct, 2024", time: "07:30 PM - 10:30 PM" },
    { date: "15th Nov - 22nd Nov, 2024", time: "07:30 PM - 10:30 PM" },
    { date: "13th Dec - 20th Dec, 2024", time: "07:30 PM - 10:30 PM" },
  ];

  const [formData, setFormData] = useState({
    name: '',
    whatsapp_number: '',
    email: '',
  });


  const [isChecked, setIsChecked] = useState(true)


  const [Razorpay] = useRazorpay();


  const [couponCode, setCouponCode] = useState(null);




  const [courseId, setCourseId] = useState(null)
  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search);
  const tokenParam = searchParams.get('coupon');
  const [coupon, setCoupon] = useState(null);
  const btnRef = useRef(null)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };






  const applyCoupon = async () => {
    try {
      const res = await handleLeadFormSubmit(`coupons/apply-coupon`, 'POST', {
        coupon_code: couponCode,
        course_id: courseData?._id
      });
      // console.log(res, 'coupon res')
      if (res.success) {
        setFinalPrice(Math.ceil(res.data.final_price));
        const discountPrice = ((Number(courseData?.course_price) - Number(res.data.final_price)) / Number(courseData?.course_price)) * 100;
        setDiscountPercentage(Math.ceil(discountPrice))
        toast.success(res.message);
      } else {
        toast.error(res?.message)
      }
    } catch (error) {
      console.log(error, 'coupon error')
    }
  }


  const handleChangeCheck = () => {
    setIsChecked(!isChecked)
  }


  const formSubmit = async (e) => {
    e.preventDefault();


    const isValid = validatePhoneNumber(formData.whatsapp_number)
    if (!isValid) {
      return toast.error("Please enter a valid Phone Number")
    }

    if (!selectedIndex) {
      return toast.error("Please select your preferred date")
    }


    const res = await handleLeadFormSubmit("leads/submit-bls-beng-paid-lead", "POST",
      {
        ...formData, notes: {
          timeslot: `${selectedIndex?.date} ${selectedIndex?.time}`,
          coupon: couponCode
        }
      }, {})
    console.log(res);
    if (res.success) {
      const paymentRes = await handlePaymentNew("BLS-BENG-9999", formData, "course", {}, couponCode, selectedIndex);
      if (paymentRes?.order_id) {
        await handleRazorPayPayment(formData, paymentRes?.order_id, setFormData, "/payment-successful", Razorpay)
      } else {
        toast.error(res.message)
      }
    }
  }








  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const couponP = params.get('coupon');
      setCoupon(couponP)
      console.log(couponP); setCouponCode(couponP);
    }
  }, [location]);


  useEffect(() => {
    if (couponCode && courseData && coupon) { btnRef?.current?.click(); }
  }, [couponCode, courseData]);





  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.getElementById("btnSubmit");
    if (!isFormFilled) {
      btn.style.opacity = 0.4
      btn.style.pointerEvents = "none"
    } else {
      btn.style.opacity = 1
      btn.style.pointerEvents = "unset"
    }
  }, [isFormFilled])












  return (
    <div>
      <section className={`heroSection lightBg position-relative coursePage ${heroExtraClass}`}>
        {/* <img src={HeaderBGImg} className="d-block w-100" alt="#" loading="lazy" /> */}

        <img
          src={bannerBgImgs?.MobileBGImg || HeaderMobileBGImg}
          class="d-md-none d-block w-100"
          alt="#"
          loading="lazy"
        />
        <img
          src={bannerBgImgs?.TabBGImg || HeaderTabBGImg}
          class="d-none d-md-block d-lg-none w-100"
          alt="#"
          loading="lazy"
        />
        <img
          src={bannerBgImgs?.DesktopBGImg || HeaderBGImg}
          class="d-none d-lg-block w-100 bannerLg"
          alt="#"
          loading="lazy"
        />

        <div className="carousel-caption">
          <div className="container">
            <div className="d-flex justify-content-between totalContArea">
              <div className="heroCont">
                <h1 className="h1 fw700 poppins">
                  {title || "Silva Method Basic Lecture Series (BLS)"}
                </h1>
                <p className="desc fw300 roboto">
                  {subTitle || "An amalgamation of the Silva Life System and Silva Intuition System, the heart of the Silva Method Training, designed by Jose Silva!"}
                </p>
                <Link to="paymentButton" smooth={true} duration={500}>
                  <a
                    href="#"
                    className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape"
                  >
                    {btnText || "Register Now"}
                  </a>
                </Link>
              </div>
              <div className="rightCard" id="paymentButton">
                <div className="thumbImg">
                  <img
                    src={payCardImg || CardThumbImg}
                    alt="#"
                    loading="lazy"
                    className="w-100"
                  />
                </div>
                <p className="fw500 roboto textDark shapeRect lightTrape mt-3 mb-2">
                  {coursePriceBtnText || "Course Price"}
                </p>
                <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mb-2">
                  <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                    <span className="me-1">₹</span>
                    <span className="roboto">{finalPrice || discountedPrice || "9999"}</span>
                  </p>
                  <p className="p1 fw900 oldPrice textLight mb-0">
                    <span className="me-1">₹</span>
                    <span className="roboto">{courseData?.course_price || originalPrice || "19999"}</span>
                  </p>
                  <p className="fw600 roboto textDark mb-0">
                    {discountText || "You will get 50% Off"}
                  </p>
                </div>
                <div className="d-flex align-items-center flex-wrap mb-4">
                  <p className="title fw700 poppins textDark mb-0">{rating || "4.6"}</p>
                  <div className="d-flex align-items-center ratingStarIcon ms-2 me-4">
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star_half</span>
                  </div>
                  <p className="fw600 roboto textDark mb-0">
                    {`(${ratingCount || "108,283 ratings"})`}
                  </p>
                </div>
                <h3 className="fw700 poppins textDark mb-3 h6">
                  {perksTitle || "Unlock Unlimited Perks"}
                </h3>
                <ul className="resetUl unlockList">
                  {
                    perksData?.map((item, i) =>
                      <li className="d-flex align-items-center gap-2 mb-2">
                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                        <p className="textDark roboto mb-0">
                          {item.text}
                        </p>
                      </li>
                    )
                  }
                </ul>
                {
                  eventDate !== "NA" &&

                  <>
                    <p className="mdFont fw700 poppins textDark mt-4 mb-0">{eventTitle} <span className="gradientText">{eventColoredTitle}</span></p>
                    <div className='dateBox mb-4'>
                      <MultiDateSelector
                        selectedDateTime={selectedIndex}
                        setSelectedDateTime={setSelectedIndex}
                        dates={dates}
                      />
                    </div>
                    <div className="mainCoursePayForm">
                      <p className="mdFont fw700 poppins textDark mb-0">
                        Pay <span className="gradientText">Now</span>
                      </p>
                      <form onSubmit={formSubmit}>
                        <div className="formCont">
                          <div>
                            <label htmlFor="couponinput" className="textDark poppins fw500 mb-1">
                              Do you have a coupon code?
                            </label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                style={{ borderColor: "#a500ff" }}
                                placeholder="Enter your coupon code"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                id="couponinput"
                                className="couponinputfield"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                              />
                              <Button
                                style={{ borderColor: "#a500ff", backgroundColor: "#a500ff", color: "#fff", fontWeight: "500" }}
                                id="basic-addon1"
                                className="applycouponbtn"
                                onClick={applyCoupon}
                                ref={btnRef}
                              >
                                Apply Coupon
                              </Button>
                            </InputGroup>
                          </div>
                          <div className="row gy-3">
                            <div className="col-md-12">
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Full Name"
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control"
                                name="whatsapp_number"
                                value={formData.whatsapp_number}
                                onChange={handleChange}
                                placeholder="Whatsapp Number"
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Email"
                                required
                              />
                            </div>
                          </div>
                          <ul className="resetUl mt-4">
                            <li className="d-flex align-items-start gap-2 mb-2">
                              <div className="form-check">
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                  <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked={isChecked} onChange={handleChangeCheck} style={{ cursor: "pointer" }} />
                                  <p className="textDark roboto mb-0 smFont">I accept your
                                    <a className="fw700 textDark" href="/terms-&-conditions"> Terms &amp; Conditions </a>
                                    and willingly agree to receive Marketing emails.
                                  </p>
                                </label>
                              </div>
                            </li>
                          </ul>
                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              id="btnSubmit"
                              className="text-capitalize fw700 primaryBlack btnPulse"
                              style={{ opacity: 0.4 }}
                            >
                              {"Register Now"}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BengaliBlsHero;
