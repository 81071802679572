import React from 'react'
import "../../components/AboutBLSStyle.css";

const AboutSls = ({title,colored,description,subheading,headingStyle}) => {
  return (
    <div>
        <section className={`${headingStyle?headingStyle:"aboutBLSSection"} ptop pbtm lightBg`}>
            <div className="container">
                <h2 className="h2 fw700 poppins textDark text-capitalize mb-3">
                    {title} <span className="gradientText">{colored}</span>
                </h2>
                {subheading?<h5 className='mb-3'>{subheading}</h5>:null}
                <p className="p1 textDark poppins">
                    {description}
                </p>
            </div>
        </section>
    </div>
  )
}
export default AboutSls