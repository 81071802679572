import React, { useContext, useState } from 'react'
import ClassAgendaImage from "../assets/class-agenda-2.jpg"
import CheckImg from "../assets/check-bg.svg"
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'
import ReactPlayer from 'react-player';
import { Appcontext } from '../context/appcontext';
import Navbar2 from '../components/Navbar2'
import Footer2 from '../components/Footer2'
import HeroSectionC99 from '../components/HeroSectionC99'
import ShouldJoinMasterclass from '../components/ShouldJoinMasterclass'
import Problems from '../components/Problems'
import Strength from '../components/Strength'
import SubconsciousMind from '../components/SubconsciousMind'
import AlphaState from '../components/AlphaState'
import SilvaDifference from '../components/SilvaDifference'
import ClassAgenda from '../components/ClassAgenda'
import VideoTestimonial from '../components/VideoTestimonial'
import FAQ2 from '../components/FAQ2'
import Bonus from '../components/Bonus'
import Sticky from '../components/Sticky'
import ClearDoubts from '../components/ClearDoubts'
import MasteryHero99 from '../components/MasteryHero99'
import VideoBtmSection from '../components/VideoBtmSection'
import MasteryBonus from '../components/MasteryBonus'
import JoinMasteryClass from '../components/JoinMasteryClass'
import MasteryForm from '../components/MasteryForm'
import LiveClassandWorkshop from '../components/LiveClassandWorkshop'
import WhatsappandDoubts from '../components/WhatsappandDoubts'
import InternationalMeditation from '../components/InternationalMeditation'
import SliderCardTestimonial from '../components/SliderCardTestimonial'
import Quiz from '../components/Quiz'
const InstructorVideo = "https://vz-6873ed28-3a2.b-cdn.net/de0aaca8-642a-4b9d-a356-faf0b9ac4f8c/playlist.m3u8"


const CourseLanding = () => {
  const [playing, setPlaying] = useState(false)
  const { isMobile } = useContext(Appcontext)


  const detailsCont = [
    {
      image: 'assets/images/rahul/testimonial-1.webp',
      name: 'Sanjay Dutt',
      designation: 'Actor',
      text: 'I learned the art of Silva meditation and it helps in positive thinking',
    },
    {
      image: 'assets/images/rahul/testimonial-2.webp',
      name: 'Amrish Puri',
      designation: 'Actor',
      text: 'I learned a lot about meditation in Silva Classes',
    },
    {
      image: 'assets/images/rahul/testimonial-3.webp',
      name: 'Kishore Namit Kapoor',
      designation: 'Acting Teacher, Actor and Author',
      text: 'Silva can help bring direction and purpose to your life',
    },
    {
      image: 'assets/images/rahul/testimonial-4.webp',
      name: 'Naveen Jindal',
      designation: 'Chairman Jindal Steel, Politician and MP',
      text: 'Silva can help in every area of life',
    },
    {
      image: 'assets/images/rahul/testimonial-5.webp',
      name: 'Chitra Singh',
      designation: 'Ghazal Singer',
      text: 'Silva is a great program',
    },
    {
      image: 'assets/images/rahul/testimonial-6.webp',
      name: 'Yashovardhan Birla',
      designation: 'Director, Yash Birla Group',
      text: 'With Silva you can enhance and maintain your health and fitness',
    },
    {
      image: 'assets/images/rahul/testimonial-7.webp',
      name: 'Tanuja Mukherjee Samarth',
      designation: 'Actress',
      text: 'Silva is very helpful for attaining mental relaxation',
    },
    {
      image: 'assets/images/rahul/testimonial-8.webp',
      name: 'Debraj Chatterjee',
      designation: 'Working Professional',
      text: 'Silva Techniques are amazing for stress relief and managing negative thoughts',
    },
    {
      image: 'assets/images/rahul/testimonial-9.webp',
      name: 'Sneha Panda',
      designation: 'IT Professional',
      text: 'I have been practicing for a year. It really helped in my work by enhancing creativity',
    },
    {
      image: 'assets/images/rahul/testimonial-10.webp',
      name: 'Monideepa Das',
      designation: 'Graphics Designer',
      text: 'Silva has been transformative in helping me stay focused and productive at work.',
    },
    {
      image: 'assets/images/rahul/testimonial-11.webp',
      name: 'Prerna Roy',
      designation: 'Budding Singer',
      text: 'As a budding singer, Silva has been instrumental in boosting my confidence and performance.',
    },
  ]

  const sliderImgs = [
    {
      id: 1,
      imgUrl: "/assets/images/rahul/international-1.webp"
    },
    {
      id: 2,
      imgUrl: "/assets/images/rahul/international-2.webp"
    },
    {
      id: 3,
      imgUrl: "/assets/images/rahul/international-3.webp"
    },
    {
      id: 4,
      imgUrl: "/assets/images/rahul/international-4.webp"
    },
    {
      id: 5,
      imgUrl: "/assets/images/rahul/international-5.webp"
    },
    {
      id: 6,
      imgUrl: "/assets/images/rahul/international-6.webp"
    },
    {
      id: 7,
      imgUrl: "/assets/images/rahul/international-7.webp"
    },
    {
      id: 8,
      imgUrl: "/assets/images/rahul/international-8.webp"
    },
    {
      id: 9,
      imgUrl: "/assets/images/rahul/international-9.webp"
    },
    {
      id: 10,
      imgUrl: "/assets/images/rahul/international-10.webp"
    },
  ]

  const bonusPoints = [
    {
      imgIcon: "",
      icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
      heading: 'Bonus#1',
      desc: `A free guided meditation for resolving life's issues.`,
    },
    {
      imgIcon: "",
      icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
      heading: 'Bonus#2',
      desc: `Download a complimentary workbook to overcome life challenges.`,
    },
    {
      imgIcon: "",
      icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
      heading: 'Bonus#3',
      desc: `Upon successful completion of this masterclass, you'll receive a recognition Certificate from The Silva Method India.`,
    },
    {
      imgIcon: "",
      icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
      heading: 'Bonus#4',
      desc: `After the masterclass, if you encounter any difficulties while practicing the techniques, we're here to help. 
        You'll get a free review call to discuss your doubts after a month.`,
      hidemargin: true
    },
  ]

  const agendaPoints = [
    {
      icon: CheckImg,
      desc: `Understanding the connection between the mind, body & brain`,
    },
    {
      icon: CheckImg,
      desc: `How to enter the Alpha state of mind`,
    },
    {
      icon: CheckImg,
      desc: `Exercise to enter the Alpha state`,
    },
    {
      icon: CheckImg,
      desc: `Benefits of using the Alpha level`,
    },
    {
      icon: CheckImg,
      desc: `Power of affirmation`,
    },
    {
      icon: CheckImg,
      desc: `Create your affirmations`,
    },
    {
      icon: CheckImg,
      desc: `Positive affirmation meditation`,
    },
    {
      icon: CheckImg,
      desc: `Balancing emotions`,
    },
    {
      icon: CheckImg,
      desc: `A special guided meditation`,
    },
    {
      icon: CheckImg,
      desc: `Reprogram the subconscious mind`,
    },
    {
      icon: CheckImg,
      desc: `Doubt clearing session`,
      hidemargin: true
    },
  ]

  const liveClassImgs = [
    {
      id: 1,
      imgUrl: '/assets/images/rahul/Artboard 1.webp'
    },
    {
      id: 2,
      imgUrl: '/assets/images/rahul/Artboard 2.webp'
    },
    {
      id: 3,
      imgUrl: '/assets/images/rahul/Artboard 3.webp'
    },
    {
      id: 4,
      imgUrl: '/assets/images/rahul/Artboard 4.webp'
    },
    {
      id: 5,
      imgUrl: '/assets/images/rahul/Artboard 5.webp'
    },
    {
      id: 6,
      imgUrl: '/assets/images/rahul/Artboard 6.webp'
    },

  ]


  return (
    <div style={{ backgroundImage: "url('/assets/images/rahul/line-bg.jpg')", backgroundSize: "contain", backgroundPosition: "center" }}>
      <Navbar2 />
      <MasteryHero99
        playing={playing}
        setPlaying={setPlaying}
        heroSection="masteryHero99"
        mobBanner="/assets/images/rahul/landing-banner-mob.webp"
        tabBanner="/assets/images/rahul/landing-banner-mob.webp"
        webBanner="/assets/images/rahul/99-landing.webp"
        heroTextCont={
          <>
            <h1 class="h1 fw400 anton colorYellow mb-4">
              Manage Overwhelming Emotions Like a PRO
            </h1>
            <h2 className='h2 primaryWhite poppins h2 mb-3'>
              <span className='first fw300 me-2'>Learn</span>
              <span className='second fw600'>
                scientifically-backed mind empowerment techniques
              </span>
            </h2>
            <h3 className='primaryWhite poppins h3'>
              <span className='first fw300 me-2'>Break free from</span>
              <span className='second fw600'>
                overthinking, Self-victimization, Financial Stress, negative thoughts
              </span>
              <span className='first fw300 ms-2 me-2'>And</span>
              <span className='second fw600'>
                master balancing your emotions!
              </span>
            </h3>
          </>
        }
        heroVidCont={
          <div className="masteryHeroVideo" style={{ maxHeight: playing && !isMobile ? '450px' : isMobile ? playing ? '192px' : '450px' : '' }}>
            <ReactPlayer
              url={InstructorVideo}
              light="assets/images/rahul/vid-banner.jpg"
              width="100%"
              height="100%"
              playing
              muted
              loop
              controls={true}
              onPlay={() =>
                setPlaying(true)
              }
            />
          </div>
        }
      />
      <VideoBtmSection
        playing={playing}
        bgImg="/assets/images/rahul/video-btmcont-bg.jpg"
        textCont={
          <>
            <p className='title textDark fw600 poppins text-center p1'>
              Invest in yourself and become an expert by learning emotional management skills. Save money on expensive therapies and counseling!
            </p>
            <p className='title textDark fw400 poppins text-center mb-0 p2'>
              3 Hours Emotion Management Workshop 8th June 2024 ( Saturday )
            </p>
          </>
        }
      />
      <MasteryBonus
        props={{
          imgTitle: "VALUE LOADED",
          imgHead: "MASTERCLASS",
          imgArea: "/assets/images/rahul/bonus-img.webp",
          fontStyle: "poppins fw700",
          headingText: "Your Exclusive",
          spanText: "Extra Bonus",
          bonusPoints,
          btnPulse: "btnPulse",
          btnText: "Reserve Your Seat For Just Rs. 99"
        }}
      />
      <JoinMasteryClass />
      <ClassAgenda
        lightBg="lightBg"
        masterClassCont={
          <div className="masterClassCont">
            <div className="row align-items-center row-gap-4">
              <div className="col-lg-6 col-xl-7">
                <div className="videoArea pe-lg-5">
                  <img src={ClassAgendaImage} alt="#" loading="lazy" className="w100 h100 agendaImg" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-5">
                <div className="rightCont">
                  <h2 className="h2 fw700 poppins textDark mb-4">
                    What you will <span className="gradientText">learn</span>
                  </h2>
                  <ul className="resetUl">
                    {
                      agendaPoints.map((items, idx) =>
                        <li className={`d-flex align-items-center gap-2 ${items.hidemargin == true ? "" : "mb-3"}`}>
                          <img src={items.icon} alt="#" loading="lazy" className="circleTick" />
                          <p className="textDark roboto fw500 mb-0">{items.desc}</p>
                        </li>
                      )
                    }
                  </ul>
                  <div className="text-center text-lg-start">
                    <Link to="masterClassForm" smooth={true} duration={500}>
                      <button type="submit" className="btnMain btnPulse fw700 primaryBlack mt-4 mt-lg-5 border-0 shadowLight" onClick={() => handleScroll()}>
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <MasteryForm
        formHead={
          <div className="div1">
            <p className="title fw700 poppins primaryBlack mb-1 p1">Join this Masterclass</p>
            <div className='d-flex'>
              <span className="primaryBlack fw500 date">
                Date <span className="fw700 shapeRect gradientBg primaryWhite">8th June</span>
              </span>
              <span className="primaryBlack fw500 ms-4">
                Duration <span className="fw700 shapeRect gradientBg primaryWhite">3Hrs</span>
              </span>
            </div>
          </div>
        }
        timeSlotCount={2}
        priceSection={
          <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
            <p className="p2 fw900 roboto textDark mb-0">
              <span className="me-1">₹</span>
              <span className="roboto">99/-</span>
            </p>
            <p className="p2 fw900 oldPrice textLight mb-0">
              <span className="me-1">₹</span>
              <span className="roboto">999/-</span>
            </p>
          </div>
        }
        formImg="assets/images/rahul/form-img.jpg"
      />
      <Quiz />
      <LiveClassandWorkshop
        sectionHead={
          <h2 class="h2 fw700 poppins textDark text-center mb-lg-5 mb-4">
            <span class="gradientText">Highlights</span> from our live classes
          </h2>
        }
        videoUrl="https://vz-6873ed28-3a2.b-cdn.net/b8aefc12-766e-42b9-8075-6c633701f26c/playlist.m3u8"
        videoThumb="assets/images/rahul/live-class-banner.jpg"
        classImgs={liveClassImgs}
        videoSection={
          <ReactPlayer
            // url={masterclassVideo}
            url="https://vz-6873ed28-3a2.b-cdn.net/b8aefc12-766e-42b9-8075-6c633701f26c/playlist.m3u8"
            light="assets/images/rahul/live-class-banner.jpg"
            width="100%"
            height="100%"
            controls={true}
          />
        }
      />
      <Problems />
      <InternationalMeditation
        lightBg="lightBg"
        ptop="ptop"
        pbtm="pbtm"
        sectionTitle={
          <div className='text-center'>
            <p class="fw600 poppins textDark shapeTrape2">
              <span className='gradientText'>Silva Method</span>
            </p>
          </div>
        }
        sectionHeading={
          <h2 class="h2 fw700 poppins textDark text-center mb-4">
            An International Self-Growth Program, <span class="gradientText">since the 1960s</span>
          </h2>
        }
        sectionDesc={
          <p class="desc fw500 textDark poppins mb-4 mb-lg-5 text-center">
            The Silva Method, a renowned mind development program, has been transforming
            lives for over 50 years. With over 12 million people worldwide benefiting from
            its techniques to overcome challenges and achieve success, the Silva Method is
            now bringing its international workshops to India for the first time. And the
            best part? You don't have to spend a lot of money, you can join for just ₹99.
          </p>
        }
        sliderImgs={sliderImgs}
      />
      <WhatsappandDoubts />
      <SliderCardTestimonial
        heading={
          <h2 class="h2 fw700 poppins textDark text-center mb-4">
            Hear From Our <span class="gradientText">Silva Enthusiasts</span>
          </h2>
        }
        data={detailsCont}
      />
      <FAQ2 />
      <Footer2
        footer2Margin='footer2Margin'
      />
      <Sticky
        date="Date: 8th June (Saturday)"
        price="99"
        actual_price="999"
        btnText="Book Now"
      />
    </div>
  )
}

export default CourseLanding