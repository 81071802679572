import React from 'react'
import './HappyLifeStyle.css'


const HappyLifeSection = ({ contentSection }) => {
    return (
        <>
            <section className='happyLifeSection ptop pbtm'>
                <div className='container text-center'>
                    {contentSection}
                    {/* <div class="videoArea m-auto d-none">
                        <img src={happylife} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default HappyLifeSection