import React from 'react'
import Navbar2 from '../components/Navbar2'
import RecordClassCont from '../components/RecordClassCont'
const recVid = "https://www.youtube.com/watch?v=cliiAfVuQ4g"

const RecordedEmotionMasterclassEvening = () => {
    return (
        <div className='silvaDiffSection pbtm vh-100'>
            <Navbar2 />
            <RecordClassCont
                maintitle="Pre recorded video of Emotion Mastery Workshop"
                vidUrl={recVid}
                recordVidCont={
                    <>
                        <p class="title fw600 poppins primaryWhite">This session was live on June 8th, 2024</p>
                        <p class="title fw600 poppins primaryWhite">Time: 06:30 PM - 09:30 PM</p>
                    </>
                }
            />
        </div>
    )
}

export default RecordedEmotionMasterclassEvening