import React from 'react'
import './WhatsappandDoubtsStyle.css'

const WhatsappandDoubts = () => {
    return (
        <>
            <section className="whatsappAndDoubts ptop pbtm">
                <div className="container">
                    <h2 class="h2 fw700 poppins textDark text-center mb-4">
                        Stay Connected with <span class="gradientText">Our Team</span>
                    </h2>
                    <p class="desc fw500 textDark poppins mb-4 mb-lg-5 text-center">
                        You’re not alone. Join our thriving community of like-minded individuals committed to
                        overcoming any mental and physical challenges. Connect now and let's conquer them together!
                    </p>
                    <div className="row align-items-center row-gap-4">
                        <div className="col-lg-6">
                            <div className="cardItem bgImgProp" style={{ backgroundImage: "url('/assets/images/rahul/card-bg.png')" }}>
                                <div className='qrImg'><img src="assets/images/rahul/whatsapp-qr.png" alt="#" loading='lazy' className='w100 h100' /></div>
                                <p className='fw400 poppins primaryWhite mb-0'>
                                    Silva Method Whatsapp Community
                                </p>
                                <button onClick={() => window.open('https://chat.whatsapp.com/HBMmPq0qdER1gk4wYeZM0V')}
                                    type="button" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0">
                                    Join Now
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cardItem bgImgProp" style={{ backgroundImage: "url('/assets/images/rahul/card-bg.png')" }}>
                                <div className="doubtsArea darkOutline my-4">
                                    <p className="title primaryWhite fw600 mb-2 poppins">Clear Your Doubts</p>
                                    <p className="primaryWhite roboto">
                                        Need clarification before making a purchase? Feel free to contact us at
                                        <a href="" className="fw600 colorYellow me-1">
                                            <span className="ms-1 me-1"><i className="fa-solid fa-phone"></i></span>6290945413</a>
                                        regarding the class. We're here to help you.
                                    </p>
                                    <a href="tel:6290945413" className="btnMain primaryBlack fw700 primaryYellow text-capitalize shapeTrape">
                                        call now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhatsappandDoubts