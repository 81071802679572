import React from 'react'
import "./NewpricecardStyle.css";

const Newpricecard = () => {
    return (
        <>
            <div className='cardBox'>
                <p className='priceTitle title fw500'>
                    Course Price
                </p>
                <div className='priceCont'>
                    <div class="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mb-4 mb-md-2">
                        <p class="p1 fw900 roboto primaryWhite d-flex align-items-center justify-content-center mb-0">
                            <span class="me-1">₹</span>
                            <span class="roboto">900</span>
                        </p>
                        <p class="p1 fw900 oldPrice primaryWhite mb-0">
                            <span class="me-1">₹</span>
                            <span class="roboto">1800</span>
                        </p>
                        <p class="fw400 roboto primaryWhite mb-0">You will get 20% Off</p>
                    </div>
                    <div class="d-flex align-items-center flex-wrap mb-0">
                        <p class="title fw500 poppins primaryWhite mb-0">4.6</p>
                        <div class="d-flex align-items-center ratingStarIcon ms-2 me-4">
                            <span class="material-symbols-outlined">star</span>
                            <span class="material-symbols-outlined">star</span>
                            <span class="material-symbols-outlined">star</span>
                            <span class="material-symbols-outlined">star</span>
                            <span class="material-symbols-outlined">star_half</span>
                        </div>
                        <p class="fw400 roboto primaryWhite mb-0">(108,283 ratings)</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newpricecard