// export const validatePhoneNumber = (number) => {
//     // const mobileRegex = /^[6789]\d{9}$/
//     const mobileRegex = /^(?:(?:\+91|91|0)?[6-9]\d{9}|(?:\+880|880)?1[3-9]\d{8})$/
//     const result = mobileRegex.test(number);
//     return result;
// }

export const validatePhoneNumber = (number) => {
    // const mobileRegex = /^[6789]\d{9}$/
    const mobileRegex = /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
    const result = mobileRegex.test(number);
    return result;
}
