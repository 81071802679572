import React from 'react'
import happylife from '../assets/happy-life.webp'
import sickman from '../assets/sick-man.webp'
import Navbar2 from '../components/Navbar2'
import HealthHero from '../components/HealthHero'
import SickSection from '../components/SickSection'
import LearnMasterClass from '../components/LearnMasterClass'
import HappyLifeSection from '../components/HappyLifeSection'
import BenefitsHealth from '../components/BenefitsHealth'
import SilvaFounder from '../components/SilvaFounder'
import Footer2 from '../components/Footer2'
import Sticky from '../components/Sticky'
import AboutInstructor from '../components/AboutInstructor'
import PracticeMeditation14 from '../components/PracticeMeditation14'
import VideoBtmBonus from '../components/VideoBtmBonus'
import HealthForm from '../components/HealthForm'
import josesilva from '../assets/josh-silva-health-masterclass.webp'
import { handleScroll } from '../utils/animPulse'
import { Link } from 'react-scroll';

const HealthLanding = () => {
    return (
        <>
            <Navbar2 />
            <HealthHero />
            <VideoBtmBonus />
            <PracticeMeditation14
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4 reverseCol">
                        <div className="col-lg-6">
                            <div className="videoArea pe-lg-5">
                                <img src="/assets/images/rahul/practice-meditation.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-4">
                                <span className="gradientText ms-2">১৪ টি ফ্রি গাইডেড মেডিটেশন</span> অনুশীলন করার সুযোগ
                            </h2>
                            <p className="textDark roboto mb-3">
                                আমাদের ক্লাস করার পর  আপনি প্রতিদিন মেডিটেশন অনুশীলন করার গুরুত্ব অনুভব করবেন।
                                কিন্তু আপনার এই অনুভবকে অভ্যাসে পরিণত করার প্রচেষ্টাই আপনি একা নন আমরাও আপনার
                                সাথে আছি। ২২ শে জুন এই মাস্টারক্লাসের পর থেকে ক্রমাগত ৭ দিন সকাল (৮ টা IST) এবং রাত ( ৯ টা IST) দুই বেলা ইন্সট্রাক্টর দের কাছ থেকে মোট ১৪ ফ্রি গাইডেড মেডিটেশন অনুশীলন করার সুযোগ পাবেন। এই মাস্টারক্লাসটিতে রেজিস্টার করার সময় আপনার দেওয়া ইমেল আইডিতে
                                ফ্রি মেডিটেশন ক্লাসের লিংক পৌঁছে যাবে। এছাড়াও আপনি আমাদের সাথে হোয়াটস্যাপ - এ যুক্ত
                                হয়েও সব তথ্য পেয়ে যাবেন।
                            </p>
                            <div className="text-center text-lg-start">
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mb-4 mb-lg-5 border-0 shadowLight" onClick={() => handleScroll()}>
                                        এখনি রেজিস্টার করুন
                                    </button>
                                </Link>
                            </div>
                            <div className="grpBox">
                                <p className='textDark roboto mb-3'>
                                    আমাদের হোয়াটস্যাপ গ্রুপ জয়েন করার জন্য নীচে দেওয়া লিংকে ক্লিক করুন:
                                </p>
                                <a href="https://chat.whatsapp.com/KXmoMfCjkEq8LwqJBcbudj"
                                    className='btnMain fw700 primaryYellow primaryBlack shapeTrape border-0 shadowLight'>
                                    হোয়াটস্যাপে যুক্ত হন
                                </a>
                            </div>
                        </div>
                    </div>
                }
            />
            <SickSection
                lightBg=""
                sectionCont={
                    <div class="row align-items-center position-relative row-gap-4">
                        <div class="col-lg-6 col-xl-5 text-center text-lg-start">
                            <h2 class="h2 fw700 poppins textDark mb-3 mb-lg-4">বর্তমান মানুষ এত
                                <span class="gradientText ms-2">রোগাক্রান্ত কেন?</span>
                            </h2>
                            <p class="textDark roboto mb-3 mb-lg-4">
                                আধুনিক ব্যস্ততার জীবনে আমরা আমাদের শরীর ও মনের খেয়াল রাখতে ভুলে যাচ্ছি।প্রায়শই আমরা
                                শারীরিক এবং মানসিক স্বাস্থ্য চ্যালেঞ্জের সম্মুখীন হচ্ছি।অনিয়মিত জীবন যাপন, খাদ্যাভ্যাস এর সাথে
                                স্ট্রেস আমাদের জীবনে  নানা রকম জটিল রোগের উপদ্রব বাড়াচ্ছে।আমাদের মন এবং শরীরের মধ্যে
                                রয়েছে জটিল সংযোগ। যখন আমরা মানসিক চাপ অনুভব করি, তখন আমাদের শরীর কর্টিসল এবং
                                অ্যাড্রেনালিনের মতো হরমোন নিঃসরণ করে। যা আমাদেরকে সাধারণ সর্দি থেকে শুরু করে কার্ডিওভাসকুলার
                                রোগ এবং ডায়াবেটিসের মতো আরও গুরুতর অবস্থার অসুস্থতার জন্য আরও সংবেদনশীল করে তোলে।
                                এছাড়াও অর্থনৈতিক অস্থিতিশীলতা, সামাজিক বিচ্ছিন্নতা এবং পরিবেশগত উদ্বেগের মতো সামাজিক কারণগুলি
                                আমাদের জীবনে স্ট্রেস সম্পর্কিত রোগের মহামারীকে বাড়িয়ে তোলে।
                            </p>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={() => handleScroll()}>
                                    মাস্টারক্লাসে যোগ দিন
                                </button>
                            </Link>
                        </div>
                        <div class="col-lg-6 col-xl-7">
                            <div class="instructorVideo ms-auto">
                                <img src={sickman} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                                {/* <ReactPlayer
                                    url={InstructorVideo}
                                    light="assets/images/rahul/kalam-thumb.webp"
                                    width="100%"
                                    height="100%"
                                    playing
                                    muted
                                    loop
                                    controls={true}
                                    onPlay={() =>
                                        setPlaying(true)
                                    }
                                /> */}
                            </div>
                        </div>
                    </div>
                }
            />
            <LearnMasterClass />
            <HappyLifeSection
                contentSection={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                            সিলভা মেথড: রোগমুক্ত সুখী
                            <span class="gradientText ms-2">জীবনের চাবিকাঠি</span>
                        </h2>
                        <p class="p1 textDark roboto mb-4 mb-lg-5 text-center m-auto">
                            দীর্ঘস্থায়ী মানসিক চাপের মধ্যে থাকা লোকেরা প্রায়শই ধূমপান ও মদ্যপানের মত ক্ষতিকারক নেশায়
                            আসক্ত হয়ে পড়েন।যা বিভিন্ন রোগের বিকাশে অবদান রাখে। অতিরিক্ত স্ট্রেস ঘুমেরও ব্যাঘাত ঘটায়
                            এবং উদ্বেগ এবং বিষণ্নতারকেও বাড়িয়ে তোলে।দামি দামি ওষুধও যেসব শারীরিক ও মানসিক সমস্যা
                            থেকে মুক্তি দিতে পারেনা, সিলভা মেথড - এর সাহায্যে সেসব সমস্যার সমাধান খুব সহজে নিজেই
                            করে ফেলতে পারবেন।
                        </p>
                        <div class="videoArea m-auto mb-4 mb-lg-5">
                            <img src={happylife} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                        </div>
                        <p class="p1 textDark roboto mb-0 text-center m-auto">
                            সিলভা মেথড - প্রাচীন মেডিটেশন এবং আধুনিক বৈজ্ঞানিক পদ্ধতির একত্রীকরণ।এটি কিছু সহজ মেডিটেশন
                            পদ্ধতির মাধ্যমে আপনাকে আপনার আলফা ( হিউম্যান ইন্টেলিজেন্স লেভেল ) এবং থিটা ( বায়োলজিক্যাল ইন্টেলিজেন্স লেভেল )
                            স্তরে পৌঁছাতে সাহায্য করবে। এর ফলে আপনি আপনার শরীর, মন, ও মস্তিষ্কে এক গভীর শান্তি অনুভব করবেন।যা আপনার স্ট্রেস,
                            উদ্বেগ, বিসন্নতা দূর করে, আন্তরিক প্রশান্তি প্রদান করে।শুধু তাই নয় এটি আপনার ইমিউনিটি সিস্টেম কেও শক্তিশালী করে তুলে,
                            বিভিন্ন রোগ সংক্রমণের হাত থেকে রক্ষা করে। নিয়মিত সিলভা মেথডের অনুশীলন মন, শরীর এবং আত্মার মধ্যে একটি ভারসাম্যও গড়ে তোলে,
                            যা প্রাণবন্ত স্বাস্থ্য এবং সুস্থতা বজায় রাখার জন্য অপরিহার্য।
                        </p>
                    </>
                }
            />
            <HealthForm />
            <BenefitsHealth />
            <SilvaFounder
                heading={
                    <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                        Silva Method- এর <span class="gradientText ms-2">অগ্রগামী এবং প্রতিষ্ঠাতা</span>
                    </h2>
                }
                textCont={
                    <p class="p1 textDark roboto mb-5 text-center m-auto">
                        José Silva হলেন Silva Method- এর প্রতিষ্ঠাতা, এবং mind empowerment
                        গবেষণার পথিকৃৎ। সিলভা মানুষের মনের লুকানো সম্ভাবনা জাগ্রত করার জন্য
                        তাঁর জীবন উৎসর্গ করেছিলেন। 22 বছরের গবেষণার পর, তিনি 1966 সালে
                        তাঁর ফলাফল প্রকাশ করেছেন।তাঁর আবিষ্কার কে নিখুঁত এবং মানুষকে শিক্ষাদানের
                        জন্য বাকি জীবন অতিবাহিত করেন।সিলভার উত্তরাধিকার আজ Silva
                        International- এর মাধ্যমে পরিচালিত হয়, একটি সংস্থা যা তাঁর মিশন চালিয়ে
                        যাওয়ার জন্য প্রতিশ্রুতিবদ্ধ - যা মননশীল অনুশীলনের মাধ্যমে জীবনকে উন্নত করার
                        লক্ষ্যে নিবেদিত।
                    </p>
                }
                imgCont={
                    <div class="videoArea m-auto">
                        <img src={josesilva} alt="#" loading="lazy" class="w100 h100 agendaImg" />
                    </div>
                }
            />
            <AboutInstructor
                lightBg="lightBg"
            />
            <Footer2
                footer2Margin='footer2Margin'
            />
            <Sticky
                date="তারিখঃ ২২শে জুন (শনিবার)"
                price="৯৯"
                actual_price="৯৯৯"
                btnText="Book Now"
            />
        </>
    )
}

export default HealthLanding