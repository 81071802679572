import React, { useEffect } from 'react'


import Newnavbar from '../components/Newnavbar'
import Footer from '../components/Footer'
import NewHeroSection from '../components/NewHeroSection'
import SendUsMessage from '../components/SendUsMessage'
import BannerImg from "../assets/contact-header.webp"
import BannerMobileImg from "../assets/instructorpage-for-mobile.webp"
import BannerTabImg from "../assets/instructorpage-for-tab.webp"
import { Link } from 'react-scroll'
import { handleScroll } from '../utils/animPulse'


const Contact = () => {

  useEffect(() => {
    const docs = document.querySelector(".navSection");
    if (docs) {
      docs.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [])


  return (
    <div>
      <Newnavbar />
      <NewHeroSection
        customClassName="contactPage"
        bannerImg={BannerImg}
        bannerTabImg={BannerTabImg}
        bannerMobileImg={BannerMobileImg}
        title="Contact Today with Silva Method India"
        subtitle="Write to us. We would love to hear from you!"
        description=""
        bannerBtn={
          <Link to="leftForm" smooth={true} duration={500}>
            <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape" onClick={() => handleScroll(".leftForm")}>
              Get Started
            </a>
          </Link>
        }
      />
      <SendUsMessage />


      <Footer />
    </div>
  )
}

export default Contact