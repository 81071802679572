import React, { useContext } from 'react'
import './SpecialRewardsStyle.css'
import { Appcontext } from '../context/appcontext'


const SpecialRewards = ({ className, ptop, pbtm, bgColor, sectionHead, mdHead1, rewardImg1, mobRewardImg1, mdHead2, rewardImg2, mobRewardImg2, sectionBtn }) => {
    const { isMobile } = useContext(Appcontext)



    return (
        <section className={`${className} ${ptop} ${pbtm}`} style={{ background: `${bgColor}` }}>
            <div className="container">
                {sectionHead}
                {mdHead1}
                {
                    !isMobile && rewardImg1
                }
                {
                    isMobile && (mobRewardImg1 || rewardImg1)
                }
                {mdHead2}
                {
                    !isMobile && rewardImg2
                }
                {
                    isMobile && (mobRewardImg2 || rewardImg2)
                }
                {sectionBtn}
            </div>
        </section>
    )
}

export default SpecialRewards