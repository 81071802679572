import React from 'react'
import './WhoProgramStyle.css'
import { Link } from 'react-scroll'
import { handleScroll } from '../utils/animPulse'

const WhoProgram = () => {
    const cardCont = [
        {
            cardIcon: "/assets/images/rahul/program-icon-1.svg",
            cardDesc: "Manage stress, anxiety, and intense emotions"
        },
        {
            cardIcon: "/assets/images/rahul/program-icon-2.svg",
            cardDesc: "Boost creativity and concentration"
        },
        {
            cardIcon: "/assets/images/rahul/program-icon-3.svg",
            cardDesc: "Reverse biological age with stress-free living"
        },
        {
            cardIcon: "/assets/images/rahul/program-icon-4.svg",
            cardDesc: "Live a healthy, quality life"
        },
        {
            cardIcon: "/assets/images/rahul/program-icon-5.svg",
            cardDesc: "Reduce negative thoughts and overthinking"
        },
        {
            cardIcon: "/assets/images/rahul/program-icon-6.svg",
            cardDesc: "Enhance real-life problem-solving"
        },
        {
            cardIcon: "/assets/images/rahul/program-icon-7.svg",
            cardDesc: "Heal naturally with less medication"
        },
    ]


    return (
        <>
            <section className="whoProgramSeciton ptop pbtm">
                <div className="container">
                    <h2 className="h2 fw700 poppins textDark text-center mb-3">
                        Who is this <span className="gradientText">program for?</span>
                    </h2>
                    <h3 className="title fw700 poppins textDark text-center mb-4">
                        Those who wants to…
                    </h3>
                    <div className="row gy-4">
                        {
                            cardCont?.map((items, idx) =>
                                <div className="col-md-6">
                                    <div className="programCard d-flex gap-3">
                                        <div className='programIcon'>
                                            <img src={items.cardIcon} alt="#" loading='lazy' className='w100 h100' />
                                        </div>
                                        <p className='title fw600 poppins mb-0 lh-sm'>
                                            {items.cardDesc}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <Link to="masterClassForm" smooth={true} duration={500}>
                        <div className="text-center">
                            <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 mt-lg-5 ms-0 border-0 shadowLight" onClick={() => handleScroll("#masterClassForm")}>
                                Enroll Now
                            </button>
                        </div>
                    </Link>
                </div>
            </section>
        </>
    )
}

export default WhoProgram