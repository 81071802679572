import React, { useEffect, useState } from 'react'
import Navbar2 from '../components/Navbar2'
import NewHeroSection from '../components/NewHeroSection'
import Quote from '../components/Quote'
import WhatSilva from '../components/WhatSilva'
import CourseForWhose from '../components/CourseForWhose'
import ReactPlayer from 'react-player'
import Reviews from '../components/Reviews'


import DynamicFaqs from "../components/DynamicFaqs";
import facebook from "../assets/facebook-official.svg"
import youtube from "../assets/youtube-official.svg"
import instagram from "../assets/instagram-official.svg"
import instructor1 from '../assets/dr-kalam.webp'
import AboutInstructor from '../components/AboutInstructor'
import Footer2 from '../components/Footer2';
import RegisterForm from '../components/RegisterForm'

import { validatePhoneNumber } from '../utils/Helper'
import { handleLeadFormSubmit, handlePaymentNew } from '../utils/PaymentHelper'
import { handleRazorPayPayment } from '../utils/RazorPayHelper'
import { handleScroll } from '../utils/animPulse'

import toast from 'react-hot-toast'
import useRazorpay from 'react-razorpay'
import { Link } from 'react-scroll'

import rzrLogo from '../assets/RAZORPAY-ICON.webp'
const introVideo = "https://www.youtube.com/watch?v=ChIZTyQnLlc"
// const introVideo = "https://silva-method-official.b-cdn.net/lions-gate-silva-india/Lions%20Gate%20Portal%20Day-1920x1080.mp4"



const ManifestationMasterclass = () => {

    const [formData, setFormData] = useState({
        name: '',
        whatsapp_number: '',
        email: '',
    });


    const [isChecked, setIsChecked] = useState(true)


    const [Razorpay] = useRazorpay();


    const handleChangeCheck = () => {
        setIsChecked(!isChecked)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const formSubmit = async (e) => {
        e.preventDefault();


        const isValid = validatePhoneNumber(formData.whatsapp_number)
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }


        const res = await handleLeadFormSubmit("leads/register-manifestation-masterclass", "POST", formData, {})
        console.log(res);
        if (res.success) {
            const paymentRes = await handlePaymentNew("MANIFEST-MASTERCLASS-299", formData, "course");
            if (paymentRes?.order_id) {
                await handleRazorPayPayment(formData, paymentRes?.order_id, setFormData, "/thank-you-page", Razorpay)
            } else {
                toast.error(res.message)
            }
        }
    }


    const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
    useEffect(() => {
        const btn = document.getElementById("btnSubmit");
        if (!isFormFilled) {
            btn.style.opacity = 0.4
            btn.style.pointerEvents = "none"
        } else {
            btn.style.opacity = 1
            btn.style.pointerEvents = "unset"
        }
    }, [isFormFilled])




    const beginnerCoursePoints = [
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Secrets of Manifestation: Discover the hidden principles behind manifestation and understand how they work.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Master Easy Manifestation Techniques: Learn simple yet powerful techniques for manifestation, with step-by-step guidance on how to apply them to achieve your desires.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Manifest Health, Wealth, and Happiness: Explore targeted strategies for manifesting improvements in your health, wealth, happiness, and financial abundance.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Achieve Your Dream Job and Career Goals: Gain insights into manifesting your ideal job, advancing your career, or launching a successful business.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Solve Problems Through Manifestation: Learn how to use manifestation techniques to address and resolve personal and professional challenges effectively.'
        },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'ক্লান্তি, অতিরিক্ত কাজের চাপ, অত্যধিক ব্যস্ততা এবং অস্থিরতা অনভবু করছেন।'
        // },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'যে কোনো মানষু যিনি, জীবনে আধ্যাত্মিক জাগরণ পেতে চায়।'
        // },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'যিনি জীবনের চূড়ান্ত সাফল্য অর্জন করতে চায়।',
        //     hidemargin: true
        // },
    ]

    const masterclassPoints = [
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Anyone who wants to learn the secret rules and how they work to effectively apply them in your life.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Those who are seekers of simple techniques can discover easy-to-follow techniques for manifestation with step-by-step guidance.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Those Seeking Improvement in Health, Wealth, and Happiness.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Career and Business Aspirants can learn how to manifest your dream job, career advancements, or business success.'
        },
        {
            checkicon: '/assets/images/rahul/check-bg.svg',
            points: 'Those who finds it difficult to solve problems can learn to manifest solutions to challenges and solutions.'
        },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'চিন্তাভাবনা এবং কল্পনাকে পুনর্নির্মাণ করা যায় কীভাবে?'
        // },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'দ্রুত লক্ষ্য অর্জন কীভাবে সম্ভব?'
        // },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'বিশ্বের সব সফল ব্যক্তিদের অকথিত গোপন রহস্য কি?',
        // },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'মানসিক ও শারীরিক সুস্থতা উন্নয়নের পদ্ধতি কি?',
        // },
        // {
        //     checkicon: '/assets/images/rahul/check-bg.svg',
        //     points: 'যেকোনো পরিস্থিতিতে সুখী এবং শান্তিপূর্ণ হওয়া যায় কীভাবে?',
        //     hidemargin: true
        // },
    ]

    const celebCont = [
        {
            points: `One of the secret weapons of success for celebrities is meditation. To enhance focus, creativity, and overall well-being, they regularly practice meditation. 
            Celebrities have said that taking a bit of time each day for meditation helps them stay focused and manage all their attention. 
            It's not just about feeling calm; meditation also aids in becoming more creative and performing better in their work. 
            Mindfulness techniques and meditation have emerged as the best ways to reduce mental stress, manage anger and various issues, and lead a peaceful life. 
            Meditation has now become a very popular practice among celebrities.`
        }
    ]

    const bengaliblsFaqs = [
        {
            id: 1,
            title: "Where is the headquarters of Silva Method India located?",
            text: "Silva Method India Head Office is located at - 1ST FLOOR, UCP 10, AMBUJA, PO, City Center, Durgapur, West Bengal 713216",
        },
        {
            id: 2,
            title: "How do I contact Silva Method India?",
            text: <div>
                <ul className="list-unstyled">
                    <li>Contact details of Silva Method India</li>
                </ul>
                <ul>
                    <li>Phone Number: +91 6290 945 413,</li>
                    <li> Email ID: silvaindiaofficial@gmail.com,</li>
                    <li>Official Website: www.silvamethod.in</li>
                </ul>
            </div>,
        },
        {
            id: 3,
            title: "What will I learn in the Manifestation Masterclass?",
            text: " In this masterclass, you will learn the core principles of manifestation, including how to set clear intentions, visualize your goals, and use affirmations effectively. You will also explore practical techniques to manifest various aspects of your life, such as health, wealth, career, and personal fulfillment.",
        },
        {
            id: 4,
            title: "Can Beginner Join this Masterclass?",
            text: " The masterclass is designed to cater to all levels, from beginners to those who have some experience with manifestation. We provide foundational knowledge as well as advanced techniques to help you at any stage of your manifestation journey.",
        },
        {
            id: 5,
            title: " How long is the masterclass?",
            text: "The duration of the masterclass is 2 hours.",
        },
        {
            id: 6,
            title: " How do I sign up for the Manifestation Masterclass?",
            text: "You can sign up for the masterclass by filling the registration form where you will have to provide necessary information and pay the registration fees. ",
        },
    ];

    const cardData = [
        {
            id: 1,
            instructorimg: instructor1,
            instructortitle: 'Dr. Abul Kalam',
            instructordesc1: `Dr. Abul Kalam was born in Bangladesh in 1967. He completed his studies and graduated in Medical Science from ICAM in 1998. 
            Dr. Abul Kalam began his career as a general physician. In 2001, he learned about the Silva Method from the legendary Mr. Mahi Kazir in Bangladesh. 
            From 2001 to 2005, Dr. Kalam participated in over 40 seminars and took the full range of Silva courses from Mr. Kazir. 
            Starting in 2006, he received personal training under Mr. Kazir, taking on roles as an organizer, manager, and instructor. 
            Moreover, he was an important part of ISOMETRIC LTD. Through the Silva Method and Mr. Kazir, he learned a great deal, especially about how to love and help people.`,
            instructordesc2: ``
        }
    ]

    const reviews = [
        {
            image: "/assets/images/rahul/hugh-jackman.webp",
            name: "Hugh Jackman",
            comment: `Hugh Jackman, known for his role as 'Wolverine' in the X-Men series, underwent rigorous training and dieting while working on this highly acclaimed film series. 
            During that time, meditation provided him with patience and helped him forget everything else, allowing him to fully focus on his character. 
            In an interview with Oprah Winfrey, Jackman said, 'While meditating, I would forget that I am a father, a husband, or even Hugh Jackman. 
            I only remembered that I was diving into a source of energy that created everything.' Through meditation, Jackman overcame his OCD.`
        },
        {
            image: "/assets/images/rahul/Steve_Jobs.webp",
            name: "Steve Jobs",
            comment: `Steve Jobs used to meditate. The late Steve Jobs, the CEO of Apple, also practiced meditation. 
            In search of spirituality, he traveled to India with his friend Daniel, and he also took lessons in Zen meditation from a Zen monk in his home country, America.`
        },
        {
            image: "/assets/images/rahul/Oprah-Winfrey.webp",
            name: "Oprah Winfrey",
            comment: `Oprah has openly discussed how she used the principles of manifestation and visualization to achieve her career goals. 
                      She credits her success to the power of intention and believing in her own vision for her life.
`
        },
    ]



    return (
        <>
            <Navbar2 />
            <NewHeroSection
                customClassName="bengaliMasterclassPage manifestationMasterclass"
                bannerImg="/assets/images/rahul/masterclass-bengali-header.webp"
                bannerTabImg="/assets/images/rahul/masterclass-bengali-header-tab.webp"
                bannerMobileImg="/assets/images/rahul/masterclass-bengali-header-mob.webp"
                title="Silva Method Manifestation Masterclass"
                nextLine=""
                subtitle="Are you living a life of your desires? No right?  You may wonder it’s not possible to get everything you desire, but Silva Method says - 
                Your life is in your hands so, seize the Wheel of Your Destiny and Accelerate Your Manifestation Journey to Create the Life You've Always Dreamed Of!  
                Join the Silva Method Manifestation masterclass to learn the powerful techniques to manifest & Transform Your Health, Wealth, Happiness, Money, and 
                Career Along the Way!"
                dateTime={
                    <p class="p2 fw600 poppins primaryWhite">
                        Date : 22nd September<br />
                        Time : 08:00 PM (IST)
                    </p>
                }
                description=""
                webBannerImg="webBannerImg"
                bannerBtn={
                    <Link to="registerForm" smooth={true} duration={500}>
                        <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape" onClick={() => handleScroll(".registerForm")}>
                            Register Now
                        </a>
                    </Link>
                }
            />
            <Quote
                quote="'The greatest discovery you'll ever make, is the potential of your own mind'"
                quoteStyle="quoteStyle"
            />
            <WhatSilva
                sectionContent={
                    <>
                        <h2 class="h2 fw700 poppins textDark text-capitalize text-center mb-4">
                            What is <span class="gradientText">Silva Method</span>
                        </h2>
                        <p class="fw500 textDark poppins mb-4 mb-lg-5 text-center p1">
                            The Silva Method – Scientifically Proven Mind Control Techniques. Developed in the 1960s by José Silva, this is a self-help meditation method.
                            It is a personal development approach that integrates meditation, visualization, and positive thinking to enhance mental capabilities and achieve personal goals.
                            Participants are guided through practices to reach a comfortable meditative state known as the Alpha level, where they become deeply introspective.
                            This profound introspection ignites inner power, transforming all aspects of life. Continuously, they learn to control their subconscious mind for problem-solving
                            and self-improvement. The method aims to cultivate a positive mindset, improve focus, and empower individuals to manifest desired outcomes in various aspects of their lives.
                        </p>
                        <div className="text-center">
                            <Link to="registerForm" smooth={true} duration={500}>
                                <button type="button" class="btnMain btnSec fw700 gradientBg primaryWhite text-capitalize border-0" onClick={() => handleScroll(".registerForm")}>
                                    Register Now
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <RegisterForm
                lightBg="lightBg"
                contentArea={
                    <div className="row row-gap-4">
                        <div className="col-lg-5">
                            <div className="registerForm">
                                <div className="formThumb">
                                    <img src="/assets/images/rahul/masterclass-bengali-header.webp" alt="#" className='w100' style={{ height: "170px", objectFit: "cover" }} />
                                    <div className="div1 d-flex flex-wrap justify-content-between row-gap-2" style={{ background: "#070214" }}>
                                        <span className="primaryWhite fw600 roboto">22nd September</span>
                                        <span className="primaryWhite fw400 roboto">
                                            08:00 PM (IST)
                                            <div className='d-inline-block mx-2'>|</div>
                                            08:30 PM (BDT)
                                        </span>
                                    </div>
                                </div>
                                <form onSubmit={formSubmit}>
                                    <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                                        <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                            <span className="me-1">₹</span><span className="roboto">149/-</span>
                                        </p>
                                        <p className="p2 fw900 oldPrice mb-0">
                                            <span className="me-1">₹</span><span className="roboto">299/-</span>
                                        </p>
                                        <p className="fw600 roboto textDark mb-0 poppins">You will get 50% Off</p>
                                    </div>
                                    <div className="row gy-3">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userName" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userPhn" name="whatsapp_number" placeholder="Mobile Number" value={formData.whatsapp_number} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control" id="userEmail" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap my-4 paymentWidget">
                                        <img src="/assets/images/rahul/check-green-bg.png" alt="#" loading='lazy' className='checkIcon' />
                                        <span className="fw600 textDark poppins ms-2 me-3">Pay securely via</span>
                                        <img src={rzrLogo} alt="#" loading="lazy" className='payIcon' />
                                    </div>
                                    <ul className="resetUl">
                                        <li className="d-flex align-items-start gap-2 mb-2">
                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked={isChecked} onChange={handleChangeCheck} style={{ cursor: "pointer" }} />
                                                    <p className="textDark roboto mb-0 smFont">I accept your
                                                        <a className="fw700 textDark" href="terms-&-conditions"> Terms &amp; Conditions </a>
                                                        and willingly agree to receive Marketing emails.
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="text-center">
                                        <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-3 ms-0 border-0" style={{ width: "94%", }}>Register Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-7 position-relative">
                            <div className="formAdjacentArea">
                                <div className="contentArea">
                                    {/* <img src="/assets/images/rahul/masterclass-bengali-header.webp" alt="#" className='w100 h100' style={{ objectFit: "cover" }} /> */}
                                    <ReactPlayer
                                        url={introVideo}
                                        // light="assets/images/rahul/eng-money-magnet-vid-banner.webp"
                                        width="100%"
                                        height="100%"
                                        controls={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            <CourseForWhose
                ptop="ptop"
                startText="Who Should Join this"
                spanText=" Masterclass?"
                endText=""
                pointsArr={masterclassPoints}
                img="/assets/images/rahul/masterclass-img.webp"
                imgPos="left"
                sectionBtn={
                    <Link to="registerForm" smooth={true} duration={500}>
                        <button type="button" class="btnMain primaryBlack fw700 primaryYellow text-capitalize shapeTrape border-0 shadowLight mt-lg-4" onClick={() => handleScroll(".registerForm")}>
                            Register Now
                        </button>
                    </Link>
                }
            />
            <CourseForWhose
                bgColor="lightBg"
                ptop="ptop"
                startText="Why Choose the "
                spanText="Manifestation Masterclass?"
                endText=""
                pointsArr={beginnerCoursePoints}
                img="/assets/images/rahul/course-img.webp"
                imgPos="right"
                sectionBtn={
                    <Link to="registerForm" smooth={true} duration={500}>
                        <button type="button" class="btnMain primaryBlack fw700 primaryYellow text-capitalize shapeTrape border-0 shadowLight mt-lg-4" onClick={() => handleScroll(".registerForm")}>
                            Register Now
                        </button>
                    </Link>
                }
            />
            <CourseForWhose
                ptop="ptop"
                startText="Meditation in the"
                spanText=" Celebrity World"
                endText=""
                pointsArr={celebCont}
                img="/assets/images/rahul/celeb-img.webp"
                imgPos="left"
                sectionBtn={
                    <Link to="registerForm" smooth={true} duration={500}>
                        <button type="button" class="btnMain primaryBlack fw700 primaryYellow text-capitalize shapeTrape border-0 shadowLight mt-lg-4" onClick={() => handleScroll(".registerForm")}>
                            Register Now
                        </button>
                    </Link>
                }
            />
            <Reviews
                reviewCont={reviews}
            />
            <AboutInstructor
                customStyle={'portalDayPage'}
                contentArea={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                            About Your <span class="gradientText">Instructor</span>
                        </h2>
                        <div className="healthInstructorCard">
                            {
                                cardData.map((item, idx) =>
                                    <div className="cardItem">
                                        <div class="leftSide">
                                            <img src={item.instructorimg} alt="#" loading="lazy" class="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 class="mdFont textDark fw700 poppins mb-2 text-center text-lg-start">{item.instructortitle}</h3>
                                            <p class="fw600 poppins textDark text-capitalize mb-2 text-center text-lg-start">Certified Silva Method Instructor</p>
                                            {/* <div class="socialIcons d-flex gap-1 mb-4 justify-content-center justify-content-lg-start">
                                                <a href="https://www.facebook.com/debalina.silvamethod.instructor">
                                                    <img src={facebook} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                                <a href="https://www.youtube.com/@SilvaMethodIndiaOfficial">
                                                    <img src={youtube} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                                <a href="https://www.instagram.com/silva.instructor.debalina/">
                                                    <img src={instagram} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                            </div> */}
                                            <p class="desc textDark fw400 poppins text-center text-lg-start">{item.instructordesc1}</p>
                                            <p class="desc textDark fw400 poppins mb-0 text-center text-lg-start">{item.instructordesc2}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='text-center mt-4'>
                            <Link to="masterClassForm_bls" smooth={true} duration={500}>
                                <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape fw600 poppins shadowLight" onClick={() => handleScroll(".registerForm")}>
                                    Register Now
                                </a>
                            </Link>
                        </div>
                    </>
                }
            />
            <DynamicFaqs data={bengaliblsFaqs} defaultactive={0} title='Frequently Asked Questions' />
            <Footer2 />
        </>
    )
}

export default ManifestationMasterclass