import React from 'react'
import "./HeroSectionStyle.css";
import FooterBGImg from "../assets/footer-bg.webp"
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'



const NewHeroSection = ({ customClassName, bannerImg, bannerMobileImg, bannerTabImg, title, titleStyle, subtitle, dateTime, subtitleStyle, description, bannerBtn, cardPrice, nextLine, webBannerImg }) => {
  return (
    <div>

      {/* <section className={`heroSection lightBg ${customClassName}`} >
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={bannerImg} className="d-block w-100" alt="#" loading="lazy" />
              <div className="carousel-caption">
                <div className="container">
                  <div className="heroCont">
                    <h1 className="h1 fw700 poppins">{title}</h1>
                    <p className="desc fw300 roboto">{subtitle}</p>
                    <p className="desc fw400 roboto">{description}</p>
                    <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className={`heroSection lightBg position-relative ${customClassName}`}>
        <img src={bannerMobileImg} className="d-md-none d-block w-100 mobBanner" alt="#" loading="lazy" />
        <img src={bannerTabImg} className="d-none d-md-block d-lg-none w-100 tabBanner" alt="#" loading="lazy" />
        <img src={bannerImg} className={`d-none d-lg-block w-100 ${webBannerImg}`} alt="#" loading="lazy" />
        <div className="carousel-caption">
          <div className="container">
            <div className="heroCont">
              <h1 className={`h1 fw700 poppins ${titleStyle}`}>
                {title}
                {
                  nextLine &&
                  <>
                    <br />
                    <span>{nextLine}</span>
                  </>
                }
              </h1>
              <p class={`mdFont fw600 primaryWhite ${subtitleStyle}`}>
                {description}
              </p>
              <p className="desc fw300 roboto">
                {subtitle}
              </p>
              {dateTime}
              {cardPrice && cardPrice.status && cardPrice.component}
              {
                bannerBtn
              }
            </div>
          </div>
        </div>
      </section>



    </div>
  )
}

export default NewHeroSection