import React, { useEffect, useState } from 'react'
import "./LeadHeroStyle.css";
import HeroImg from "../assets/lead1-banner.webp"
import HeroMobileImg from "../assets/lead-banner-mob.webp"
import HeroTabImg from "../assets/lead-banner-mob.webp"
import BonusVideo from "../assets/meditation_audio.mp3"

import InsideTabImg from "../assets/tab.webp"
import axios from 'axios';



const LeadHeroOne = () => {

  //---------------code for form data submit----------------

  // const [formData, setFormData] = useState({
  //     fname: '',
  //     email: '', 
  //     mobile: ''



  //   });
  //   const [error, setError] = useState('');
  //   console.log('formdata', formData)
  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData(prevState => ({
  //       ...prevState,
  //       [name]: value
  //     }));
  //   };

  // const handleSubmit = async (e) => {

  //   e.preventDefault();

  //   // Basic validation
  //   if (!formData.fname || !formData.email ||  !formData.mobile  ) {
  //     setError('All fields are required');
  //     return;
  //   }

  //   try {
  //     console.log('i am inside try');
  //     console.log('formdata', formData)
  //     const response = await axios.post(
  //       'https://dedicated-nurture-a182b31812.strapiapp.com/api/lead-pages',
  //       {
  //         data: {
  //           Name: formData.fname,
  //           Email: formData.email,
  //           WhatsappNumber: formData.mobile,


  //         }
  //       },
  //       {
  //         headers: {
  //           Authorization: 'Bearer 8e7d2989d9039d9c8fba6d245d1bb1c0898645e8e4b26711f59cec3ba772233d3e60bc7bb4f4931676c29a2559050e81c5ece229a6bc8a0498f2851b9b6ef4533a9558c2744add054ed4f146276458fffb3b8c53cd383ab43d711e3fefee76906a4027c6928013fa8cb610510777294f665194e4951432b147cca7e28913c6bc'
  //           // Replace 'YOUR_API_TOKEN' with your actual API token
  //         }
  //       }
  //     );
  //     console.log('Response:', response.data);
  //     // Reset form fields after successful submission

  //     setFormData({
  //       fname: '',
  //       email: '',
  //       mobile: ''


  //     });



  //   const audioUrl = BonusVideo;
  //   const link = document.createElement('a');
  //   link.href = audioUrl;
  //   link.download = 'meditation_audio.mp3'; 
  //   link.click();



  //     setError('');
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setError('An error occurred, please try again later.');
  //   }
  // };

  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: '',


  });

  const [error, setError] = useState('');
  console.log('formdata', formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email) {
      setError('All fields are required');
      return;
    }

    try {
      console.log('i am inside try');
      console.log('formdata', formData)



      const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-thankpage-lead', {
        method: "POST",
        body: JSON.stringify({
          "name": formData.fname,
          "email": formData.email,
          "whatsaap_number": formData.mobile,
          // "name": "sukanta",
          // "email": "sukanta@gmail.com",
          // "whatsaap_number": "9999999999"

        }),
        headers: {
          "Content-Type": "application/json",
        }
      });


      console.log('Response:', await response.json());
      // Reset form fields after successful submission
      // launchRazorpayPayment11(formData);
      setFormData({
        fname: '',
        mobile: '',
        email: '',

      });
      setError('');

      // code for file download

      const audioUrl = BonusVideo;
      const link = document.createElement('a');
      link.href = audioUrl;
      link.download = 'meditation_audio.mp3';
      link.click();


    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };












  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.getElementById("btnSubmit");
    if (!isFormFilled) {
      btn.style.opacity = 0.4
    } else {
      btn.style.opacity = 1
    }
  }, [isFormFilled])

  return (
    <div>
      <section className="heroSection lightBg position-relative leadPage">
        <img src={HeroMobileImg} className="d-md-none d-block w-100 mobBg" alt="#" loading="lazy" />
        <img src={HeroTabImg} className="d-none d-md-block d-lg-none w-100 tabBg" alt="#" loading="lazy" />
        <img src={HeroImg} className="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" />
        <div className="carousel-caption">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center totalContArea">
              <div className="heroCont">
                <h1 className="h1 fw700 poppins">
                  Claim Your Bonus Workbook Today!
                </h1>
                <p className="desc fw400 roboto">
                  Get your exclusive bonus workbook filled with invaluable tools to overcome your daily
                  life challenges and foster a serene sense of calm and peace.
                </p>
                <form onSubmit={handleSubmit} className="leadForm1">
                  <div className="row gy-4">
                    <div className="col-12">
                      <input type="text" className="form-control" id="userName" name="fname" value={formData.fname} onChange={handleChange} placeholder="Full Name" />
                    </div>
                    <div className="col-12">
                      <input type="email" className="form-control" id="userEmail" name="email" value={formData.email} onChange={handleChange} placeholder="Email ID" />
                    </div>
                    <div className="col-12">
                      <input type="text" className="form-control" id="userPhn" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="WhatsApp No" />
                    </div>
                  </div>
                  <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 border-0" disabled={!isFormFilled}>
                    Download The Audio
                  </button>
                </form>
              </div>
              <div className="heroRightImg">
                <img src={InsideTabImg} alt="#" loading="lazy" className="w100 h100" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LeadHeroOne