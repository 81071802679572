import React from 'react'
import "./MasterTechStyle.css";


const MasterTech = () => {
    const dataArr = [
        {
            id: 1,
            mainIcon: "/assets/images(rahul)/technique-icon-01.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "/assets/images(rahul)/technique-audio.svg",
            mainHead: "Countdown Deepening Exercise",
            subHead1: "Intro Video",
            subHead2: "Guided Meditation Audio",
        },
        {
            id: 2,
            mainIcon: "/assets/images(rahul)/technique-icon-02.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "/assets/images(rahul)/technique-audio.svg",
            mainHead: "Silva Short Relaxation Exercise",
            subHead1: "Intro Video",
            subHead2: "Guided Meditation Audio",
        },
        {
            id: 3,
            mainIcon: "/assets/images(rahul)/technique-icon-03.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "/assets/images(rahul)/technique-audio.svg",
            mainHead: " Silva Long Relaxation Exercise",
            subHead1: "Intro Video",
            subHead2: "Guided Meditation Audio",
        },
        {
            id: 4,
            mainIcon: "/assets/images(rahul)/technique-icon-04.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "/assets/images(rahul)/technique-audio.svg",
            mainHead: "Silva Mental Screen Technique",
            subHead1: "Intro Video",
            subHead2: "Guided Meditation Audio",
        },
        {
            id: 5,
            mainIcon: "/assets/images(rahul)/technique-icon-05.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "/assets/images(rahul)/technique-audio.svg",
            mainHead: " Silva Mirror of the Mind Technique",
            subHead1: "Intro Video",
            subHead2: "Guided Meditation Audio",
        },
        {
            id: 6,
            mainIcon: "/assets/images(rahul)/technique-icon-06.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "/assets/images(rahul)/technique-audio.svg",
            mainHead: "Silva 3 Finger Technique",
            subHead1: "Intro Video",
            subHead2: "Guided Meditation Audio",
        },
        {
            id: 7,
            mainIcon: "/assets/images(rahul)/technique-icon-07.svg",
            subIcon1: "/assets/images(rahul)/technique-video.svg",
            subIcon2: "",
            mainHead: "Real-Life Application of Problem Fixer Techniques",
            subHead1: "Intro Video",
            subHead2: "",
        }
    ]
    return (
        <>
            <section className='masterTech lightBg ptop pbtm'>
                <div className='container'>
                    <h2 className="h2 fw700 poppins textDark text-center mb-4 mb-lg-5">
                        Silva Method <span className="gradientText">Techniques to Master
                        </span>
                    </h2>
                    <div className='row gy-4'>
                        {
                            dataArr.map((item, idx) =>
                                <div className='col-lg-4'>
                                    <div className='techCard'>
                                        <div className='d-flex align-items-center gap-3'>
                                            <div className='techIcon'>
                                                <img src={item.mainIcon} alt="#" loading="lazy" />
                                            </div>
                                            <h3 className='title fw500 poppins techMainHead mb-0'>{item.mainHead}</h3>
                                        </div>
                                        {
                                            item.subIcon1 && item.subHead1 &&
                                            <div className='d-flex align-items-center gap-2 innerTechIcon'>
                                                <img src={item.subIcon1} alt="#" loading="lazy" />
                                                <p className='fw400 poppins techInnerHead mb-0'>{item.subHead1}</p>
                                            </div>
                                        }
                                        {
                                            item.subIcon2 && item.subHead2 &&
                                            <div className='d-flex align-items-center gap-2 innerTechIcon'>
                                                <img src={item.subIcon2} alt="#" loading="lazy" />
                                                <p className='fw400 poppins techInnerHead mb-0'>{item.subHead2}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default MasterTech