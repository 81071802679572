import React, { useEffect, useState } from 'react'
import "./UpcomingEventsStyle.css";
import EventImage from "../assets/enroll-banner.webp"

import { Link } from 'react-router-dom'

const UpcomingEvents = () => {
  const [eventData, setEventData] = useState(null)
  const getEventData = async () => {
    try {
      const res = await fetch('https://silvamethod.in:5000/api/v1/events/get-events')
      const data = await res.json()
      // console.log(data, 'hellowwwww')
      if (data.success) {
        setEventData(data.data)
      } else {
        console.log(data)
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getEventData()
  }, [])




  return (
    <div>
      <section className="eventsSection text-center ptop pbtm lightBg">
        <div className="container">
          <p className="title fw600 poppins textDark text-capitalize">
            Silva Method Live Class
          </p>
          <h2 className="h2 fw700 poppins textDark">
            Be a <span className="gradientText">Silva Graduate</span>
          </h2>

          {/* <Link to="/basic-lecture-series" className="enrollBanner">
          <img src={EventImage} alt="#" loading="lazy" className="w100 h100" />
        </Link> */}
          <div className="row gy-4">
            {
              eventData?.map((items, idx) =>
                <div className="col-md-6">
                  <div className="eventCard d-flex justify-content-between align-items-xxl-center flex-column flex-xxl-row bgImgProp"
                    style={{ backgroundImage: `url('assets/images/rahul/event-card-bg.webp')` }}>
                    <div className="imgArea">
                      <img src={`https://silvamethod.in:5000${items?.image}`} alt="#" loading='lazy' className='w100 h100' />
                    </div>
                    <div className="textArea text-start">
                      <h3 className='h3 primaryWhite fw700 poppins'>
                        {items?.title}
                      </h3>
                      <p className='title poppins mb-3 fw400 primaryWhite'>
                        {items?.language}
                      </p>
                      {
                        items?.date !== "NA" &&
                        <p className='poppins primaryWhite fw400 mb-3 borderRect'>
                          {items?.date}
                        </p>
                      }
                      {
                        items?.date !== "NA" &&
                        <p className='poppins primaryWhite fw600 mb-4'>
                          {items?.time}
                        </p>
                      }
                      <Link to={items?.pageUrl} className='btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape'>
                        {items?.btnText}
                      </Link>
                    </div>
                    <div className="eventPrice">
                      <span className='colorYellow fw500 poppins'>{`₹${items?.price}/-`}</span>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default UpcomingEvents