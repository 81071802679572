import React, { useEffect, useState } from 'react'
import './MasteryFormStyle.css'
import razorpayImg from '../assets/RAZORPAY-ICON.webp'
import useRazorpay from "react-razorpay";
import { validatePhoneNumber } from '../utils/Helper';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const MasteryForm = ({ formImg, formHead, priceSection, timeSlotCount, formStyle }) => {

  //---------------code for form data submit----------------

  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: '',
    timeslot: ''

  });

  const [error, setError] = useState('');
  console.log('formdata', formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email || !formData.timeslot) {
      setError('All fields are required');
      return;
    }

    const isValid = validatePhoneNumber(formData.mobile)
    // console.log(isValid,"hello");
    if (!isValid) {
      return toast.error("Please enter a valid Phone Number")
    }

    try {
      console.log('i am inside try');
      console.log('formdata', formData)

      const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-landingpage-leads', {
        method: "POST",
        body: JSON.stringify({
          "name": formData.fname,
          "email": formData.email,
          "phone": formData.mobile,
          "timeslot": formData.timeslot
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });


      console.log('Response:', await response.json());
      // Reset form fields after successful submission
      // launchRazorpayPayment11(formData);

      setError('');
      await handlePayment(formData);
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };



  //---------------------------------------------------------

  // -----------------sukanta code----------------------------------------------
  const [Razorpay] = useRazorpay();
  var timeslotCount;
  if (formData.timeslot === '1') {
    timeslotCount = '1'
  } else if (formData.timeslot === '2') {
    timeslotCount = '2'
  }
  console.log("timeslotcount", timeslotCount)
  const handlePayment = async (formData) => {

    try {
      const createOrder = await fetch('https://silvamethod.in:5000/api/v1/orders/create-order', {
        method: "POST",
        body: JSON.stringify({
          product_id: "EMOTION-99",
          // product_id: "test01",
          user_id: `${formData.fname}-${formData.email}-${formData.mobile}-${formData.timeslot}-${timeslotCount}`,
          purchase_type: "course"
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });
      const order = await createOrder.json();
      // console.log(order,"order created") 

      if (order?.order_id) {
        var options = {
          "key": process.env.REACT_APP_RAZORPAY_KEY,
          // "key": "rzp_live_vzB1GK1MVQTtsU",
          name: "Payment",
          description: "Payment Method",
          image: "https://silvamethod.com/img/logo/title-logo-removebg-preview.png",
          order_id: order?.order_id,
          handler: async function (response) {
            if (response?.razorpay_payment_id) {
              const capturePayment = await
                //   Apirequests("orders/capture-order","POST",{
                //   "paymentId": response?.razorpay_payment_id,
                //  },{"Content-Type": "application/json"});

                fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                  method: "POST",
                  body: JSON.stringify({
                    "paymentId": response?.razorpay_payment_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              // console.log(capturePayment,'captureRes')

              const captureRes = await capturePayment?.json();
              console.log(captureRes, 'captureRes')

              if (captureRes?.success) {
                // toast.success("Payment Successful",{
                //   position:"bottom-center"
                // });
                console.log("pay success")
                setFormData({
                  fname: '',
                  mobile: '',
                  email: '',
                  timeslot: ''
                });
                window.location.href = '/thank-you';
              }
            }
          },
          "modal": {
            "ondismiss": async function () {
              // console.log(res,"failed response");
              const capt = await
                // Apirequests("orders/capture-failed-payment","POST",{
                //   "order_id": order?.order_id,
                // },{ "Content-Type": "application/json"})

                fetch('https://silvamethod.in:5000/api/v1/orders/capture-failed-payment', {
                  method: "POST",
                  body: JSON.stringify({
                    "order_id": order?.order_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              const captRes = await capt.json();
              console.log(capt, 'failed res')

              if (capt?.success) {
                // toast.error("Payment Failed",{
                //   position:"bottom-center"
                // })
                console.log("payment failed")
              }
            }
          },
          prefill: {
            name: formData.fname,
            email: formData.email,
            contact: formData.mobile,
          },
          notes: {
            address: "The Silva Method India",
          },
          theme: {
            color: "#A500FF",
          },
        }

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", async function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);

          if (response?.error?.reason === "payment_failed") {
            const capturePayment = await
              fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                method: "POST",
                body: JSON.stringify({
                  "paymentId": response?.error?.metadata?.payment_id,
                }),
                headers: {
                  "Content-Type": "application/json",
                }
              });
            if (capturePayment?.success) {
              const modalElement = document.querySelector('.razorpay-container');
              if (modalElement) {
                modalElement.style.display = 'none';
              }

            }
            window.location.href = '/payment-failure';
          }
        });

        rzp1.open();
      }
      // setApiOrder(order); 
    } catch (error) {
      console.log("error:", error);
    }


    // console.log(process.env.REACT_APP_RAZORPAY_KEY,"payment key")
  };

  // ----------code for payment ends------------------------------




  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.getElementById("btnSubmit");
    if (!isFormFilled) {
      btn.style.opacity = 0.4
    } else {
      btn.style.opacity = 1
    }
  }, [isFormFilled])


  const handleScroll = () => {
    const rightForm = document.querySelector(".rightForm");
    // console.log(doc, "document")
    if (rightForm) {
      rightForm.scrollIntoView({
        behavior: "smooth"
      })
    }
    setTimeout(() => {
      rightForm.classList.remove("pulseAnim");
      rightForm.classList.remove("pulseShadow");
    }, 4000)
    rightForm.classList.add("pulseAnim");
    rightForm.classList.add("pulseShadow");
  }

  const [isChecked, setIsChecked] = useState(true); // Set to true to make it checked by default

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };




  return (
    <>
      <section className={`masteryFormSection ptop pbtm ${formStyle}`}>
        <div className="container">
          <div className="row align-items-center row-gap-4">
            <div className="col-lg-6">
              <div className="rightForm" id="masterClassForm">
                {formHead}
                <form onSubmit={handleSubmit}>
                  {error && <div className="error">{error}</div>}
                  <div className="row gy-4">
                    <div className="col-12">
                      <input type="text" className="form-control" id="userName" name="fname" value={formData.fname} onChange={handleChange} placeholder="Full Name" />
                    </div>
                    <div className="col-12">
                      <input type="text" className="form-control" id="userPhn" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile Number" />
                    </div>
                    <div className="col-12">
                      <input type="email" className="form-control" id="userEmail" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                    </div>
                    {
                      timeSlotCount > 1 ?
                        <div className="col-12">
                          <select className="form-select form-control" id="selectTag" name="timeslot" value={formData.timeslot} onChange={handleChange} aria-label="Default select example">
                            <option value="" disabled>Book Time Slot</option>
                            <option value="1">10 a.m. - 1 p.m. (IST)</option>
                            <option value="2">6:30 p.m. - 9:30 p.m.(IST)</option>
                          </select>
                        </div> : null
                    }
                  </div>
                  {priceSection}
                  <div className="d-flex align-items-center flex-wrap column-gap-3 mb-4 paymentWidget" role="button">
                    <span className="fw600 textDark">Pay Via</span>
                    <img src={razorpayImg} alt="#" loading="lazy" />
                  </div>
                  <ul className="resetUl">
                    <li className="d-flex align-items-start gap-2 mb-2">
                      <div className="form-check">
                        <label className="form-check-label" for="flexCheckChecked">
                          {/* <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked="" /> */}
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="flexCheckChecked"
                            checked={isChecked} // Set checked attribute based on the state
                            onChange={handleCheckboxChange} // Call the function to handle checkbox change
                          />
                          <p className="textDark roboto mb-0 smFont">
                            I accept your <Link to={'/terms-&-conditions'} className="fw700 textDark">Terms &amp; Conditions</Link> and willingly agree to receive Marketing emails.
                          </p>
                        </label>
                      </div>
                    </li>
                  </ul>
                  <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0" disabled="" style={{ opacity: 0.4 }}>
                    Enroll now
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <img src={formImg} alt="#" loading='lazy' className='w100 h100' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MasteryForm