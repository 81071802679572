import React from 'react'
import "./FooterStyle.css";

import logoImage from '../assets/silva-india-logo-white.webp';
import locationIcon from '../assets/icon-location.webp';
import phoneIcon from '../assets/icon-phn.webp';
import mailIcon from '../assets/icon-mail.webp';
import facebookIcon from '../assets/icon-facebook.webp';
import twitterIcon from '../assets/icon-twitter.webp';
import instagramIcon from '../assets/icon-instagram.webp';
import linkedinIcon from '../assets/icon-linkedin.webp';
import youtubeIcon from '../assets/icon-youtube.webp';
import footerBgImage from '../assets/footer-bg.webp';
import footerIconImage from '../assets/footer-icon-img.webp';
import { Link } from 'react-router-dom';

const Footer2 = ({ footer2Margin }) => {
  return (
    <div>
      <footer>
        <section className={`footerSection bgImgProp ptop ${footer2Margin}`} style={{ backgroundImage: `url(${footerBgImage})` }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-12">
                <div className="footerLeft d-flex gap-4 py-4">
                  <Link to={"/"} className="footerLogo d-inline-block">
                    <img src={logoImage} alt="#" loading="lazy" />
                  </Link>
                  <div className="footerDesc">
                    <h2 className="title fw600 primaryWhite poppins pt-1 mb-3">
                      About The Silva Method India
                    </h2>
                    <p className="fw300 primaryWhite roboto mb-0">
                      Jose Silva revolutionized meditation with the Silva Method after decades of research
                      and hands-on experimentation. The Silva Method harnesses meditation and relaxation
                      to rewire our minds, bringing about significant personal growth. With regular
                      practice of Silva Mind control techniques, it can transform our outlook on life.
                      Also, it incorporates advanced meditation and mind control techniques for holistic
                      development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-sm-12">
                <div className="middleBorder"></div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="footerRight py-3">
                  <div className="links mb-4">
                    <h3 className="poppins primaryWhite">
                      Contact Info
                    </h3>
                    <ul className="resetUl">
                      <li className="d-flex align-items-baseline gap-2">
                        <div className="infoIcon">
                          <img src={locationIcon} alt="#" loading="lazy" />
                        </div>
                        <a href="#">West Bengal, India</a>
                      </li>
                      <li className="d-flex align-items-baseline gap-2">
                        <div className="infoIcon">
                          <img src={phoneIcon} alt="#" loading="lazy" />
                        </div>
                        <a href="#">+91 6290 945 413</a>
                      </li>
                      <li className="d-flex align-items-baseline gap-2">
                        <div className="infoIcon">
                          <img src={mailIcon} alt="#" loading="lazy" />
                        </div>
                        <a href="#">silvaindiaofficial@gmail.com</a>
                      </li>
                      <li className="d-flex align-items-baseline gap-2">
                        <div className="infoIcon">
                          <span style={{ fontSize: '14px', color: 'white' }}><i class="fa-solid fa-globe"></i></span>
                        </div>
                        <a href="https://www.silvamethod.in/">silvamethod.in</a>
                      </li>
                    </ul>
                  </div>
                  <div className="links">
                    <h3 className="poppins primaryWhite">
                      Stay Connected
                    </h3>
                    <div className="socialIcons">
                      <a href="https://www.facebook.com/silvamethodindia">
                        <img src={facebookIcon} alt="#" loading="lazy" className="w100 h100" />
                      </a>
                      <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FSilvaIndia_">
                        <img src={twitterIcon} alt="#" loading="lazy" className="w100 h100" />
                      </a>
                      <a href="https://www.instagram.com/silvamethodindia/">
                        <img src={instagramIcon} alt="#" loading="lazy" className="w100 h100" />
                      </a>
                      <a href="https://www.linkedin.com/company/silvamethodindia/">
                        <img src={linkedinIcon} alt="#" loading="lazy" className="w100 h100" />
                      </a>
                      <a href="https://www.youtube.com/@SilvaMethodIndiaOfficial">
                        <img src={youtubeIcon} alt="#" loading="lazy" className="w100 h100" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="imgBar ptop">
            <img src={footerIconImage} alt="#" className="w100 h100" />
          </div>
          <div className="copyRightArea">
            <p className="primaryWhite text-center mb-0 p-4">
              The Silva Method India. Copyright 2024, All rights reserved
            </p>
          </div>
        </section>
      </footer>
    </div>
  )
}

export default Footer2