import React, { useEffect, useRef, useState } from 'react'
import "./HeroSection_CoursesStyle.css";
import HeaderBGImg from "../assets/course-header.webp"
import CardThumbImg from "../assets/card-thumb-img.webp"
import CheckDarkImg from "../assets/check-dark.svg"
import HeaderMobileBGImg from "../assets/courses-header-for-mobile-tab.webp"
import HeaderTabBGImg from "../assets/courses-header-for-mobile-tab.webp"
import useRazorpay from "react-razorpay";
import { Link } from 'react-scroll';
import { validatePhoneNumber } from '../utils/Helper';
import toast from 'react-hot-toast';
import MultiDateSelector from './MultiDateSelector';
import { handleLeadFormSubmit, handlePaymentNew } from '../utils/PaymentHelper';
import { handleRazorPayPayment } from '../utils/RazorPayHelper';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import rzrLogo from '../assets/RAZORPAY-ICON.webp'

const HeroSection_Courses = () => {

    const [selectedIndex, setSelectedIndex] = useState(null);

    const dates = [
        { date: "21st - 28th September, 2024", time: "06:30 PM - 10:30 PM" },
    ];


    const [formData, setFormData] = useState({
        name: '',
        whatsapp_number: '',
        email: '',
    });


    const [isChecked, setIsChecked] = useState(true)


    const [couponCode, setCouponCode] = useState(null);
    const [courseData, setCourseData] = useState(null);
    const [finalPrice, setFinalPrice] = useState(null);
    const [discountPercentage, setDiscountPercentage] = useState(null);


    const [courseId, setCourseId] = useState(null)
    const location = useLocation()
    const searchParams = new URLSearchParams(location?.search);
    const tokenParam = searchParams.get('coupon');
    const [coupon, setCoupon] = useState(null);
    const btnRef = useRef(null)


    const [Razorpay] = useRazorpay();


    const fetchCourse = async (course_id) => {
        console.log(course_id, "world")
        try {
            const res = await handleLeadFormSubmit(`courses/get-course/${course_id}`, 'GET', {}, {});
            console.log(res, 'course res')
            if (res.success) {
                setCourseData(res.data[0]);
                // console.log(res?.data?.course_price,'price')
                const price = Number(res?.data[0]?.course_price) - (Number(res?.data[0]?.course_price) * (Number(res?.data[0]?.course_discount_percentage) / 100));
                setFinalPrice(Math.ceil(price))
                setDiscountPercentage(res?.data[0]?.course_discount_percentage)
            } else {
                console.log(res?.message, 'fetch went wrong')
            }
        } catch (error) {
            console.log(error, 'course fetch error')
        }
    }


    const applyCoupon = async () => {
        try {
            const res = await handleLeadFormSubmit(`coupons/apply-coupon`, 'POST', {
                coupon_code: couponCode,
                course_id: courseData?._id
            });
            // console.log(res, 'coupon res')
            if (res.success) {
                setFinalPrice(Math.ceil(res.data.final_price));
                const discountPrice = ((Number(courseData?.course_price) - Number(res.data.final_price)) / Number(courseData?.course_price)) * 100;
                setDiscountPercentage(Math.ceil(discountPrice))
                toast.success(res.message);
            } else {
                toast.error(res?.message)
            }
        } catch (error) {
            console.log(error, 'coupon error')
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleChangeCheck = () => {
        setIsChecked(!isChecked)
    }


    const formSubmit = async (e) => {
        e.preventDefault();


        const isValid = validatePhoneNumber(formData.whatsapp_number)
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }

        if (!selectedIndex) {
            return toast.error("Please select your preferred date")
        }


        const res = await handleLeadFormSubmit("leads/submit-bls-paid-lead", "POST",
            {
                ...formData, notes: {
                    timeslot: `${selectedIndex?.date} ${selectedIndex?.time}`,
                    coupon: couponCode
                }
            }, {})
        console.log(res);
        if (res.success) {
            const paymentRes = await handlePaymentNew("BLS-ENG-9999", formData, "course", {}, couponCode, selectedIndex);
            if (paymentRes?.order_id) {
                await handleRazorPayPayment(formData, paymentRes?.order_id, setFormData, "/payment-successful", Razorpay)
            } else {
                toast.error(res.message)
            }
        }
    }
    console.log(selectedIndex)


    useEffect(() => {
        if (courseId) {
            fetchCourse(courseId)
        }
    }, [courseId])


    useEffect(() => {
        if (location?.pathname?.includes("basic-lecture-series")) {
            setCourseId("BLS-ENG-9999")
        };
    }, [location])


    useEffect(() => {
        if (location) {
            const params = new URLSearchParams(location.search);
            const couponP = params.get('coupon');
            setCoupon(couponP)
            console.log(couponP); setCouponCode(couponP);
        }
    }, [location]);


    useEffect(() => {
        if (couponCode && courseData && coupon) { btnRef?.current?.click(); }
    }, [couponCode, courseData]);





    const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
    useEffect(() => {
        const btn = document.getElementById("btnSubmit");
        if (!isFormFilled) {
            btn.style.opacity = 0.4
            btn.style.pointerEvents = "none"
        } else {
            btn.style.opacity = 1
            btn.style.pointerEvents = "unset"
        }
    }, [isFormFilled])




    return (
        <div>
            <section className="heroSection lightBg position-relative coursePage">
                <img src={HeaderMobileBGImg} className="d-md-none d-block w-100" alt="#"
                    loading="lazy" />
                <img src={HeaderTabBGImg} className="d-none d-md-block d-lg-none w-100"
                    alt="#" loading="lazy" />
                <img src={HeaderBGImg} className="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" />

                <div className="carousel-caption">
                    <div className="container">
                        <div className="d-flex justify-content-between totalContArea">
                            <div className="heroCont">
                                <h1 className="h1 fw700 poppins">
                                    Silva Method Basic Lecture Series (BLS)
                                </h1>
                                <p className="desc fw300 roboto">
                                    An amalgamation of the Silva Life System and Silva Intuition System, the heart of the
                                    Silva Method Training, designed by Jose Silva!
                                </p>
                                <Link to="paymentButton" smooth={true} duration={500}>
                                    <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
                                        Register Now
                                    </a>
                                </Link>
                            </div>
                            <div className="rightCard" id="paymentButton">
                                <div className="thumbImg">
                                    <img src={CardThumbImg} alt="#" loading="lazy" className="w-100" />
                                </div>
                                <p className="fw500 roboto textDark shapeRect lightTrape mt-3 mb-2">
                                    Course Price
                                </p>
                                <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mb-2">
                                    <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                        <span className="me-1">₹</span>
                                        <span className="roboto">{finalPrice}</span>
                                    </p>
                                    <p className="p1 fw900 oldPrice textLight mb-0">
                                        <span className="me-1">₹</span>
                                        <span className="roboto">{courseData?.course_price}</span>
                                    </p>
                                    <p className="fw600 roboto textDark mb-0">
                                        You will get {Math.ceil(discountPercentage)}% Off
                                    </p>
                                </div>
                                <div className="d-flex align-items-center flex-wrap mb-4">
                                    <p className="title fw700 poppins textDark mb-0">4.6</p>
                                    <div className="d-flex align-items-center ratingStarIcon ms-2 me-4">
                                        <span className="material-symbols-outlined">
                                            star
                                        </span>
                                        <span className="material-symbols-outlined">
                                            star
                                        </span>
                                        <span className="material-symbols-outlined">
                                            star
                                        </span>
                                        <span className="material-symbols-outlined">
                                            star
                                        </span>
                                        <span className="material-symbols-outlined">
                                            star_half
                                        </span>
                                    </div>
                                    <p className="fw600 roboto textDark mb-0">
                                        (108,283 ratings)
                                    </p>
                                </div>
                                <h3 className="title fw700 poppins textDark mb-3">
                                    Unlock Unlimited Perks
                                </h3>
                                <ul className="resetUl unlockList">
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Life Changing Transformation Courses</p>
                                    </li>
                                    {/* More list items */}
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Powerful Silva Method Meditation Techniques</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Mind Control Tools and Training</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Access to a Global Community</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Doubt Clearing and Healing Session</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Weekly Meetups for Silva Graduates</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2">
                                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                                        <p className="textDark roboto mb-0">Instant Money Back Guarantee</p>
                                    </li>
                                </ul>
                                <p className="mdFont fw700 poppins textDark mt-4 mb-0">Event <span className="gradientText">Time</span></p>
                                <div className='dateBox'>
                                    <MultiDateSelector
                                        selectedDateTime={selectedIndex}
                                        setSelectedDateTime={setSelectedIndex}
                                        dates={dates}
                                    />
                                </div>
                                <div className="mainCoursePayForm mt-4">
                                    <p className="mdFont fw700 poppins textDark mb-0">
                                        Pay <span className="gradientText">Now</span>
                                    </p>
                                    <form onSubmit={formSubmit}>
                                        <div className="formCont">
                                            <>
                                                <label htmlFor="couponinput" className="textDark poppins fw500 mb-1">
                                                    Do you have a coupon code?
                                                </label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        style={{ borderColor: "#a500ff" }}
                                                        placeholder="Enter your coupon code"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        id="couponinput"
                                                        className="couponinputfield"
                                                        value={couponCode}
                                                        onChange={(e) => setCouponCode(e.target.value)}
                                                    />
                                                    <Button
                                                        style={{ borderColor: "#a500ff", backgroundColor: "#a500ff", color: "#fff", fontWeight: "500" }}
                                                        id="basic-addon1"
                                                        className="applycouponbtn"
                                                        onClick={applyCoupon}
                                                        ref={btnRef}
                                                    >
                                                        Apply Coupon
                                                    </Button>
                                                </InputGroup>
                                            </>
                                            <div className="row gy-3">
                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange} placeholder="Full Name" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" name="whatsapp_number" value={formData.whatsapp_number} onChange={handleChange} placeholder="Whatsapp Number" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center flex-wrap my-4 paymentWidget">
                                                <img src="/assets/images/rahul/check-green-bg.png" alt="#" loading="lazy" className="checkIcon" style={{ width: "20px", height: "auto" }} />
                                                <span className="fw600 textDark poppins ms-2 me-3">Pay securely via</span>
                                                <img src={rzrLogo} alt="#" loading="lazy" className="payIcon" style={{ width: "90px", height: "auto" }} />
                                            </div>
                                            <ul className="resetUl mt-4">
                                                <li className="d-flex align-items-start gap-2 mb-2">
                                                    <div className="form-check">
                                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                                            <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked={isChecked} onChange={handleChangeCheck} style={{ cursor: "pointer" }} />
                                                            <p className="textDark roboto mb-0 smFont">I accept your
                                                                <a className="fw700 textDark" href="/terms-&-conditions"> Terms &amp; Conditions </a>
                                                                and willingly agree to receive Marketing emails.
                                                            </p>
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="text-center mt-4">
                                                <button type="submit" id='btnSubmit' className="text-capitalize fw700 primaryBlack btnPulse">
                                                    Register Now
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HeroSection_Courses