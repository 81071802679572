import React, { useEffect, useState } from 'react'
import './MasterclassFormStyle.css'
import { validatePhoneNumber } from '../utils/Helper';
import toast from 'react-hot-toast';


const MasterclassForm = ({ heading, spanText, descText, dateBox, formPosition, video, rightText, mobFormPos, formHeading, placeName, placeNo, placeMail, btnText, btnBtmText }) => {

    //---------------code for form data submit----------------

    const [formData, setFormData] = useState({
        fname: '',
        mobile: '',
        email: '',


    });

    const [error, setError] = useState('');
    console.log('formdata', formData)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.fname || !formData.mobile || !formData.email) {
            setError('All fields are required');
            return;
        }

        const isValid = validatePhoneNumber(formData.mobile)
        // console.log(isValid,"hello");
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }

        try {
          console.log('i am inside try');
          console.log('formdata', formData)
          const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-blslead',{
            method: "POST",
            body: JSON.stringify({"name":formData.fname,
                "email":formData.email,
                "whatsapp_number":formData.mobile,
                
                                      }),
            headers:{
                  "Content-Type": "application/json",
            }
           });
            
    
    
          console.log('Response:', response);
          // Reset form fields after successful submission
        //   launchRazorpayPayment11(formData);
        
          setFormData({
            fname: '',
            mobile: '',
            email: '',
            
          });
          
          setError('');
          window.location.href = 'https://chat.whatsapp.com/KZD2gmUb5KV7TTHNoN76ut';
        //   await handlePayment();
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred, please try again later.');
        }
    };


    return (
        <>
            <section className="masterclassForm lightBg ptop pbtm">
                <div className="container">
                    {
                        heading &&
                        <h2 class="h2 fw700 poppins textDark text-capitalize text-center mb-4">
                            {heading}
                            <span class="gradientText"> {spanText}</span>
                        </h2>
                    }
                    {
                        descText &&
                        <p class="p1 fw600 poppins textDark text-center mb-4">
                            {descText}
                        </p>
                    }
                    {dateBox ? dateBox : null}
                    <div className={`row row-gap-5 ${mobFormPos ? "mobColDirection" : formPosition === "left" ? "flex-row-reverse" : ""} ${rightText ? "align-items-center" : ""}`}>
                        <div className="col-lg-4">
                            <div className="formContent" id="masterClassForm_bls">
                                <div className="div1">
                                    <p className="title fw600 poppins primaryBlack mb-0 text-center">{formHeading}</p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    {error && <div className="error">{error}</div>}
                                    <div className="row gy-4">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userName" name="fname" value={formData.fname} onChange={handleChange} placeholder={placeName} />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userPhn" name="mobile" value={formData.mobile} onChange={handleChange} placeholder={placeNo} />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control" id="userEmail" name="email" value={formData.email} onChange={handleChange} placeholder={placeMail} />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 mt-lg-5 border-0 shadowLight">
                                            {btnText}
                                        </button>
                                        {
                                            btnBtmText &&
                                            <p className="fw300 poppins primaryBlack mt-4 mt-lg-5 mb-0 text-center">{btnBtmText}</p>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className={`rightContent ${formPosition === "left" ? "ms-0 overflow-visible" : ""}`}>
                                {video}{rightText}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default MasterclassForm