import React, { useState } from 'react';
import Result from './Result';
import { Button, Form, Modal } from 'react-bootstrap';
import "../components/InstructorListStyle.css";
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

function Quiz() {

    const quizquestions = [
        {
            id: 1,
            heading: "Self-awareness",
            question: "How well do you recognize your own emotions and their impact?",
            answers: [{ point: 1, text: "Rarely/Never" }, { point: 2, text: "Sometimes" }, { point: 3, text: "Often" }, { point: 4, text: "Usually" }, { point: 5, text: "Always" }],
        },
        {
            id: 2,
            heading: "Self-regulation",
            question: "How well do you manage your emotions in stressful situations?",
            answers: [{ point: 1, text: "Rarely/Never" }, { point: 2, text: "Sometimes" }, { point: 3, text: "Often" }, { point: 4, text: "Usually" }, { point: 5, text: "Always" }],
        },
        {
            id: 3,
            heading: "Motivation",
            question: "How driven are you to achieve your goals despite obstacles and setbacks?",
            answers: [{ point: 1, text: "Rarely/Never" }, { point: 2, text: "Sometimes" }, { point: 3, text: "Often" }, { point: 4, text: "Usually" }, { point: 5, text: "Always" }],
        },
        {
            id: 4,
            heading: "Empathy",
            question: "How well do you understand and share the feelings of others?",
            answers: [{ point: 1, text: "Rarely/Never" }, { point: 2, text: "Sometimes" }, { point: 3, text: "Often" }, { point: 4, text: "Usually" }, { point: 5, text: "Always" }],
        },
        {
            id: 5,
            heading: "Social Skills",
            question: "How adept are you at managing relationships and building networks?",
            answers: [{ point: 1, text: "Rarely/Never" }, { point: 2, text: "Sometimes" }, { point: 3, text: "Often" }, { point: 4, text: "Usually" }, { point: 5, text: "Always" }],
        }
    ];

    const [currentQuestion, setCurrentQuestions] = useState(0);
    const [result, setResult] = useState([]);
    const [selectans, setSelectans] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        whatsapp_number: ""
    });


    const [loading, setLoading] = useState(false);

    const handleNext = (currentq) => {
        if (selectans === null) {
            toast.error("Please select an answer");
            return;
        }
        const cur = {
            question: currentq.question,
            answer: selectans.text,
            point: selectans.point
        };
        const updatedResult = [...result, cur];

        if (quizquestions.length === currentQuestion + 1) {
            setResult(updatedResult);
            setModalShow(true)
        } else {
            setResult(updatedResult);
            setCurrentQuestions(currentQuestion + 1);
            setSelectans(null);
        }
    };

    // if (showResults) {
    //     return (
    //         <div className='vh-100 vw-100 d-flex justify-content-center align-items-center'>
    //         <div className='container'>
    //             <div className='card' style={{maxWidth:"600px",padding:"25px",margin:"auto"}}>
    //                 <h1>Results</h1>
    //                 <ul className="list-unstyled">
    //                     {result?.map((res, i) => (
    //                         <li key={i}>
    //                             <strong>{res.question}</strong>
    //                             <p>{res.answer}</p>
    //                         </li>
    //                     ))}
    //                 </ul>
    //             </div>
    //         </div>
    //         </div>
    //     );
    // };


    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (!formData.email || !formData.name || !formData.whatsapp_number) {
            return toast.error('please provide name email and whatsapp number')
        };

        try {
            const postLead = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-quiz-lead', {
                method: "POST",
                body: JSON.stringify({ ...formData, score: result.reduce((acc, next) => acc + next.point, 0), status: detectRes(result.reduce((acc, next) => acc + next.point, 0)) }),
                headers: {
                    "Content-Type": "application/json",
                }
            });
            const res = await postLead.json()
            console.log(res);
            if (res.success) {
                // toast.success(res.message)
                Swal.fire({
                    title: "Well done 👏",
                    text: "Check your email to see the results!",
                    icon: "success"
                });
                // console.log(await postLead,'postres')
                // setShowResults(true);
                setCurrentQuestions(0)
                setSelectans(null)
                setModalShow(false);
            } else {
                toast.error('something went wrong')
            }

        } catch (error) {
            console.log(error, 'lead error')
            toast.error('Internal Server Error')
        }
        setLoading(false);
    }

    const detectRes = (point) => {
        if (point >= 5 && point <= 10) {
            return "Low EQ"
        };
        if (point >= 11 && point <= 15) {
            return "Below Average EQ"
        };
        if (point >= 16 && point <= 20) {
            return "Average EQ"
        };
        if (point >= 21 && point <= 25) {
            return "Above Average EQ"
        };
        if (point >= 26 && point <= 30) {
            return "High EQ"
        };

    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center mb-5 mb-lg-0 quizSection'>
            <>
                {
                    showResults ?
                        <Result resultData={result} /> :
                        <div className='container'>
                            <div className="text-center">
                                <p class="fw700 poppins textDark shapeTrape2">EQ Test</p>
                            </div>
                            <h2 class="h2 fw700 poppins textDark text-center mb-3">
                                Are you in control of your <span class="gradientText">emotions?</span>
                            </h2>
                            <p class="poppins textDark text-center mb-0 mb-lg-4">
                                Participate in our EQ Self-Assessment test to discover your emotional intelligence level. Just rate yourself on the questions below!
                            </p>
                            <div>
                                <div>
                                    <div className='text-center'>
                                        <button role="heading" className="btnMain primaryYellow primaryBlack fw600 border-0 rounded-3 my-4 badgeBtn">
                                            {quizquestions[currentQuestion]?.heading}
                                        </button>
                                        {/* <button role="heading" className="btnMain primaryYellow primaryBlack fw600 border-0 rounded-3 my-3 badgeBtn">
                                            Q. No. {currentQuestion + 1}
                                        </button> */}
                                    </div>
                                    <h3 className='title poppins textDark mb-4'>
                                        <span className='fw700'>Q.{currentQuestion + 1} </span>
                                        <span className='fw500'>{quizquestions[currentQuestion]?.question}</span>
                                    </h3>
                                    <ul className="list-unstyled">
                                        {quizquestions[currentQuestion]?.answers?.map((answer, i) => (
                                            <div className={`alert alert-${selectans?.text === answer.text ? 'primary' : 'secondary'}`} role="button" onClick={() => setSelectans(answer)}>
                                                {answer.text}
                                            </div>
                                            // <li key={i}>
                                            //     <input
                                            //         type="radio"
                                            //         value={answer}
                                            //         name="selectoptions"
                                            //         checked={selectans === answer}
                                            //         onChange={(e) => setSelectans(e.target.value)}
                                            //     /> <span>{answer}</span>
                                            // </li>
                                        ))}
                                    </ul>
                                    <button type="button" className="btnMain gradientBg primaryWhite fw600 border-0 rounded-3" onClick={() => handleNext(quizquestions[currentQuestion])}>
                                        {quizquestions.length === (currentQuestion + 1) && selectans ? "View Score" : "Next"}
                                    </button>
                                    {/* <Button
                                        variant="primary"
                                        disabled={loading}
                                        onClick={() => !loading ? handleNext(quizquestions[currentQuestion]) : null}
                                        className="btnMain gradientBg primaryWhite fw600 border-0 rounded-3"
                                    >
                                        {loading ? 'Loading…' : quizquestions.length === (currentQuestion + 1) && selectans ? "View Score" : "Next"}
                                    </Button> */}
                                    {/* <button onClick={() => handleNext(quizquestions[currentQuestion])}>
                        {quizquestions.length === (currentQuestion + 1) ? "View Score" : "Next"}
                    </button> */}

                                </div>
                            </div>
                        </div>
                }
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton className='quizleadmodal'>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Please fill this form before get your result
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control size="lg" type="text" placeholder="Name" className='mb-4' onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                        <Form.Control size="lg" type="email" placeholder="email address" className='mb-4' onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                        <Form.Control size="lg" type="tel" placeholder="Whatsapp number" className='mb-4' onChange={(e) => setFormData({ ...formData, whatsapp_number: e.target.value })} />

                        {/* <Button className='btnMain gradientBg primaryWhite fw600 border-0 rounded-3' onClick={}></Button> */}
                        <Button
                            variant="primary"
                            disabled={loading}
                            onClick={!loading ? handleSubmit : null}
                            className='btnMain gradientBg primaryWhite fw600 border-0 rounded-3'
                        >
                            {loading ? 'Loading…' : 'Get Result'}
                        </Button>
                    </Modal.Body>
                </Modal>
            </>
        </div>

    );
}

export default Quiz;
