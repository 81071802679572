import React, { useContext } from 'react'
import './VideoBtmStyle.css'
import { Appcontext } from '../context/appcontext'


const VideoBtmSection = ({ playing, textCont, bgImg }) => {
    const { isMobile } = useContext(Appcontext)


    return (
        <>
            <section className="videoBtmSection">
                <div className="container">
                    <div className="videoBtmCont"
                        style={{ margin: playing && !isMobile ? '18% auto 50px' : isMobile ? playing ? '-10% auto 0' : '8% auto 0' : '', backgroundImage: `url('${bgImg}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        {textCont}
                    </div>
                </div>
            </section>
        </>
    )
}

export default VideoBtmSection