import React from 'react'
import './MasteryBonusStyle.css'
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'


const MasteryBonus = ({ props }) => {
    const { lightBg, masteryBonusStyle, imgTitle, imgHead, imgArea, fontStyle, headingText, spanText, bonusPoints, btnPulse, btnText, midHead, midSpanText, extraBonus, extraClass } = props

    return (
        <>
            <section className={`masteryBonusSection ptop pbtm ${masteryBonusStyle} ${lightBg}`}>
                <div className="container">
                    <div className="row align-items-center row-gap-4">
                        <div className="col-lg-5">
                            <div className="text-center">
                                {
                                    imgTitle &&
                                    <p className='title fw400 primaryWhite mb-2 poppins gradientBg bgShape'>{imgTitle}</p>
                                }
                                {
                                    imgHead &&
                                    <h3 className='h2 fw600 textDark poppins mb-0'>{imgHead}</h3>
                                }
                                <img src={imgArea} alt="#" loading='lazy' className='w100 h100' />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <h2 class={`h2 ${fontStyle ? fontStyle : 'poppins'} textDark mb-4 ps-lg-5`}>
                                {headingText} <span class="gradientText pe-3">{spanText}</span>
                            </h2>
                            <ul className='resetUl ps-lg-5'>
                                {
                                    bonusPoints?.map((items, idx) =>
                                        <li class={`d-flex align-items-${items.icon ? 'start' : 'center'} gap-2 ${items.hidemargin ? "" : "mb-2"}`}>
                                            {
                                                items.icon &&
                                                <span>{items.icon}</span>
                                            }
                                            {
                                                items.imgIcon &&
                                                < img src={items.imgIcon} alt="#" loading='lazy' className='circleTick' />
                                            }
                                            {/* <p class="title textDark fw500 poppins mb-0 me-2">{items.heading}</p> */}
                                            <p class="textDark fw500 roboto mb-0 pt-1">{items.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                            {
                                midHead && midSpanText &&
                                <h3 className={`mdFont fw400 textDark mt-4 mt-lg-5 mb-4 ps-lg-5 ${fontStyle ? fontStyle : 'poppins'} text-uppercase additionalBonus`}>
                                    {midHead} <span className="gradientText pe-3">{midSpanText}</span>
                                </h3>
                            }
                            {
                                extraBonus ?
                                    <ul className='resetUl ps-lg-5'>
                                        {
                                            extraBonus?.map((items, idx) =>
                                                <li class={`d-flex align-items-${items.icon ? 'start' : 'center'} gap-2 ${items.hidemargin ? "" : "mb-2"}`}>
                                                    {
                                                        items.icon &&
                                                        <span>{items.icon}</span>
                                                    }
                                                    {
                                                        items.imgIcon &&
                                                        < img src={items.imgIcon} alt="#" loading='lazy' className='circleTick' />
                                                    }
                                                    {/* <p class="title textDark fw500 poppins mb-0 me-2">{items.heading}</p> */}
                                                    <p class="textDark fw500 roboto mb-0 pt-1">{items.desc}</p>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                btnText &&
                                <div className="text-center text-lg-start ps-lg-5">
                                    <Link to="masterClassForm" smooth={true} duration={500}>
                                        <button type="button" className={`btnMain fw700 primaryBlack mt-4 mt-lg-5 border-0 ${btnPulse} ${extraClass}`} onClick={() => handleScroll()}>
                                            {btnText}
                                        </button>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default MasteryBonus