import React from 'react';
import "../../components/CourseScheduleStyle.css";

import ZoomImg from "../../assets/zoom.webp";


const BengaliSlsCouseSchedule = ({ courseScheduleData }) => {

    return (
        <div>
            <section className="courseScheduleSection">  
                <div className="container">

                    <div className="text-center mb-5">
                        <h2 className="h2 fw700 poppins textDark text-center mb-2">
                            কোর্স <span className="gradientText">সিডিউল</span>
                        </h2>
                        <p className="title fw600 poppins textDark">
                            আমাদের প্রোগ্রামের বিস্তারিত পরিকল্পনা জেনে নিন
                        </p>
                    </div>

                    <div className="tabsCont text-center">

                        <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist" style={{maxWidth:"700px"}}>
                            {courseScheduleData?.map((course, index) => (
                                <li className="nav-item" role="presentation" key={course.id}>
                                    <button className={`nav-link ${index === 0 ? 'active' : ''}`} id={course.id} data-bs-toggle="pill"
                                        data-bs-target={`#${course.ariaControl}`} type="button" role="tab" aria-controls={course.ariaControl}
                                        aria-selected={index === 0 ? 'true' : 'false'}>
                                        <div className="tabBtn d-flex gap-3 align-items-center">
                                            <div className="h3 leftSide roboto fw700 textDark">{course.title1}</div>
                                            <div className="rightSide">
                                                <h3 className="title fw600 roboto textDark mb-0">
                                                    {course.title2}
                                                </h3>
                                                <p className="p1 textDark roboto mb-0">{course?.dayText}</p>
                                            </div>
                                        </div>
                                    </button>
                                </li>
                            ))}
                        </ul>

                        <div className="tab-content" id="pills-tabContent">
                            {courseScheduleData.map((course, index) => (
                                <div className={`tab-pane fade ${index === 0 ? 'show active' : ''}`} id={course.ariaControl} role="tabpanel" aria-labelledby={course.id} tabIndex="0" key={course.id}>
                                    <div className="instructorCard">
                                        <div className="cardItem text-start justify-content-around align-items-center">
                                            <div className="leftSide">
                                                <img src={course.data.instructorImage} alt="#" loading="lazy" className="w100 h100" />
                                            </div>
                                            <div className="rightSide">
                                                <h3 className="fw700 h3 poppins textDark mb-3">
                                                    {course.data.cardTitle}
                                                </h3>
                                                <p className="roboto textDark shapeRect lightTrape mb-4">
                                                    <span className="fw600">ইন্সট্রাক্টর-</span>{course.data.instructor}
                                                </p>
                                                <ul className='resetUl'>
                                                    {course.data.points.map((item) => (
                                                        <li className='d-flex align-items-center gap-2 mb-2' key={item.id}>
                                                            <span className='fw500 pointsNum'>{item.pointNumber}</span>
                                                            <p className='textDark mb-0'>{item.points}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">
                                                    <p className="desc textDark roboto mb-0">
                                                        Online class via
                                                    </p>
                                                    <img src={ZoomImg} alt="#" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
            </section>
        </div>
    );
}

export default BengaliSlsCouseSchedule;
