import React from 'react'
import ThankYouPageBody from '../components/ThankYouPageBody'
import HeartImg from "../assets/heart-tick-01.svg"


const ThankYouPage = () => {
  return (
    <ThankYouPageBody
      contentArea={
        <div className="pageCont">
          <div className="heartTick">
            <img src={HeartImg} alt="#" loading="lazy" className="w100 h100" />
          </div>
          <h2 className="h2 fw700 poppins textDark text-center mb-4">
            আপনার রেজিস্ট্রেশন টি <span className="gradientText"> সফল </span>হয়েছে!
          </h2>
          <p className="desc fw500 textDark poppins">
            এটি আমাদের অফিসিয়াল নিশ্চিতকরণ। “মানি ম্যাগনেট মাস্টারক্লাস” - এ রেজিস্ট্রেশন করার জন্য ধন্যবাদ।
            শীঘ্রই আপনি  আপনার  রেজিস্টার করা ইমেল আইডিতে জুম লিঙ্ক সহ একটি মেল পাবেন, আপনার ইনবক্স চেক করতে ভুলবেন না।
            আপনার যদি আরও কোন প্রশ্ন থাকে তবে আপনি আমাদের টিম মেম্বারদের সাথে সংযোগ করতে পারেন।
            আপনি আমাদের ইমেল করুন:<a href="" className="textDark fw700"> silvaindiaofficial@gmail.com </a> অথবা <a href="" className="textDark fw700">6290945413</a> নম্বরে WhatsApp এর মাধ্যমে আমাদের সাথে যোগাযোগ করুন।

          </p>
          <p className="mdFont textDark fw700 mb-4 text-center poppins">কিছু এক্সট্রা বোনাস যেগুলি আপনি পাবেন</p>
          <div className="row justify-content-center gy-4">
            <div className="col-12">
              <div className="bonusCard row-gap-2">
                <div className="bonusCont mt-3 d-flex align-items-center row-gap-2 flex-wrap">
                  <p className="fw600 primaryWhite poppins mb-0 p1">বোনাস #1</p>
                  <p className="fw400 textDark roboto m-0 p2">ইন্সট্রাক্টর দের সাথে ৫ দিন ৫ টি ফ্রি মানি এট্ট্রাকশন গাইডেড মেডিটেশন অনুশীলন করতে পারবেন।</p>
                </div>
                <div className="bonusCont mt-3 d-flex align-items-center row-gap-2 flex-wrap">
                  <p className="fw600 primaryWhite poppins mb-0 p1">বোনাস #2</p>
                  <p className="fw400 textDark roboto m-0 p2">সিলভা মেথডের পক্ষ থেকে একটি সার্টিফিকেট পাবেন।</p>
                </div>
                <div className="bonusCont mt-3 d-flex align-items-center row-gap-2 flex-wrap">
                  <p className="fw600 primaryWhite poppins mb-0 p1">বোনাস #3</p>
                  <p className="fw400 textDark roboto m-0 p2">আমাদের অফিসিয়াল হোয়াটস্যাপ গ্রুপের সদস্য হতে পারবেন।</p>
                </div>
                <div className="bonusCont mt-3 d-flex align-items-center row-gap-2 flex-wrap">
                  <p className="fw600 primaryWhite poppins mb-0 p1">বোনাস #4</p>
                  <p className="fw400 textDark roboto m-0 p2">সার্টিফায়েড সিলভা মেথড ইন্সট্রাক্টর দের সাথে সরাসরি যোগাযোগ করার সুযোগ পাবেন।</p>
                </div>
                {/* <div className="bonusBtn">
                                    <button type="button" onClick={handleDownloadButtonClick1} className="btnMain btnSec fw700 primaryYellow primaryBlack text-capitalize border-0">
                                        download
                                    </button>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default ThankYouPage