import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { handleScroll } from '../utils/animPulse'
import { Link } from 'react-scroll';
import Navbar2 from '../components/Navbar2'
import MasteryHero99 from '../components/MasteryHero99'
import { Appcontext } from '../context/appcontext';
import VideoBtmSection from '../components/VideoBtmSection';
import MasteryBonus from '../components/MasteryBonus';
import PracticeMeditation14 from '../components/PracticeMeditation14';
import SilvaFounder from '../components/SilvaFounder';
import MasteryForm from '../components/MasteryForm';
import LiveClassandWorkshop from '../components/LiveClassandWorkshop';
import InternationalMeditation from '../components/InternationalMeditation';
import AboutInstructor from '../components/AboutInstructor';
import Reviews from '../components/Reviews';
import DynamicFaqs from '../components/DynamicFaqs';
import Footer2 from '../components/Footer2';
import Sticky from '../components/Sticky';
import MoneyMagnetForm from '../components/MoneyMagnetForm';
import { Helmet } from 'react-helmet';
import instructor1 from '../assets/Debasish-Chatterjee.webp'
import instructor2 from '../assets/dr-kalam.webp'
const InstructorVideo = "https://silva-method-official.b-cdn.net/money-masterclass-videos/VIDEO-FOR-LANDINGPAGE%20(1).mp4"



const BengaliMoneyManagement = () => {
    const [playing, setPlaying] = useState(false)
    const { isMobile } = useContext(Appcontext)


    const bonusPoints = [
        {
            imgIcon: "",
            icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
            heading: 'Bonus#1',
            desc: `ইন্সট্রাক্টর দের সাথে ৫ দিন ৫ টি ফ্রি গাইডেড মেডিটেশন অনুশীলন করতে পারবেন।`,
        },
        {
            imgIcon: "",
            icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
            heading: 'Bonus#2',
            desc: `সিলভা মেথডের পক্ষ থেকে একটি সার্টিফিকেট পাবেন।`,
        },
        {
            imgIcon: "",
            icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
            heading: 'Bonus#3',
            desc: `আমাদের অফিসিয়াল হোয়াটস্যাপ গ্রুপের সদস্য হতে পারবেন।`,
        },
        {
            imgIcon: "",
            icon: <i class="fa-regular fa-square-check gradientText mdFont baselineMiddle"></i>,
            heading: 'Bonus#4',
            desc: `সার্টিফায়েড সিলভা মেথড ইন্সট্রাক্টর দের সাথে সরাসরি যোগাযোগ করার সুযোগ পাবেন।`,
            hidemargin: true
        },
    ]


    const masterclassPoints = [
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `অর্থনৈতিক স্বাধীনতা অর্জনের উপায় শিখুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `ঋণ এবং আর্থিক চাপ কাটিয়ে উঠুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `নিজের মাইন্ডসেটকে উন্নত করে আয় এবং ব্যয় এর মধ্যে ভারসাম্য গড়ে তুলুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `ইতিবাচক মানসিকতা তৈরি করে উপার্জিত অর্থের সঠিক ম্যানেজমেন্ট করুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `মানি ব্লক এবং লিমিটিং বিলিফস গুলি থেকে মুক্ত হন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `আপনার সংগৃহীত অর্থ বজায় রাখার উপায় শিখুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `অর্থ উপার্জনের ভয় অতিক্রম করুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `মানি ম্যানিফেস্টেশন - এর শক্তিশালী টেকনিক গুলি শিখুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `অর্থনৈতিক সাফল্যের জন্য গুরুত্বপূর্ণ অ্যাফারমেশন গুলি জানুন`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: 'Bonus#1',
            desc: `ঈর্ষা, বিদ্বেষ এবং অনিরাপত্তা অতিক্রম করুন`,
            hideMargin: true
        },
    ]

    const challengingPoints = [
        {
            imgIcon: "/assets/images/rahul/check-bg-yellow.svg",
            icon: "",
            heading: 'ঋণ ব্যবস্থাপনা:',
            desc: `ঋণ পরিচালনা ও হ্রাস এবং আর্থিক চাপ দূর করার জন্য কার্যকর কৌশল।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg-yellow.svg",
            icon: "",
            heading: 'মানি ম্যানেজমেন্ট:',
            desc: `আপনার আয় এবং খরচ অপ্টিমাইজ করুন এবং আর্থিক স্থিতিশীলতা অর্জন করুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg-yellow.svg",
            icon: "",
            heading: 'আয় সৃষ্টি:',
            desc: `আপনার উপার্জনের সম্ভাবনা বাড়ান এবং অধিকতর আর্থিক স্বাধীনতা ও নিরাপত্তা অর্জন করুন।`
        },
        {
            imgIcon: "/assets/images/rahul/check-bg-yellow.svg",
            icon: "",
            heading: 'মানসিকতার পরিবর্তন:',
            desc: `সিলভা পদ্ধতির মাধ্যমে একটি ইতিবাচক এবং প্রাচুর্য-ভিত্তিক মানসিকতা বিকাশ করুন এবং সীমিত বিশ্বাসগুলিকে অতিক্রম করুন।`
        },
    ]

    const sliderImgs = [
        {
            id: 1,
            imgUrl: "/assets/images/rahul/international-1.webp"
        },
        {
            id: 2,
            imgUrl: "/assets/images/rahul/international-2.webp"
        },
        {
            id: 3,
            imgUrl: "/assets/images/rahul/international-3.webp"
        },
        {
            id: 4,
            imgUrl: "/assets/images/rahul/international-4.webp"
        },
        {
            id: 5,
            imgUrl: "/assets/images/rahul/international-5.webp"
        },
        {
            id: 6,
            imgUrl: "/assets/images/rahul/international-6.webp"
        },
        {
            id: 7,
            imgUrl: "/assets/images/rahul/international-7.webp"
        },
        {
            id: 8,
            imgUrl: "/assets/images/rahul/international-8.webp"
        },
        {
            id: 9,
            imgUrl: "/assets/images/rahul/international-9.webp"
        },
        {
            id: 10,
            imgUrl: "/assets/images/rahul/international-10.webp"
        },
    ]

    const cardData = [
        {
            id: 1,
            instructorimg: instructor1,
            instructortitle: 'দেবাশীষ চ্যাটার্জী',
            instructordesc: `একজন সার্টিফায়েড সিলভা মেথড প্রশিক্ষকের ভূমিকায়, তাঁর senior accountant হিসাবে 
            প্রায় ৪০ বছরের দক্ষতা এবং আধ্যাত্মিক অন্তর্দৃষ্টি মসৃণভাবে একত্রিত হয়। পাঁচ দশকেরও বেশি সময় ধরে নিবেদিত 
            আধ্যাত্মিক অনুশীলন তাঁর গভীর যাত্রাকে সংজ্ঞায়িত করে।তাঁর লক্ষ্য হল ছাত্রদের ক্ষমতায়ন করা, আর্থিক দক্ষতা 
            এবং আধ্যাত্মিকতা উভয় ক্ষেত্রেই প্রসিদ্ধ করা। আপনার অভ্যন্তরীণ উজ্জ্বলতাকে খুঁজে বের করতে এবং আপনার 
            লক্ষ্যগুলি অর্জন করতে তিনি আপনাকে সাহায্য করবেন।একজন লাইফ ট্রান্সফরমেশন কোচ হিসাবে তিনি মানুষকে 
            তাদের জীবনে ইতিবাচক শক্তি খুঁজে পেতে সাহায্য করেন। সিলভা পদ্ধতির মাধ্যমে, তিনি আপনার সত্যিকারের 
            বাস্তবতাকে রূপ দেওয়ার চেষ্টা করেন।তিনি ব্যক্তিদের তাদের ভাগ্য নিয়ন্ত্রণ করতে এবং তাদের অভ্যন্তরীণ সম্ভাবনা 
            আবিষ্কার করতে সহায়তা করার জন্য প্রতিশ্রুতিবদ্ধ।`
        },
        {
            id: 2,
            instructorimg: instructor2,
            instructortitle: 'ডা. কালাম ফারুক',
            instructordesc: `ডাঃ আবুল কালাম ১৯৬৭ সালে বাংলাদেশে জন্মগ্রহণ করেন। তিনি তার পড়াশোনা শেষ করেন 
            এবং ১৯৯৮ সালে ICAM থেকে মেডিকেল সায়েন্সে স্নাতক হন। ডাঃ আবুল কালাম একজন জেনারেল ফিজিশিয়ান হিসেবে 
            তার কর্মজীবন শুরু করেন। ২০০১ সালে, তিনি বাংলাদেশের সিলভা কিংবদন্তি জনাব মাহি কাজীর কাছ থেকে জোসে 
            সিলভার সিলভা পদ্ধতি সম্পর্কে জানতে পারেন। ২০০১ থেকে ২০০৫ সাল পর্যন্ত, ডাঃ কালাম ৪০ টিরও বেশি সেমিনারে 
            অংশ নিয়ে জনাব কাজীর কাছ থেকে সিলভা কোর্সের পুরো পরিসর নিয়েছিলেন। ২০০৬ থেকে তিনি ব্যক্তিগতভাবে জনাব 
            কাজীর অধীনে প্রশিক্ষণ নেন, সংগঠক, ব্যবস্থাপক এবং ইন্সট্রাক্ট্ররের দায়িত্ব পালন করেন। শুধু তাই নয় তিনি ISOMETRIC LTD-এর 
            একটি গুরুত্বপূর্ণ অংশ ছিলেন। তিনি সিলভা পদ্ধতি এবং জনাব কাজীর কাছ থেকে অনেক কিছু শিখেছেন, বিশেষ করে কীভাবে 
            মানুষকে ভালোবাসতে হয় এবং সাহায্য করতে হয়।`
        },
    ]

    const faqData = [
        {
            id: 1,
            title: `১. কেন আমাদের কে অর্থের জন্য স্ট্রাগল করতে হয়ে ?`,
            text: `উঃ। আমাদের মধ্যে ৯০% মানুষ ছোটোর থেকে আমাদের মাইন্ড এবং মস্তিষ্ককে ব্লক করে রাখি। আমরা অর্থের প্রতি ভয়, সন্দেহ ইত্যাদি তৈরি করি।`
        },
        {
            id: 2,
            title: `২. আমরা কি ভাবে জানবো যে আমাদের মস্তিষ্কে মানি ব্লক হয়ে আছে ?`,
            text: `উঃ। যখন আমরা অনুভব করি যে আমাদের অর্থের জন্য ডিমান্ড এবং সাপ্লায় সামঞ্জস্যহীন, যেটা মানে আমরা একটি মানি ব্লকে রয়েছি।`
        },
        {
            id: 3,
            title: `৩. মানি মাইন্ডসেটের আসল অর্থ কী ?`,
            text: `উঃ। মানি মাইন্ডসেট বলতে কোন ব্যক্তির অর্থ সম্পর্কে যে বিশ্বাস, আচরণ এবং দৃষ্টিভঙ্গি রয়েছে তা বোঝানো হয়।`
        },
        {
            id: 4,
            title: `৪. আমরা কীভাবে আরও সহজে টাকা উপার্জন করতে পারি ?`,
            text: `উঃ। আমাদের কে সঠিক দক্ষতা এবং কর্মসূচি শিখতে হবে । সিলভা মেথড আপনাকে আপনার মনের শক্তিতে অধিক পরিমাণের ব্যবহার এবং মনের শান্তি কে বজায় রেখে টাকা উপার্জন করতে শিখাবে।`
        },
        {
            id: 5,
            title: `৫. কিভাবে আমরা আর্থিক স্বাধীনতা অর্জন করতে পারি ?`,
            text: `উঃ। যখন আপনি নিজের মন কে নিয়ন্ত্রন করে প্রত্যাশিত এবং অপ্রত্যাশিত উৎস থেকে অর্থের উপার্জন করতে শিখবেন, মানি ম্যানেজমেন্ট করতে শিখবেন।`
        }
    ]

    const liveClassImgs = [
        {
            id: 1,
            imgUrl: '/assets/images/rahul/money-magnet-1.webp'
        },
        {
            id: 2,
            imgUrl: '/assets/images/rahul/money-magnet-2.webp'
        },
        {
            id: 3,
            imgUrl: '/assets/images/rahul/money-magnet-3.webp'
        },
        {
            id: 4,
            imgUrl: '/assets/images/rahul/money-magnet-4.webp'
        },
        {
            id: 5,
            imgUrl: '/assets/images/rahul/money-magnet-5.webp'
        },
        {
            id: 6,
            imgUrl: '/assets/images/rahul/money-magnet-6.webp'
        },
    ]

    useEffect(() => {
        const docs = document.querySelector(".navSection");
        if (docs) {
            docs.scrollIntoView({
                behavior: "smooth"
            })
        }
    }, [])



    return (
        <div style={{ backgroundImage: "url('/assets/images/rahul/line-bg.jpg')", backgroundSize: "contain", backgroundPosition: "center" }}>

            <Helmet>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              !function(f,b,e,v,n,t,s) {
                if (f.fbq) return; n = f.fbq = function() {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
              }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '779900594311840');
              fbq('track', 'PageView');
              fbq('track', 'Purchase', {value: 1.00, currency: 'USD'});
            `,
                    }}
                />
                <noscript>{`
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=779900594311840&ev=PageView&noscript=1"
            alt="Facebook Pixel"
          />
        `}</noscript>
            </Helmet>


            <Navbar2 />
            <MasteryHero99
                playing={playing}
                setPlaying={setPlaying}
                heroSection="benMoneyManagement"
                mobBanner="/assets/images/rahul/ben-money-management-mob.webp"
                tabBanner="/assets/images/rahul/ben-money-management-web.webp"
                webBanner="/assets/images/rahul/ben-money-management-web.webp"
                heroTextCont={
                    <>
                        <h1 className='mdFont fw700 NotoSansBengali primaryWhite mb-3 mb-lg-4 smHead'>
                            সিলভা মেথড <span className='colorYellow'>“মানি ম্যাগনেট মাস্টারক্লাস”</span>
                        </h1>
                        <h2 class="h1 fw700 NotoSansBengali primaryWhite mb-3 mb-lg-4">
                            আপনি কি জানেন আমাদের সকলের মধ্যেই ছোটবেলা থেকেই কিছু ট্রমা বা মেন্টাল মানি ব্লকেজ থাকে যা আমাদের সমৃদ্ধির পথে বাধা হয়ে দাঁড়ায় ?
                        </h2>
                        <h3 className='h2 primaryWhite NotoSansBengali mb-0 mb-lg-3'>
                            <span className='mdFont fw300 me-2 first'>এই মাস্টারক্লাসটি আপনাকে আপনার</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                মানি ব্লক এবং লিমিটিং বিলিফস
                            </span>
                            <span className='mdFont fw300 me-2 first'>গুলিকে ভেঙ্গে আপনাকে</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                মানি ম্যানিফেস্ট
                            </span>
                            <span className='mdFont fw300 me-2 first'>করতে শেখাবে। সিলভা মেথডের সাহায্যে</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                নিজেকে ‘মানি ম্যাগনেট’ - এ পরিনত করুন।
                            </span>
                            <span className='mdFont fw300 me-2 first'>কীভাবে</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                প্রত্যাশিত এবং অপ্রত্যাশিত উৎস থেকে অর্থের উপার্জন
                            </span>
                            <span className='mdFont fw300 me-2 first'>করবেন তা শিখুন।</span>
                        </h3>
                    </>
                }
                heroVidCont={
                    <div className="masteryHeroVideo" style={{ maxHeight: playing && !isMobile ? '450px' : isMobile ? playing ? '192px' : '450px' : '' }}>
                        <ReactPlayer
                            url={InstructorVideo}
                            light="assets/images/rahul/money-masterclass-vid-banner.webp"
                            width="100%"
                            height="100%"
                            playing
                            muted
                            loop
                            controls={true}
                            onPlay={() =>
                                setPlaying(true)
                            }
                        />
                    </div>
                }
            />
            <VideoBtmSection
                playing={playing}
                bgImg="/assets/images/rahul/money-management-bg.webp"
                textCont={
                    <>
                        <div className='d-flex justify-content-around flex-column row-gap-4 flex-lg-row NotoSansBengali'>
                            <div className='dateTime fw600 poppins primaryWhite text-center flex-column'>
                                <p className='mdFont fw900 colorYellow mb-2'>
                                    <span className='me-2'><i class="fa-solid fa-clock"></i></span>
                                    ২৪শে আগস্ট, শনিবার
                                </p>
                                <p className='mb-3'>সন্ধ্যা ৭:৩০ থেকে রাত ৯:৩০ (ভারতীয় সময়)</p>
                                <p className='mb-0'>রাত ৮:০০ থেকে রাত ১০:০০ (বাংলাদেশী সময়)</p>
                                {/* <p className='mb-0'>শীঘ্রই আসছে</p> */}
                            </div>
                            <div className='text-center text-lg-start'>
                                <p className='primaryWhite fw800 NotoSansBengali mb-4 dateTimeTitle ps-lg-5'>
                                    মানি ম্যাগনেট মাস্টারক্লাস
                                </p>
                                <p className='primaryWhite NotoSansBengali mb-0 ps-lg-5'>
                                    <p className='mb-0 fw500 title colorYellow'>রেজিস্ট্রেশন ফি:</p>
                                    <p className='dateTimeTitle fw800'>₹ ৩৪৯/- (INR) / ৳ ৪৯৯/- (BDT)</p>
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="masterClassForm" smooth={true} duration={500}>
                                <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 mt-lg-5 border-0" onClick={() => handleScroll()}>
                                    রেজিস্টার করুন
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <MasteryBonus
                props={{
                    lightBg: "lightBg",
                    imgTitle: "",
                    imgHead: "",
                    imgArea: "/assets/images/rahul/ben-money-bonus.webp",
                    fontStyle: "NotoSansBengali fw700",
                    headingText: "কিছু এক্সট্রা বোনাস",
                    spanText: "যেগুলি আপনি পাবেন",
                    bonusPoints,
                    btnPulse: "shapeTrape primaryYellow shadowLight",
                    btnText: "এখনি রেজিস্টার করুন"
                }}
            />
            <PracticeMeditation14
                customStyle="moneyAttraction"
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4 reverseCol">
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 NotoSansBengali textDark mb-4">
                                <span className="gradientText ms-2">৫ টি ফ্রি মানি এট্ট্রাকশন গাইডেড মেডিটেশন</span> অনুশীলন করার সুযোগ
                            </h2>
                            <p className="textDark NotoSansBengali mb-3">
                                ২১ শে জুলাই এই মাস্টারক্লাসের পর থেকে ক্রমাগত ৫ দিন রাত ৯:৩০ টায় (IST)  /  ১০ টায় (BST) ইন্সট্রাক্টরদের
                                কাছ থেকে ফ্রি গাইডেড মেডিটেশন অনুশীলন করার সুযোগ পাবেন। যার সাহায্যে আপনি আপনার মাইন্ডকে কন্ট্রোল
                                করে আর্থিক সাচ্ছল্যতার সাথে সাথে, বেতনের হাইক, উপার্জন বৃদ্ধি, উপার্জিত অর্থের সঠিক ম্যানেজমেন্ট এই সব
                                টেকনিক আয়ত্ত করতে পারবেন। এই মাস্টারক্লাসটিতে রেজিস্টার করার সময় আপনার দেওয়া ইমেল আইডিতে ফ্রি মেডিটেশন
                                ক্লাসের লিংক পৌঁছে যাবে। এছাড়াও আপনি আমাদের সাথে হোয়াটস্যাপ - এ যুক্ত হয়েও সব তথ্য পেয়ে যাবেন।
                            </p>
                            <div className="text-center text-lg-start">
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mb-4 mb-lg-5 border-0 shadowLight" onClick={() => handleScroll()}>
                                        এখনি জয়েন করুন
                                    </button>
                                </Link>
                            </div>
                            <div className="grpBox">
                                <p className='primaryWhite NotoSansBengali mb-3'>
                                    আমাদের হোয়াটস্যাপ গ্রুপ জয়েন করার জন্য নীচে দেওয়া লিংকে ক্লিক করুন:
                                </p>
                                <a href="https://chat.whatsapp.com/KXmoMfCjkEq8LwqJBcbudj"
                                    className='btnMain fw700 primaryBlack shapeTrape border-0'>
                                    <span className='me-2 d-inline-block iconWhatsapp'>
                                        <img src="/assets/images/rahul/whatsapp-svg-icon.svg" alt="#" className='w100 h100' />
                                    </span>
                                    হোয়াটস্যাপে যুক্ত হন
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="videoArea ps-lg-5">
                                <img src="/assets/images/rahul/money-attraction.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                            </div>
                        </div>
                    </div>
                }
            />
            <PracticeMeditation14
                customStyle="benefitsMoneyMasterclss"
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6">
                            <div className="videoArea pe-lg-5">
                                <img src="/assets/images/rahul/benefits-money-masterclass.webp" alt="#" loading="lazy" className="w100 h100 agendaImg" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 NotoSansBengali textDark mb-4">
                                <span className="gradientText ms-2">এই মাস্টারক্লাসটি</span> আপনি কেন করবেন?
                            </h2>
                            <ul className='resetUl'>
                                {
                                    masterclassPoints?.map((items, idx) =>
                                        <li className={`d-flex align-items-start text-start gap-2 ${items.hideMargin === true ? "" : "mb-3"}`}>
                                            <img src={items.imgIcon} alt="#" className="circleTick" />
                                            <p className="textDark NotoSansBengali fw500 mb-0">{items.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                }
            />
            <SilvaFounder
                sectionBg="/assets/images/rahul/texture-bg.webp"
                heading={
                    <h2 class="h2 fw700 NotoSansBengali primaryWhite mb-4 mb-lg-5 text-center">
                        এই মাস্টারক্লাসে কোন কোন <span className='colorYellow'>আর্থিক চ্যালেঞ্জ</span> মোকাবেলা করা হবে?
                    </h2>
                }
                textCont={
                    <ul className='resetUl challengePoints'>
                        {
                            challengingPoints?.map((items, idx) =>
                                <li className='d-flex align-items-start text-start gap-2 mb-3'>
                                    <img src={items.imgIcon} alt="#" className="circleTick pt-1" />
                                    <p className="title primaryWhite NotoSansBengali mb-0">
                                        <strong>{items.heading}</strong>
                                        <span className='ms-2 fw300'>{items.desc}</span>
                                    </p>
                                </li>
                            )
                        }
                    </ul>
                }
                imgCont={
                    <div class="videoArea m-auto mt-4 mt-lg-5">
                        <img src="/assets/images/rahul/money-challenge.webp" alt="#" loading="lazy" class="w100 h100 challengeImg" />
                    </div>
                }
            />
            {/* <MasteryForm
                formStyle="moneyMagnetForm"
                formHead={
                    <div className="div1">
                        <p className="title fw700 NotoSansBengali primaryWhite mb-1 p1">মানি ম্যাগনেট মাস্টারক্লাস</p>
                        <div className=''>
                            <span className="primaryWhite fw500 NotoSansBengali date">
                                তারিখঃ <span className="fw600 shapeRect primaryYellow primaryBlack">২১ শে জুলাই</span>
                            </span>
                            <span className="primaryWhite fw500 NotoSansBengali ms-1 ms-lg-4">
                                সময়কালঃ <span className="fw600 shapeRect primaryYellow primaryBlack">২ ঘণ্টা</span>
                            </span>
                        </div>
                    </div>
                }
                priceSection={
                    <div className="d-flex align-items-center column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">₹</span>
                            <span className="NotoSansBengali">৩৪৯/-<span className='title fw600'>(INR)</span></span>
                        </p>
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">₹</span>
                            <span className="NotoSansBengali">৫০০/-<span className='title fw600'>(BDT)</span></span>
                        </p>
                    </div>
                }
                formImg="assets/images/rahul/addition-money-bonus.webp"
            /> */}

            <MoneyMagnetForm
                formStyle="moneyMagnetForm"
                formHead={
                    <div className="div1">
                        <p className="title fw700 NotoSansBengali primaryWhite mb-1 p1">মানি ম্যাগনেট মাস্টারক্লাস</p>
                        <p className="title fw700 NotoSansBengali primaryWhite mb-0 p1">২৪ শে আগস্ট</p>
                        {/* <div className=''>
                            <span className="primaryWhite fw500 NotoSansBengali date">
                                তারিখঃ <span className="fw600 shapeRect primaryYellow primaryBlack">২১ শে জুলাই</span>
                            </span>
                            <span className="primaryWhite fw500 NotoSansBengali ms-1 ms-lg-4">
                                সময়কালঃ <span className="fw600 shapeRect primaryYellow primaryBlack">২ ঘণ্টা</span>
                            </span>
                        </div> */}
                    </div>
                }
                priceSection={
                    <div className="d-flex align-items-center column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">₹</span>
                            <span className="NotoSansBengali">৩৪৯/-<span className='title fw600'>(INR)</span></span>
                        </p>
                        <span>|</span>
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">৳</span>
                            <span className="NotoSansBengali">৪৯৯/-<span className='title fw600'>(BDT)</span></span>
                        </p>
                    </div>
                }
                formImg="assets/images/rahul/addition-money-bonus-1.webp"
                productId="MONEY_MAGNET_349"
                leadAPI="https://silvamethod.in:5000/api/v1/leads/submit-bengali-money-magnet-lead"
                thankYouPageUrl="/thank-you-page"
                langCount="true"
            />
            <LiveClassandWorkshop
                sectionHead={
                    <h2 class="h2 fw700 NotoSansBengali textDark text-center mb-lg-5 mb-4">
                        আমাদের লাইভ ক্লাসের <span class="gradientText">কিছু মুহূর্ত</span>
                    </h2>
                }
                videoSection={
                    <div className='react-payer-wrapper-custom'>
                        <ReactPlayer
                            // url={masterclassVideo}
                            url="https://www.youtube.com/watch?v=W9OLaQyykHg"
                            // light="assets/images/rahul/ben-live-class-thumb.webp"
                            width="100%"
                            height="100%"
                            controls={true}
                        />
                    </div>
                }
                classImgs={liveClassImgs}
                extraClass={"extra-video-class"}
            />
            <InternationalMeditation
                lightBg="lightBg"
                ptop="ptop"
                pbtm="pbtm"
                sectionTitle={
                    <div className='text-center'>
                        <p class="title fw600 NotoSansBengali gradientText">
                            সিলভা মেথড আপনি কেন বেছে নেবেন?
                        </p>
                    </div>
                }
                sectionHeading={
                    <h2 class="h2 fw700 NotoSansBengali textDark text-center mb-4">
                        এটি একটি আন্তর্জাতিক মাইন্ড এমপাওয়ারমেন্ট প্রোগ্রাম, <span class="gradientText">সেই ১৯৬৬ সাল থেকে।</span>
                    </h2>
                }
                sectionDesc={
                    <p class="desc fw500 textDark NotoSansBengali mb-4 mb-lg-5 text-center">
                        ৫০ বছরেরও বেশি সময় ধরে এই পদ্ধতি জীবনকে পরিবর্তন করে চলেছে। বিশ্বব্যাপী ১২ মিলিয়নেরও
                        বেশি মানুষ এর কৌশলগুলি ব্যবহার করে তাদের জীবনের প্রতিকূল চ্যালেঞ্জগুলি কাটিয়ে উঠেছেন এবং
                        সাফল্য অর্জন করেছেন। বিশ্ববিখ্যাত সিলভা পদ্ধতি এখন ভারতেও তার আন্তর্জাতিক কর্মশালা নিয়ে চলে এসেছে৷
                    </p>
                }
                sliderImgs={sliderImgs}
            />
            <AboutInstructor
                contentArea={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-center">
                            আপনার <span class="gradientText">ইন্সট্রাক্টরের সম্পর্কে</span> কিছু কথা
                        </h2>
                        <div className="healthInstructorCard">
                            {
                                cardData.map((item, idx) =>
                                    <div className="cardItem">
                                        <div class="leftSide">
                                            <img src={item.instructorimg} alt="#" loading="lazy" class="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 class="mdFont textDark fw700 poppins mb-3">{item.instructortitle}</h3>
                                            <p class="desc textDark fw400 poppins mb-0">{item.instructordesc}</p>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="text-center">
                                <Link to="masterClassForm_health" smooth={true} duration={500}>
                                    <button type="submit" class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-5 ms-0 border-0 shadowLight" onClick={() => handleScroll()}>
                                        মাস্টারক্লাসে নথিভুক্ত করুন
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </>
                }
            />
            <Reviews
                sectionHead={
                    <h2 class="h2 fw700 poppins textDark mb-4 mt-4 text-center">সেলিব্রিটি জগতে <span class="gradientText">মেডিটেশন</span></h2>
                }
            />
            <DynamicFaqs
                title='কিছু প্রায়শই জিজ্ঞাসিত প্রশ্ন'
                fontStyle="NotoSansBengali"
                defaultactive={0}
                data={faqData}
            />
            <Footer2 />
            <Sticky
                date="তারিখঃ ২১ শে জুলাই (রবিবার)"
                price="৩৪৯"
                actual_price=""
                btnText="জয়েন করুন"
            />
        </div>
    )
}

export default BengaliMoneyManagement