import React from 'react'
import "./StickyStyle.css";
import CheckImg from "../assets/check-bg-dark.svg"
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'

const Sticky = ({ date, price, actual_price, btnText }) => {
  return (
    <section className="stickyCont d-md-none">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between row-gap-2">
          <div className="stickyLeft">
            <div className='d-flex align-items-center column-gap-3 row-gap-1 offerPrice'>
              {
                price &&
                <p className="p2 fw900 primaryWhite d-flex align-items-center justify-content-center mb-0">
                  <span className="me-1">₹</span>
                  <span className="poppins">{price}/-</span>
                </p>
              }
              {
                actual_price &&
                <p className="p2 fw600 oldPrice textLight2 mb-0">
                  <span className="me-1">₹</span>
                  <span className="poppins">{actual_price}/-</span>
                </p>
              }
            </div>
            <div>
              <p className="p2 fw600 poppins textLight2 mb-0">
                {date}
              </p>
            </div>
          </div>
          <div className="stickyRight">
            <Link to="masterClassForm" smooth={true} duration={500}>
              <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0 me-3" onClick={() => handleScroll()}>
                {btnText}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sticky