
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./NavbarStyle.css";
import LogoImg from "../assets/silva-india-logo-white.webp"



const Navbar = () => {

  const location = useLocation();

  // Define an array of objects for your navbar links
  const navLinks = [
    { to: '/', text: 'Home' },
    { to: '/about', text: 'About' },
    { to: '/courses', text: 'About' },
    { to: '/about', text: 'About' },
    // Add other links
  ];


  return (
    <div>
    {/* <section className="navSection">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link to="/" className="navbar-brand" >
            <img src={LogoImg} alt="Logo" loading="lazy" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" aria-label="Toggle navigation">
          </button>

          <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop"
            aria-labelledby="staticBackdropLabel">
            <div className="offcanvas-header">
              <Link to ="/" className="navbar-brand" >
                <img src={LogoImg} alt="#" loading="lazy" />
              </Link>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link active" aria-current="page">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" >About</Link>
              </li>
              <li className="nav-item">
                <Link to="/courses" className="nav-link">Courses</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link" >Contact</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">Silva International</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section> */}

<section className="navSection">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="index.html">
            <img src={LogoImg} alt="#" loading="lazy" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#staticBackdrop" aria-controls="staticBackdrop" aria-label="Toggle navigation">
          </button>

          <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop"
            aria-labelledby="staticBackdropLabel">
            <div className="offcanvas-header">
              <a className="navbar-brand" href="index.html">
                <img src={LogoImg} alt="#" loading="lazy" />
              </a>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link active" aria-current="page" href="index.html">Home</Link>
              </li>
              <li className="nav-item dropdown">
                <Link to="/about" className="nav-link" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  About
                  <span><i className="fa-solid fa-angle-down"></i></span>
                </Link>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="instructor.html">Instructors</a></li>
                  <li><a className="dropdown-item" href="solution.html">Solutions Web Provide</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link to="/courses" className="nav-link" href="courses.html">Courses</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link" href="#">Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#">Silva International</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
    </div>
  )
}

export default Navbar