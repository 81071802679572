import React, { useEffect } from 'react'
import Form from '../components/Form'
import FAQ from '../components/FAQ'
import Newnavbar from '../components/Newnavbar'
import Footer from '../components/Footer'
import InstructorList from '../components/InstructorList'
import NewHeroSection from '../components/NewHeroSection'

import BannerImg from "../assets/instructor-header.webp"
import BannerMobileImg from "../assets/instructorpage-for-mobile.webp"
import BannerTabImg from "../assets/instructorpage-for-tab.webp"
import { Link } from 'react-scroll'

const Instructor = () => {

  useEffect(() => {
    const docs = document.querySelector(".navSection");
    if (docs) {
      docs.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [])


  return (
    <div>
      <Newnavbar />
      <NewHeroSection
        customClassName="aboutPage"
        bannerImg={BannerImg}
        bannerTabImg={BannerTabImg}
        bannerMobileImg={BannerMobileImg}
        title="Best Instructors Silva Method India"
        subtitle="Find The Best Silva Method Certified Instructors Near You"
        description=""
        bannerBtn={
          <Link to="instructorsSection" smooth={true} duration={500}>
            <a href="#" className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape">
              Get Started
            </a>
          </Link>
        }
      />
      <InstructorList />
      <Form />
      <FAQ />
      <Footer />
    </div>
  )
}

export default Instructor