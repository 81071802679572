
export const handleScroll = (name) => {
    if (name) {
        const rightForm = document.querySelector(name);
        console.log(name, "document")
        if (rightForm) {
            rightForm.scrollIntoView({
                behavior: "smooth"
            })
        }
        setTimeout(() => {
            rightForm.classList.remove("pulseAnim");
            rightForm.classList.remove("pulseShadow");
        }, 4000)
        rightForm.classList.add("pulseAnim");
        rightForm.classList.add("pulseShadow");
    } else {
        const rightForm = document.querySelector(".rightForm");
        // console.log(doc, "document")
        if (rightForm) {
            rightForm.scrollIntoView({
                behavior: "smooth"
            })
        }
        setTimeout(() => {
            rightForm.classList.remove("pulseAnim");
            rightForm.classList.remove("pulseShadow");
        }, 4000)
        rightForm.classList.add("pulseAnim");
        rightForm.classList.add("pulseShadow");
    }

}