import React from 'react'
import './SickSectionStyle.css'
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'
// import ReactPlayer from 'react-player';
// const InstructorVideo = "https://vz-6873ed28-3a2.b-cdn.net/96b2aa97-85dd-492a-8ffc-8a3d5dc1fdbc/playlist.m3u8"
// import sickman from '../assets/sick-man.webp'

const SickSection = ({ sectionCont, lightBg, extraSecName }) => {
    return (
        <>
            <section className={`sickSection ptop pbtm ${lightBg} ${extraSecName}`}>
                <div className='container'>
                    {sectionCont}
                </div>
            </section>
        </>
    )
}

export default SickSection