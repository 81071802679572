import React, { useEffect, useState } from 'react'
import MasteryHero99 from '../components/MasteryHero99'
import Navbar2 from '../components/Navbar2'
import MasteryBonus from '../components/MasteryBonus'
import SilvaFounder from '../components/SilvaFounder'
import SickSection from '../components/SickSection'
import DynamicFaqs from '../components/DynamicFaqs'
import MasterclassBgImg from '../components/MasterclassBgImg'
import { Link } from 'react-scroll'
import { Link as RouteLink } from 'react-router-dom'
import { validatePhoneNumber } from '../utils/Helper';
import toast from 'react-hot-toast';
import { handleScroll } from '../utils/animPulse'
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

// Initialize the modal library
Modal.setAppElement('#root');


const FinancesLead = () => {

    //---------------code for form data submit----------------

    const [formData, setFormData] = useState({
        fname: '',
        mobile: '',
        email: '',


    });

    const [error, setError] = useState('');


    console.log('formdata', formData)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.fname || !formData.mobile || !formData.email) {
            setError('All fields are required');
            return;
        }

        const isValid = validatePhoneNumber(formData.mobile)
        // console.log(isValid,"hello");
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }

        try {
            console.log('i am inside try');
            console.log('formdata', formData)
            const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-free-affirmation-lead', {
                method: "POST",
                body: JSON.stringify({
                    "name": formData.fname,
                    "email": formData.email,
                    "whatsapp_number": formData.mobile

                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });



            console.log('Response:', response);
            // Reset form fields after successful submission
            //   launchRazorpayPayment11(formData);

            setFormData({
                fname: '',
                mobile: '',
                email: '',

            });

            setError('');



            // window.location.href = '/successfully-registered';
            // Swal.fire({
            //     position: "center",
            //     icon: "success",
            //     title: "Congrats !!",
            //     html: `Congratulations! You’ve successfully unlocked your FREE Money Magnet Affirmation PDF! 
            //     To attract the wealth you desire, we recommend practicing these affirmations daily. <strong>Please check 
            //     your registered email ID for the PDF</strong>. If you don’t see the email right away, kindly wait a moment 
            //     and check again. You can mail us at: silvaindiaofficial@gmail.com or contact us via WhatsApp at 6290945413.`,
            //     showConfirmButton: true,  // This adds the default confirm button
            //     confirmButtonText: 'Join Our Whatsapp Group',  // Custom text for the close button
            //     confirmButtonColor: "#128c7e",
            //     cancelButtonText: "Close",
            //     cancelButtonColor: "#dc3741",
            //     showCancelButton: true,
            //     didOpen: () => {
            //         Swal.hideLoading();  // Ensure any loading spinner is hidden
            //     }
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         window.open("https://chat.whatsapp.com/HBMmPq0qdER1gk4wYeZM0V")
            //     }
            // })

            Swal.fire({
                title: "Congrats !!",
                icon: "success",
                html:
                    `Congratulations! You’ve successfully unlocked your FREE Money Magnet Affirmation PDF! 
                To attract the wealth you desire, we recommend practicing these affirmations daily. <strong>Please check 
                your registered email ID for the PDF</strong>. If you don’t see the email right away, kindly wait a moment 
                and check again. You can mail us at: silvaindiaofficial@gmail.com or contact us via WhatsApp at 6290945413.`
                ,
                showCloseButton: true,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: 'Join Our Whatsapp Group',  // Custom text for the close button
                confirmButtonColor: "#128c7e",
                // confirmButtonAriaLabel: "Thumbs up, great!",
                // cancelButtonText: `
                //   <i class="fa fa-thumbs-down"></i>
                // `,
                // cancelButtonAriaLabel: "Thumbs down"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.open("https://chat.whatsapp.com/HBMmPq0qdER1gk4wYeZM0V")
                }
            });

            //   await handlePayment();
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred, please try again later.');

        }
    };

    const [isChecked, setIsChecked] = useState(true); // Set to true to make it checked by default
    // Function to handle checkbox change
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
    };



    const bonusPoints1 = [
        {
            imgIcon: "",
            icon: "",
            heading: '',
            desc: `Are you ready to transform your relationship with money? Our thoughts 
            and beliefs about money can significantly impact our financial well-being. 
            Yet, many of us carry subconscious beliefs and attitudes that may be holding 
            us back from achieving true financial freedom.`,
        },
        {
            imgIcon: "",
            icon: "",
            heading: '',
            desc: `Discover the power of money affirmations to increase your income, eliminate debt, or simply create a healthier relationship with money.`,
        },
    ]

    const bonusPoints2 = [
        {
            imgIcon: "",
            icon: "",
            heading: '',
            desc: `Money affirmations are positive statements to attract a more positive and abundance-oriented 
            attitude towards finances, ultimately attracting greater prosperity into your life.`,
        }
    ]

    const affirmationsPoints = [
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            desc: `What are affirmations?`,
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            desc: `Benefits of using money affirmations`,
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            desc: `Steps to create an affirmation for yourself`,
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            desc: `Amazing 12 affirmations that can change your life completely`,
        },
    ]

    const moneyMagnet1 = [
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: '',
            desc: `Debt Management- Improves strategies to reduce stress and solve financial stress.`,
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: '',
            desc: `Smart financial decisions- Helps to control your for long-term financial well-being.`,
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: '',
            desc: `Better earning scope- Increases your earning scope and enjoy financial freedom.`,
        },
        {
            imgIcon: "/assets/images/rahul/check-bg.svg",
            icon: "",
            heading: '',
            desc: `Improves mindset- Helps to develop a positive mindset and restricts negative beliefs.`,
        },
    ]

    // const moneyMagnet2 = [
    //     {
    //         imgIcon: "/assets/images/rahul/check-bg.svg",
    //         icon: "",
    //         heading: '',
    //         desc: `You have negative money beliefs.`,
    //     },
    //     {
    //         imgIcon: "/assets/images/rahul/check-bg.svg",
    //         icon: "",
    //         heading: '',
    //         desc: `You want to improve financial situation.`,
    //     },
    //     {
    //         imgIcon: "/assets/images/rahul/check-bg.svg",
    //         icon: "",
    //         heading: '',
    //         desc: `Feeling stuck or frustrated in your current financial status.`,
    //     },
    //     {
    //         imgIcon: "/assets/images/rahul/check-bg.svg",
    //         icon: "",
    //         heading: '',
    //         desc: `Finding strategies to improve finances.`,
    //     },
    // ]

    const faqData = [
        {
            id: 1,
            title: `1. What are money magnet affirmations?`,
            text: `Ans. Money affirmations are positive statements or phrases that you repeat to yourself regularly to help shift your mindset and beliefs about money.`
        },
        {
            id: 2,
            title: `2. How do money magnet affirmations work?`,
            text: `Ans. Money affirmations work by influencing your subconscious mind to adopt new beliefs and attitudes towards money.`
        },
        {
            id: 3,
            title: `3. Are money magnet affirmations effective?`,
            text: `Ans. Yes, many people have experienced significant positive changes in their financial lives as a result of practicing money affirmations.`
        },
        {
            id: 4,
            title: `4. How often should I practice money magnet affirmations?`,
            text: `Ans. It's recommended to practice money affirmations daily for maximum effectiveness. Incorporate them into your morning or bedtime routine, or whenever you have a few moments to focus on your mindset.`
        },
        {
            id: 5,
            title: `5.  Can anyone use money magnet affirmations?`,
            text: `Ans. Yes, money affirmations are suitable for anyone who wants to improve their relationship with money and attract more abundance into their life.`
        },
        {
            id: 6,
            title: `6. What if I don't see immediate results?`,
            text: `Ans. While some people may experience immediate benefits from practicing money affirmations, it's more common for results to manifest gradually over time. Consistency and patience are the keys.`
        },
        {
            id: 7,
            title: `7. Do I need to believe the affirmations for them to work?`,
            text: `Ans.While believing in the affirmations can enhance their effectiveness. Simply repeating the affirmations regularly can gradually shift your mindset and beliefs over time.`
        },
        {
            id: 8,
            title: `8.  Are there any resources to support my affirmation practice?`,
            text: `Ans. Yes, there are many resources available to support your affirmation practice, including guided meditations, money affirmations etc. You can download our Free money magnet affirmation pdf for a better practice and effective outcome.`
        },
    ]


    return (
        <>

            <Helmet>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              !function (f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '2790427521125440');
    fbq('track', 'PageView');
            `,
                    }}
                />
                <noscript>{`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=2790427521125440&ev=PageView&noscript=1" />`}</noscript>
            </Helmet>



            <Navbar2 />
            <MasteryHero99
                heroSection="financeLeadHero"
                mobBanner="/assets/images/rahul/money-magnet-banner-mob.webp"
                tabBanner="/assets/images/rahul/money-magnet-header-web.webp"
                webBanner="/assets/images/rahul/money-magnet-header-web.webp"
                heroTextCont={
                    <>
                        <h1 class="h1 fw400 anton primaryWhite mb-4 text-uppercase">
                            Money Magnet FREE Affirmations PDF
                        </h1>
                        <h2 className='title primaryWhite poppins mb-3'>
                            Download Silva Method FREE Money Affirmations PDF for a Debt-Free and Financially Independent Future!
                        </h2>
                    </>
                }
                heroForm={
                    <div className="rightForm" id="masterClassForm">
                        <div className="div1">
                            <p className='title fw400 poppins primaryBlack mb-0 text-center anton text-uppercase p1'>
                                Register Now
                            </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            {error && <div className="error">{error}</div>}
                            <div className="row gy-4">
                                <div className="col-12">
                                    <input type="text" className="form-control" id="userName" name="fname" placeholder="Full Name" value={formData.fname} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" id="userPhn" name="mobile" placeholder="Whatsapp Number" value={formData.mobile} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <input type="email" className="form-control" id="userEmail" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                </div>
                            </div>
                            <ul className="resetUl">
                                <li className="d-flex align-items-start gap-2 mb-2 mt-3">
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckChecked">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="flexCheckChecked"
                                                checked={isChecked} // Set checked attribute based on the state
                                                onChange={handleCheckboxChange} // Call the function to handle checkbox change
                                            />
                                            <p className="textDark roboto mb-0 smFont text-start">
                                                I accept your <RouteLink to='/terms-&-conditions' className="fw700 textDark">Terms &amp; Conditions</RouteLink> and
                                                willingly agree to receive Marketing emails.
                                            </p>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 ms-0 border-0 shadowLight">Download PDF</button>
                        </form>


                    </div>
                }
            />
            <MasteryBonus
                props={{
                    masteryBonusStyle: "masteryBonusStyle",
                    imgArea: "/assets/images/rahul/financial-freedom.webp",
                    fontStyle: "anton fw400 text-uppercase additionalBonus text-center text-lg-start",
                    headingText: "Welcome to the Journey of",
                    spanText: "Financial Freedom",
                    bonusPoints: bonusPoints1,
                    midHead: "What are",
                    midSpanText: "Money Magnet Affirmations?",
                    extraBonus: bonusPoints2,
                    btnText: "Get PDF!",
                    extraClass: "shapeTrape  primaryYellow"

                }}
            />
            <SickSection
                extraSecName="affirmationMoney"
                lightBg="lightBg"
                sectionCont={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6 col-xl-7 text-center text-lg-start">
                            <h2 className="h2 fw400 anton text-uppercase additionalBonus textDark mb-3 mb-lg-4">What Financial Problems Will be Solved With
                                <span className="gradientText"> Money Magnet Affirmations?</span>
                            </h2>
                            <ul className='resetUl'>
                                {
                                    moneyMagnet1?.map((item, idx) =>
                                        <li className="d-flex align-items-center gap-2 mb-2">
                                            <img src={item.imgIcon} alt="#" loading="lazy" className="circleTick" />
                                            <p className="textDark fw500 roboto mb-0 pt-1 text-start">{item.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                            {/* <h3 className="h2 fw400 anton text-uppercase additionalBonus textDark mt-5 mb-3 mb-lg-4">
                                Is This <span className="gradientText">You?</span>
                            </h3>
                            <ul className='resetUl'>
                                {
                                    moneyMagnet2?.map((item, idx) =>
                                        <li className="d-flex align-items-center gap-2 mb-2">
                                            <img src={item.imgIcon} alt="#" loading="lazy" className="circleTick" />
                                            <p className="textDark fw500 roboto mb-0 pt-1 text-start">{item.desc}</p>
                                        </li>
                                    )
                                }
                            </ul> */}
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-3 border-0 mt-2 mt-lg-5" onClick={() => handleScroll()}>
                                    Download PDF
                                </button>
                            </Link>
                        </div>
                        <div class="col-lg-6 col-xl-5">
                            <div class="instructorVideo ms-auto">
                                <img src="/assets/images/rahul/addition-money-bonus-1.webp" alt="#" loading="lazy" className="w100 h100" />
                            </div>

                        </div>

                    </div>
                }
            />
            <MasterclassBgImg
                fullWidthImg={
                    <img src="/assets/images/rahul/is-this-you-bg-web.webp" alt="#" loading='lazy' className='w100 h100' />
                }
                mobImg={
                    <img src="/assets/images/rahul/is-this-you-bg-mob.webp" alt="#" loading='lazy' className='w100 h100' />
                }
            />
            <SilvaFounder
                lightBg=""
                heading={
                    <>
                        <h2 class="h2 fw400 anton textDark mb-3 text-center text-uppercase additionalBonus mdH2">
                            Our <span className="gradientText">Free Money Magnet Affirmations PDF</span> Can Resolve Your Financial Status
                        </h2>
                        <p class="title fw200 anton textDark text-center mb-4 mb-lg-5">
                            Contents of the <span className="gradientText">Free Affirmations PDF</span>
                        </p>
                    </>
                }
                textCont={
                    <>
                        <ul className='resetUl affirmationsPDF'>
                            {
                                affirmationsPoints?.map((items, idx) =>
                                    <li className="d-flex align-items-center gap-3 mb-2">
                                        <img src={items.imgIcon} alt="#" loading="lazy" className="circleTick" />
                                        <p className="textDark fw500 roboto mb-0 pt-1">{items.desc}</p>
                                    </li>
                                )
                            }
                        </ul>
                    </>
                }
                imgCont={
                    <div class="videoArea m-auto">
                        <img src="/assets/images/rahul/finance-insructor.webp" alt="#" loading="lazy" class="w100 h100" />
                    </div>
                }
                imgBtmText={
                    <div className='bgImgProp rounded-4 px-4 pt-2 pb-4 mt-4' style={{ backgroundImage: "url('/assets/images/rahul/money-management-bg.webp')" }}>
                        {/* <h2 className="h2 fw400 p1 textDark anton mt-4 mb-4 mb-lg-5 text-center text-uppercase m-auto fst-italic additionalBonus">
                            To learn more, join our exclusive <span className="gradientText pe-3 d-inline-block">“Money Management”</span> masterclass today!
                        </h2> */}
                        <p className="mdFont fw400 p1 primaryWhite anton mt-4 mb-4 text-center text-uppercase m-auto additionalBonus">
                            To learn more, join our exclusive “Money Management” masterclass today!
                        </p>
                        <div className="text-center">
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 border-0" onClick={() => handleScroll()}>
                                    Join Now
                                </button>
                            </Link>
                        </div>
                    </div>
                }
            />
            <DynamicFaqs
                title='Frequently Asked Questions'
                fontStyle=""
                defaultactive={0}
                data={faqData}
            />
        </>
    )
}

export default FinancesLead