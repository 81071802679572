import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Alert, Table } from 'react-bootstrap';

function Result({resultData}) {

  const detectRes=(point)=>{
    if(point>=5 && point <=10){
      return "Low EQ"
    };
    if(point>=11 && point <=15){
      return "Below Average EQ"
    };
    if(point>=16 && point <=20){
      return "Average EQ"
    };
    if(point>=21 && point <=25){
      return "Above Average EQ"
    };
    if(point>=26 && point <=30){
      return "High EQ"
    };
    
  }
  return (
    <div className="d-flex">
      <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Stats</button>
        <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">QNA</button>
        {/* <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</button>
        <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button> */}
      </div>
      <div className="tab-content" id="v-pills-tabContent">
        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Your final score</th>
            </tr>
          </thead>
      <tbody>
        <tr>
          <td>Score:{resultData.reduce((acc,next)=> acc+ next.point,0)}</td>
         
        </tr>
        <tr>
          <td>EQ Level: {detectRes(resultData.reduce((acc,next)=> acc+ next.point,0))}</td>
        </tr>
      </tbody>
    </Table>
        </div>
        <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        <div className='d-flex justify-content-center align-items-center'>
            <div className='container'>
                <div>
                    <h5 className='my-3'></h5>
                    <Alert variant={'primary'}>
                      Selected Answers
                    </Alert>
                    <ul className="list-unstyled">
                        {resultData?.map((res, i) => (
                            <li key={i}>
                                <strong>{res.question}</strong>
                                <p>{res.answer}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            </div>
        </div>
        {/* <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">Messages content...</div>
        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">Settings content...</div> */}
      </div>
    </div>
  );
}

export default Result;
