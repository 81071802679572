import React, { useEffect } from 'react'
import '../components/TermsStyle.css'
import Newnavbar from '../components/Newnavbar'
import MasteryHero99 from '../components/MasteryHero99'
import Footer2 from '../components/Footer2'

const Terms = () => {


    useEffect(() => {
        const docs = document.querySelector(".navSection");
        if (docs) {
            docs.scrollIntoView({
                behavior: "smooth"
            })
        }
    }, [])



    return (
        <>
            <Newnavbar />
            <MasteryHero99
                heroSection="termsPage"
                mobBanner="/assets/images/rahul/terms-banner-mobile.webp"
                tabBanner="/assets/images/rahul/terms-banner-web.webp"
                webBanner="/assets/images/rahul/terms-banner-web.webp"
                heroTextCont={
                    <h1 class="h1 poppins primaryWhite text-center mb-0">
                        Silva Method India Terms & Conditions
                    </h1>
                }
            />
            <div className='termsCont ptop'>
                <div className="container">
                    <h2 className='h2 textDark poppins fw700'>
                        Terms and Conditions
                    </h2>
                    <p className='textDark poppins mb-5'>
                        By using this website, you are verifying that you have read this agreement and that you agree
                        to comply with all terms, conditions, policies, and notices stated here. By visiting our website
                        or purchasing something, you agree to engage in our “service” and agree to be bound by the
                        rules and conditions. If you do not agree to the terms of the agreement, you are requested to
                        stop using the website and cannot access the website any further.
                    </p>
                    <h3 className='title textDark poppins fw600'>Age restriction</h3>
                    <p className='textDark poppins mb-5'>
                        You are allowed to use this website only if you are over 18 years of age. By using this
                        website, you are indicating that you are 18 years of age and agree to this agreement.
                    </p>
                    <h3 className='title textDark poppins fw600'>Changes in subjects and prices</h3>
                    <p className='textDark poppins mb-5'>
                        We have the right to modify the content covered in the course as well as adjust the prices for
                        enrollment. We reserve the right to change the description of the course and pricing at any
                        time without notice, at our sole discretion.
                    </p>
                    <h3 className='title textDark poppins fw600'>Personal information</h3>
                    <p className='textDark poppins mb-5'>
                        As a user, you are required to register with us and provide personal information. You have
                        the responsibility to ensure accurate information. It is important to keep everything
                        confidential, respect everyone’s privacy, and maintain the security of all activities occurring
                        under your account.
                    </p>
                    <h3 className='title textDark poppins fw600'>Inaccurate information and errors</h3>
                    <p className='textDark poppins mb-5'>
                        There may be some information on our site or in the subject that contains unintentional errors
                        or typing mistakes that are related to the subject descriptions, pricing, timing availability, etc.
                        We reserve the right to rectify any errors or inaccurate information and update the
                        information about any subject related to the website at any time.
                    </p>
                    <h3 className='title textDark poppins fw600'>Updates in terms and conditions</h3>
                    <p className='textDark poppins mb-3'>
                        You can check the latest update of the terms and conditions at any time on this page.
                    </p>
                    <p className='textDark poppins mb-5'>
                        We hold the right of sole discretion, where we are allowed to update and change any terms
                        and policies by posting an update on our website. You are requested to check our website
                        regularly to keep up with the latest changes.
                    </p>
                </div>
            </div>
            <Footer2 />
        </>
    )
}

export default Terms