import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';


function CustomDropdown({ dates, selectedDateTime, setSelectedDateTime }) {
    const handleSelect = (eventKey) => {
        const selected = JSON.parse(eventKey);
        setSelectedDateTime(selected);
    };

    return (
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle id="dropdown-basic" style={{ width: "100%", background: "transparent", color: "black", border: "none", textAlign: "left" }}>
                <span>
                    {selectedDateTime ?
                        (
                            <>
                                <span style={{ marginRight: "20px", fontWeight: '600', color: '#000' }}>{selectedDateTime.date}</span>
                                <span style={{ fontWeight: '600', color: '#000' }}>{selectedDateTime.time}</span>
                            </>
                        ) :
                        (
                            <>
                                <span style={{ fontWeight: '600', color: '#000' }}>Select Your Preferred Event Date</span>
                            </>
                        )
                    }
                </span>
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: "100%", padding: "0", marginTop: "0" }}>
                {dates.map((item, idx) => (
                    <Dropdown.Item
                        key={idx}
                        eventKey={JSON.stringify(item)}
                        style={{
                            display: "flex",
                            columnGap: "40px",
                        }}
                    >
                        <span style={{ fontWeight: '600' }}>{item.date}</span>
                        <span style={{ fontWeight: '600' }}>{item.time}</span>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CustomDropdown;