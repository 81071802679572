import React, { useContext } from "react";
import "../components/FAQStyle.css";
import "./SlsFaqsStyle.css";
import { Accordion } from "react-bootstrap";
import { Appcontext } from "../context/appcontext";

function DynamicFaqs({
  title = "জিজ্ঞাসিত কিছু প্রশ্ন।",
  defaultactive,
  data,
  fontStyle = "poppins"
}) {
  const { isMobile } = useContext(Appcontext)


  return (
    <div className={`lightBg ${isMobile ? "ptop" : ""}`}>
      <section className="faqSection slsFaqsPadding">
        <div className="faqCont">
          <div className="headingArea text-center mb-4">
            <h2 className={`h2 fw700 textDark ${fontStyle}`}>{title}</h2>
          </div>
          <Accordion defaultActiveKey={defaultactive || 0}>
            {data?.map((item, i) => (
              <Accordion.Item eventKey={i}>
                <Accordion.Header className={fontStyle}>{item.title}</Accordion.Header>
                <Accordion.Body className={fontStyle}>
                  {item.text}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </section>
    </div>
  );
}

export default DynamicFaqs;
