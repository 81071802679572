import React from 'react'
import "./CoursesListStyle.css";
import EnrollImage1 from "../assets/enroll-img-1.webp"
import EnrollImage2 from "../assets/enroll-img-2.webp"
import EnrollImage3 from "../assets/enroll-img-3.webp"
import EnrollImage4 from "../assets/enroll-img-4.webp"
import EnrollImage5 from "../assets/enroll-img-5.webp"
import EnrollImage6 from "../assets/enroll-img-6.webp"
import EnrollImage7 from "../assets/enroll-img-7.webp"



const Courses = () => {
  return (
    <div>
      <section className="coursesSection ptop pbtm">
        <div className="container">
          <div className="coursesCont text-center">
            <p className="fw700 poppins textDark shapeTrape2">
              Silva Method
            </p>
            <h2 className="h2 fw700 poppins textDark text-capitalize mb-3">
              original mind control <span className="gradientText">courses</span>
            </h2>
            <p className="fw600 poppins textDark">
              Equip Yourself with a Unique Set of Skills and Reach New Horizons of Success through Silva Method Mind Control Courses
            </p>
            <div className="row justify-content-center cardRowGap">
              <div className="col-lg-4 col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="imgCont">
                      <img src={EnrollImage1} alt="#" loading="lazy" className="w100 h100" />
                      <div className="priceWidget">
                        <p className="oldPrice primaryWhite mb-0">
                          <span className="me-1">₹</span>
                          <span className="fw600 roboto">20,999</span>
                        </p>
                        <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-2">
                          <span className="me-1">₹</span>
                          <span className="fw600 roboto">9,999</span>
                        </p>
                        <p className="fw600 poppins primaryWhite discountTag mb-0">
                          50% Discount
                        </p>
                      </div>
                    </div>
                    <h3 className="title fw700 poppins textDark mt-4 mb-3">
                      Basic Lecture Series(in English)
                    </h3>
                    <p className="poppins textDark shapeRect lightTrape">
                      4 Days Online Seminar
                    </p>
                    <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                      coming soon
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="imgCont">
                      <img src={EnrollImage1} alt="#" loading="lazy" className="w100 h100" />
                      <div className="priceWidget">
                        <p className="oldPrice primaryWhite mb-0">
                          <span className="me-1">₹</span>
                          <span className="fw600 roboto">20,999</span>
                        </p>
                        <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-2">
                          <span className="me-1">₹</span>
                          <span className="fw600 roboto">9,999</span>
                        </p>
                        <p className="fw600 poppins primaryWhite discountTag mb-0">
                          50% Discount
                        </p>
                      </div>
                    </div>
                    <h3 className="title fw700 poppins textDark mt-4 mb-3">
                      Basic Lecture Series(in Bengali)
                    </h3>
                    <p className="poppins textDark shapeRect lightTrape">
                      4 Days Online Seminar
                    </p>
                    <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                      coming soon
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="imgCont">
                      <img src={EnrollImage3} alt="#" loading="lazy" className="w100 h100" />
                      <div className="priceWidget">
                        <p className="mdFont fw600 roboto primaryWhite mb-0">Free</p>
                      </div>
                    </div>
                    <h3 className="title fw700 poppins textDark mt-4 mb-3">
                      Silva Method Introductory Webinar
                    </h3>
                    <p className="poppins textDark shapeRect lightTrape">
                      4 Days Online Seminar
                    </p>
                    <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                      coming soon
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="imgCont">
                      <img src={EnrollImage7} alt="#" loading="lazy" className="w100 h100" />
                      <div className="priceWidget">
                        <p className="title fw600 roboto primaryWhite mb-0">Customized <br></br> Pricing</p>
                      </div>
                    </div>
                    <h3 className="title fw700 poppins textDark mt-4 mb-3">
                      Silva Method For Family and Children
                    </h3>
                    <p className="poppins textDark shapeRect lightTrape">
                      4 Days Online Seminar
                    </p>
                    <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                      coming soon
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="imgCont">
                      <img src={EnrollImage6} alt="#" loading="lazy" className="w100 h100" />
                      <div className="priceWidget">
                        <p className="mdFont fw600 roboto primaryWhite mb-0">Free</p>
                      </div>
                    </div>
                    <h3 className="title fw700 poppins textDark mt-4 mb-3">
                      Silva Graduate Meet
                    </h3>
                    <p className="poppins textDark shapeRect lightTrape">
                      4 Days Online Seminar
                    </p>
                    <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                      coming soon
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="trapeCardItem">
                  <div className="cardItem">
                    <div className="imgCont">
                      <img src={EnrollImage5} alt="#" loading="lazy" className="w100 h100" />
                      <div className="priceWidget">
                        <p className="oldPrice primaryWhite mb-0">
                          <span className="me-1">₹</span>
                          <span className="fw600 roboto">11,000</span>
                        </p>
                        <p className="title fw600 roboto primaryWhite d-flex align-items-center justify-content-center mb-2">
                          <span className="me-1">₹</span>
                          <span className="fw600 roboto">5,500</span>
                        </p>
                        <p className="fw600 poppins primaryWhite discountTag mb-0">
                          50% Discount
                        </p>
                      </div>
                    </div>
                    <h3 className="title fw700 poppins textDark mt-4 mb-3">
                      Silva Life System(in Bengali)
                    </h3>
                    <p className="poppins textDark shapeRect lightTrape">
                      4 Days Online Seminar
                    </p>
                    <a href="#" className="btnMain btnSec fw600 gradientBg primaryWhite text-capitalize">
                      coming soon
                    </a>
                  </div>
                </div>
              </div>
              {/* Repeat the same structure for other courses */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Courses