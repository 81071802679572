import React, { useEffect, useState } from "react";
import "../../components/HeroSection_CoursesStyle.css";
// import HeaderBGImg from "../../../assets/course-header.webp"
import CardThumbImgDef from "../../assets/card-thumb-img.webp";
import CheckDarkImg from "../../assets/check-dark.svg";
import toast from "react-hot-toast";
import useRazorpay from "react-razorpay";
import Swal from "sweetalert2";
import { validatePhoneNumber } from "../../utils/Helper";
// import HeaderMobileBGImg from "../../../assets/courses-header-for-mobile-tab.webp"
// import HeaderTabBGImg from "../../../assets/courses-header-for-mobile-tab.webp"

const SlsHeroSection = ({
  course_title,
  course_description,
  btnCta,
  BtnClick,
  CardThumbImg,
  discountPrice,
  originalPrice,
  discount_percentage,
  star,
  ratingCount,
  perksData,
  card,
  buyBtnCTA,
  discount_text,
  perksTitle,
  ratingText,
  coursePriceBtnText,
  btnShow,
  extraClass,
  showForm
}) => {

  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: ''

  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // code for form data store------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email) {
      setError('All fields are required');
      return;
    }
    const isValid = validatePhoneNumber(formData.mobile)
    if (!isValid) {
      return toast.error("Please enter a valid Phone Number")
    }
    try {

      const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-sls-paid-lead', {
        method: "POST",
        body: JSON.stringify({
          "name": formData.fname,
          "email": formData.email,
          "whatsapp_number": formData.mobile

        }),
        headers: {
          "Content-Type": "application/json",
        }
      });

      await handlePayment(formData);
      setFormData({
        fname: '',
        mobile: '',
        email: '',

      });
      setError('');
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };


  // code for button active/inactive----------------------------------------------------
  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.querySelector(".btnSubmit");
    if (btn) {
      if (!isFormFilled) {
        btn.style.opacity = 0.4
      } else {
        btn.style.opacity = 1
      }
    }
  }, [isFormFilled])


  // ------------code for payment--------------------------------
  const [Razorpay] = useRazorpay();
  const handlePayment = async () => {

    try {
      const createOrder = await fetch('https://silvamethod.in:5000/api/v1/orders/create-order', {
        method: "POST",
        body: JSON.stringify({
          product_id: "SLS-ENG-5500",
          user_id: `${formData.fname}-${formData.email}-${formData.mobile}`,
          purchase_type: "course"
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });
      const order = await createOrder.json();
      // console.log(order,"order created") 

      if (order?.order_id) {
        var options = {
          "key": process.env.REACT_APP_RAZORPAY_KEY,
          name: "Payment",
          description: "Payment Method",
          image: "https://silvamethod.com/img/logo/title-logo-removebg-preview.png",
          order_id: order?.order_id,
          handler: async function (response) {
            if (response?.razorpay_payment_id) {
              const capturePayment = await
                //   Apirequests("orders/capture-order","POST",{
                //   "paymentId": response?.razorpay_payment_id,
                //  },{"Content-Type": "application/json"});

                fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                  method: "POST",
                  body: JSON.stringify({
                    "paymentId": response?.razorpay_payment_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              // console.log(capturePayment,'captureRes')

              const captureRes = await capturePayment?.json();
              console.log(captureRes, 'captureRes')
              console.log(response, 'Response')
              if (captureRes?.success) {
                // toast.success("Payment Successful",{
                //   position:"bottom-center"
                // });

                console.log("pay success")
                window.location.href = '/payment-successful';
              }
            }
          },
          "modal": {
            "ondismiss": async function () {
              // console.log(res,"failed response");
              const capt = await
                // Apirequests("orders/capture-failed-payment","POST",{
                //   "order_id": order?.order_id,
                // },{ "Content-Type": "application/json"})

                fetch('https://silvamethod.in:5000/api/v1/orders/capture-failed-payment', {
                  method: "POST",
                  body: JSON.stringify({
                    "order_id": order?.order_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              const captRes = await capt.json();
              console.log(capt, 'failed res')

              if (capt?.success) {
                // toast.error("Payment Failed",{
                //   position:"bottom-center"
                // })
                console.log("payment failed")
              }
            }
          },
          prefill: {
            name: formData.fname,
            email: formData.email,
            contact: formData.mobile,
          },
          notes: {
            address: "The Silva Method India",

          },
          theme: {
            color: "#A500FF",
          },
        }

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", async function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);

          if (response?.error?.reason === "payment_failed") {
            const capturePayment = await
              // Apirequests("orders/capture-order","POST",{
              //   "paymentId": response?.error?.metadata?.payment_id,
              //  },{"Content-Type": "application/json"});
              fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                method: "POST",
                body: JSON.stringify({
                  "paymentId": response?.error?.metadata?.payment_id,
                }),
                headers: {
                  "Content-Type": "application/json",
                }
              });
            if (capturePayment?.success) {
              const modalElement = document.querySelector('.razorpay-container');
              if (modalElement) {
                modalElement.style.display = 'none';
              }

            }
            window.location.href = '/payment-failure';
          }

        });

        // rzp1.open();
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Caution",
          text: "After successful payment please wait until you go to the payment success page. DO NOT close or refresh the page before that.",
          timer: 6000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        setTimeout(() => {
          rzp1.open();
        }, 6000)
      }
      // setApiOrder(order); 
    } catch (error) {
      console.log("error:", error);
    }


    // console.log(process.env.REACT_APP_RAZORPAY_KEY,"payment key")
  };

  return (
    <div>
      <section className="heroSection lightBg position-relative coursePage sls">
        {/* <img src={HeaderBGImg} className="d-block w-100" alt="#" loading="lazy" /> */}

        <img
          src={"/assets/assets/img/sls/sls-15.webp"}
          class="d-md-none d-block w-100"
          alt="#"
          loading="lazy"
          style={{ height: "700px", objectFit: "cover" }}
        />
        <img
          src={"/assets/assets/img/sls/sls-15.webp"}
          class="d-none d-md-block d-lg-none w-100"
          alt="#"
          loading="lazy"
          style={{ height: "700px", objectFit: "cover" }}
        />
        <img
          src={"/assets/assets/img/sls/sls-15.webp"}
          class="d-none d-lg-block w-100 bannerLg"
          alt="#"
          loading="lazy"
          style={{ height: "1000px", objectFit: "cover" }}
        />

        <div className="sls carousel-caption">
          <div className="container">
            <div className="d-flex justify-content-between totalContArea">
              <div className="heroCont">
                <h1 className={`h1 fw700 poppins ${extraClass}`}>{course_title}</h1>
                <p className="desc fw300 roboto">{course_description}</p>

                <button
                  className="btnMain primaryBlack primaryYellow text-capitalize shapeTrape border-0 mb-5"
                  onClick={BtnClick}
                >
                  {btnCta}
                </button>
                {card}
              </div>

              <div className="rightCard">
                <div className="thumbImg">
                  <img
                    src={CardThumbImg || CardThumbImgDef}
                    alt="#"
                    loading="lazy"
                    className="w-100 h100"
                  />
                </div>
                <p className="fw500 roboto textDark shapeRect lightTrape mt-3 mb-2">
                  {coursePriceBtnText || "Course Price"}
                </p>
                <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mb-4 mb-md-2">
                  <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                    <span className="me-1">₹</span>
                    <span className="roboto">
                      {discountPrice}{" "}
                      {course_title === "Silva Life System" ? "(INR)" : ""}
                    </span>
                  </p>
                  {course_title === "Silva Life System" && (
                    <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                      <span className="me-1">₹</span>
                      <span className="roboto">
                        {"7500"}{" "}
                        {course_title === "Silva Life System" ? "(BDT)" : ""}
                      </span>
                    </p>
                  )}
                  {originalPrice && <p className="p1 fw900 oldPrice textLight mb-0">
                    <span className="me-1">₹</span>
                    <span className="roboto">{originalPrice}</span>
                  </p>}
                  {discount_percentage && (
                    <p className="fw600 roboto textDark mb-0">
                      You will get {discount_percentage}% Off
                    </p>
                  )}
                  {discount_text && (
                    <p className="fw600 roboto textDark mb-0">
                      {discount_text}
                    </p>
                  )}
                </div>
                <div className="d-flex align-items-center flex-wrap mb-4">
                  <p className="title fw700 poppins textDark mb-0">{star}</p>
                  <div className="d-flex align-items-center ratingStarIcon ms-2 me-4">
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star</span>
                    <span className="material-symbols-outlined">star_half</span>
                  </div>
                  {ratingCount ? <p className="fw600 roboto textDark mb-0">
                    ({ratingCount} ratings)
                  </p> : null}
                  {ratingText ? <p className="fw600 roboto textDark mb-0">
                    ({ratingText} ratings)
                  </p> : null}
                </div>
                <h3 className="fw700 poppins textDark mb-3 h5">
                  {perksTitle || "Unlock Unlimited Perks"}
                </h3>
                <ul className="resetUl unlockList">
                  {perksData &&
                    perksData.map((perks, i) => (
                      <li className="d-flex align-items-center gap-2 mb-2">
                        <img src={CheckDarkImg} alt="#" loading="lazy" />
                        <p className="textDark roboto mb-0">{perks}</p>
                      </li>
                    ))}
                </ul>
                {
                  showForm ?
                    <div className="mainCoursePayForm">
                      <p className="mdFont fw700 poppins textDark mb-0">
                        Pay <span className="gradientText">Now</span>
                      </p>
                      <form onSubmit={handleSubmit}>
                        {error && <div className="error">{error}</div>}
                        <div className="formCont">
                          <div className="row gy-3">
                            <div className="col-md-12">
                              <input type="text" className="form-control" name="fname" value={formData.fname} onChange={handleChange} placeholder="Full Name" required />
                            </div>
                            <div className="col-md-6">
                              <input type="text" className="form-control" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Whatsapp Number" required />
                            </div>
                            <div className="col-md-6">
                              <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
                            </div>
                          </div>
                          <div className="text-center mt-4">
                            <button type="submit" className="text-capitalize fw700 primaryBlack btnPulse btnSubmit" style={{ opacity: 0.4 }}>
                              Register Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    :
                    null
                }

                <div className="text-center mt-5">

                  {btnShow && <div class="text-center">
                    <button
                      type="button"
                      class="text-capitalize fw700 primaryBlack btnPulse"
                    >
                      {buyBtnCTA || "Coming Soon"}
                    </button>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SlsHeroSection;
