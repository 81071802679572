import React from 'react'
import "../../components/FAQStyle.css";


const SlsFaqs = ({faqsData}) => {
  return (
    <div>
        <section className="faqSection lightBg">
      <div className="faqCont">
        <div className="headingArea text-center mb-4">
          <p className="title fw600 poppins textDark mb-2">
            Frequently asked questions
          </p>
          <h2 className="h2 fw700 poppins textDark text-capitalize">
            about <span className="gradientText">silva method</span>
          </h2>
        </div>
        <div className="accordion" id="accordionExample">
            {/* {
                faqsData?.map((faq,i) =>
                    <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    {faq?.header}
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                   {faq?.text}
                  </div>
                </div>
              </div> 
                )
            } */}
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                What is the Silva Life System?
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample">
              <div className="accordion-body">
              The Silva Life System is the starting point of the complete program. It teaches you basic skills in meditation, visualization, making your desires come true, healing, and more. These are simple and practical techniques that you can use right away in your daily life.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Does the Silva Method really work?
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
              The Silva Method can be a helpful tool for reducing stress, improving focus, and boosting self-confidence. The effectiveness of this method varies from person to person. Over 6 million people in 120 countries have experienced the power of the Silva Method Meditation.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                What are the benefits of the Silva Method?
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
              The benefits of practicing the Silva Method are as numerous as your imagination. By mastering the art of tapping into your subconscious mind, you can effectively alleviate stress and anxiety, enhance your concentration and sleep patterns, facilitate self-healing and assist others, eradicate feelings of depression and headaches, and ultimately attain greater success in all aspects of your life.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Does The Silva Life System course teach healing?
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
              The Silva Life System offers essential techniques to enhance your ability to address physical and emotional issues, alleviate headaches, boost memory, and enhance sleep quality. It includes simple step-by-step exercises to help you apply your learning to your life immediately.
              </div>
            </div>
          </div>
          {/* <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Is meditation really that important?
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes. Meditation is crucial for health and well-being, countering stress, the main cause
                of illness. Whether you call it relaxation, centering, or prayer with intent, daily
                practice strengthens the connection between body, mind, spirit, and emotions.
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
    </div>
  )
}

export default SlsFaqs