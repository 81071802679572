import React from 'react'
import "./ThankYouBodyStyle.css";
import { useNavigate } from 'react-router-dom';

const ThankYouPageBody = ({contentArea}) => {

    const navigate = useNavigate(); // Define navigate here

    const handleDownloadButtonClick1 = () => {
        // Redirect to the lead page
        navigate('/lead-page1');
    };

    const handleDownloadButtonClick2 = () => {
        // Redirect to the lead page
        navigate('/lead-page');
    };


    return (
        <section className="thankYouPage bgImgProp lightBg ptop pbtm ">
            <div className="container">
                {contentArea}
            </div>
        </section>
    )
}

export default ThankYouPageBody