import React from 'react'
import "./ThankYouBodyStyle.css";
import HeartImg from "../assets/heart-tick-01.svg"
import { useNavigate } from 'react-router-dom';

const ThankYouBody = () => {
  
  const navigate = useNavigate(); // Define navigate here

  const handleDownloadButtonClick1 = () => {
    // Redirect to the lead page
    navigate('/lead-page1');
  };

  const handleDownloadButtonClick2 = () => {
    // Redirect to the lead page
    navigate('/lead-page');
  };



  return (
    <div><section className="thankYouPage bgImgProp lightBg ptop pbtm ">
    <div className="container">
      <div className="pageCont">
        <div className="heartTick">
          <img src={HeartImg} alt="#" loading="lazy" className="w100 h100" />
        </div>
        <h2 className="h2 fw700 poppins textDark text-center mb-4">
          You Have Successfully <span className="gradientText">Registered!</span>
        </h2>
        <p className="desc fw500 textDark poppins">
          This is our official confirmation. Thanks for registering for this masterclass. Soon you’ll
          receive a mail with the Zoom link and a purchase receipt in your registered email ID,
          don’t forget to check your inbox. If you have any further queries you can connect with
          our team. You can mail us at: <a href="#" className="textDark fw700">silvaindiaofficial@gmail.com</a> or
          contact us via WhatsApp at 6290945413.
        </p>
        <p className="mdFont textDark fw700 mb-4 text-center poppins">Claim Your Bonuses</p>
        <div className="row justify-content-center gy-4">
          <div className="col-12">
            <div className="bonusCard row-gap-2">
              <div className="bonusCont d-flex align-items-center row-gap-2 flex-wrap">
                <p className="fw600 primaryWhite poppins mb-0 p1">Bonus #1</p>
                <p className="fw400 textDark roboto m-0 p2">A free guided meditation for resolving life's issues.</p>
              </div>
              <div className="bonusBtn">
                <button type="button" onClick={handleDownloadButtonClick1} className="btnMain btnSec fw700 primaryYellow primaryBlack text-capitalize border-0">
                  download
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="bonusCard row-gap-2">
              <div className="bonusCont d-flex align-items-center row-gap-2 flex-wrap">
                <p className="fw600 primaryWhite poppins mb-0 p1">Bonus #2</p>
                <p className="fw400 textDark roboto m-0 p2">Download a complimentary workbook to overcome life challenges.</p>
              </div>
              <div className="bonusBtn">
                <button type="button" onClick={handleDownloadButtonClick2} className="btnMain btnSec fw700 primaryYellow primaryBlack text-capitalize border-0">
                  download
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="bonusCard row-gap-2">
              <div className="bonusCont d-flex align-items-center row-gap-2 flex-wrap">
                <p className="fw600 primaryWhite poppins mb-0 p1">Bonus #3</p>
                <p className="fw400 textDark roboto m-0 p2">
                Upon successful completion of this masterclass, you'll receive a recognition Certificate from The Silva Method India.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="bonusCard row-gap-2">
              <div className="bonusCont d-flex align-items-center row-gap-2 flex-wrap">
                <p className="fw600 primaryWhite poppins mb-0 p1">Bonus #4</p>
                <p className="fw400 textDark roboto m-0 p2">
                  Within a few days after the masterclass, you will get all the details about the review call session in your registered email ID.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}

export default ThankYouBody