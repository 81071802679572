import React from 'react'
import Navbar2 from '../components/Navbar2'
import LeadHeroOne from '../components/LeadHeroOne'
import Quote from '../components/Quote'
import AllAbout from '../components/AllAbout'

const LeadPageOne = () => {
  return (
    <div>
         <Navbar2/>
        <LeadHeroOne/>
        <Quote/>
        <AllAbout/>
    </div>
  )
}

export default LeadPageOne