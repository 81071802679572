import React, { useContext, useEffect, useState } from 'react'
import CheckImg from "../assets/check-bg.svg"
import Navbar2 from '../components/Navbar2'
import MasteryHero99 from '../components/MasteryHero99'
import ReactPlayer from 'react-player';
import { Appcontext } from '../context/appcontext';
import VideoBtmSection from '../components/VideoBtmSection';
import ClassAgenda from '../components/ClassAgenda';
import SickSection from '../components/SickSection'
import LiveClassandWorkshop from '../components/LiveClassandWorkshop'
import MasterclassBgImg from '../components/MasterclassBgImg'
import HappyLifeSection from '../components/HappyLifeSection'
import DynamicFaqs from '../components/DynamicFaqs'
import SpecialRewards from '../components/SpecialRewards'
import Footer2 from '../components/Footer2'
import MoneyMagnetForm from '../components/MoneyMagnetForm';
import { handleScroll } from '../utils/animPulse'
import { Link } from 'react-scroll';
const InstructorVideo = "https://vz-6873ed28-3a2.b-cdn.net/de0aaca8-642a-4b9d-a356-faf0b9ac4f8c/playlist.m3u8"


const MoneyMagnet = () => {
    const [playing, setPlaying] = useState(false)
    const { isMobile } = useContext(Appcontext)


    const financialPoints = [
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Debt Management:</strong> Effective strategies to manage and reduce debt and
                eliminate financial stress.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Money Management:</strong> Optimize your income and expenses and achieve
                financial stability.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Income Generation:</strong> Increase your earning potential and achieve greater
                financial freedom and security.</p>,
        },
        {
            icon: CheckImg,
            desc: <p style={{ margin: 0 }}><strong>Mindset Shift:</strong> Develop a positive and abundance-oriented mindset through
                the Silva Method and overcome limiting beliefs.</p>,
            hidemargin: true
        },
    ]


    const workshopPoints = [
        {
            icon: CheckImg,
            desc: `Master the art of manifesting Peaceful Money`,
        },
        {
            icon: CheckImg,
            desc: `How to achieve Financial Freedom`,
        },
        {
            icon: CheckImg,
            desc: `Learn to Sustain your hard-earned wealth`,
        },
        {
            icon: CheckImg,
            desc: `Overcoming Fears of Making Money`,
        },
        {
            icon: CheckImg,
            desc: `Powerful Techniques of Money Manifestation`,
        },
        {
            icon: CheckImg,
            desc: `Affirmations for Financial Success`,
        },
        {
            icon: CheckImg,
            desc: `Adjust Your Money Thermostat`,
        },
        {
            icon: CheckImg,
            desc: `Overcoming Envy, Jealousy, and Insecurity`,
        },
        {
            icon: CheckImg,
            desc: `Learn The Arigato Principle`,
        },
    ]


    const liveClassImgs = [
        {
            id: 1,
            imgUrl: '/assets/images/rahul/Artboard 1.webp'
        },
        {
            id: 2,
            imgUrl: '/assets/images/rahul/Artboard 2.webp'
        },
        {
            id: 3,
            imgUrl: '/assets/images/rahul/Artboard 3.webp'
        },
        {
            id: 4,
            imgUrl: '/assets/images/rahul/Artboard 4.webp'
        },
        {
            id: 5,
            imgUrl: '/assets/images/rahul/Artboard 5.webp'
        },
        {
            id: 6,
            imgUrl: '/assets/images/rahul/Artboard 6.webp'
        },

    ]


    const faqData = [
        {
            id: 1,
            title: `Q. Why are we struggling for money? `,
            text: `Ans. Our 90% of people block their minds in childhood. We create fear of money, 
                    doubt, etc.`
        },
        {
            id: 2,
            title: `Q. How do we know that we have a money block?`,
            text: `Ans. When we experience our needs and resources are not balanced, we are 
                    suffering continuously, which means we are in a money block.`
        },
        {
            id: 3,
            title: `Q. What is the actual meaning of a money mindset?`,
            text: `Ans. A money mindset refers to the beliefs, attitudes, and perspectives individuals 
                    hold about money and wealth. It encompasses the conscious and subconscious thoughts that influence financial decisions and behaviors.`
        },
        {
            id: 4,
            title: `Q. How can we earn more money easily?`,
            text: `Ans. Need to learn the Proper mindset, proper skill, and perfect action. 
                    The Silva Method teaches you how to use more power of your mind and earn peaceful money easily. Step-by-step formula.`
        },
        {
            id: 5,
            title: `Q. How can we achieve financial freedom?`,
            text: `Ans. When you learn, how your money works for you, how people are working for 
                    you. When you learn the auto income-generating system. Need Silva Mind training.`
        },
        {
            id: 6,
            title: `Q. How can we sustain our money?`,
            text: `Ans. Need to learn the law of giving, Money and your Thought process, Money and 
                    your emotion, Money and your feelings.`
        },
        {
            id: 7,
            title: `Q. How do we overcome our money crisis?`,
            text: `Ans. Learn to use how we can alter our mindset, need to learn Alpha-level practice.  
                    ALPHA LEVEL gives you all the solutions to a money life.`
        },
    ]

    useEffect(() => {
        const docs = document.querySelector(".navSection");
        if (docs) {
            docs.scrollIntoView({
                behavior: "smooth"
            })
        }
    }, [])




    return (
        <div style={{ backgroundImage: "url('/assets/images/rahul/line-bg.jpg')", backgroundSize: "contain", backgroundPosition: "center" }}>
            <Navbar2 />
            <MasteryHero99
                heroSection="moneyMagnet"
                mobBanner="/assets/images/rahul/eng-money-magnet-mob.webp"
                tabBanner="/assets/images/rahul/eng-money-magnet-web.webp"
                webBanner="/assets/images/rahul/eng-money-magnet-web.webp"
                heroTextCont={
                    <>
                        <h1 class="h1 fw400 anton primaryWhite mb-3 mb-lg-4">
                            Become a Money Magnet and Attract Wealth Like Millionaires
                        </h1>
                        <h2 className='h2 primaryWhite poppins mb-0 mb-lg-3'>
                            <span className='mdFont fw300 me-2 first'>Master effective</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                money management skills
                            </span>
                            <span className='mdFont fw300 me-2 first'>with</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                NO financial background,
                            </span>
                            <span className='mdFont fw300 me-2 first'>create</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                multiple sources of income,
                            </span>
                            <span className='mdFont fw300 me-2 first'>identify and</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                remove mental money blocks,
                            </span>
                            <span className='mdFont fw300 me-2 first'>develop a</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                money mindset,
                            </span>
                            <span className='mdFont fw300 me-2 first'>and</span>
                            <span className='mdFont fw600 me-2 second txtUnderline'>
                                plan your smart action plan.
                            </span>
                        </h2>
                    </>
                }
                playing={playing}
                setPlaying={setPlaying}
                heroVidCont={
                    <div className="masteryHeroVideo" style={{ maxHeight: playing && !isMobile ? '450px' : isMobile ? playing ? '192px' : '450px' : '' }}>
                        <ReactPlayer
                            url={InstructorVideo}
                            light="assets/images/rahul/eng-money-magnet-vid-banner.webp"
                            width="100%"
                            height="100%"
                            playing
                            muted
                            loop
                            controls={true}
                            onPlay={() =>
                                setPlaying(true)
                            }
                        />
                    </div>
                }
            />
            <VideoBtmSection
                playing={playing}
                bgImg="/assets/images/rahul/money-management-bg.webp"
                textCont={
                    <>
                        <div className='d-flex justify-content-around flex-column row-gap-4 flex-lg-row'>
                            <p className='dateTime fw600 poppins primaryWhite text-center text-lg-center'>
                                19th October <br /> 7:30 PM - 9:30 PM (IST) 
                                {/* Coming Soon */}
                            </p>
                            <div>
                                <p className='primaryWhite fw500 poppins text-start mb-1 desc'>
                                    This is a 3-Hrs Workshop, divided into 2 days.
                                </p>
                                <p className='primaryWhite fw500 poppins text-start mb-1 desc'>
                                    If your substantial earnings vanish by month's end,
                                </p>
                                <p className='primaryWhite fw500 poppins text-start mb-0 desc'>
                                    Then this power-packed workshop is just what you need.
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-5 border-0" onClick={() => handleScroll()}>
                                    Book Now for ₹249/-
                                </button>
                            </Link>
                        </div>
                    </>
                }
            />
            <SpecialRewards
                className="specialRewardSection"
                ptop="ptop"
                pbtm="pbtm"
                bgColor="#131633"
                sectionHead={
                    <h2 className="h2 fw400 anton text-center additionalBonus primaryWhite mb-3 mb-lg-5">
                        Special Rewards<span className="gradientText"> You’ll Receive</span>
                    </h2>
                }
                mdHead1={
                    <h3 className="title fw400 poppins text-center primaryWhite mb-3 mb-lg-4">
                        Get Instant Access to our 7-Day Guided Meditation for Free
                    </h3>
                }
                rewardImg1={
                    <div className='imgCont'>
                        <img src="/assets/images/rahul/Special-Rewards-1.webp" alt="#" loading='lazy' className='w100 h100' />
                    </div>
                }
                mobRewardImg1={
                    <div className='imgCont'>
                        <img src="/assets/images/rahul/Special-Rewards-1-mob.webp" alt="#" loading='lazy' className='w100 h100' />
                    </div>
                }
                mdHead2={
                    <h3 className="title fw400 poppins text-center primaryWhite mt-5 mb-3 mb-lg-4">
                        Download Weekly Affirmation PDF
                    </h3>
                }
                rewardImg2={
                    <div className='imgCont'>
                        <img src="/assets/images/rahul/Special-Rewards-2.webp" alt="#" loading='lazy' className='w100 h100' />
                    </div>
                }
                mobRewardImg2={
                    <div className='imgCont'>
                        <img src="/assets/images/rahul/Special-Rewards-2-mob.webp" alt="#" loading='lazy' className='w100 h100' />
                    </div>
                }
                sectionBtn={
                    <div className='text-center mt-lg-5'>
                        <Link to="masterClassForm_health" smooth={true} duration={500}>
                            <button type="button"  class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-5 border-0" onClick={() => handleScroll()}>Book Your Spot</button>
                        </Link>
                    </div>
                }
            />
            <ClassAgenda
                lightBg="lightBg"
                masterClassCont={
                    <div className="masterClassCont">
                        <div className="row align-items-center row-gap-4">
                            <div className="col-lg-6">
                                <div className="videoArea pe-lg-5">
                                    <img src="/assets/images/rahul/financial-challenges.webp" alt="#" loading="lazy" className="w100 h100 agendaImg border-0" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="rightCont">
                                    <h2 className="h2 fw700 poppins textDark mb-4 text-center text-lg-start">
                                        What financial challenges will be tackled in this <span className="gradientText">Masterclass?</span>
                                    </h2>
                                    <ul className="resetUl">
                                        {
                                            financialPoints.map((items, idx) =>
                                                <li className={`d-flex align-items-start gap-2 ${items.hidemargin == true ? "" : "mb-3"}`}>
                                                    <img src={items.icon} alt="#" loading="lazy" className="circleTick" />
                                                    <p className="textDark roboto fw500 mb-0">{items.desc}</p>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />

            <SickSection
                extraSecName="attendWorkshop"
                lightBg=""
                sectionCont={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h2 className="h2 fw400 anton additionalBonus textDark mb-3 mb-lg-4">Why Attend this
                                <span className="gradientText"> Workshop</span>
                            </h2>
                            <ul className='resetUl'>
                                {
                                    workshopPoints?.map((item, idx) =>
                                        <li className="d-flex align-items-center gap-2 mb-2">
                                            <img src={item.icon} alt="#" loading="lazy" className="circleTick" />
                                            <p className="textDark fw500 roboto mb-0 pt-1 text-start">{item.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <div class="instructorVideo ms-auto">
                                <img src="/assets/images/rahul/workshop-img.webp" alt="#" loading="lazy" className="w100 h100" />
                            </div>
                        </div>
                    </div>
                }
            />

            <MoneyMagnetForm
                formStyle="moneyMagnetForm"
                formHead={
                    <div className="div1">
                        <p className="title fw700 NotoSansBengali primaryWhite mb-1 p1">Money Magnet Masterclass</p>
                        <div className='d-flex flex-wrap row-gap-2'>
                            <span className="title primaryWhite fw600 NotoSansBengali">
                                19th October | 7:30 PM (IST)
                            </span>
                            {/* <span className="primaryWhite fw500 NotoSansBengali date">
                                Date: <span className="fw600 shapeRect primaryYellow primaryBlack">10th Aug</span>
                            </span>
                            <span className="primaryWhite fw500 NotoSansBengali ms-1 ms-lg-4">
                                Time: <span className="fw600 shapeRect primaryYellow primaryBlack">08:00 PM - 10:00 PM</span>
                            </span> */}
                        </div>
                    </div>
                }
                priceSection={
                    <div className="d-flex align-items-center column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">₹</span>
                            <span className="NotoSansBengali">249/-</span>
                        </p>
                        {/* <span>|</span>
                        <p className="p2 fw900 NotoSansBengali textDark mb-0">
                            <span className="me-1">৳</span>
                            <span className="NotoSansBengali">499/-<span className='title fw600'>(BDT)</span></span>
                        </p> */}
                    </div>
                }
                formImg="assets/images/rahul/addition-money-bonus-1.webp"
                productId="ENG_MONEY_MAGNET_249"
                leadAPI="https://silvamethod.in:5000/api/v1/leads/submit-english-money-magnet-lead"
                thankYouPageUrl="/thank-you-page-eng"
                langCount="false"
            />
            <MasterclassBgImg
                fullWidthImg={
                    <img src="/assets/images/rahul/is-this-bg-2-web.webp" alt="#" loading='lazy' className='w100 h100' />
                }
                mobImg={
                    <img src="/assets/images/rahul/is-this-bg-2-mob.webp" alt="#" loading='lazy' className='w100 h100' />
                }
            />
            <LiveClassandWorkshop
                sectionName="engMoneyLiveWorkshop"
                sectionHead={
                    <h2 class="h2 fw700 poppins textDark text-center mb-lg-5 mb-4">
                        <span class="gradientText">Highlights</span> from our live classes
                    </h2>
                }
                videoUrl="https://vz-6873ed28-3a2.b-cdn.net/b8aefc12-766e-42b9-8075-6c633701f26c/playlist.m3u8"
                videoThumb="assets/images/rahul/live-class-banner.jpg"
                classImgs={liveClassImgs}
                videoSection={
                    <ReactPlayer
                        // url={masterclassVideo}
                        url="https://vz-6873ed28-3a2.b-cdn.net/b8aefc12-766e-42b9-8075-6c633701f26c/playlist.m3u8"
                        light="assets/images/rahul/live-class-banner.jpg"
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                }
            />
            <HappyLifeSection
                contentSection={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 text-center">
                            An Overview of the
                            <span class="gradientText ms-2">Silva Method</span>
                        </h2>
                        <p class="p1 textDark roboto mb-4 text-center m-auto">
                            The Silva Method provides a solution through its teachings of meditation and
                            relaxation techniques. These practices aim to rewire and reprogram our minds,
                            leading to profound life transformations. By regularly engaging in these proven
                            techniques, our perspective on life can undergo significant changes
                        </p>
                        <div class="videoArea m-auto mb-4">
                            <img src="/assets/images/rahul/Overview-Silva-Method.webp" alt="#" loading="lazy" class="w100 h100 agendaImg" />
                        </div>
                        <p class="p1 textDark roboto mb-0 text-center m-auto">
                            Moreover, the Silva Method incorporates advanced dynamic meditation training and
                            various other mind empowerment tools and techniques. The Silva Method
                            techniques, act as guides on the path to positive transformation, aligning us with our
                            goals and aspirations. Remarkably, many individuals have reported experiencing
                            health benefits through the Silva Method when conventional medications and doctor
                            visits proved insufficient.
                        </p>
                        <div className='text-center mt-lg-5'>
                            <Link to="masterClassForm_health" smooth={true} duration={500}>
                                <button type="button"  class="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape ms-0 mt-3 border-0 shadowLight" onClick={() => handleScroll()}>Book Your Spot</button>
                            </Link>
                        </div>
                    </>
                }
            />
            <DynamicFaqs
                title='Frequently Asked Questions'
                fontStyle="poppins"
                defaultactive={0}
                data={faqData}
            />
            <Footer2 />
        </div>
    )
}

export default MoneyMagnet