import React from 'react'
import "../../components/CourseScheduleStyle.css";
import DebayanImg from "../../assets/Debayan-Sen.webp"
import ZoomImg from "../../assets/zoom.webp"
import SoumyajeetImg from "../../assets/Soumyajeet-Bandyopadhay.webp"
import DebalinaImg from "../../assets/Debalina-Chatterjee.webp"
import DebasishImg from "../../assets/Debasish-Chatterjee.webp"



const SlsCourseSchedule = () => {

    const cardPoints = [
        {
            pointsNum: '1',
            points: 'Intro & Short Relaxation'
        },
        {
            pointsNum: '2',
            points: 'Long Relaxation(explainantion & exercise)'
        },
        {
            pointsNum: '3',
            points: 'Sleep Control'
        },
        {
            pointsNum: '4',
            points: 'Awake Control'
        },
        {
            pointsNum: '5',
            points: 'Alarm Clock Technique'
        },
    ]

    const cardPoints2 = [
        {
            pointsNum: '1',
            points: 'Dream Control'
        },
        {
            pointsNum: '2',
            points: 'Headache Control'
        },
        {
            pointsNum: '3',
            points: 'Changing Limiting Belief Exercise'
        },
        {
            pointsNum: '4',
            points: 'Question and Answer Session'
        },
    ]

    const cardPoints3 = [
        {
            pointsNum: '1',
            points: 'Review of Last Sessions'
        },
        {
            pointsNum: '2',
            points: 'Mental Screen and Memory Pegs Explain and Exercise'
        },
        {
            pointsNum: '3',
            points: 'Three Fingers Technique(explain and exercise)'
        },
        {
            pointsNum: '4',
            points: 'Mirror of the Mind(explain and exercise)'
        },
    ]

    const cardPoints4 = [
        {
            pointsNum: '1',
            points: 'Hand Levitation and Glove Anaesthesia'
        },
        {
            pointsNum: '2',
            points: 'Glass of Water'
        },
        {
            pointsNum: '3',
            points: 'Habit Control'
        },
        {
            pointsNum: '4',
            points: 'Question and Answer'
        },
    ]



  return (
    <div>
        <section className="courseScheduleSection">  
            <div className="container">

                <div className="text-center mb-5">
                    <h2 className="h2 fw700 poppins textDark text-center mb-2">
                        Course <span className="gradientText">Schedule</span>
                    </h2>
                    <p className="title fw600 poppins textDark">
                        Here is the detailed schedule of our program
                    </p>
                </div>

                <div className="tabsCont text-center">

                    <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist" style={{maxWidth:"602px"}}>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-one-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one"
                                aria-selected="true">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">1</div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-two-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">2</div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">3</div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3 align-items-center">
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Day
                                        </h3>
                                        {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                    </div>
                                    <div className="leftSide roboto fw700 textDark">4</div>
                                </div>
                            </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3">
                                    <div className="leftSide roboto fw700 textDark">3</div>
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Weekend
                                        </h3>
                                        <p className="p1 textDark roboto mb-0">2 days</p>
                                    </div>
                                </div>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                aria-selected="false">
                                <div className="tabBtn d-flex gap-3">
                                    <div className="leftSide roboto fw700 textDark">4</div>
                                    <div className="rightSide text-start">
                                        <h3 className="title fw600 roboto textDark mb-0">
                                            Weekend
                                        </h3>
                                        <p className="p1 textDark roboto mb-0">2 days</p>
                                    </div>
                                </div>
                            </button>
                        </li> */}
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        {/* Content for different tabs goes here */}
                        <div className="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                Topics for First Day
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">Speaker-</span> Debasish Chatterjee
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">

                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                            </div>
                                        </div>
                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                Topics for Second Day
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">Speaker-</span> Debayan Sen
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints2?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">

                                                {/* <div className="rightCont"> */}
                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                </div>

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review day one
                                    <p className="mt-1">(explaination and exercise)</p>
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">09:30 am - 09:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={SoumyajeetImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mental screen and memory pegs explain and exercise
                                    <p className="mt-1">(explaination and exercise)</p>
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Soumyajeet Bandyopadhay
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">9:40 am - 10:40 am</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Three fingers technique(explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">11:00 am - 12:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}
                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Mirror of the mind (explain and exercise)
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">12:10 pm - 01:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Hand levitation and glove anesthesia
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">02:30 pm - 03:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Glass of water and habit control
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">03:45 pm - 05:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}
                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Review of last two days
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">05:30 pm - 06:30 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                                {/* <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebalinaImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                    <h3 className="fw700 poppins textDark mb-2">
                                    Question and answer session
                                    </h3>
                                    <p className="roboto textDark shapeRect lightTrape mb-4">
                                    <span className="fw600">Speaker-</span> Debalina Chatterjee
                                    </p>
                                    <div className="eventArea d-flex align-items-center justify-content-between mt-4">
                                    <div className="leftCont">
                                        <p className="desc textDark roboto mb-1">Opening Event</p>
                                        <p className="title fw600 textDark roboto">06:30 pm - 07:00 pm</p>
                                    </div>
                                    <div className="rightCont">
                                        <p className="desc textDark roboto mb-2">Online class via</p>
                                        <img src={ZoomImg} alt="#" loading="lazy" />
                                    </div>
                                    </div>
                                </div>
                                </div> */}

                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                Topics for Third Day
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">Speaker-</span> Debasish Chatterjee
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints3?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">
                                               
                                                {/* <div className="rightCont"> */}
                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab" tabIndex="0">
                            <div className="instructorCard">
                                <div className="cardItem text-start justify-content-around align-items-center">
                                <div className="leftSide">
                                    <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                </div>
                                <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                Topics for Fourth Day
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">Speaker-</span> Debayan Sen
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints4?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">
                                               
                                                {/* <div className="rightCont"> */}
                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </div>
  )
}

export default SlsCourseSchedule