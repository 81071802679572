import React, { useEffect, useState } from 'react'
import './HealthFormStyle.css'
import { Link } from 'react-scroll';
import { Link as RouteLink } from 'react-router-dom';
import useRazorpay from "react-razorpay";
import { useNavigate } from 'react-router-dom'
import razorpay from '../assets/RAZORPAY-ICON.webp'
import { validatePhoneNumber } from '../utils/Helper';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';


const HealthForm = () => {
    //---------------code for form data submit----------------
    const [formData, setFormData] = useState({
        fname: '',
        mobile: '',
        email: '',
        timeslot: ''
    });
    const [error, setError] = useState('');
    console.log('formdata', formData)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Basic validation
        if (!formData.fname || !formData.mobile || !formData.email || !formData.timeslot) {
            setError('All fields are required');
            return;
        }
        const isValid = validatePhoneNumber(formData.mobile)
        // console.log(isValid,"hello");
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }
        try {
            console.log('i am inside try');
            console.log('formdata', formData)
            // const response = await axios.post(
            //   'https://dedicated-nurture-a182b31812.strapiapp.com/api/landing-pages',
            //   {    
            //     data: {
            //       Name: formData.fname,
            //       Phone: formData.mobile,
            //       Email: formData.email,
            //       TimeSlot: formData.timeslot
            //     }
            //   },
            //   {
            //     headers: {
            //       Authorization: 'Bearer b22c667d5e14a86c5f9c3fbbc9489b9028e05b3d7263a8c2531403ddfb91aaf51b0a30a88b2da909549932e07b125430f7ffc13b3f7ec4b88910fc498dfb0f04778127e33bfdb99543f9e678a1211b88cae385c64d7e93610f0aa6dd983b2c7bea7556c27e1cfc0cafeed6f671ced98b9fa7f5d8e6c501a86df53edf50521999'
            //     }
            //   }
            // );
            const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-healthpage-lead', {
                method: "POST",
                body: JSON.stringify({
                    "name": formData.fname,
                    "email": formData.email,
                    "phone": formData.mobile,
                    "timeslot": formData.timeslot
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });


            console.log('Response:', response);
            // Reset form fields after successful submission
            //   launchRazorpayPayment11(formData);

            await handlePayment(formData);

            setError('');
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred, please try again later.');
        }
    };


    // ------------code for payment--------------------------------
    const [Razorpay] = useRazorpay();
    var timeslotCount;
    if (formData.timeslot === '10 a.m. - 12 p.m.(IST) / 10:30 a.m. - 12:30 p.m.(BDT)') {
        timeslotCount = '1'
    } else if (formData.timeslot === '6:30 p.m. - 8:30 p.m.(IST) / 7p.m. - 9 p.m. (BDT)') {
        timeslotCount = '2'
    }
    console.log("timeslotcount", timeslotCount)
    const handlePayment = async (formData) => {
        try {
            const createOrder = await fetch('https://silvamethod.in:5000/api/v1/orders/create-order', {
                method: "POST",
                body: JSON.stringify({
                    product_id: "Health_99_",
                    // product_id: "test01",
                    user_id: `${formData.fname}-${formData.email}-${formData.mobile}-${timeslotCount}`,
                    purchase_type: "course"
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });
            const order = await createOrder.json();
            // console.log(order,"order created") 
            if (order?.order_id) {
                var options = {
                    "key": process.env.REACT_APP_RAZORPAY_KEY,
                    name: "Payment",
                    description: "Payment Method",
                    image: "https://silvamethod.com/img/logo/title-logo-removebg-preview.png",
                    order_id: order?.order_id,
                    handler: async function (response) {
                        if (response?.razorpay_payment_id) {
                            const capturePayment = await
                                //   Apirequests("orders/capture-order","POST",{
                                //   "paymentId": response?.razorpay_payment_id,
                                //  },{"Content-Type": "application/json"});
                                fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                                    method: "POST",
                                    body: JSON.stringify({
                                        "paymentId": response?.razorpay_payment_id,
                                    }),
                                    headers: {
                                        "Content-Type": "application/json",
                                    }
                                });
                            // console.log(capturePayment,'captureRes')
                            const captureRes = await capturePayment?.json();
                            console.log(captureRes, 'captureRes')
                            console.log(response, 'Response')
                            if (captureRes?.success) {
                                // toast.success("Payment Successful",{
                                //   position:"bottom-center"
                                // });
                                setFormData({
                                    fname: '',
                                    mobile: '',
                                    email: '',
                                    timeslot: ''
                                });
                                console.log("pay success")
                                window.location.href = '/payment-successful';
                            }
                        }
                    },
                    "modal": {
                        "ondismiss": async function () {
                            // console.log(res,"failed response");
                            const capt = await
                                // Apirequests("orders/capture-failed-payment","POST",{
                                //   "order_id": order?.order_id,
                                // },{ "Content-Type": "application/json"})
                                fetch('https://silvamethod.in:5000/api/v1/orders/capture-failed-payment', {
                                    method: "POST",
                                    body: JSON.stringify({
                                        "order_id": order?.order_id,
                                    }),
                                    headers: {
                                        "Content-Type": "application/json",
                                    }
                                });
                            const captRes = await capt.json();
                            console.log(capt, 'failed res')
                            if (capt?.success) {
                                // toast.error("Payment Failed",{
                                //   position:"bottom-center"
                                // })
                                console.log("payment failed")
                            }
                        }
                    },
                    prefill: {
                        name: formData.fname,
                        email: formData.email,
                        contact: formData.mobile,
                    },
                    notes: {
                        address: "The Silva Method India",
                        timeslot: formData.timeslot
                    },
                    theme: {
                        color: "A500FF",
                    },
                }


                const rzp1 = new Razorpay(options);
                rzp1.on("payment.failed", async function (response) {
                    console.log(response.error.code);
                    console.log(response.error.description);
                    console.log(response.error.source);
                    console.log(response.error.step);
                    console.log(response.error.reason);
                    console.log(response.error.metadata.order_id);
                    console.log(response.error.metadata.payment_id);
                    if (response?.error?.reason === "payment_failed") {
                        const capturePayment = await
                            // Apirequests("orders/capture-order","POST",{
                            //   "paymentId": response?.error?.metadata?.payment_id,
                            //  },{"Content-Type": "application/json"});
                            fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                                method: "POST",
                                body: JSON.stringify({
                                    "paymentId": response?.error?.metadata?.payment_id,
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                }
                            });
                        if (capturePayment?.success) {
                            const modalElement = document.querySelector('.razorpay-container');
                            if (modalElement) {
                                modalElement.style.display = 'none';
                            }
                        }
                        window.location.href = '/payment-failure';
                    }
                });
                // rzp1.open();
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Caution",
                    text: "After successful payment please wait until you go to the payment successful page. DO NOT close or refresh the page before that.",
                    timer: 6000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                setTimeout(() => {
                    rzp1.open();
                }, 6000)
            }
            // setApiOrder(order); 
        } catch (error) {
            console.log("error:", error);
        }

        // console.log(process.env.REACT_APP_RAZORPAY_KEY,"payment key")
    };
    // ----------code for payment ends------------------------------


    const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
    useEffect(() => {
        const btn = document.getElementById("btnSubmit");
        if (!isFormFilled) {
            btn.style.opacity = 0.4
        } else {
            btn.style.opacity = 1
        }
    }, [isFormFilled])

    const handleScroll = () => {
        const rightForm = document.querySelector(".rightForm");
        // console.log(doc, "document")
        if (rightForm) {
            rightForm.scrollIntoView({
                behavior: "smooth"
            })
        }
        setTimeout(() => {
            rightForm.classList.remove("pulseAnim");
            rightForm.classList.remove("pulseShadow");
        }, 4000)
        rightForm.classList.add("pulseAnim");
        rightForm.classList.add("pulseShadow");
    }
    const [isChecked, setIsChecked] = useState(true); // Set to true to make it checked by default
    // Function to handle checkbox change
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
    };


    return (
        <>
            <section className="healthForm lightBg ptop pbtm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="rightForm" id="masterClassForm_health">
                                <div className="div1">
                                    <p className="fw700 poppins primaryBlack mb-1 p1">হেলথ ম্যানেজমেন্ট মাস্টারক্লাস</p>
                                    <span className="primaryBlack fw500 date">
                                        তারিখ <span className="fw500 shapeRect gradientBg primaryWhite">২২শে জুন</span>
                                    </span>
                                    <span className="primaryBlack fw500 ms-4">
                                        সময়কাল <span className="fw500 shapeRect gradientBg primaryWhite">২ ঘণ্টা</span>
                                    </span>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    {error && <div className="error">{error}</div>}
                                    <div className="row gy-4">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userName" name="fname" placeholder="Name" value={formData.fname} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userPhn" name="mobile" placeholder="Phone No." value={formData.mobile} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control" id="userEmail" name="email" placeholder="Email ID" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <select className="form-select form-control" id="selectTag" name="timeslot" aria-label="Default select example" value={formData.timeslot} onChange={handleChange}>
                                                <option value="" selected disabled>Book Your Timeslot</option>
                                                <option value="10 a.m. - 12 p.m.(IST) / 10:30 a.m. - 12:30 p.m.(BDT)">10 a.m. - 12 p.m.(IST) / 10:30 a.m. - 12:30 p.m.(BDT)</option>
                                                <option value="6:30 p.m. - 8:30 p.m.(IST) / 7p.m. - 9 p.m. (BDT)">6:30 p.m. - 8:30 p.m.(IST) / 7p.m. - 9 p.m. (BDT)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                                        <p className="p2 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                            <span className="me-1">₹</span>
                                            <span className="roboto">৯৯/-</span>
                                        </p>
                                        <p className="p2 fw900 oldPrice textLight mb-0">
                                            <span className="me-1">₹</span>
                                            <span className="roboto">৯৯৯/-</span>
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap column-gap-3 mb-4 paymentWidget" role="button">
                                        <span className="fw600 textDark">Pay Via</span>
                                        <img src={razorpay} alt="#" loading="lazy" />
                                    </div>
                                    <ul className="resetUl">
                                        <li className="d-flex align-items-start gap-2 mb-2">
                                            <div className="form-check">
                                                <label className="form-check-label" for="flexCheckChecked">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="flexCheckChecked"
                                                        checked={isChecked} // Set checked attribute based on the state
                                                        onChange={handleCheckboxChange} // Call the function to handle checkbox change
                                                    />
                                                    <p className="textDark roboto mb-0 smFont">
                                                        I accept your <RouteLink to={'/terms-&-conditions'} className="fw700 textDark">Terms &amp; Conditions</RouteLink> and willingly agree to receive Marketing emails.
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0" style={{ opacity: 0.4 }}>
                                        রেজিস্টার করুন
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className='videoArea'>
                                <img src="/assets/images/rahul/practice-meditation-2.webp" alt="#" loading='lazy' className='w100 h100' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HealthForm