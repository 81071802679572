import React from 'react'
import ThankYouPageBody from '../components/ThankYouPageBody'
import HeartImg from "../assets/heart-tick-01.svg"


const ThankYouPageEng = () => {
    return (
        <ThankYouPageBody
            contentArea={
                <div className="pageCont">
                    <div className="heartTick">
                        <img src={HeartImg} alt="#" loading="lazy" className="w100 h100" />
                    </div>
                    <h2 className="h2 fw700 poppins textDark text-center mb-4">
                        You Have <span className="gradientText"> Successfully </span> Registered
                    </h2>
                    <p className="desc fw500 textDark poppins">
                        Thank you for your payment and registration for the Money Magnet Masterclass!
                        Your payment has been successfully processed, and we appreciate your interest
                        in our program. If you have any questions, please feel free to email us at <a href="" className="textDark fw700"> better@silvamethod.in </a> or call us at <a href="" className="textDark fw700">+91 6290945413</a>

                    </p>
                    <p className="mdFont textDark fw700 mb-4 text-center poppins">Exclusive Bonuses Just for You!</p>
                    <div className="row justify-content-center gy-4">
                        <div className="col-12">
                            <div className="bonusCard row-gap-2">
                                <div className="bonusCont mt-3 d-flex align-items-center row-gap-2 flex-wrap">
                                    <p className="fw600 primaryWhite poppins mb-0 p1">Bonus #1</p>
                                    <p className="fw600 textDark poppins mb-0">Access to Silva Method 5-Day Free Guided Meditation</p>
                                    <div>
                                        <p className="fw400 textDark roboto m-0 p2">Immerse yourself in a transformative meditation experience designed to help you develop a habit of practicing daily meditation with a positive mindset.</p>
                                    </div>
                                </div>
                                <div className="bonusCont mt-3 d-flex align-items-center row-gap-2 flex-wrap">
                                    <p className="fw600 primaryWhite poppins mb-0 p1">Bonus #2</p>
                                    <p className="fw600 textDark poppins mb-0">Download Silva Method FREE Weekly Affirmation PDF</p>
                                    <div>
                                        <p className="fw400 textDark roboto m-0 p2">Practice powerful affirmations each day to shift your vibrational frequency and attract financial abundance
                                            Your exclusive bonuses will be sent to your registered email address. Please check your inbox to access these valuable resources from Silva Method India. Thank you once again for joining the Money Magnet Masterclass. We look forward to guiding you on your journey to financial success!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default ThankYouPageEng