import React from 'react'
import Navbar2 from '../components/Navbar2'
import RecordClassCont from '../components/RecordClassCont'
const recVid = "https://www.youtube.com/watch?v=-hF1Qv6Q86A"


const RecordedMoneyMagnetMasterclass = () => {
    return (
        <div className='silvaDiffSection pbtm vh-100'>
            <Navbar2 />
            <RecordClassCont
                maintitle="মানি ম্যাগনেট মাস্টারক্লাস - এর রেকর্ডেড ভিডিও"
                vidUrl={recVid}
            />
        </div>
    )
}

export default RecordedMoneyMagnetMasterclass