import React from 'react'
import './HighlightsStyle.css'

const Highlights = () => {
    const boxCont = [
        {
            iconimg: "/assets/images/rahul/trophy-cup.svg",
            title: "80,000 +",
            desc: "সাফল্যের গল্প"
        },
        {
            iconimg: "/assets/images/rahul/teacher.svg",
            title: "500 +",
            desc: "প্রশিক্ষক"
        },
        {
            iconimg: "/assets/images/rahul/globe.svg",
            title: "120 +",
            desc: "দেশ"
        },
        {
            iconimg: "/assets/images/rahul/graduate.svg",
            title: "12 M +",
            desc: "স্নাতক"
        },
        {
            iconimg: "/assets/images/rahul/users.svg",
            title: "1 M +",
            desc: "প্রশংসাপত্র"
        },
    ]


    return (
        <>
            <section className="highlightSection gradientBg">
                <div className="container">
                    <div className="highlightBox d-flex align-items-center">
                        {
                            boxCont.map((item, idx) =>
                                <div className="boxItem d-flex gap-3">
                                    <div className="iconCont">
                                        <img src={item.iconimg} alt="#" loading='lazy' />
                                    </div>
                                    <div className="textCont">
                                        <h3 className='title primaryWhite fw600 poppins mb-0'>{item.title}</h3>
                                        <p className='fw500 primaryWhite mb-0'>{item.desc}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Highlights