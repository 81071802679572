import React, { useEffect } from "react";
import Newnavbar from "../../components/Newnavbar";
// import HeroSection_Courses from "../../../components/HeroSection_Courses";
// import AboutBLS from "../../../components/AboutBLS";
// import KeyPoints from "../../../components/KeyPoints";
// import CourseSchedule from "../../../components/CourseSchedule";
// import WebinarWorks from "../../../components/WebinarWorks";
// import PersonalGrowth from "../../../components/PersonalGrowth";
// import CoursesList from "../../../components/CoursesList";
import Form from "../../components/Form";
import FAQ from "../../components/FAQ";
import Footer from "../../components/Footer";
import SlsHeroSection from "./SlsHeroSection";
import AboutSls from "./AboutSls";
import SlsKeypoints from "./SlsKeypoints";
import SlsWebinarWorks from "./SlsWebinarWorks";

import WebinarCard_1_Img from "../../assets/webinar-card-1.webp";
import WebinarCard_2_Img from "../../assets/webinar-card-2.webp";
import WebinarCard_3_Img from "../../assets/webinar-card-3.webp";

import EnrollImage1 from "../../assets/enroll-img-1.webp";
import EnrollImage2 from "../../assets/enroll-img-2.webp";
import EnrollImage3 from "../../assets/enroll-img-3.webp";
import EnrollImage4 from "../../assets/enroll-img-4.webp";
import EnrollImage5 from "../../assets/enroll-img-5.webp";
import EnrollImage6 from "../../assets/enroll-img-6.webp";
import EnrollImage7 from "../../assets/enroll-img-7.webp";

import SlsCourses from "./SlsCourseList";
import SlsPersonalGrowth from "./SlsPersonalGrowth";
import SlsFaqs from "./SlsFaqs";
import SlsCourseSchedule from "./SlsCourseSchedule";

import { FaCalendarAlt, FaClock } from "react-icons/fa";
import { IoMdPricetags } from "react-icons/io";




const CourseSLS = () => {


  const slsKeydata = [
    {
      id: 1,
      text: "Sleep control : Learn to sleep naturally without the use of drugs, naturally.",
    },
    {
      id: 2,
      text: "Awake control : Learn to remain awake longer when necessary.",
    },
    {
      id: 3,
      text: "Headache control : Learn to relieve headaches and migraines without using medication, only your mind.",
    },
    {
      id: 4,
      text: "Hand levitation : Learn to enter the theta level by yourself.",
    },
    {
      id: 5,
      text: "Glass of Water technique : Use this technique when you need information or guidance toward a solution.",
    },
    {
      id: 6,
      text: "Habit control : Use to eliminate smoking, lose weight, stop biting your nails, and more.",
    },
    {
      id: 7,
      text: "Glove anaesthesia : Learn to develop control of physiological pain and in many cases control bleeding and haemorrhaging.",
    },
    {
      id: 8,
      text: "Clock Exercise : Practice awakening at the desired time without an alarm clock.",
    },
    {
      id: 9,
      text: "Dream control : Learn to recall your dreams and use them to solve problems.",
    },
    {
      id: 10,
      text: "Three fingers technique : Get to alpha level more quickly.",
    },
    {
      id: 11,
      text: "Mental screen / memory pegs : Learn to project and develop your visualization and imagination abilities.",
    }
  ];

  const webinarWorksData = [
    {
      id: 1,
      img: WebinarCard_1_Img,
      text: "Understand the purpose of the Silva Method Free Webinar"
    },
    {
      id: 2,
      img: WebinarCard_2_Img,
      text: "Reach out to our Support Team to clear any doubt"
    },
    {
      id: 3,
      img: WebinarCard_3_Img,
      text: "Fill out the form with your details and begin your transformation Journey"
    }
  ];

  const courseData = [
    {
      id: 1,
      title: "Basic Lecture Series",
      desp: "The foundational pillar of the Silva Method, originally crafted by the iconic Jose Silva.",
      actual_price: "20,000",
      discounted_price: "9,999",
      discounted_percentage: "50%",
      image: EnrollImage1,
    },
    {
      id: 2,
      title: "Silva Life System",
      desp: "Effortlessly conquer complexity and embark on uncharted journeys within your mind.",
      actual_price: "12,000",
      discounted_price: "5,500",
      discounted_percentage: "50%",
      image: EnrollImage2
    },
    {
      id: 3,
      title: "Silva Intuition System",
      desp: "Enhance your intuitive prowess, utilizing it as your moral compass for discernment.",
      actual_price: "12,000",
      discounted_price: "6,000",
      discounted_percentage: "50%",
      image: EnrollImage3
    },
    {
      id: 4,
      title: "Silva Life & Intuition System (FDIS)",
      desp: "A dynamic four-day immersion seminar fusing the Silva Method’s two potent courses.",
      actual_price: "20,000",
      discounted_price: "9,999",
      discounted_percentage: "50%",
      image: EnrollImage4
    },
    {
      id: 5,
      title: "Silva Method for Family and Children",
      desp: "A holistic family program catering to both parent’s and children’s overall growth.",
      actual_price: "Cutomized Price",
      discounted_price: "",
      discounted_percentage: "",
      image: EnrollImage5
    },
    {
      id: 6,
      title: "Silva Method Introductory Webinar",
      desp: "An open-access webinar for those eager to uncover the hidden depths of their minds.",
      actual_price: "Free",
      discounted_price: "",
      discounted_percentage: "",
      image: EnrollImage6
    }
  ];


  const faqs = [
    {
      header: "What is the Silva Life System?",
      text: "The Silva Life System is the starting point of the complete program. It teaches you basic skills in meditation, visualization, making your desires come true, healing, and more. These are simple and practical techniques that you can use right away in your daily life.",
    },
    {
      header: "Does the Silva Method really work?",
      text: "The Silva Method can be a helpful tool for reducing stress, improving focus, and boosting self-confidence. The effectiveness of this method varies from person to person. Over 6 million people in 120 countries have experienced the power of the Silva Method Meditation.",
    },
    {
      header: "What are the benefits of the Silva Method?",
      text: "The benefits of practicing the Silva Method are as numerous as your imagination. By mastering the art of tapping into your subconscious mind, you can effectively alleviate stress and anxiety, enhance your concentration and sleep patterns, facilitate self-healing and assist others, eradicate feelings of depression and headaches, and ultimately attain greater success in all aspects of your life.",
    },
    {
      header: "Does The Silva Life System course teach healing?",
      text: "The Silva Life System offers essential techniques to enhance your ability to address physical and emotional issues, alleviate headaches, boost memory, and enhance sleep quality. It includes simple step-by-step exercises to help you apply your learning to your life immediately.",
    }
  ];

  const perksData = [
    "Life Changing Transformation courses",
    "Powerful Silva Method meditation techniques",
    "Mind control tools and training",
    "Access to a global community",
    "Doubt clearing and healing session",
    "Weekly meetups for Silva graduates",
    "Instant money back guarantee"
  ];


  useEffect(() => {
    const docs = document.querySelector(".navSection");
    if (docs) {
      docs.scrollIntoView({
        behavior: "smooth"
      })
    }
  }, [])




  return (
    <>
      <Newnavbar />
      {/* <HeroSection_Courses /> */}
      <SlsHeroSection
        course_title={"Silva Life System (SLS)"}
        course_description={
          "Begin your transformation journey by letting go of stress and negative emotions that hinder your progress"
        }
        btnCta={"Know More"}
        CardThumbImg={"/assets/assets/img/sls/SLS img-16.webp"}
        discountPrice={"6,000"}
        originalPrice={"12,000"}
        discount_percentage={"50"}
        star={"4.5"}
        ratingCount={"7000+"}
        perksData={perksData}
        card={<div className="doubtsArea">
          <div className="d-flex gap-3">
            <FaCalendarAlt />
            <p class="primaryWhite roboto">10th, 11th, 17th & 18th August</p>
          </div>
          <div className="d-flex gap-3">
            <FaClock />
            <p class="primaryWhite roboto">8PM - 11PM(BDT)</p>
          </div>
          <div className="d-flex gap-3">
            <FaClock />
            <p class="primaryWhite roboto">7:30PM - 10:30PM(IST)</p>
          </div>
          <div className="d-flex gap-3">
            <IoMdPricetags />
            <p class="primaryWhite roboto mb-0">₹ 7500 (BDT) / ₹ 6000 (INR)</p>
          </div>
        </div>}
        showForm={true}
      />
      <AboutSls
        title={"About the Silva Life"}
        colored={"System"}
        description={`Make your life more manageable with Silva Life System. Kick off negative thought patterns with positive and mindful thoughts. Liberate yourself from headaches, migraines, and ingrained habits. Conquer complexity effortlessly and venture into the unexplored territories of your mind. Experience the transformative power of the Silva Life System - a 100% Authentic and Original Mind Control Course. Discover the New You!`}
      />
      {/* <KeyPoints /> */}
      <SlsKeypoints data={slsKeydata} />
      {/* <CourseSchedule /> */}
      <SlsCourseSchedule />
      {/* <WebinarWorks /> */}
      <SlsWebinarWorks data={webinarWorksData} />
      <SlsPersonalGrowth />
      {/* <PersonalGrowth /> */}
      <SlsCourses data={courseData} />
      {/* <CoursesList /> */}
      <Form />
      {/* <FAQ /> */}
      <SlsFaqs faqsData={faqs} />
      <Footer />
    </>
  );
};

export default CourseSLS;
