import React from 'react'
import './notfound.css';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className='notfoundmain'>
    <div className="container">
        <div className="row notfoundrow">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="col-lg-12 text-404">
                    <b>404</b>
                </div>
                <div className="col-lg-12 text">
                    <b> PAGE NOT FOUND </b>
                </div>
                <div className="col-lg-12 text-btn">
                    <Link to={"/"}>
                    <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0">Back to Home</button>
                    </Link>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
    </div>
    </div>
  )
}

export default NotFound