import React from 'react'
import './PracticeMeditation14Style.css'


const PracticeMeditation14 = ({ contentArea, customStyle, lightBg }) => {
    return (
        <>
            <section className={`practiceMeditation ptop pbtm ${customStyle ? customStyle : ""} ${lightBg ? lightBg : ""}`}>
                <div className="container">
                    {contentArea}
                </div>
            </section>
        </>
    )
}

export default PracticeMeditation14