import React from 'react'
import "./LifeChallengeStyle.css";
import AngerImg from "../assets/anger.webp"
import CheckBGImg from "../assets/check-bg-dark.svg"
import DepressionImg from "../assets/depression.webp"
import SleepImg from "../assets/sleep.webp"
import StressImg from "../assets/stress.webp"


const LifeChallenge = () => {
  return (
    <div>
      <section className="instructorsSection lightBg ptop pbtm position-relative">
        <div id="clouds">
          <div className="cloud x1"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
          <div className="cloud x6"></div>
          <div className="cloud x7"></div>
          <div className="cloud x8"></div>
          <div className="cloud x9"></div>
          <div className="cloud x10"></div>
          <div className="cloud x11"></div>
          <div className="cloud x12"></div>
        </div>
        <div className="container">
          <div className="headingArea text-center position-relative">
            <p className="title fw600 poppins textDark mb-2">
              Silva Mind Empowerment Techniques
            </p>
            <h2 className="h2 fw700 poppins textDark">
              Conquer <span className="gradientText">Life Challenges</span>
            </h2>
          </div>
          <div className="instructorCard courseCard">
            <div className="cardItem">
              <div className="leftSide">
                <img src={StressImg} alt="#" loading="lazy" className="w100 h100" />
                <p className="overlayText fw600 poppins mb-0">STRESS</p>
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins textDark mb-5">Unwind the Tangles of Stress</h3>
                <p className="title fw600 roboto textDark mb-3">Suggested Course</p>
                <ul className="resetUl mb-5">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Short Relaxation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Transform Stress Exercise</p>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Long Relaxation Technique</p>
                  </li>
                </ul>
                <a href="#" className="btnMain fw600 gradientBg primaryWhite text-capitalize shapeTrape">view courses</a>
              </div>
            </div>
            <div className="cardItem">
              <div className="leftSide">
                <img src={AngerImg} alt="#" loading="lazy" className="w100 h100" />
                <p className="overlayText fw600 poppins mb-0">ANGER</p>
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins textDark mb-5">Find Peace within the Storm of Aggression</h3>
                <p className="title fw600 roboto textDark mb-3">Suggested Course</p>
                <ul className="resetUl mb-5">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Short Relaxation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Transform Stress Exercise</p>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Long Relaxation Technique</p>
                  </li>
                </ul>
                <a href="#" className="btnMain fw600 gradientBg primaryWhite text-capitalize shapeTrape">view courses</a>
              </div>
            </div>
            <div className="cardItem">
              <div className="leftSide">
                <img src={DepressionImg} alt="#" loading="lazy" className="w100 h100" />
                <p className="overlayText fw600 poppins mb-0">DEPRESSION</p>
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins textDark mb-5">Stop The Silent Struggle and Rise Above</h3>
                <p className="title fw600 roboto textDark mb-3">Suggested Course</p>
                <ul className="resetUl mb-5">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Short Relaxation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Transform Stress Exercise</p>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Long Relaxation Technique</p>
                  </li>
                </ul>
                <a href="#" className="btnMain fw600 gradientBg primaryWhite text-capitalize shapeTrape">view courses</a>
              </div>
            </div>
            <div className="cardItem">
              <div className="leftSide">
                <img src={SleepImg} alt="#" loading="lazy" className="w100 h100" />
                <p className="overlayText fw600 poppins mb-0">SLEEP</p>
              </div>
              <div className="rightSide">
                <h3 className="fw700 poppins textDark mb-5">Stop The Silent Struggle and Rise Above</h3>
                <p className="title fw600 roboto textDark mb-3">Suggested Course</p>
                <ul className="resetUl mb-5">
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Short Relaxation</p>
                  </li>
                  <li className="d-flex align-items-center gap-2 mb-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Transform Stress Exercise</p>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <img src={CheckBGImg} alt="#" loading="lazy" />
                    <p className="textDark roboto mb-0">Long Relaxation Technique</p>
                  </li>
                </ul>
                <a href="#" className="btnMain fw600 gradientBg primaryWhite text-capitalize shapeTrape">view courses</a>
              </div>
            </div>
            {/* Add more card items if needed */}
          </div>
          <div className="paginationBtn">
            <div className="arwBtn">
              <i className="fa-solid fa-angle-left"></i>
            </div>
            <div className="arwBtn">
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LifeChallenge