import React from 'react'
import './NewLandingBannerStyle.css'

const NewLandingBanner = ({ customClassName, bannerMobileImg, bannerTabImg, bannerWebImg, bannerContent }) => {
    return (
        <section className={`position-relative newLandingHero ${customClassName}`}>
            <img src={bannerMobileImg} className="d-md-none d-block w-100 mobBanner" alt="#" loading="lazy" />
            <img src={bannerTabImg} className="d-none d-md-block d-lg-none w-100 tabBanner" alt="#" loading="lazy" />
            <img src={bannerWebImg} className="d-none d-lg-block w-100 webBanner" alt="#" loading="lazy" />
            {bannerContent}
        </section>
    )
}

export default NewLandingBanner