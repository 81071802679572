import React from 'react'
import "./PersonalGrowthStyle.css";
import ProfileImage from "../assets/profile-pic.webp"

const PersonalGrowth = () => {
  return (
    <div>
        <section className="personalGrowthSection pbtm">
            <div className="container">
                <div className="contArea text-center">
                    <p className="title fw600 poppins textDark">
                        The Original Silva Basic Lecture Series (BLS)
                    </p>
                    <h2 className="h2 fw700 poppins textDark text-center mb-5">
                        Your Gateway to <span className="gradientText">Personal Growth</span>
                    </h2>
                    <p className="desc fw400 textDark poppins text-start">
                        In the realm of personal growth, the Silva Method Foundation Course (BLS) stands as your gateway
                        to transformation. As you embark on this enlightening journey, you'll discover the keys to
                        unlocking your full potential. With a rich legacy of empowerment and self-realization, the Silva
                        BLS Course Online equips you with the tools to expand your mind, conquer challenges, and
                        manifest your aspirations. It's not just a course; it's an extraordinary opportunity to rewrite
                        your story, foster positive change, and step into a brighter, more fulfilling future. Join us
                        and start your path to personal growth today.
                    </p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default PersonalGrowth