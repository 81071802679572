import React from 'react'
import ThankYouBody from '../components/ThankYouBody'

const ThankYou = () => {
  return (
    <div>

<ThankYouBody/>

    </div>
  )
}

export default ThankYou