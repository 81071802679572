import React, { useEffect, useState } from 'react'
import './HealthHeroStyle.css'
import HeroBGImg from "../assets/health-banner-web.webp"
import HeroBGMobileImg from "../assets/health-banner-mob.webp"
import HeroBGTabImg from "../assets/health-banner-tab.webp"
// import RazorpayImg from "../assets/RAZORPAY-ICON.webp"
// import axios from 'axios';
import { Link } from 'react-scroll';
import { handleScroll } from '../utils/animPulse'
import useRazorpay from "react-razorpay";
// import { useNavigate } from 'react-router-dom'
// import { handleScroll } from '../utils/animPulse'
import ReactPlayer from 'react-player';
const InstructorVideo = "https://vz-6873ed28-3a2.b-cdn.net/96b2aa97-85dd-492a-8ffc-8a3d5dc1fdbc/playlist.m3u8"


const HealthHero = () => {
  // const navigate = useNavigate();
  //---------------code for form data submit----------------

  const [formData, setFormData] = useState({
    fname: '',
    mobile: '',
    email: '',
    timeslot: ''

  });

  const [error, setError] = useState('');
  console.log('formdata', formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.fname || !formData.mobile || !formData.email || !formData.timeslot) {
      setError('All fields are required');
      return;
    }

    try {
      console.log('i am inside try');
      console.log('formdata', formData)
      // const response = await axios.post(
      //   'https://dedicated-nurture-a182b31812.strapiapp.com/api/landing-pages',
      //   {    
      //     data: {
      //       Name: formData.fname,
      //       Phone: formData.mobile,
      //       Email: formData.email,
      //       TimeSlot: formData.timeslot
      //     }
      //   },
      //   {
      //     headers: {
      //       Authorization: 'Bearer b22c667d5e14a86c5f9c3fbbc9489b9028e05b3d7263a8c2531403ddfb91aaf51b0a30a88b2da909549932e07b125430f7ffc13b3f7ec4b88910fc498dfb0f04778127e33bfdb99543f9e678a1211b88cae385c64d7e93610f0aa6dd983b2c7bea7556c27e1cfc0cafeed6f671ced98b9fa7f5d8e6c501a86df53edf50521999'

      //     }
      //   }
      // );

      const response = await fetch('https://silvamethod.in:5000/api/v1/leads/submit-healthpage-lead', {
        method: "POST",
        body: JSON.stringify({
          "name": formData.fname,
          "email": formData.email,
          "phone": formData.mobile,
          "timeslot": formData.timeslot
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });



      console.log('Response:', response);
      // Reset form fields after successful submission
      //   launchRazorpayPayment11(formData);

      setFormData({
        fname: '',
        mobile: '',
        email: '',
        timeslot: ''
      });

      setError('');
      await handlePayment();
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred, please try again later.');
    }
  };

  // ------------code for payment--------------------------------
  const [Razorpay] = useRazorpay();
  const handlePayment = async () => {

    try {
      const createOrder = await fetch('https://silvamethod.in:5000/api/v1/orders/create-order', {
        method: "POST",
        body: JSON.stringify({
          product_id: "Health_99_",
          user_id: `${formData.fname}-${formData.email}-${formData.mobile}`,
          purchase_type: "course"
        }),
        headers: {
          "Content-Type": "application/json",
        }
      });
      const order = await createOrder.json();
      console.log(order,"order created") 

      if (order?.order_id) {
        var options = {
          "key": process.env.REACT_APP_RAZORPAY_KEY,
          name: "Payment",
          description: "Payment Method",
          image: "https://silvamethod.com/img/logo/title-logo-removebg-preview.png",
          order_id: order?.order_id,
          handler: async function (response) {
            if (response?.razorpay_payment_id) {
              const capturePayment = await
                //   Apirequests("orders/capture-order","POST",{
                //   "paymentId": response?.razorpay_payment_id,
                //  },{"Content-Type": "application/json"});

                fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                  method: "POST",
                  body: JSON.stringify({
                    "paymentId": response?.razorpay_payment_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              // console.log(capturePayment,'captureRes')

              const captureRes = await capturePayment?.json();
              console.log(captureRes, 'captureRes')
              console.log(response, 'Response')
              if (captureRes?.success) {
                // toast.success("Payment Successful",{
                //   position:"bottom-center"
                // });
                console.log("pay success")
                window.location.href = '/payment-successful';
              }
            }
          },
          "modal": {
            "ondismiss": async function () {
              // console.log(res,"failed response");
              const capt = await
                // Apirequests("orders/capture-failed-payment","POST",{
                //   "order_id": order?.order_id,
                // },{ "Content-Type": "application/json"})

                fetch('https://silvamethod.in:5000/api/v1/orders/capture-failed-payment', {
                  method: "POST",
                  body: JSON.stringify({
                    "order_id": order?.order_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  }
                });
              const captRes = await capt.json();
              console.log(capt, 'failed res')

              if (capt?.success) {
                // toast.error("Payment Failed",{
                //   position:"bottom-center"
                // })
                console.log("payment failed")
              }
            }
          },
          prefill: {
            name: formData.fname,
            email: formData.email,
            contact: formData.mobile,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "A500FF",
          },
        }

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", async function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);

          if (response?.error?.reason === "payment_failed") {
            const capturePayment = await
              // Apirequests("orders/capture-order","POST",{
              //   "paymentId": response?.error?.metadata?.payment_id,
              //  },{"Content-Type": "application/json"});
              fetch('https://silvamethod.in:5000/api/v1/orders/capture-order', {
                method: "POST",
                body: JSON.stringify({
                  "paymentId": response?.error?.metadata?.payment_id,
                }),
                headers: {
                  "Content-Type": "application/json",
                }
              });
            if (capturePayment?.success) {
              const modalElement = document.querySelector('.razorpay-container');
              if (modalElement) {
                modalElement.style.display = 'none';
              }

            }
            window.location.href = '/payment-failure';
          }

        });

        rzp1.open();
      }
      // setApiOrder(order); 
    } catch (error) {
      console.log("error:", error);
    }


    // console.log(process.env.REACT_APP_RAZORPAY_KEY,"payment key")
  };

  // ----------code for payment ends------------------------------

  //---------------------------------------------------------

  const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
  useEffect(() => {
    const btn = document.getElementById("btnSubmit");
    if (!isFormFilled) {
      btn.style.opacity = 0.4
    } else {
      btn.style.opacity = 1
    }
  }, [isFormFilled])

  const handleScroll = () => {
    const rightForm = document.querySelector(".rightForm");
    // console.log(doc, "document")
    if (rightForm) {
      rightForm.scrollIntoView({
        behavior: "smooth"
      })
    }
    setTimeout(() => {
      rightForm.classList.remove("pulseAnim");
      rightForm.classList.remove("pulseShadow");
    }, 4000)
    rightForm.classList.add("pulseAnim");
    rightForm.classList.add("pulseShadow");
  }

  const [isChecked, setIsChecked] = useState(true); // Set to true to make it checked by default

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };

  return (
    <>
      <section className="heroSection position-relative healthHeroPage">
        <img src={HeroBGMobileImg} className="d-md-none d-block w-100" alt="#" loading="lazy" />
        <img src={HeroBGTabImg} className="d-none d-md-block d-lg-none w-100" alt="#" loading="lazy" />
        <img src={HeroBGImg} className="d-none d-lg-block w-100 bannerLg" alt="#" loading="lazy" />
        <div className="carousel-caption">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center totalContArea">
              <div className="heroCont">
                <h1 class="h1 fw700 poppins colorYellow d-lg-none">
                  সিলভা মেথড হেলথ ম্যানেজমেন্ট মাস্টারক্লাস মাত্র ৯৯ টাকায়
                  <p class="title fw400 subTitle mb-0 primaryWhite mt-3">
                    আপনার অভ্যন্তরীণ চিকিৎসা শক্তির উন্মোচন করুন
                  </p>
                </h1>
                <h1 class="h1 fw700 poppins primaryWhite d-none d-lg-block">
                  সিলভা মেথড হেলথ ম্যানেজমেন্ট মাস্টারক্লাস মাত্র ৯৯ টাকায়
                  <p class="title fw400 subTitle mb-0 primaryWhite mt-3">
                    আপনার অভ্যন্তরীণ চিকিৎসা শক্তির উন্মোচন করুন
                  </p>
                </h1>
                <div className="classTiming">
                  <ul className='resetUl'>
                    <li class="d-flex align-items-start gap-2 mb-3">
                      {/* <img src="/static/media/check-dark.7a01ddc7f7b6b932418881c939b7606e.svg" alt="#" loading="lazy" className='circleTick' /> */}
                      <span><i class="fa-solid fa-calendar-days"></i></span>
                      <p class="primaryWhite roboto mb-0">২২ শে জুন</p>
                    </li>
                    <li class="d-flex align-items-start gap-2 mb-3">
                      <span><i class="fa-regular fa-clock"></i></span>
                      <p class="primaryWhite roboto mb-0">
                        সকাল ১০ টা থেকে দুপুর ১২ টা ( ভারতীয় সময়)
                      </p>
                    </li>
                    <li class="d-flex align-items-start gap-2 mb-0">
                      <span><i class="fa-regular fa-clock"></i></span>
                      <p class="primaryWhite roboto mb-0">
                        সন্ধ্যে ৬ঃ৩০ টা থেকে রাত  ৮:৩০ টা ( ভারতীয় সময়)
                      </p>
                    </li>
                  </ul>
                </div>
                <p className="fw4300 roboto mt-4 mb-4">
                  দীর্ঘদিন ধরে আপনি কি শারীরিক বা মানসিক অসুস্থতায় ভুগছেন? আপনার উপার্জিত অর্থের অনেকটায়
                  কি ওষুধের জন্য ব্যয় হয়ে যাচ্ছে তবুও আপনি সুস্থ ও স্বাভাবিক জীবন যাপন করতে পারছেন না? আপনার শারীরিক ও মানসিক সুস্থতার সহজ উপায় হল - সিলভা মেথড।
                </p>
                <div className="text-center text-lg-start">
                  <Link to="masterClassForm_health" smooth={true} duration={500}>
                    <a href="#" class="btnMain primaryBlack primaryYellow text-capitalize shapeTrape"  onClick={() => handleScroll()}>
                      এখনি রেজিস্টার করুন
                    </a>
                  </Link>
                </div>
              </div>
              <div class="instructorVideo">
                {/* <img src={sickman} alt="#" loading="lazy" class="w100 h100 agendaImg" /> */}
                <ReactPlayer
                  url={InstructorVideo}
                  light="assets/images/rahul/video-thumb.webp"
                  width="100%"
                  height="100%"
                  playing
                  muted
                  loop
                  controls={true}
                // onPlay={() =>
                //     setPlaying(true)
                // }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HealthHero