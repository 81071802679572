import React, { useEffect, useState } from 'react'
import Navbar2 from '../components/Navbar2'
import NewLandingBanner from '../components/NewLandingBanner'
import RegisterForm from '../components/RegisterForm'
import PracticeMeditation14 from '../components/PracticeMeditation14'
import AboutInstructor from '../components/AboutInstructor'
import SilvaFounder from '../components/SilvaFounder'
import SliderCardTestimonial from '../components/SliderCardTestimonial'
import DynamicFaqs from '../components/DynamicFaqs'

import { validatePhoneNumber } from '../utils/Helper'
import { handleLeadFormSubmit, handlePaymentNew } from '../utils/PaymentHelper'
import { handleRazorPayPayment } from '../utils/RazorPayHelper'
import { handleScroll } from '../utils/animPulse'

import toast from 'react-hot-toast'
import useRazorpay from 'react-razorpay'
import { Link } from 'react-scroll'
import ReactPlayer from 'react-player'

import checkYellow from '../assets/check-bg-yellow.svg'
import rzrLogo from '../assets/RAZORPAY-ICON.webp'
import CheckImg from "../assets/check-bg.svg"
// import facebook from "../assets/facebook-official.svg"
// import youtube from "../assets/youtube-official.svg"
// import instagram from "../assets/instagram-official.svg"
import instructor1 from '../assets/Debasish-Chatterjee.webp'
import CurriculumAccordion from '../components/CurriculumAccordion'

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { HiOutlineArrowRight } from "react-icons/hi"
import { AiFillLock } from "react-icons/ai"
import { Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const introVideo = "https://www.youtube.com/watch?v=vt8E9JK0ujo"
// const introVideo = "https://silva-method-official.b-cdn.net/lions-gate-silva-india/Lions%20Gate%20Portal%20Day-1920x1080.mp4"


const StressManagement = () => {

    const [formData, setFormData] = useState({
        name: '',
        whatsapp_number: '',
        email: '',
    });


    const [isChecked, setIsChecked] = useState(true)


    const [Razorpay] = useRazorpay();


    const navigate = useNavigate()


    const handleChangeCheck = () => {
        setIsChecked(!isChecked)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const formSubmit = async (e) => {
        e.preventDefault();


        const isValid = validatePhoneNumber(formData.whatsapp_number)
        if (!isValid) {
            return toast.error("Please enter a valid Phone Number")
        }


        const res = await handleLeadFormSubmit("leads/submit-blslead", "POST", formData, {})
        console.log(res);
        if (res.success) {
            const paymentRes = await handlePaymentNew("Health_99_", formData, "course");
            if (paymentRes?.order_id) {
                await handleRazorPayPayment(formData, paymentRes?.order_id, setFormData, "/thank-you-page", Razorpay)
            } else {
                toast.error(res.message)
            }
        }
    }


    const isFormFilled = Object.values(formData).every((value) => value.trim() !== '');
    useEffect(() => {
        const btn = document.getElementById("btnSubmit");
        if (!isFormFilled) {
            btn.style.opacity = 0.4
            btn.style.pointerEvents = "none"
        } else {
            btn.style.opacity = 1
            btn.style.pointerEvents = "unset"
        }
    }, [isFormFilled])





    const accordionData = [
        {
            curriculumTitle: "Basics of Stress",
            curriculumPoints: [
                { id: 1, text: "What is stress of common people?", prevw: false },
                { id: 2, text: "Bad effects of Stress", prevw: true },
                { id: 3, text: "Why Stress is involved in Human body?", prevw: false },
                { id: 4, text: "Brain Evolution chart", prevw: false },
                { id: 5, text: "How to enter at Alpha level", prevw: false },
                { id: 6, text: "Exercise", prevw: false }
            ]
        },
        {
            curriculumTitle: "Limiting Belief and Mental House Cleaning",
            curriculumPoints: [
                { id: 7, text: "What is Limiting Belief?", prevw: false },
                { id: 8, text: "How anyone can get rid of Limiting Belief?", prevw: false },
                { id: 9, text: "Mental House Cleaning and its importance", prevw: false },
                { id: 10, text: "Exercise and Affirmations", prevw: false }
            ]
        },
        {
            curriculumTitle: "Detail discussion on Stress",
            curriculumPoints: [
                { id: 11, text: "Good and Bad Stress for human body", prevw: false },
                { id: 12, text: "How Stress can have long term effect on our Brain", prevw: false },
                { id: 13, text: "Fight or Flight", prevw: false },
                { id: 14, text: "Reaction & Response of Stress", prevw: false },
                { id: 15, text: "Exercise", prevw: false }
            ]
        },
        {
            curriculumTitle: "Detection of Stress",
            curriculumPoints: [
                { id: 16, text: "Triggering point of Stress", prevw: false },
                { id: 17, text: "Transformation of Stress", prevw: false },
                { id: 18, text: "Relief from Stress", prevw: false },
                { id: 19, text: "Complete eradication of Stress from your life", prevw: false },
                { id: 20, text: "Exercise based on the Silva Mind Control Method", prevw: false }
            ]
        },
        {
            curriculumTitle: "Stress Free Life Conclusion",
            curriculumPoints: []
        }
    ];


    const bonusPoints = [
        {
            icon: CheckImg,
            desc: `Gain access to comprehensive practice guidelines.`,
        },
        {
            icon: CheckImg,
            desc: `Enjoy unlimited lifetime access to course materials and updates.`,
        },
        {
            icon: CheckImg,
            desc: `Participate in interactive monthly Q&A sessions where you can ask questions and get personalized advice directly from our expert instructor.`,
        },
        {
            icon: CheckImg,
            desc: `Join our vibrant, interactive community where you can connect with fellow learners, and share insights throughout your learning journey.`,
            marginHide: true
        },
    ]


    const aboutInstuctor = [
        {
            id: 1,
            instructorimg: instructor1,
            instructortitle: 'Debasish-Chatterjee',
            instructordesc1: `Hello, I’m Debasish Chatterjee, a certified Silva Method instructor with 
            nearly 40 years of experience as a senior accountant, seamlessly blending financial expertise 
            with spiritual insight. My journey, marked by over five decades of dedicated spiritual practice, 
            shapes my deep understanding and approach. My mission is to empower students, elevating both financial 
            skills and spirituality. I am here to help you discover your inner brilliance and achieve your goals. 
            As a life transformation coach, I guide individuals in finding positive energy in their lives. Through 
            the Silva Method, I aim to shape your true reality and help you take control of your destiny while uncovering 
            your inner potential.`,
        }
    ]


    const choosePoints = [
        {
            icon: CheckImg,
            desc: `Manage the causes and effects of stress in everyday life with stress management techniques.`,
        },
        {
            icon: CheckImg,
            desc: `Gain insights into how stress affects the human body and brain.`,
        },
        {
            icon: CheckImg,
            desc: `Discover methods for entering the Alpha level of relaxation and practical exercises to manage stress.`,
        },
        {
            icon: CheckImg,
            desc: `Identify and overcome limiting beliefs through mental house cleaning techniques, affirmations, and exercises.`,
        },
        {
            icon: CheckImg,
            desc: `Learn how to identify stress triggers and achieve complete stress relief.`,
        },
        {
            icon: CheckImg,
            desc: `Get to know about the course’s comprehensive strategies for a more balanced, fulfilling, and stress-free lifestyle.`,
        },
        {
            icon: CheckImg,
            desc: `Join our vibrant, interactive community where you can connect with fellow learners, and share insights throughout your learning journey.`,
            marginHide: true
        },
    ]


    const testimonialCont = [
        {
            image: 'assets/images/rahul/testimonial-1.webp',
            name: 'Sanjay Dutt',
            designation: 'Actor',
            text: 'I learned the art of Silva meditation and it helps in positive thinking',
        },
        {
            image: 'assets/images/rahul/testimonial-2.webp',
            name: 'Amrish Puri',
            designation: 'Actor',
            text: 'I learned a lot about meditation in Silva Classes',
        },
        {
            image: 'assets/images/rahul/testimonial-3.webp',
            name: 'Kishore Namit Kapoor',
            designation: 'Acting Teacher, Actor and Author',
            text: 'Silva can help bring direction and purpose to your life',
        },
        {
            image: 'assets/images/rahul/testimonial-4.webp',
            name: 'Naveen Jindal',
            designation: 'Chairman Jindal Steel, Politician and MP',
            text: 'Silva can help in every area of life',
        },
        {
            image: 'assets/images/rahul/testimonial-5.webp',
            name: 'Chitra Singh',
            designation: 'Ghazal Singer',
            text: 'Silva is a great program',
        },
        {
            image: 'assets/images/rahul/testimonial-6.webp',
            name: 'Yashovardhan Birla',
            designation: 'Director, Yash Birla Group',
            text: 'With Silva you can enhance and maintain your health and fitness',
        },
        {
            image: 'assets/images/rahul/testimonial-7.webp',
            name: 'Tanuja Mukherjee Samarth',
            designation: 'Actress',
            text: 'Silva is very helpful for attaining mental relaxation',
        },
        {
            image: 'assets/images/rahul/testimonial-8.webp',
            name: 'Debraj Chatterjee',
            designation: 'Working Professional',
            text: 'Silva Techniques are amazing for stress relief and managing negative thoughts',
        },
        {
            image: 'assets/images/rahul/testimonial-9.webp',
            name: 'Sneha Panda',
            designation: 'IT Professional',
            text: 'I have been practicing for a year. It really helped in my work by enhancing creativity',
        },
        {
            image: 'assets/images/rahul/testimonial-10.webp',
            name: 'Monideepa Das',
            designation: 'Graphics Designer',
            text: 'Silva has been transformative in helping me stay focused and productive at work.',
        },
        {
            image: 'assets/images/rahul/testimonial-11.webp',
            name: 'Prerna Roy',
            designation: 'Budding Singer',
            text: 'As a budding singer, Silva has been instrumental in boosting my confidence and performance.',
        },
    ]


    const faqData = [
        {
            id: 1,
            title: `1. What topics are covered in the course?`,
            text: `Ans. The course covers a wide range of topics related to stress management, 
            including the basics of stress, limiting beliefs, detailed discussion on stress, detection 
            of stress, and achieving a stress-free life.`
        },
        {
            id: 2,
            title: `2. Will I learn about the Silva Mind Control Method?`,
            text: `Ans. Yes, the course incorporates exercises from the Silva Mind Control Method to help 
            you manage stress and achieve a more relaxed state of mind.`
        },
        {
            id: 3,
            title: `3. What are the benefits of taking this course?`,
            text: `Ans. By taking our course, you can learn effective stress management techniques, improve 
            your mental and physical health, enhance your focus and productivity, and achieve a greater sense 
            of peace and well-being.`
        },
        {
            id: 4,
            title: `4. Will the course help me overcome specific stress triggers?`,
            text: `Ans. Yes, the course will provide you with tools and strategies to identify and manage your specific stress triggers.`
        },
    ]


    return (
        <div style={{ backgroundImage: "url('/assets/images/rahul/line-bg.jpg')", backgroundSize: "contain", backgroundPosition: "center" }}>
            <Navbar2 />
            <NewLandingBanner
                customClassName="stressManagement"
                bannerMobileImg="/assets/images/rahul/courses-header-for-mobile.webp"
                bannerTabImg="/assets/images/rahul/courses-header-for-tab.webp"
                bannerWebImg="/assets/images/rahul/stress-management-program-web.webp"
                bannerContent={
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="heroCont">
                                <h1 className="h1 fw700 primaryWhite poppins mb-3">
                                    Silva Method Stress Management Program
                                </h1>
                                <p className="mdFont fw400 primaryWhite roboto">
                                    Stress-Free Living Start Here!
                                </p>
                                <p className="desc fw600 primaryWhite roboto mb-2">
                                    Learn Techniques to:
                                </p>
                                <ul className='resetUl'>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={checkYellow} alt="#" loading="lazy" className="circleTick" />
                                        <p className="fw400 roboto mb-0 pt-1 primaryWhite">Stop Negative Thinking</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={checkYellow} alt="#" loading="lazy" className="circleTick" />
                                        <p className="fw400 roboto mb-0 pt-1 primaryWhite">Limiting Belief &</p>
                                    </li>
                                    <li className="d-flex align-items-center gap-2 mb-2">
                                        <img src={checkYellow} alt="#" loading="lazy" className="circleTick" />
                                        <p className="fw400 roboto mb-0 pt-1 primaryWhite">Manage Stress</p>
                                    </li>
                                </ul>
                                <h3 className="mdFont fw600 colorYellow roboto mt-4">
                                    Lifetime Doubt Clearance!
                                </h3>
                                <Link to="registerForm" smooth={true} duration={500}>
                                    <button type="button" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-3 mt-lg-4 border-0" onClick={() => handleScroll(".registerForm")}>
                                        Enroll Now
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
            />
            <RegisterForm
                lightBg=""
                contentArea={
                    <div className="row row-gap-4">
                        <div className="col-lg-5">
                            <div className="registerForm">
                                <div className="formThumb">
                                    <img src="/assets/images/rahul/banner-stress-program-form.webp" alt="#" className='w100' />
                                    <div className="div1 d-flex flex-wrap justify-content-between">
                                        <span className="primaryWhite fw600 roboto">15th September</span>
                                        <span className="primaryWhite fw400 roboto">
                                            7:30 PM (IST)
                                            <div className='d-inline-block mx-2'>|</div>
                                            7:30 PM (BDT)
                                        </span>
                                    </div>
                                </div>
                                <form onSubmit={formSubmit}>
                                    <div className="d-flex align-items-center flex-wrap column-gap-4 row-gap-1 mt-3 mb-4 mb-md-3">
                                        <p className="p1 fw900 roboto textDark d-flex align-items-center justify-content-center mb-0">
                                            <span className="me-1">₹</span><span className="roboto">499/-</span>
                                        </p>
                                        <p className="p2 fw900 oldPrice mb-0">
                                            <span className="me-1">₹</span><span className="roboto">749/-</span>
                                        </p>
                                        <p className="fw600 roboto textDark mb-0 poppins">You will get 50% Off</p>
                                    </div>
                                    <div className="row gy-3">
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userName" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <input type="text" className="form-control" id="userPhn" name="whatsapp_number" placeholder="Mobile Number" value={formData.whatsapp_number} onChange={handleChange} />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control" id="userEmail" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap my-4 paymentWidget">
                                        <img src="/assets/images/rahul/check-green-bg.png" alt="#" loading='lazy' className='checkIcon' />
                                        <span className="fw600 textDark poppins ms-2 me-3">Pay securely via</span>
                                        <img src={rzrLogo} alt="#" loading="lazy" className='payIcon' />
                                    </div>
                                    <ul className="resetUl">
                                        <li className="d-flex align-items-start gap-2 mb-2">
                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    <input type="checkbox" className="form-check-input" id="flexCheckChecked" checked={isChecked} onChange={handleChangeCheck} style={{ cursor: "pointer" }} />
                                                    <p className="textDark roboto mb-0 smFont">I accept your
                                                        <a className="fw700 textDark" href="terms-&-conditions"> Terms &amp; Conditions </a>
                                                        and willingly agree to receive Marketing emails.
                                                    </p>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="text-center">
                                        <button type="submit" id="btnSubmit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-3 ms-0 border-0" style={{ width: "94%" }}>Register Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-7 position-relative">
                            <div className="formAdjacentArea">
                                <div className="contentArea">
                                    {/* <img src="/assets/images/rahul/masterclass-bengali-header.webp" alt="#" className='w100 h100' style={{ objectFit: "cover" }} /> */}
                                    <ReactPlayer
                                        url={introVideo}
                                        // light="assets/images/rahul/eng-money-magnet-vid-banner.webp"
                                        width="100%"
                                        height="100%"
                                        controls={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            <CurriculumAccordion
                lightBg="lightBg"
                ptop="ptop"
                pbtm="pbtm"
                accordionCont={
                    <div className="row align-items-center gy-4">
                        <div className="col-lg-7">
                            <Accordion defaultActiveKey={"0"}>
                                {
                                    accordionData?.map((item, id) =>
                                        <Accordion.Item eventKey={id.toString()}>
                                            <Accordion.Header>{item.curriculumTitle}</Accordion.Header>
                                            <Accordion.Body>
                                                <>
                                                    {
                                                        item && item.curriculumPoints.map((val, idx) => (
                                                            <div className={`d-flex justify-content-between py-3`}
                                                                style={{ borderBottom: "1px solid gray" }}>

                                                                <div className='d-flex justify-content-start align-items-center gap-3'>
                                                                    <p className='fw600 poppins m-0'>{val.text}</p>
                                                                </div>
                                                                {
                                                                    (val?.prevw) ? (
                                                                        <div className={`d-flex justify-content-center align-items-center gap-4`}>
                                                                            <Button className='p-0 border-0 bg-transparent' onClick={() => navigate("/pre-record-masterclass")}>
                                                                                <Badge className='badgenew' bg="light" style={{ color: "black" }}>
                                                                                    {
                                                                                        val.prevw ? "View" : "Preview"
                                                                                    }
                                                                                    <HiOutlineArrowRight size={20} color='#000' style={{ marginLeft: "10px" }} />
                                                                                </Badge>
                                                                            </Button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='d-flex justify-content-center align-items-center'>
                                                                            <AiFillLock size={30} color='#000' />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                }
                            </Accordion>
                        </div>
                        <div className="col-lg-5">
                            <div className="videoArea ps-lg-5">
                                <img src="/assets/images/rahul/masterclass-bengali-header-tab.webp" alt="#" loading="lazy" className="w100 h-auto agendaImg border-0" />
                            </div>
                        </div>
                    </div>
                }
            />
            <PracticeMeditation14
                lightBg=""
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6">
                            <div className="videoArea pe-lg-5">
                                <img src="/assets/images/rahul/stress-program-bonus.webp" alt="#" loading="lazy" className="w100 h100 agendaImg border-0" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-3">
                                Don’t Forget to Claim Your <span className="gradientText">Special Bonus!</span>
                            </h2>
                            <p className="textDark poppins mb-4">
                                Enroll to the Silva Method Stress Management Program worth Rs.10,000, enjoy a special bonus at no extra cost!
                            </p>
                            <ul className='resetUl'>
                                {
                                    bonusPoints?.map((items, idx) =>
                                        <li className={`d-flex gap-2 ${items.marginHide === true ? "" : "mb-3"}`}>
                                            <img src={items.icon} alt="#" loading="lazy" className="circleTick" />
                                            <p className="textDark fw500 roboto mb-0 text-start">{items.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                            <Link to="registerForm" smooth={true} duration={500}>
                                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={() => handleScroll(".registerForm")}>
                                    Register Now
                                </button>
                            </Link>
                        </div>
                    </div>
                }
            />
            <AboutInstructor
                lightBg="lightBg"
                customStyle="portalDayPage"
                contentArea={
                    <>
                        <h2 class="h2 fw700 poppins textDark mb-4 mb-lg-5 text-lg-center">
                            About Your <span class="gradientText">Instructor</span>
                        </h2>
                        <div className="healthInstructorCard">
                            {
                                aboutInstuctor.map((item, idx) =>
                                    <div className="cardItem">
                                        <div class="leftSide">
                                            <img src={item.instructorimg} alt="#" loading="lazy" class="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 class="mdFont textDark fw700 poppins mb-2">{item.instructortitle}</h3>
                                            <p class="fw600 poppins textDark text-capitalize mb-2">Certified Silva Method Instructor</p>
                                            {/* <div class="socialIcons d-flex gap-1 mb-4">
                                                <a href="https://www.facebook.com/debalina.silvamethod.instructor">
                                                    <img src={facebook} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                                <a href="https://www.youtube.com/@SilvaMethodIndiaOfficial">
                                                    <img src={youtube} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                                <a href="https://www.instagram.com/silva.instructor.debalina/">
                                                    <img src={instagram} alt="#" loading="lazy" class="w100 h100" />
                                                </a>
                                            </div> */}
                                            <p class="desc textDark fw400 poppins">{item.instructordesc1}</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                }
            />
            <PracticeMeditation14
                // lightBg="lightBg"
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-4">
                                Why to Choose <span className="gradientText">This Course?</span>
                            </h2>
                            <ul className='resetUl'>
                                {
                                    choosePoints?.map((items, idx) =>
                                        <li className={`d-flex gap-2 ${items.marginHide === true ? "" : "mb-3"}`}>
                                            <img src={items.icon} alt="#" loading="lazy" className="circleTick" />
                                            <p className="textDark fw500 roboto mb-0 text-start">{items.desc}</p>
                                        </li>
                                    )
                                }
                            </ul>
                            <Link to="registerForm" smooth={true} duration={500}>
                                <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape mt-4 border-0 shadowLight" onClick={() => handleScroll(".registerForm")}>
                                    Register Now
                                </button>
                            </Link>
                        </div>
                        <div className="col-lg-6">
                            <div className="videoArea ps-lg-5">
                                <img src="/assets/images/rahul/stress-program-choose-course.webp" alt="#" loading="lazy" className="w100 h100 agendaImg border-0" />
                            </div>
                        </div>
                    </div>
                }
            />
            <SilvaFounder
                sectionBg="/assets/images/rahul/texture-bg.webp"
                heading={
                    <h2 class="h2 fw700 poppins primaryWhite mb-4 text-center">
                        What is <span className='colorYellow'>Silva Method?</span>
                    </h2>
                }
                textCont={
                    <>
                        <p className="p1 fw300 primaryWhite poppins mb-0 m-auto text-center">
                            The Silva Method – Scientifically Proven Mind Control Techniques. Developed in the 1960s
                            by José Silva, this is a self-help meditation method. It is a personal development approach
                            that integrates meditation, visualization, and positive thinking to enhance mental capabilities
                            and achieve personal goals. Participants are guided through practices to reach a comfortable
                            meditative state known as the Alpha level, where they become deeply introspective. This profound
                            introspection ignites inner power, transforming all aspects of life. Continuously, they learn to
                            control their subconscious mind for problem-solving and self-improvement. The method aims to cultivate
                            a positive mindset, improve focus, and empower individuals to manifest desired outcomes in various aspects
                            of their lives.
                        </p>
                    </>
                }
                imgCont={
                    <div class="videoArea m-auto mt-5">
                        <img src="/assets/images/rahul/star-lion-copy.webp" alt="#" loading="lazy" class="w100 h100 challengeImg" />
                    </div>
                }
            />
            <SliderCardTestimonial
                heading={
                    <h2 class="h2 fw700 poppins textDark text-center mb-4">
                        Hear From Our <span class="gradientText">Silva Enthusiasts</span>
                    </h2>
                }
                data={testimonialCont}
            />
            <PracticeMeditation14
                contentArea={
                    <div className="row align-items-center position-relative row-gap-4">
                        <div className="col-lg-6">
                            <div className="videoArea pe-lg-5">
                                <img src="/assets/images/rahul/Numerological-Importance.webp" alt="#" loading="lazy" className="w100 h100 agendaImg border-0" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="h2 fw700 poppins textDark mb-3 text-center text-lg-start">
                                The Blueprint for Turning <span className="gradientText">Stress into Success</span>
                            </h2>
                            <p className="textDark poppins mb-3 text-center text-lg-start">
                                Get access to the Silva Method Stress Management Program and begin your journey to live
                                stress-free & gain clarity with proven and effective Silva Method Technique.
                            </p>
                            <div className="text-center text-lg-start">
                                <Link to="registerForm" smooth={true} duration={500}>
                                    <button type="submit" className="btnMain fw700 primaryYellow primaryBlack text-capitalize shapeTrape border-0 shadowLight" onClick={() => handleScroll(".registerForm")}>
                                        Register Now
                                    </button>
                                </Link>
                            </div>
                            {/* <div className='blueprintDateTime mt-4 d-flex align-items-center justify-content-center justify-content-lg-start row-gap-3 column-gap-5 flex-wrap'>
                                <div className='d-flex align-items-center gap-2'>
                                    <span><i class="fa-solid fa-calendar-days mdFont"></i></span>
                                    <p className='title fw700 poppins textDark m-0'>24th August</p>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <span><i class="fa-regular fa-clock mdFont"></i></span>
                                    <p className='title fw700 poppins textDark m-0'>10AM (IST)</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                }
            />
            <DynamicFaqs
                title='Frequently Asked Questions'
                fontStyle=""
                defaultactive={0}
                data={faqData}
            />
        </div>
    )
}

export default StressManagement