import React from 'react'
import './RecordClassContStyle.css'
import ReactPlayer from 'react-player'


const RecordClassCont = ({ maintitle, vidUrl, recordVidCont }) => {
    return (
        <section className='container'>
            <h2 className="h2 fw700 poppins primaryWhite mb-4 mb-lg-4 text-center recordHead">
                {maintitle}
            </h2>
            <div className="recordVid">
                <ReactPlayer
                    url={vidUrl}
                    width="100%"
                    height="100%"
                    loop
                    controls={true}
                />
            </div>
            {
                recordVidCont &&
                <div className='recordVidCont'>
                    {recordVidCont}
                </div>
            }
        </section>
    )
}

export default RecordClassCont