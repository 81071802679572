import React from 'react'
import "../../../components/CourseScheduleStyle.css";
// import DebayanImg from "../../../assets/Debayan-Sen.webp"
import ZoomImg from "../../../assets/zoom.webp"
import DebasishImg from "../../../assets/Debasish-Chatterjee.webp"
import KalamImg from '../../../assets/dr-kalam.webp';
import DebayanImg from "../../../assets/Debayan-Sen.webp"


const BengaliSlsCouseSchedule = () => {

    const cardPoints = [
        {
            pointsNum: '1',
            points: 'ইন্ট্রো এবং শর্ট রিলাক্সেশন'
        },
        {
            pointsNum: '2',
            points: 'লং রিলাক্সেশন (এক্সপ্লেইনেশন এবং এক্সারসাইজ)'
        },
        {
            pointsNum: '3',
            points: 'স্লীপ কন্ট্রোল'
        },
        {
            pointsNum: '4',
            points: 'অ্যাওয়েক কন্ট্রোল'
        },
        {
            pointsNum: '5',
            points: 'অ্যালার্ম ক্লক'
        },
    ]

    const cardPoints2 = [
        {
            pointsNum: '1',
            points: 'ড্রিম কন্ট্রোল'
        },
        {
            pointsNum: '2',
            points: 'হেডএক কন্ট্রোল'
        },
        {
            pointsNum: '3',
            points: 'চেঞ্জিং লিমিটিং বিলিফ এক্সারসাইজ'
        },
        {
            pointsNum: '4',
            points: 'প্রশ্ন - উত্তর সেশন'
        },
    ]

    const cardPoints3 = [
        {
            pointsNum: '1',
            points: 'আগের সেশনের পর্যালোচনা'
        },
        {
            pointsNum: '2',
            points: 'মেন্টাল স্ক্রিন এবং মেমরি পেগস  (এক্সপ্লেইনেশন এবং এক্সারসাইজ)'
        },
        {
            pointsNum: '3',
            points: 'থ্রী ফিঙ্গারস টেকনিক   (এক্সপ্লেইনেশন এবং এক্সারসাইজ))'
        },
        {
            pointsNum: '4',
            points: 'মিরর অফ দ্য মাইন্ড   (এক্সপ্লেইনেশন এবং এক্সারসাইজ)'
        },
    ]

    const cardPoints4 = [
        {
            pointsNum: '1',
            points: 'হ্যান্ড লেভিটেশন এবং গ্লোভ এনেস্থেসিয়া'
        },
        {
            pointsNum: '2',
            points: 'গ্লাস অফ ওয়াটার'
        },
        {
            pointsNum: '3',
            points: 'হ্যাবিট কন্ট্রোল'
        },
        {
            pointsNum: '4',
            points: 'প্রশ্ন - উত্তর সেশন'
        },
    ]



    return (
        <div>
            <section className="courseScheduleSection">
                <div className="container">

                    <div className="text-center mb-5">
                        <h2 className="h2 fw700 poppins textDark text-center mb-2">
                            কোর্স <span className="gradientText">সিডিউল</span>
                        </h2>
                        <p className="title fw600 poppins textDark">
                            আমাদের প্রোগ্রামের বিস্তারিত পরিকল্পনা জেনে নিন
                        </p>
                    </div>

                    <div className="tabsCont text-center">

                        <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist" style={{ maxWidth: "602px" }}>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-one-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one"
                                    aria-selected="true">
                                    <div className="tabBtn d-flex gap-3 align-items-center">
                                        <div className="h3 leftSide roboto fw700 textDark">১ম</div>
                                        <div className="rightSide">
                                            <h3 className="title fw600 roboto textDark mb-0">
                                                দিন
                                            </h3>
                                            {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                        </div>
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-two-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two"
                                    aria-selected="false">
                                    <div className="tabBtn d-flex gap-3 align-items-center">
                                        <div className="h3 leftSide roboto fw700 textDark">২য়</div>
                                        <div className="rightSide text-start">
                                            <h3 className="title fw600 roboto textDark mb-0">
                                                দিন
                                            </h3>
                                            {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                        </div>
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-three-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three"
                                    aria-selected="false">
                                    <div className="tabBtn d-flex gap-3 align-items-center">
                                        <div className="h3 leftSide roboto fw700 textDark">৩য়</div>
                                        <div className="rightSide text-start">
                                            <h3 className="title fw600 roboto textDark mb-0">
                                                দিন
                                            </h3>
                                            {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                        </div>
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-four-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four"
                                    aria-selected="false">
                                    <div className="tabBtn d-flex gap-3 align-items-center">
                                        <div className="h3 leftSide roboto fw700 textDark">৪র্থ</div>
                                        <div className="rightSide text-start">
                                            <h3 className="title fw600 roboto textDark mb-0">
                                                দিন
                                            </h3>
                                            {/* <p className="p1 textDark roboto mb-0">2 days</p> */}
                                        </div>
                                    </div>
                                </button>
                            </li>
                        </ul>

                        <div className="tab-content" id="pills-tabContent">
                            {/* Content for different tabs goes here */}
                            <div className="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab" tabIndex="0">
                                <div className="instructorCard">
                                    <div className="cardItem text-start justify-content-around align-items-center">
                                        <div className="leftSide">
                                            <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                প্রথম দিনের বিষয়বস্তু
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">ইন্সট্রাক্টর-</span>দেবাশীষ চ্যাটার্জ্জী
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">

                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab" tabIndex="0">
                                <div className="instructorCard">
                                    <div className="cardItem text-start justify-content-around align-items-center">
                                        <div className="leftSide">
                                            <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                দ্বিতীয় দিনের বিষয়বস্তু
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">ইন্সট্রাক্টর-</span> দেবায়ন সেন
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints2?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">

                                                {/* <div className="rightCont"> */}
                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab" tabIndex="0">
                                <div className="instructorCard">
                                    <div className="cardItem text-start justify-content-around align-items-center">
                                        <div className="leftSide">
                                            <img src={DebasishImg} alt="#" loading="lazy" className="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                তৃতীয় দিনের বিষয়বস্তু
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">ইন্সট্রাক্টর-</span>দেবাশীষ চ্যাটার্জ্জী
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints3?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">

                                                {/* <div className="rightCont"> */}
                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab" tabIndex="0">
                                <div className="instructorCard">
                                    <div className="cardItem text-start justify-content-around align-items-center">
                                        <div className="leftSide">
                                            <img src={DebayanImg} alt="#" loading="lazy" className="w100 h100" />
                                        </div>
                                        <div className="rightSide">
                                            <h3 className="fw700 h3 poppins textDark mb-3">
                                                চতুর্থ দিনের বিষয়বস্তু
                                            </h3>
                                            <p className="roboto textDark shapeRect lightTrape mb-4">
                                                <span className="fw600">ইন্সট্রাক্টর-</span> দেবায়ন সেন
                                            </p>
                                            <ul className='resetUl'>
                                                {
                                                    cardPoints4?.map((items, idx) =>
                                                        <li className='d-flex align-items-center gap-2 mb-2'>
                                                            <span className='fw500 pointsNum'>{items.pointsNum}</span>
                                                            <p className='textDark mb-0'>{items.points}</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                            <div className="eventArea d-flex align-items-center gap-4 mt-5 pb-4">

                                                {/* <div className="rightCont"> */}
                                                <p className="desc textDark roboto mb-0">
                                                    Online class via
                                                </p>
                                                <img src={ZoomImg} alt="#" loading="lazy" />
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default BengaliSlsCouseSchedule